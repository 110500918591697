import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";

const AppInfoGridPriceRow = ({
  icon,
  model,
  years,
  feature,
  last,
  features = [],
  user,
  prices = [],
  vehicleType = 'cars'
}) => {
  const [hovered, setHovered] = useState(false);
  const [version, setVersion] = useState({ ...model, prices: [] });
  const [showPrices, setShowPrices] = useState(prices.length);
  const [listPrice, setListPrice] = useState()
  const dispatch = useDispatch();
  const [showInfoCreditsAlertModal, setShowInfoCreditsAlertModal] =
    useState(false);
  const firstCell = useRef()

 
  const handlePin = () => {
    const message = {
      type: 'pin',
      payload: model
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(message))
  }


  useEffect(() => {
    
    if (user?.codiasConsultados.some((v) => v.codia === parseInt(model.codia))) {
      getPrices();
    }

    if (model.list_price > 0) {
      setListPrice(model.list_price);
    }

  }, [model]);



  const getPrices = async () => {
    
    const message = {
      type: 'price',
      payload: model
    }


    !prices.length && window.ReactNativeWebView.postMessage(JSON.stringify(message))
  };


  return (
    <tr
      className={`${prices.length && "viewed"} ${last && "pinned_row"
        }`}
    >
      <td
        ref={firstCell}
        className={`text-center infogrid-row link`}
        onClick={() => handlePin(version)}
      >
        <FontAwesomeIcon icon={icon} />
      </td>
      <td
        className={`pl-2 py-2 pr-5`}
      >
        {user.muestraCodia ? (
          <small className="">
            <b>{model.codia} </b>
          </small>
        ) : (
          ""
        )}
        <small>{model.description}</small>
      </td>
      {feature === 'prices' && <td
        onClick={() => getPrices()}
        className={`see_price link text-center`}
      >
        <small className={"text-uppercase"}>
          <strong>{prices.length ? "Visto" : "Ver"}</strong>{" "}
          <FontAwesomeIcon
            className={"infopro_svg"}
            icon={hovered && !prices.length ? faSearch : faChevronRight}
          />
        </small>
      </td>}
      {feature === "prices" &&
        years.map((year) => {
          const existingPrice = model.years.includes(year);

          const price = prices.find((p) => p.year === year);

          if (year === "0km" && listPrice) {
            return (
              <td
                key={year}
                className='text-center'
              >
                <span>{listPrice}</span>
              </td>
            )
          }
          return (
            <td
              key={year}
              className={`price_cell ${existingPrice && "filled_cell"} text-center`}
            >
              <span>{existingPrice ? (price ? price.price : "-") : "-"}</span>
            </td>
          );
        })}
      {feature !== "prices" &&
        features[feature]?.map((f, index) => {
          if (model) {
            let value = model.features.find((ef) => ef.id === f.id);

            if (f.type === "boolean" && value) {
              value.value =
                value.value === false
                  ? "NO"
                  : value.value === true
                    ? "SI"
                    : value.value;
            }

            return (
              <td key={index} className={"text-center"}>
                {value?.type === "choice" && <ReactTooltip effect={"solid"} />}
                <span data-tip={value?.value_description}>
                  {value ? value.value : "-"}
                </span>
              </td>
            );
          } else {
            return <td key={index} />;
          }
        })}
    </tr>
  );
};
export default AppInfoGridPriceRow