import React, { useEffect } from 'react'
import AdXL from '../components/ads/AdXL'
import SearchTable from '../components/advancedSearch/SearchTable'

export const AdvancedSearch = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div id={'advanced_search_page'} className={'page_default_margin'}>
			<div className={'container-lg'}>
				<AdXL subsection='-1' />
				<SearchTable />
				<AdXL subsection='-2' />
			</div>
		</div>
	)
}
