import React from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";

export const ComfortOption = ({ option }) => {
  const filters = useSelector((state) => state.search.filters);
  const dispatch = useDispatch();
  const activeFilter = filters.find(
    (filter) => filter.type === option.description
  );

  const onClick = () => {
    const filter = {
      name: option.description,
      id: option.id,
      type: option.description,
    };
    dispatch(actions.search.setSearch([...filters, filter]));
  };

  if (!activeFilter) {
    return (
      <p className="text-muted my-2 link" onClick={onClick}>
        {option.description}
      </p>
    );
  } else {
    return null;
  }
};
