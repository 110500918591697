import React from "react";
import { IoChevronBackOutline } from "react-icons/io5";

export default function Navigator({ title, prevStep, activeStep, steps }) {
  if (activeStep === 0) {
    return null;
  }
  return (
    <div>
      <p onClick={prevStep} className="link">
        <IoChevronBackOutline />
        {steps[activeStep - 1].name}
      </p>
    </div>
  );
}
