import React from "react";
import { PageIndex, SectionSeparator } from "../atoms";

export default () => {
  return (
    <section id={"enterprise_header"}>
      <div className={"p-5 container mt-5"}>
        <PageIndex />
        <h2 className={"font-weight-bold text-center pt-5"}>
          Para nuestros clientes somos <br /> simplemente “InfoAuto”
        </h2>
        <p className="text-center mt-3">
          InfoAuto es la única base de datos estandarizada en Latinoamérica,
          compuesta por más de 15.000 Autos, Motos, Camiones, Utilitarios y
          Pick-Ups. Hace más de 25 años se consolidó como la{" "}
          <strong>Guía oficial de precios de la República Argentina.</strong>
        </p>
        <div className="row mt-5 mb-5 top_ul">
          <div className="col-md-3 sm-12">
            <ul>
              <li>
                <span className={"font-weight-bold"}>Código Único</span>
              </li>
              <li>
                <span className={"font-weight-bold"}>Marcas</span>
              </li>
            </ul>
          </div>
          <div className="col-md-3 sm-12">
            <ul>
              <li>
                <span className={"font-weight-bold"}>Modelos</span>
              </li>
              <li>
                <span className={"font-weight-bold"}>Versiones</span>
              </li>
            </ul>
          </div>
          <div className="col-md-3 sm-12">
            <ul>
              <li>
                <span className={"font-weight-bold"}>
                  Valuaciones hasta 30 años
                </span>
              </li>
              <li>
                <span className={"font-weight-bold"}>Datos Técnicos</span>
              </li>
            </ul>
          </div>
          <div className="col-md-3 sm-12">
            <ul>
              <li>
                <span className={"font-weight-bold"}>Año de Fabricación</span>
              </li>
              <li>
                <span className={"font-weight-bold"}>
                  Valuaciones Históricas
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <SectionSeparator right={true} />
    </section>
  );
};
