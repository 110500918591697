import axios from "axios";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { ApiHelper } from "../../helpers";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";
import { SquareButton } from "../atoms";
import InputMask from "react-input-mask";
import { Select } from "../atoms/Select";

const barrios = [
  {
    name: "Chacarita",
    comuna: 15,
    perimetro: 7724.8529545700003,
    area: 3115707.1062699999,
  },
  {
    name: "Paternal",
    comuna: 15,
    perimetro: 7087.5132953299999,
    area: 2229829.03363,
  },
  {
    name: "Villa Crespo",
    comuna: 15,
    perimetro: 8131.8570753200001,
    area: 3615977.5563400001,
  },
  {
    name: "Almagro",
    comuna: 5,
    perimetro: 8537.9013684900001,
    area: 4050752.2452400001,
  },
  {
    name: "Caballito",
    comuna: 6,
    perimetro: 10990.964471499999,
    area: 6851028.9135699989,
  },
  {
    name: "Villa Santa Rita",
    comuna: 11,
    perimetro: 5757.7523347099996,
    area: 2153690.81329,
  },
  {
    name: "Monte Castro",
    comuna: 10,
    perimetro: 7361.0364175499999,
    area: 2627481.3250799999,
  },
  {
    name: "Villa Real",
    comuna: 10,
    perimetro: 5070.9656524399998,
    area: 1334146.08283,
  },
  {
    name: "Flores",
    comuna: 7,
    perimetro: 15772.439509600001,
    area: 8590784.1312899999,
  },
  {
    name: "Floresta",
    comuna: 10,
    perimetro: 7519.6540918000001,
    area: 2320640.73551,
  },
  {
    name: "Constitución",
    comuna: 1,
    perimetro: 6326.8413747300001,
    area: 2101994.9981300002,
  },
  {
    name: "San Cristobal",
    comuna: 3,
    perimetro: 5987.2264787900003,
    area: 2043711.1216599999,
  },
  {
    name: "Boedo",
    comuna: 5,
    perimetro: 6602.5102659300001,
    area: 2609850.8792099999,
  },
  {
    name: "Velez Sarsfield",
    comuna: 10,
    perimetro: 7045.5783317200003,
    area: 2400518.32602,
  },
  {
    name: "Villa Luro",
    comuna: 10,
    perimetro: 8652.9300312699997,
    area: 2566936.8725100001,
  },
  {
    name: "Parque Patricios",
    comuna: 4,
    perimetro: 7878.4349931099996,
    area: 3743439.99645,
  },
  {
    name: "Mataderos",
    comuna: 9,
    perimetro: 11124.6357921,
    area: 7398178.8403500002,
  },
  {
    name: "Villa Lugano",
    comuna: 8,
    perimetro: 15057.468389400001,
    area: 9296142.3614000008,
  },
  {
    name: "San Telmo",
    comuna: 1,
    perimetro: 5178.0328392800002,
    area: 1232270.1587400001,
  },
  {
    name: "Saavedra",
    comuna: 12,
    perimetro: 11132.139345600001,
    area: 5538676.8328200001,
  },
  {
    name: "Coghlan",
    comuna: 12,
    perimetro: 4627.5631313800004,
    area: 1279951.37256,
  },
  {
    name: "Villa Urquiza",
    comuna: 12,
    perimetro: 10021.459664800001,
    area: 5449058.9228499997,
  },
  {
    name: "Colegiales",
    comuna: 13,
    perimetro: 6374.6190450699996,
    area: 2289774.1042800001,
  },
  {
    name: "Balvanera",
    comuna: 3,
    perimetro: 8375.8218108100009,
    area: 4342280.2680400005,
  },
  {
    name: "Villa Gral. Mitre",
    comuna: 11,
    perimetro: 6290.4405605600004,
    area: 2162275.1153500001,
  },
  {
    name: "Parque Chas",
    comuna: 15,
    perimetro: 4531.2533962799998,
    area: 1385411.5137400001,
  },
  {
    name: "Agronomía",
    comuna: 15,
    perimetro: 6556.1677718700003,
    area: 2122169.3421200002,
  },
  {
    name: "Villa Ortuzar",
    comuna: 15,
    perimetro: 5684.9952111399998,
    area: 1853802.0520299999,
  },
  {
    name: "Barracas",
    comuna: 4,
    perimetro: 12789.7917712,
    area: 7953453.4314299999,
  },
  {
    name: "Parque Avellaneda",
    comuna: 9,
    perimetro: 10165.614027699999,
    area: 4732708.2093099998,
  },
  {
    name: "Parque Chacabuco",
    comuna: 7,
    perimetro: 8406.9143207500001,
    area: 3832116.6992199998,
  },
  {
    name: "Nueva Pompeya",
    comuna: 4,
    perimetro: 11033.5103019,
    area: 4951510.8742699996,
  },
  {
    name: "Palermo",
    comuna: 14,
    perimetro: 22126.531858400002,
    area: 15845871.288000001,
  },
  {
    name: "Villa Riachuelo",
    comuna: 8,
    perimetro: 10596.8267392,
    area: 4232595.9247899996,
  },
  {
    name: "Villa Soldatti",
    comuna: 8,
    perimetro: 12441.695877300001,
    area: 8688163.5582800005,
  },
  {
    name: "Villa Pueyrredon",
    comuna: 12,
    perimetro: 7522.3603824299998,
    area: 3303239.50936,
  },
  {
    name: "Villa Devoto",
    comuna: 11,
    perimetro: 11562.0068,
    area: 6404480.8537100013,
  },
  {
    name: "Liniers",
    comuna: 9,
    perimetro: 9256.9273212900007,
    area: 4374418.7367700003,
  },
  {
    name: "Versalles",
    comuna: 10,
    perimetro: 5650.4662864299999,
    area: 1406833.5958,
  },
  {
    name: "Puerto Madero",
    comuna: 1,
    perimetro: 17725.720821200001,
    area: 5040970.8851399999,
  },
  {
    name: "Monserrat",
    comuna: 1,
    perimetro: 6739.2223110200002,
    area: 2198621.9408900002,
  },
  {
    name: "San Nicolas",
    comuna: 1,
    perimetro: 6548.0847539599999,
    area: 2289007.5825899998,
  },
  {
    name: "Belgrano",
    comuna: 13,
    perimetro: 20609.7753969,
    area: 7999239.81384,
  },
  {
    name: "Recoleta",
    comuna: 2,
    perimetro: 21452.838647799999,
    area: 6317264.5605600001,
  },
  {
    name: "Retiro",
    comuna: 1,
    perimetro: 18837.988687000001,
    area: 4512710.9070300004,
  },
  {
    name: "Nuñez",
    comuna: 13,
    perimetro: 10785.2610814,
    area: 4424199.2650899999,
  },
  {
    name: "Boca",
    comuna: 4,
    perimetro: 20060.003939400001,
    area: 5036240.4544099998,
  },
];

const InfoCreditsBillingModal = ({ setShowInfoBillingModal, show }) => {
  const user = useSelector((state) => state.user.user);
  // const show = useSelector(state => state.app.showBillingModal)
  const [taxType, setTaxType] = useState("");
  const [taxStatus, setTaxStatus] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [localities, setLocalities] = useState([]);
  const [email, setEmail] = useState("");
  const [taxId, setTaxId] = useState("");
  const [province, setProvince] = useState();
  const [locality, setLocality] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [enterpriseName, setEnterpriseName] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [errors, setErrors] = useState({
    taxType: null,
  });
  const mask = "99-99999999-9";
  const dispatch = useDispatch();

  const hide = () => {
    dispatch(actions.app.setShowBillingModal(false));
  };

  useEffect(() => {
    if (show && !provinces.length) {
      getProvinces();
    }
  }, [show, provinces]);

  useEffect(() => {
    if (show && !taxId) {
      getBillingInfo();
    }
  }, [show, taxId]);

  useEffect(() => { }, [show, taxId]);

  useEffect(() => {
    if (
      taxStatus?.value !== "Consumidor Final" &&
      taxType?.value !== "CUIL" &&
      taxType?.value !== "CUIT"
    ) {
      setTaxType(null);
    }
  }, [taxStatus]);

  useEffect(() => {
    const getLocalities = async () => {
      let localities = [];
      console.log(province);
      if (province.label.includes("Ciudad")) {
        localities = barrios;
      } else {
        let { data } = await ApiHelper.getLocalities(province.name);
        localities = data;
      }

      localities.map((localidad) => {
        localidad.label = localidad.name;
        localidad.value = localidad.name;
      });

      localities.sort(function (a, b) {
        let textA = a.label.toUpperCase();
        let textB = b.label.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setLocalities(localities);
    };
    if (province) {
      getLocalities();
      setLocality("")
    }
  }, [province]);

  const getBillingInfo = async () => {
    try {
      const { data } = await ApiHelper.get("admin", "/user/billingInformation");
      setTaxType(taxTypes.find((type) => type.value === data.taxType));
      setTaxStatus(
        taxStatusList.find((status) => status.value === data.taxStatus)
      );
      setTaxId(data.taxId);
      setZipCode(data.enterpriseZipCode);
      setAddress(data.enterpriseAddress);
      setEmail(data.email);
      setEnterpriseName(data.enterpriseName);
      console.log(data);
      if (data.enterpriseProvince) {
        setProvince(data.enterpriseProvince);
        setLocality(data.enterpriseLocality);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProvinces = async () => {
    try {
      let res = await ApiHelper.getProvinces();
      res.data.map((provincia) => {
        provincia.value = provincia.id;
        provincia.label = provincia.name;
      });
      res.data.sort(function (a, b) {
        let textA = a.label.toUpperCase();
        let textB = b.label.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setProvinces(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const taxTypes = [
    { label: "CUIT", value: "CUIT" },
    { label: "CUIL", value: "CUIL" },
    { label: "DNI", value: "DNI" },
    { label: "PASAPORTE", value: "PASAPORTE" },
  ];

  const taxStatusList = [
    { label: "IVA Responsable Inscripto", value: "IVA Responsable Inscripto" },
    { label: "IVA no Responsable", value: "IVA no Responsable" },
    { label: "IVA Sujeto Exento", value: "IVA Sujeto Exento" },
    { label: "Consumidor Final", value: "Consumidor Final" },
    { label: "Responsable Monotributo", value: "Responsable Monotributo" },
    { label: "Proveedor del Exterior", value: "Proveedor del Exterior" },
    { label: "Cliente del Exterior", value: "Cliente del Exterior" },
    {
      label: "IVA Liberado – Ley Nº 19.640",
      value: "IVA Liberado – Ley Nº 19.640",
    },
    {
      label: "CUIVA Responsable Inscripto – Agente de PercepciónIT",
      value: "IVA Responsable Inscripto – Agente de Percepción",
    },
    {
      label: "Pequeño Contribuyente Eventual",
      value: "Pequeño Contribuyente Eventual",
    },
    { label: "Monotributista Social", value: "Monotributista Social" },
    {
      label: "Pequeño Contribuyente Eventual Social",
      value: "Pequeño Contribuyente Eventual Social",
    },
  ];

  const validate = () => {
    let errors = {};
    if (!taxStatus) errors.taxStatus = "Ingrese condición";

    if (!address) {
      errors.address = "Ingrese una dirección";
    } else if (!/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/i.test(address)) {
      errors.address =
        "La dirección solo puede estar formada por letras y números";
    }
    if (!taxType) {
      errors.taxType = "Ingrese un tipo de documento";
    } else {
      if (
        (taxType.value === "CUIL" || taxType.value === "CUIT") &&
        !/\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]/i.test(taxId)
      ) {
        errors.taxId = "Ingrese un CUIT/CUIL valido";
      } else {
        if (taxType.value === "DNI" && !/^[0-9]*$/i.test(taxId)) {
          errors.taxId = "Ingrese un DNI/PASAPORTE válido";
        } else {
          if (taxId.length < 6) {
            errors.taxId = "Ingrese 6 digitos";
          }
        }
      }
    }

    if (user.type === "professional") {
      if (!enterpriseName) {
        errors.enterpriseName = "Ingrese la Razón Social sin comas ni comillas";
      } else {
        if (
          !/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/i.test(enterpriseName)
        ) {
          errors.enterpriseName = "Ingrese una Razón Social válida";
        }
      }
    }

    if (!zipCode) {
      errors.zipCode = "Ingrese el código postal";
    }

    if (!taxStatus) {
      errors.taxStatus = "Seleccione una condición";
    }

    if (!province) {
      errors.province = "Seleccione una Provincia";
    }

    if (!locality) {
      errors.locality = "Seleccione una Localidad";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSaveBillingInfo = async () => {
    const valid = validate();
    if (valid) {
      await saveBillingInfo();
      setShowInfoBillingModal(false);
    }
  };

  const saveBillingInfo = async () => {
    if (user?.type === "individual") {
      const data = {
        enterpriseName: "",
        email: email,
        taxStatus: taxStatus.value,
        taxType: taxType.value,
        taxId: taxId,
        enterpriseAddress: address,
        enterpriseProvince: province.name,
        enterpriseLocality: locality,
        enterpriseZipCode: zipCode,
      };
      await ApiHelper.post("admin", "/user/billingInformation", data);
    } else {
      const data = {
        enterpriseName: enterpriseName,
        email: email,
        taxStatus: taxStatus.value,
        taxType: taxType.label,
        taxId: taxId,
        enterpriseAddress: address,
        enterpriseProvince: province.name,
        enterpriseLocality: locality,
        enterpriseZipCode: zipCode,
      };
      await ApiHelper.post("admin", "/user/billingInformation", data);
    }
  };

  return (
    <div className="container p-5">
      <div className="row">
        <div className="col-12">
          <h5 className={"mb-4 text-center font-weight-bold"}>
            Información de facturación
          </h5>
          <p className="text-center">
            Antes de continuar con el proceso de suscripción es necesario que
            complete la siguiente información.
          </p>
        </div>
        {user?.type === "professional" && (
          <>
            <div className="col-12 col-md-6 mt-3">
              <span className="font-weight-bold mb-2">Razón Social</span>
              <input
                value={enterpriseName}
                onChange={(e) => setEnterpriseName(e.target.value)}
                name={"razonSocial"}
                className={"custom_input default-shadow p-2 w-100 my-3"}
                placeholder={"Razón Social"}
                type={"text"}
              />
              {errors.enterpriseName && (
                <p className="color_red">{errors.enterpriseName}</p>
              )}
            </div>
            <div className="col-12 col-md-6 mt-3">
              <span className="font-weight-bold mb-2">Email</span>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name={"email"}
                className={"custom_input default-shadow p-2 w-100 mt-3"}
                placeholder={"Email"}
                type={"email"}
              />
            </div>
          </>
        )}

        {user?.activeSubscription?.subscriptionType.type !== "fleet" && (
          <>
            <div className="col-12 col-md-4 mt-4">
              <span className="font-weight-bold mb-2">
                Condición frente al IVA
              </span>
              <Select
                required
                className="my-3"
                placeholder={"Seleccionar"}
                options={taxStatusList}
                selectedOption={taxStatus}
                onSelect={setTaxStatus}
              />
              {errors.taxStatus && (
                <p className="color_red">{errors.taxStatus}</p>
              )}
            </div>

            <div className="col-12 col-md-4 mt-4">
              <span className="font-weight-bold mb-2">Tipo de documento</span>
              <Select
                required
                className="my-3"
                placeholder={"Seleccionar"}
                options={
                  taxStatus?.value !== "Consumidor Final"
                    ? taxTypes.filter(
                      (type) => type.value === "CUIL" || type.value === "CUIT"
                    )
                    : taxTypes
                }
                onSelect={setTaxType}
                selectedOption={taxType}
              />
              {errors.taxType && <p className="color_red">{errors.taxType}</p>}
            </div>
            <div className="col-12 col-md-4 mt-4">
              <span className="font-weight-bold mb-2">Número</span>
              {taxType?.value === "CUIT" || taxType?.value === "CUIL" ? (
                <InputMask
                  mask={mask}
                  value={taxId}
                  placeholder={"Número"}
                  type={"text"}
                  name={"dni"}
                  onChange={(e) => setTaxId(e.target.value)}
                >
                  {(inputProps) => (
                    <input
                      {...inputProps}
                      className={"custom_input default-shadow p-2 w-100 my-3"}
                    />
                  )}
                </InputMask>
              ) : (
                <input
                  value={taxId}
                  onChange={(e) => setTaxId(e.target.value)}
                  name={"dni"}
                  className={"custom_input default-shadow p-2 w-100 my-3"}
                  placeholder={"Número"}
                  type={"text"}
                />
              )}

              {errors.taxId && <p className="color_red">{errors.taxId}</p>}
            </div>
          </>
        )}
      </div>

      {user?.activeSubscription?.subscriptionType.type != "fleet" && (
        <div className="row">
          <div className="col-12 mt-4">
            <h5 className="mb-4 font-weight-bold text-center">
              Dirección de Facturación
            </h5>
          </div>

          <div className="col-12 col-md-6">
            <span className="font-weight-bold mb-2">Código Postal</span>
            <input
              name={"apartment"}
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              className={"custom_input default-shadow p-2 w-100 my-3"}
              placeholder={"Código Postal"}
              type={"text"}
            />
            {errors.zipCode && <p className="color_red">{errors.zipCode}</p>}
          </div>

          <div className="col-12 col-md-6">
            <span className="font-weight-bold mb-2">Dirección</span>
            <input
              name={"address"}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className={"custom_input default-shadow p-2 w-100 my-3"}
              placeholder={"Dirección"}
              type={"text"}
            />
            {errors.address && <p className="color_red">{errors.address}</p>}
          </div>

          <div className="col-12 col-md-6">
            <span className="font-weight-bold mb-2">Provincia</span>
            <Select
              className="select-modal my-3"
              onSelect={(e) => setProvince(e)}
              selectedOption={provinces.find((o) => o.label === user.province)}
              placeholder={"Provincia"}
              options={provinces}
            />
            {errors.province && <p className="color_red">{errors.province}</p>}
          </div>
          <div className="col-12 col-md-6">
            <span className="font-weight-bold mb-2">Localidad</span>
            <Select
              className={"my-3"}
              onSelect={(e) => setLocality(e.label)}
              selectedOption={localities.find((o) => o.label === locality)}
              placeholder={"Localidad"}
              options={localities}
            />
            {errors.locality && <p className="color_red">{errors.locality}</p>}
          </div>
          <div className="col-12 d-flex justify-content-center mt-3">
            <SquareButton
              text="Siguiente"
              fullWidth={false}
              className="px-5"
              onClick={handleSaveBillingInfo}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoCreditsBillingModal;
