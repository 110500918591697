import React from 'react';

export const Terminos = () => {
    return (
        <div className="page_default_margin">
            <div className={'container mb-5'}>
                <h2 className="font-weight-bolder">Términos y condiciones generales de Uso</h2>
                <h4 className="mt-5"><b>Acuerdo entre InfoAuto y el Usuario</b></h4>
                <hr />
                <p>El presente documento describe los términos y condiciones (en adelante los “Términos y Condiciones”) aplicables al acceso y navegación por el sitio www.infoauto.com.ar (en adelante el “Sitio”) y la aplicación móvil (la “Aplicación” y junto con el Sitio, la “Plataforma”) asi como al uso de los distintos planes comercializados y ofrecidos por INFO AUTO S.R.L. (CUIT 30-68686695-1), con domicilio en Soldado de la Independencia 1130, 2do Piso, Depto. A, Ciudad Autónoma de Buenos Aires (en adelante “INFO AUTO”).</p>
                <p>La actividad de INFO AUTO consiste en la provisión de servicios de información a terceros, entre la cual se encuentra la base de datos codificada de investigación y relevamiento de precios y características del mercado automotor Argentino que se utiliza de referencia para asesoramiento sobre el valor de automotores (camiones, motos y autos), y que es de titularidad y propiedad intelectual de INFO AUTO (la “Base”). </p>

                <h4 className="mt-5"><b>1.- Introducción</b></h4>
                <hr />
                <p>1.1. Las personas humanas que accedan y/o interactúen a través de la Plataforma y/o tomen contacto con relación a cualquiera de los planes ofrecidos para acceder a la información (los “Planes”), independientemente de que contraten o no los servicios ofrecidos a través de tal Plataforma, serán considerados como “Usuarios” bajo los presentes Términos y Condiciones. Dentro de este concepto se incluye a cualquier individuo que lo haga por sí mismo o en representación de una persona jurídica. Si el Usuario representa o actúa en nombre de una persona jurídica, deberá identificarse al momento de registrarse como Profesional y brindar la información correspondiente para acreditar su vínculo con tal persona jurídica y la documentación que le sea requerida para la facturación correspondiente.</p>
                <p>1.2. Todo Usuario deberá sujetar su interactuación con la Plataforma y los Planes a los Términos y Condiciones aquí establecidos así como a las demás políticas y principios incorporados por referencia, siendo obligatorios para éstos. También resultarán aplicables a los Usuarios las condiciones particulares de los distintos Planes seleccionados por el Usuario.</p>
                <p>1.3. La utilización de la Plataforma y/o de los Planes, en cualquier forma, implica la aceptación expresa, plena y sin reservas por parte del Usuario a todos y cada uno de los Términos y Condiciones y demás políticas y condiciones incorporadas por referencia, reconociendo quedar obligado al cumplimiento de los mismos. En consecuencia, el Usuario debe leer atentamente, entender y aceptar los Términos y Condiciones y demás políticas y condiciones particulares aplicables previo a la utilización de la Plataforma y los Planes.</p>
                <p className="font-weight-bold">1.4. Cualquier persona que no acepte los Términos y Condiciones, deberá abstenerse de utilizar la Plataforma y/o los Planes y/o la información provista a través de los mismos. </p>
                <p>1.5. INFO AUTO se reserva el derecho de modificar los presentes Términos y Condiciones, total o parcialmente, en cualquier momento. Cualquier modificación de estos Términos y Condiciones se publicará mediante la actualización de estos Términos y Condiciones en la Plataforma, empezando a regir desde la fecha indicada en tal actualización. En caso de que el Usuario no esté de acuerdo con alguna de sus modificaciones; podrá dejar de usar la Plataforma, los Planes (y, si así lo prefiere, dar de baja inmediatamente el Plan al que se ha adherido) cesando en el uso de la información obtenida hasta dicha fecha de parte de la Plataforma y los Planes, siendo condición para su acceso y utilización la previa aceptación de los presentes Términos y Condiciones y sus modificaciones por el Usuario. </p>
                <p>1.6. Sin perjuicio de lo expuesto, se aclara que no es obligación de INFO AUTO realizar una notificación individual ni escrita a cada Usuario respecto de cualquier modificación a los Términos y Condiciones, bastando su publicación en la Plataforma. El Usuario entiende y conviene que es el único responsable de revisar los Términos y Condiciones cada vez que acceda a la Plataforma y/o utilice los Planes. El hecho de que INFO AUTO realice una notificación individual al Usuario informando tal modificación, no relevará al Usuario en adelante de tal obligación debiendo éste continuar manteniéndose informado de todas las modificaciones que puedan ocurrir desde la última versión leída y aceptada por éste. La continuidad en el uso de la Plataforma y de los Planes después que se hayan modificado los presentes Términos y Condiciones significará la aceptación del Usuario a la versión actualizada de los mismos. </p>
                <p>1.7. Al aceptar los presentes Términos y Condiciones, además, el Usuario entiende y acepta que podrá recibir comunicaciones de INFO AUTO, incluyendo pero no limitándose a avisos, consultas, encuestas y/o notificaciones relacionado con la Plataforma, los Planes, la información incluida en los mismos, opciones de contratación de los Planes, promociones, newsletters y/o cualquier otra información con relación a la Plataforma y/o a los Planes en general. En caso de no querer recibir más tales comunicaciones, deberá ejercer tal opción haciendo “click” en el link con dicha opción al final de tales notificaciones recibidas en sus correos electrónicos. </p>
                <p>1.8. El Usuario reconoce y acepta que INFO AUTO no es responsable de la veracidad ni exactitud de los anuncios publicados en la Plataforma ni de aquellos que formen parte de los mensajes enviados a los Usuarios vía correo electrónico, ni los publicados en sus revistas o tiradas, siendo solamente el medio para la difusión de los mismos, no siendo responsabilidad de INFO AUTO verificar la veracidad y cumplimiento de lo ofertado. </p>
                <p>1.9. Si el Usuario tiene empleados, es bajo su exclusiva responsabilidad informar debidamente a sus empleados y contratistas de los términos del presente y asegurarse su cumplimiento por éstos. El incumplimiento del Usuario y/o sus empleados de cualquiera de las condiciones establecidas en las presentes, lo hará responsable frente a INFO AUTO por todos los daños y perjuicios ocasionados. </p>
                <h4 className="mt-5"><b>2. Obligaciones de los Usuarios</b></h4>
                <hr />
                <p>2.1. El Usuario se compromete a utilizar la Plataforma y los Planes de conformidad con la ley, estos Términos y Condiciones y demás políticas y condiciones particulares incluidos por referencia.</p>
                <p>2.2. El acceso a ciertos contenidos de la Plataforma y/o de los Planes y de los servicios prestados a través de los mismos, puede hallarse condicionado a un previo registro por el Usuario. Ese previo registro sólo puede realizarse por el Usuario en forma directa.</p>
                <p>2.3. Puede registrarse en la Plataforma cualquier persona humana mayor de 18 años por sí o en representación de una persona jurídica, lo cual deberá informarse al momento de su registro y como consecuencia de lo cual se le requerirá información adicional para acreditar tal vinculación. Además, deben tener capacidad legal para contratar. No podrán contratar los menores de edad ni aquellos Usuarios a los que se hubiera suspendido temporalmente o cancelado su acceso por cualquier causa, hasta su posterior autorización por INFO AUTO, la que depende de la exclusiva discreción de INFO AUTO. </p>
                <p>2.4. La Plataforma tiene un volumen limitado de operaciones, de modo que de requerir un volumen adicional podrán contratar el servicio de INFO AUTO mediante el contacto con un asesor comercial en forma directa a los contactos informados en la misma Plataforma o completar el formulario online a fin de coordinar un contacto para una propuesta de un plan a medida. </p>
                <p>2.5. La registración en la Plataforma es libre y gratuita a los fines de convertirse en Usuario de la Plataforma y de algunos Planes informativos de la Plataforma. Sin perjuicio de ello, la utilización de algunos otros Planes y servicios ofrecidos a través de la Plataforma pueden ser arancelados, lo que será informado en cada oportunidad. En ambos casos se accederá con la misma Cuenta pero en caso de Planes arancelados su acceso requerirá cumplir previamente por el Usuario, como condición necesaria para su acceso con el pago del arancel establecido por INFO AUTO en forma adecuada y de acuerdo a las pautas fijadas en estos Términos y Condiciones y en la Plataforma.</p>
                <p>2.6. Al registrarse, el Usuario podrá crear su cuenta personal ("Cuenta") mediante el ingreso de su nombre de usuario y clave de seguridad personal elegida ("Clave de Seguridad"). En el caso de tratarse de una persona jurídica, se podrán generar varios usuarios, lo que deberá ser solicitado expresamente a tal fin a INFO AUTO mediante los formularios de contacto de la Plataforma. El uso del usuario y Clave de Seguridad es personal e intransferible, al igual que la Cuenta.
                <br />
                <br />
                Está prohibido que un mismo Usuario registre o posea más de una Cuenta, excepto en el caso expresamente autorizado por INFO AUTO. Se aclara que está prohibida la venta, cesión o transferencia de la Cuenta bajo cualquier título, asi como compartirla con otros usuarios.
                <br />
                <br />
                No se encuentra permitido utilizar la cuenta de otra persona con la intención de hacerse pasar por dicha persona. Esta obligación es esencial. En caso de verificarse un incumplimiento a la misma podrá darse de baja o suspenderse temporaria o permanentemente la cuenta del Usuario, a discreción de INFO AUTO.
                <br />
                <br />
                El Usuario es responsable por todas las operaciones efectuadas en su Cuenta, ya que el acceso a la misma está restringido al ingreso y uso de su Clave de Seguridad, de conocimiento exclusivo del Usuario.
                <br />
                <br />
                El Usuario se compromete a mantener en forma confidencial sus datos de usuario y contraseña utilizados en la Plataforma y en los Planes accedidos a través de la Plataforma y con relación a toda otra información provista como parte de los servicios contratados por el Usuario.
                </p>
                <p>2.7. El Usuario se compromete a notificar a INFO AUTO en forma inmediata, por medio escrito, idóneo y fehaciente, cualquier uso no autorizado de su Cuenta, así como el ingreso por terceros no autorizados a la misma. </p>
                <p>2.8. En caso que INFO AUTO detecte distintas Cuentas que contengan datos coincidentes o relacionados, podrá cancelar, suspender o inhabilitarlas, pudiendo solicitar información adicional a tal fin y con el objeto de mantener actualizada su base de clientes y Usuarios.</p>
                <p>2.9. El Usuario presta expresa conformidad con que, en caso de así requerirlo, INFO AUTO utilice diversos medios para identificar sus datos personales, asumiendo el Usuario la obligación de revisarlos y mantenerlos actualizados.</p>
                <p>2.10. INFO AUTO se reserva el derecho de solicitar algún comprobante y/o dato adicional a efectos de corroborar los datos personales brindados al momento de registrarse o su relación con la persona jurídica invocada, así como de suspender temporal o definitivamente a aquellos Usuarios cuyos datos no hayan podido ser confirmados.</p>
                <p>2.11. INFO AUTO se reserva el derecho de rechazar cualquier solicitud de registración o de cancelar una registración previamente aceptada, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento. También podrá tomar esta decisión en forma automática, cuando se incumpla el pago de un período mensual correspondiente a alguno de los Planes contratados por el Usuario.</p>
                <p>2.12. El Usuario, al registrarse, deberá brindar información veraz, completa, actual y precisa, siendo este el único responsable por la veracidad, exactitud, vigencia y autenticidad de los datos suministrados, comprometiéndose a actualizarlo cuando sea pertinente, siendo responsable de mantener actualizada tal información.
                    <br />
                    <br />
                    INFO AUTO no se hace responsable sobre la certeza de los datos personales suministrados y la veracidad de estos.
                </p>
                <p>2.13. Los datos son proporcionados de forma voluntaria por el titular de estos, prestando el Usuario su consentimiento libre e informado para que INFO AUTO y las entidades relacionadas y/o vinculadas y/o controladas por ésta, o los terceros que con esta contraten; utilicen, traten, cedan y transfieran sus datos personales con el fin de poder darlo de alta como Usuario y permitir al Usuario el uso de los distintos Planes comercializados por INFO AUTO conforme los fines contratados y ofrecidos y en un todo de acuerdo con las Políticas de Privacidad.</p>
                <p>2.14. Los Usuarios son responsables de adquirir y actualizar el software y/o hardware compatibles y necesarios para acceder a los Planes y a los servicios brindados a través de los mismos. INFO AUTO no garantiza que la Plataforma ni el acceso a los Planes de INFO AUTO funcione en cualquier hardware o software en particular. INFO AUTO no será responsable si el Usuario no cuenta con una computadora o de un teléfono celular inteligente compatible con el uso de la Plataforma. Antes de la contratación de cualquier Plan, es responsabilidad de Usuario chequear esta compatibilidad para asegurarse que podrá gozar el uso de los Planes contratados.</p>
                <p>2.15. Toda la información que se reciba en la Plataforma, en los formularios de contacto y en las direcciones de email de INFO AUTO, tales como comentarios, sugerencias o ideas, se considerará cedida a INFO AUTO a título gratuito. INFO AUTO se reserva el derecho a eliminar los comentarios inapropiados u ofensivos bajo su propio criterio. </p>

                <h4 className="mt-5"><b>3. Datos Personales</b></h4>
                <hr />
                <p>3.1. El Usuario manifiesta que entiende que en caso de no proporcionar los datos requeridos por INFO AUTO, ésta se verá imposibilitada de poder dar cumplimiento con las obligaciones contractuales pertinentes. Dicha información será ingresada en la base de datos de INFO AUTO y/o de un tercero que esta designe cómo procesador de datos personales, la cual será utilizada a los fines indicados en la Política de Privacidad y confidencialidad de la información. </p>
                <p>3.2. Los datos suministrados por los Usuarios son confidenciales y se recolectan, gestionan, y utilizan en los términos descriptos en la Política de privacidad y confidencialidad de la información. En tal Política se establecen los términos y condiciones relativo al uso de los datos pro INFO AUTO, por lo que el Usuario deberá revisarla antes de aceptar los presentes términos y condiciones.</p>

                <h4 className="mt-5"><b>4. Uso apropiado de la Plataforma y de los Planes</b></h4>
                <hr />
                <p>4.1. La Base y demás información asociada es de titularidad y propiedad intelectual única y exclusiva de INFO AUTO, sin que el Usuario adquiera derecho alguno de propiedad, u otro derecho real y/o personal sobre las mismas. La contratación de Planes no brinda al Usuario ningún derecho sobre la información de la Base y su uso, sino que tal contratación le permite el acceso a la misma solamente. Los usos que se le de a la Base y la información allí contenida es de responsabilidad exclusiva del Usuario, el cual también deberá cumplir con los Términos y Condiciones del presente. </p>
                <p>4.2. El Usuario no deberá usar los derechos de propiedad intelectual de INFO AUTO, incluyendo su nombre ni el de cualquier sociedad relacionada y/o vinculada y/o la información obtenida de la Base, en una publicación interna ni externa ni publicidad, sin el consentimiento previo por escrito de INFO AUTO, para lo cual deberá hacer aprobar la forma en que se realizará tal mención. </p>
                <p>4.3. La información a la que se accede a la Base solo puede ser compartida con terceros del modo dispuesto debajo. Asimismo, el Usuario deberá hacer su mejor esfuerzo para prevenir cualquier acontecimiento o circunstancia que pudiese afectar la buena reputación de INFO AUTO y de sus compañías vinculadas y/o relacionadas, sus marcas y/o sus servicios aun cuando obtengan tal consentimiento para el uso de su nombre o cualquier otro derecho de propiedad intelectual de INFO AUTO.</p>
                <p>4.4. El Usuario reconoce y acepta que la información suministrada a través de los Planes y de la Plataforma es para su uso exclusivo e interno, y tal información no podrá ser vendida, cedida, compartida, impresa, reimpresa ni distribuida en todo ni en partes. La única excepción es la posibilidad de compartir la información mediante la utilización del botón de “Compartir” incluido en la Plataforma.</p>
                <p className='font-weight-bold'>Cualquier otro uso que no esté expresamente establecido en el presente, estará prohibido y deberá contar en tal caso con la previa aprobación escrita de INFO AUTO.</p>

                <p>4.5. El Usuario es consciente y acepta voluntariamente que el uso que otorgue a la Plataforma y a la información obtenida de la Base y de los Planes es bajo su única y exclusiva responsabilidad. El Usuario es el único responsable de determinar si el servicio provisto a través de los Planes de INFO AUTO se ajusta a sus necesidades. INFO AUTO exhibe a través de las Bases la información resultante de la investigación de mercado realizada, siendo su exclusiva obligación brindar acceso a la misma al Usuario de acuerdo al servicio o Plan contratado.</p>

                <p>4.6. El Usuario se compromete a utilizar la Plataforma y la información recibida de la Base y a través de los Planes de conformidad con estos Términos y Condiciones y la buena fe. En tal sentido, se aclara que:</p>

                <p>a) El Usuario se obliga a abstenerse de utilizar la Plataforma, los Planes y la información obtenida de las Bases con fines o efectos ilícitos, contrarios a lo establecido en estos Términos y Condiciones y las demás políticas y condiciones particulares incluidas por referencia, de forma lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar la Plataforma o impedir la normal utilización o disfrute de la Plataforma y de los Planes por parte de los Usuarios.</p>

                <p>b) El Usuario entiende, reconoce y acepta que queda prohibido, sin que tal listado sea taxativo:</p>
                <p>(i) registrarse en la Plataforma utilizando un nombre falso, erróneo o inexistente, ya sea como persona física o jurídica;</p>
                <p>(ii) cualquier acción que tenga por objeto recopilar información acerca de los demás Usuarios. También, el Usuario deberá abstenerse de obtener e incluso de intentar obtener informaciones, mensajes, gráficos, dibujos, archivos de sonido y/o imagen, fotografías, grabaciones, software y, en general, cualquier clase de material accesibles a través de la Plataforma y de los Planes empleando para ello medios o procedimientos distintos de los que, según los casos, se hayan puesto a su disposición a este efecto o se hayan indicado a este efecto en los términos y condiciones de la misma. El Usuario se obliga a usar la información de la Base y los Planes en los términos y condiciones establecidos en la presente, de forma diligente, correcta y lícita;</p>

                <p>(iii) realizar ingeniería inversa, desmontar o descompilar cualquier sección o tecnología de la Plataforma y/o de los Planes y con relación a la Base;</p>

                <p>(iv) obtener acceso no autorizado a los Planes, la Base e información incluida en la Plataforma, a las cuentas, los nombres, la información de identificación personal u otra información de otros Usuarios, o a otros equipos o sitios web conectados o vinculados a los Planes. También está prohibido utilizar medios automáticos, con inclusión de arañas, robots, rastreadores, herramientas de ataque a datos, o similares para descargar información de la Plataforma y/o de los Planes y/o de otros usuarios; </p>

                <p>(v) reproducir o copiar, distribuir, permitir el acceso del público a través de cualquier modalidad de comunicación pública, transformar o modificarlos. Tampoco se podrá emplear la información de cualquier clase obtenida a través de la Plataforma o de los Planes para comercializar o divulgar de cualquier modo dicha información (estando solo permitida compartirla mediante el uso del botón “Compartir” en la Plataforma);</p>

                <p>(vi) publicar, transmitir o poner a disposición cualquier virus, gusano, spyware o cualquier otro código, archivo o programa informático que pueda o tenga la intención de dañar o secuestrar el funcionamiento de cualquier hardware, software o equipo de telecomunicaciones de INFO AUTO, la Base y/o sus Usuarios; </p>

                <p>(vii) incumplir o eludir las medidas de seguridad o autenticación de INFO AUTO, esto incluye, y no se limita, a tratar de acceder a datos no destinados al Usuario, intentar ingresar en el servidor o cuentas sin contar con la expresa autorización para hacerlo, o intentar probar la seguridad de los Planes por cualquier medio; y </p>

                <p>(viii) ayudar o permitir que ninguna persona participe en cualquiera de las actividades descritas anteriormente. </p>


                <h4 className="mt-5"><b>5. Planes arancelados</b></h4>
                <hr />
                <p>5.1. Si bien el registro por el Usuario es gratuito, el acceso a algunos contenidos de la Plataforma y Planes, puede estar sujeto al previo pago de un arancel, lo que se indicará en cada caso. En tal caso, el pago del mismo será condición necesaria para la utilización de los Planes contratados. Los precios de tales Planes y los servicios ofrecidos a través de los mismos serán aquellos indicados oportunamente en la Plataforma. A todos los precios informados se le deberá adicionar el Impuesto al Valor Agregado en caso de corresponder (I.V.A.).</p>
                <p>5.2. INFO AUTO se reserva el derecho de modificar los precios a su sola discreción en cualquier momento. Aquellos Usuarios que no estén de acuerdo con la modificación del precio, deberán comunicar su cese en forma inmediata y previa a la entrada en vigencia de los mismos notificando su voluntad de de-suscribirse desde su propio perfil en la Plataforma, brindando así la notificación correspondiente a INFO AUTO mediante el canal de atención al cliente indicado al efecto. En caso de omitir tal notificación, se entenderá que ha aceptado las modificaciones de precio propuestas, sin aceptarse prueba en contrario, siendo el medio escrito el único designado para tal rechazo.</p>
                <p>5.3. Se interpreta que los Usuarios que continúen utilizando los Planes y contenidos arancelados de la Plataforma luego de la notificación de la entrada en vigencia de tales nuevos precios, han aceptado los mismos, sin que sea necesario realizar acto adicional alguno al respecto. </p>

                <h4 className="mt-5"><b>6. Modalidad de pago</b></h4>
                <hr />
                <p>6.1. En el caso que el contenido de la Plataforma y/o acceso de los Planes y uso del mismo fuera arancelado, el pago del precio correspondiente se podrá realizar únicamente en moneda de curso legal del país del domicilio del Usuario y mediante alguno de los medios de pago allí informados. </p>
                <p>6.2. Al momento de realizar el pago, la Plataforma conectará mediante una interfaz a la aplicación de Mercado Pago, para que el Usuario pueda completar los datos correspondientes para realizar su pago con los medios de pago habilitados por Mercado Pago. Se deja constancia que en tal caso los datos de pago se remiten en forma encriptada a Mercado Pago para su procesamiento. Asimismo, previo a realizar tal pago, el Usuario deberá a leer los Términos y Condiciones de utilización de Mercado Pago. El pago a través de la Plataforma, de este modo, implica el consentimiento del Usuario con los términos y condicione de Mercado Pago además de los presentes Términos y Condiciones. </p>

                <p>6.3. INFO AUTO no es responsable de cualquier falla en el funcionamiento de la plataforma de Mercado Pago ni en la interrupción por cualquier causa de los servicios de Mercado Pago al cual su plataforma se encuentra integrada. Todo lo concerniente a pagos mediante tal plataforma, se regirá por los términos y condiciones establecidos en la misma, eximiéndose de responsabilidad a INFO AUTO por cualquier problema o inconveniente al respecto, deberá cumplir para sus reclamos con los términos y condiciones allí establecidos.</p>

                <h4 className="mt-5"><b>7. Servicios provistos mediante los Planes</b></h4>
                <hr />
                <p>7.1. INFO AUTO permite a los Usuarios mediante la contratación de alguno de los Planes acceder a la información en la Base con distintas modalidades. La cantidad y tipo de acceso dependerá del Plan contratado. En la Plataforma se detallan los alcances de cada uno de los Planes. En caso de duda sobre su utilidad para un Usuario determinado, se puede consultar a INFO AUTO mediante el formulario de consulta de la Plataforma, quien los asesorará para que el Usuario defina el Plan que mejor se adapte a sus necesidades Con la contratación del Plan, se brinda al Usuario una licencia limitada, no exclusiva, que no se puede sublicenciar, revocable, no transferible, para utilizar la Plataforma de acuerdo con los presentes Términos y Condiciones y los términos y condiciones particulares de cada Plan. Dicha licencia finalizará de forma automática al término de su carácter como Usuario.</p>

                <h4 className="mt-5"><b>8. Link externos</b></h4>
                <hr />
                <p>8.1. Esta Plataforma y/o los Planes pueden contener links a otros sitios web u otras fuentes de información de Internet. Estos links son provistos al solo beneficio del Usuario y el hecho de facilitar el acceso no será entendido como aval, sponsoreo o recomendación de INFO AUTO. INFO AUTO no tendrá responsabilidad alguna por cualquier contenido, servicios o productos disponibles a través de fuentes de terceros. INFO AUTO no controla la información de dichos sitios web. El uso de estos sitios será a riesgo exclusivo del Usuario.
                    INFO AUTO no realiza manifestaciones ni otorga garantías respecto de la precisión o la autenticidad de la información contenida en dichos sitios. La conexión que el Usuario realice con otros sitios web queda totalmente bajo la responsabilidad de dicho Usuario y nada tiene que reclamarle a INFO AUTO.
                </p>

                <h4 className="mt-5"><b>9. Uso de Cookies</b></h4>
                <hr />
                <p>9.1. Una Cookie es un fichero que se descarga en el ordenador o dispositivo del Usuario al acceder a determinadas páginas web. Las cookies permiten a una página web y/o aplicaciones y plataformas, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contenga y de la forma en que utilice su equipo o dispositivo, pueden utilizarse para reconocer al Usuario.
                    La Plataforma y los Planes utilizan cookies. El uso de cookies aportando innumerables ventajas en la prestación de servicios interactivos, facilitando la navegación y usabilidad de la Plataforma y de los Planes por los Usuarios
                    En tal sentido, INFO AUTO informa al Usuario que tiene la posibilidad de configurar su navegador de modo que se le informe de la recepción de cookies, pudiendo, si así lo desea, impedir que sean instaladas en su disco duro.
                </p>

                <h4 className="mt-5"><b>10. Fallas en la Plataforma y en el acceso a los Planes y la información</b></h4>
                <hr />
                <p>10.1. INFO AUTO no garantiza la inexistencia de eventuales dificultades técnicas o fallas en los sistemas o en Internet. INFO AUTO no garantiza el acceso y uso continuado de la Plataforma y de los Planes y la información en ella contenidos, no garantizándose la inexistencia de interrupciones ni errores, ni que el servidor que los pone a disposición contenga virus u otros componentes perjudiciales.</p>
                <p>10.2. La Plataforma y/o los Planes (y/o la información contenida en ellos) pueden no estar disponibles debido a dificultades técnicas o fallas de Internet o por cualquier otra circunstancia ajena a INFO AUTO. Si las cuestiones fueran atribuibles a INFO AUTO, INFO AUTO se compromete a restablecerlo con la mayor celeridad posible.</p>
                <p>10.3. INFO AUTO excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza que sean originados en forma directa, indirecta o remota, por la interrupción, suspensión, finalización, falta de disponibilidad o de continuidad del funcionamiento de la Plataforma y de los Planes, por la defraudación de la utilidad que los Usuarios hubieren podido atribuir a la Plataforma y a los Planes y la información obtenida a través de los mismos, a la falibilidad de la Plataforma o de la información brindada o accedida a través de los Planes, y en particular, aunque no de modo exclusivo, por los fallos en el acceso a las distintas páginas web de la Plataforma o de los Planes.
                    <br />
                    <br />
                    INFO AUTO no se responsabiliza por cualquier daño, perjuicio o pérdida en el equipo del Usuario originado por fallas en el sistema, servidor o en Internet.
                </p>

                <h4 className="mt-5"><b>11. Facultades de INFO AUTO</b></h4>
                <hr />
                <p>11.1. El Usuario entiende que INFO AUTO se reserva el derecho para, en todo momento, modificar o interrumpir total o parcialmente el uso de los Planes y su acceso, con o sin notificación, y que INFO AUTO no será responsable ante el Usuario ni ante un tercero por la modificación, la suspensión o la interrupción de tal acceso y uso de la Plataforma y de los Planes. El Usuario conviene en que INFO AUTO, a su entera y sola discreción, tiene el derecho (pero no la obligación) de eliminar, revocar o desactivar la cuenta del Usuario, de bloquear su dirección de email o IP, o bien de cualquier otra forma cancelar el acceso o el uso del Usuario a los Planes (o parte de ésta o alguno de sus planes) por parte del Usuario, en forma inmediata y sin notificación previa y sin que tales acciones le den al Usuario derecho de reclamo alguno. El Usuario conviene en no intentar usar los Planes luego de la cancelación por parte de INFO AUTO.</p>

                <h4 className="mt-5"><b>12. Propiedad intelectual</b></h4>

                <p>12.1. La Plataforma, la Base y los Planes son propiedad exclusiva de INFO AUTO. La Plataforma, la Base y los Planes se encuentran protegidos dentro del máximo alcance permitido por las leyes y los tratados internacionales respecto a los derechos de autor. Tanto INFO AUTO como sus mandantes se reservan todos y cada uno de los derechos asociados con la operación de la Plataforma, el acceso a las Bases y de los Planes.
                    Los materiales provistos en esta Plataforma, en la Base, así como los Planes, incluyendo todo el contenido, los derechos de propiedad intelectual e industrial de la Plataforma y de los Planes y la Base, el contenidos que se muestre en las pantallas, bases de datos, redes, códigos, desarrollo, software, arquitectura, hardware, contenidos, información, tecnología, fases de integración, funcionalidades, dominios, herramientas de venta, marcas, patentes, derechos de autor, diseños y modelos industriales, nombres comerciales, entre otros, son de propiedad de INFO AUTO y están protegidos por Derechos de Autor, la legislación marcaria y otros derechos de propiedad intelectual. Los contenidos de la Plataforma y de los Planes descriptos arriba no pueden ser cambiados, copiados, reproducidos, vendidos, alquilados, licenciados, usados, ampliados o utilizados de cualquier forma sin el previo consentimiento escrito de INFO AUTO, único titular de tales derechos.
                </p>

                <p>12.2. Nada de lo expuesto en los presentes Términos y Condiciones será considerado como la concesión, tácita o de otra manera, de ninguna licencia o derecho a usar ninguna patente, marca o ningún otro derecho de propiedad intelectual de INFO AUTO. Tampoco el presente concede ninguna licencia o derecho a usar cualquiera de los Derechos de Autor u otros derechos relativos a los materiales mencionados precedentemente.
                <br />

                La denominación y diseños de los productos expuestos en la Plataforma, en la Base y en los Planes y/o de sus marcas asociadas, así como sus ilustraciones y logos son propiedad de INFO AUTO. En consecuencia, cualquier uso de la denominación y/o de las marcas, logos y/o de la imagen de INFO AUTO requiere autorización expresa previa y escrita de INFO AUTO.</p>

                <p>12.3. El Usuario no puede descompilar o desensamblar o aplicar ingeniería inversa o de otra manera intentar descubrir los códigos fuente que pudieran encontrarse en la Plataforma. El Usuario se obliga a no reproducir, duplicar, copiar, vender, revender ni explotar con fines comerciales ningún aspecto de la Plataforma.</p>

                <p>12.4. El Usuario deberá adoptar y mantener, a su costo y cargo, las medidas de seguridad habituales en el mercado para proteger la propiedad intelectual de INFO AUTO frente a situaciones de acceso a la Base o Planes, utilización y/o reproducción no autorizadas, y en caso de que se produzca alguna de las anteriores situaciones, deberá notificarlo inmediatamente a INFO AUTO.</p>

                <h4 className="mt-5"><b>13. Incumplimientos</b></h4>

                <p>13.1. Sin perjuicio de otras medidas, INFO AUTO podrá advertir, suspender en forma temporal o definitivamente la Cuenta de un Usuario, iniciar las acciones que estime pertinentes y/o suspender la prestación de servicios a través de los Planes si (a) se quebrantara alguna ley, o cualquiera de las estipulaciones de los presentes Términos y Condiciones y demás políticas incorporadas por referencia; (b) incumpliera sus compromisos como Usuario, en particular (aunque no limitado a ello) en lo referente al uso de la información obtenida de las Bases y a través de la Plataforma y/o los Planes; (c) se incurriera a criterio de INFO AUTO en conductas o actos dolosos o fraudulentos; (d) no pudiera verificarse la identidad del Usuario o cualquier información proporcionada por el mismo fuere errónea; (e) se rechazara el pago online por cualquier causa y esto no es solucionado inmediatamente por el Usuario (y a más tardar en la fecha de vencimiento del plazo de pago).
                <br />
                <br />
                Ninguna de estas acciones serán objeto de indemnización alguna al Usuario, estando permitidas dentro de las facultades reservadas por INFO AUTO como titular de los derechos de propiedad intelectual de la Plataforma y de la Base.
                </p>

                <h4 className="mt-5"><b>14. Responsabilidad e indemnidades </b></h4>
                <p>14.1. El Usuario reconoce y acepta que es responsable, indemnizará y mantendrá indemne a INFO AUTO, sus funcionarios, afiliados, sucesores, cesionarios, directores, agentes, proveedores de servicios, prestadores y empleados, de los reclamos o las demandas, incluyendo pero no limitado a los honorarios de los letrados y las costas judiciales razonables, y que defenderá y mantendrá indemne a INFO AUTO, contra todo daño y/o perjuicio, cualquiera fuese su naturaleza, como consecuencia del incumplimiento del Usuario de cualquiera de las disposiciones del presente, o de la violación por el mismo de cualquier ley o derecho de un tercero, o a raíz de o bien con relación al uso dado por los Usuarios a la información de las Bases, el acceso a los Planes, su violación de los Términos y Condiciones, o bien el incumplimiento de las declaraciones y las garantías establecidas en el presente o la violación de los derechos de otros.</p>
                <p>14.2. EN NINGÚN CASO INFO AUTO SERÁ RESPONSABLE POR DAÑOS Y PERJUICIOS DIRECTOS, INDIRECTOS, IMPREVISTOS, MEDIATOS O PUNITORIOS  QUE RESULTEN DE ALGÚN ASPECTO DEL USO QUE EL USUARIO HAGA DE LA PLATAFORMA O LA INFORMACIÓN BRINDADA, SEA O NO A TRAVÉS DE LOS PLANES, YA SEA QUE LOS DAÑOS Y PERJUICIOS SURJAN A PARTIR DEL USO O EL MAL USO DE LA PLATAFORMA Y/O DE LOS PLANES Y/O DE LA INFORMACIÓN INCLUIDA EN ELLOS, ANTE LA INCAPACIDAD DE UTILIZAR LA PLATAFORMA Y/O LOS PLANES /O ACCEDER A LA INFORMACIÓN CONTENIDA EN ELLOS O LA INTERRUPCIÓN, SUSPENSIÓN, MODIFICACIÓN, ALTERACIÓN O CANCELACIÓN DE LA PLATAFORMA Y/O DE LOS PLANES, O SU ACCESO A LOS MISMOS. LAS LIMITACIONES QUE ANTECEDEN SE APLICARÁN DENTRO DEL MÁXIMO ALCANCE PERMITIDO POR LA LEY.</p>

                <p>14.3. A todo efecto, la responsabilidad total y por todo concepto de INFO AUTO  queda limitada como importe máximo al arancel correspondiente a tres meses de uso de los Planes arancelados contratados y en caso de reclamos por Usuarios que no contrataron Planes, al máximo arancel correspondiente a tres meses de uso del Plan más caro a la fecha de ocurrido el evento que da lugar al reclamo.</p>

                <h4 className="mt-5"><b>15. Exención de Garantías</b></h4>
                <p>15.1. EL USUARIO EXPRESAMENTE CONVIENE EN QUE EL USO DE LA PLATAFORMA Y DE LA INFORMACIÓN OBTENIDA DE LA BASE Y DE SU ACCESO A LOS PLANES ES DE SU EXCUSIVA RESPONSABILIDAD. LA PLATAFORMA Y LOS PLANES ASÍ COMO LAS BASES SE OFRECEN "COMO ESTÁN" O BIEN "COMO SE ENCUENTRAN DISPONIBLES", SIN GARANTÍA ALGUNA. DENTRO DEL ALCANCE PERMITIDO POR LA LEY, SE EXIME A INFO AUTO, SUS OFICIALES, DIRECTORES, EMPLEADOS, Y AGENTES DE TODA GARANTÍA, EXPRESA O IMPLICITA, EN CONEXIÓN CON LA PLATAFORMA, LOS PLANES Y LA BASE Y SU USO. INFO AUTO NO GARANTIZA NI REPRESENTA LA CERTEZA, EXACTITUD O PUNTUALIDAD DE LA INFORMACIÓN O CONTENIDO TRANSMITIDO A TRAVÉS DE LA PLATAFORMA, LOS PLANES Y LA BASE NI DE LOS ENLACES EN LA PLATAFORMA Y NO ASUME RESPONSABILIDAD ALGUNA POR (I) ERRORES, IMPRECISIONES, DEL CONTENIDO, (II) DAÑO PERSONAL O DAÑO A PROPIEDADES, O DAÑO DE CUALQUIER NATURALEZA QUE RESULTE DE SU ACCESO Y/O USO DE LA PLATAFORMA Y DE LOS PLANES Y DE LA BASE (III) ACCESO Y/O USO SIN AUTORIZACION DE LOS SERVIDORES DE INFO AUTO O CONTRATADOS POR INFO AUTO AL EFECTO Y/O A CUALQUIER INFORMACION PERSONAL ALMACENADA EN LOS MISMOS (IV) CUALQUIER INTERRUPCION O CESACION DE TRANSMISION DESDE O HACIA LA PLATAFORMA Y LOS PLANES Y LA BASE (V) CUALQUIER FALLA, VIRUS, TROYANO O SIMILAR QUE PUEDA SER TRANSMITIDO DESDE O A TRAVES DE NUESTRA PLATAFORMA POR UN TERCERO, Y/O (VI) CUALQUIER ERROR U OMISION EN EL CONTENIDO, ATENCIÓN, ASESORAMIENTO, RECOMENDACIÓN O CUALQUIER OTRA INFORMACIÓN BRINDADA A TRAVÉS DE LA PLATAFORMA, LOS PLANES, LAS BASES Y OTROS CANALES DE ATENCIÓN O POR CUALQUIER PERDIDA O DAÑO DE CUALQUIER TIPO EN EL CUAL SE HAYA INCURRIDO COMO RESULTADO DEL USO DEL CONTENIDO POSTEADO EN LA PLATAFORMA, EMAIL ENVIADO A TRAVES DE LA PLATAFORMA O DE LA BASE Y PLANES, TRANSMITIDO O HECHO DISPONIBLE A TRAVES DE LA PLATAFORMA O DE LOS PLANES. </p>

                <h4 className="mt-5"><b>16. Fuerza mayor </b></h4>

                <p>16.1. Ninguna de las partes será responsable del incumplimiento de sus obligaciones, si dicho incumplimiento se debiera a un acontecimiento imprevisible, inevitable y más allá del control de la parte afectada, y no pueda evitarse a pesar de la puesta en práctica de acciones comerciales razonables por la parte afectada (“Motivo de Fuerza Mayor”).</p>

                <h4 className="mt-5"><b>17. Cesión</b></h4>

                <p>17.1. Estos Términos y Condiciones, y los derechos y obligaciones derivadas del uso de la Plataforma, así como de las demás políticas incorporadas por referencia no pueden ser transferidas o asignadas por el Usuario a un tercero, pero si pueden ser cedidos por INFO AUTO sin restricciones.</p>

                <h4 className="mt-5"><b>18. Renuncia</b></h4>

                <p>18.1. Ningún retraso u omisión por parte de INFO AUTO para ejercer cualquier derecho o poder que posea en virtud de los presentes Términos y Condiciones o para objetar el incumplimiento de cualquier cláusula de su parte de manera oportuna y completa perjudicará dicho derecho o poder ni se interpretará como una renuncia a cualquier incumplimiento posterior o a cualquier otra cláusula.</p>

                <h4 className="mt-5"><b>19. Ley aplicable y jurisdicción</b></h4>

                <p>INFO AUTO fija como domicilio Soldado de la Independencia 1130, 2do Piso, Depto. A, Ciudad Autónoma de Buenos Aires, República Argentina. Se entenderá que a todos los efectos que pudieran corresponder, el Usuario fija domicilio en aquél informado a los fines de su registración en la Plataforma.
                <br />
                <br />
                INFO AUTO podrá informar a los Usuarios cualquier cuestión relativa a la Plataforma y su uso o su contratación, por medio de un aviso general en la Plataforma, o por medio de mensaje de correo electrónico a la dirección de correo electrónico registrada en la información brindada por el Usuario.
                <br />
                <br />
                Estos Términos y Condiciones de Uso han sido redactados y deberán ser interpretados de acuerdo con las leyes de la República Argentina. Cualquier disputa, conflicto o divergencia que pudiera derivarse de la utilización de la Plataforma y de los Términos y Condiciones se someterá a la jurisdicción de los Tribunales Nacionales Ordinarios con sede en la Ciudad Autónoma de Buenos Aires, con exclusión de cualquier otro fuero o jurisdicción que pudiere corresponder.
                </p>

                <h4 className="mt-5"><b>20. Atención a Usuarios</b></h4>

                <p>Por cualquier consulta incluyendo consultas técnicas, los Usuarios deberán comunicarse por e-mail a info@infoauto.com.ar, donde se les responderá a la mayor brevedad posible.</p>

                <h4 className="mt-5"><b>21.  Otros documentos</b></h4>

                <p>Los presentes Términos y Condiciones incluyen también las Políticas de privacidad y confidencialidad de la información y toda otra política descripta en la Plataforma asi como las condiciones particulares de los Planes, todos los que se consideran incorporadas por referencia a la presente.
                <br />
                <br />
                Estos Términos y Condiciones y las demás políticas incorporadas a la presente constituyen el total y completo entendimiento y todo lo acordado entre el Usuario e INFO AUTO. Asimismo, rigen el uso de la Plataforma y prevalecen sobre todo previo entendimiento, propuesta, acuerdo, negociación y discusión entre las partes, ya sea en forma escrita o verbal. 

                </p>







            </div>
        </div>
    )
}
