import {
  faCheckCircle,
  faCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiHelper } from "../../helpers";
import actions from "../../store/actions";
import { SquareButton } from "../atoms";

export default ({ pro }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [idFree, setIdFree] = useState()
  const [idAutoBasico, setIdAutoBasico] = useState()
  const [idPremium, setIdPremium] = useState()


  const getSubscriptions = async () => {
    const { data } = await ApiHelper.get("admin", "/subscription/template");

    const freePlan = data.filter(plan => plan.subscriptionType.subscriptionCode === "professionalFree")
    setIdFree(freePlan[0].id)

    const carPlan = data.filter(plan => plan.subscriptionType.subscriptionCode === 'individualCar')
    setIdAutoBasico(carPlan[0]?.id)

    const premiumPlan = data.filter(plan => plan.subscriptionType.subscriptionCode === 'individualPremium')
    setIdPremium(premiumPlan[0]?.id)

    setSubscriptions(data);
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  return (
    <div className={"row mt-5 d-flex justify-content-center"}>
      <div className="col-12 col-md-4">
        <SquareButton
          onClick={() => setShowTable(!showTable)}
          text={
            showTable
              ? "Ocultar características"
              : "Ver todas las características"
          }
          className='col-md-12 col-5'
        />
      </div>
      {showTable && (
        <div className="col-12" style={{ overflowX: "scroll" }}>
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-md-10 background_gray mt-4 p-0">
              <table className={"w-100"}>
                <tr >
                  <th className={"bg_black font-weight-bold text-center p-3 border-rigth border-dark "}>Opciones del plan</th>
                  <th
                    className={
                      "text-uppercase p-3 bg_blue"
                    }
                  ></th>
                  <th
                    className={
                      "text-uppercase p-3 text-center bg_blue"
                    }
                  >
                    Particular
                  </th>
                  <th className={"text-uppercase p-3 bg_blue"}></th>
                </tr>
                <tr>
                  <td className={"p-3 font-weight-bold border-right border-dark"}>Categoría</td>
                  <td
                    className={
                      "p-3 text-center font-weight-bold border-right border-dark"
                    }
                  >
                    Gratis
                  </td>
                  <td
                    className={
                      "p-3 text-center font-weight-bold border-right border-dark"
                    }
                  >
                    Auto / Moto
                  </td>
                  <td className={"p-3 text-center font-weight-bold"}>
                    Premium
                  </td>
                </tr>
                <tr className={"bg_white"}>
                  <td className={" border-right border-dark p-3 text-left"}>
                    <FontAwesomeIcon icon={faCircle} className={"mr-2"} />{" "}
                    Consulta de 0km
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    Ilimitado
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    Ilimitado
                  </td>
                  <td className={"p-3 text-center"}>Ilimitado</td>
                </tr>
                <tr>
                  <td className={" border-right border-dark p-3 text-left"}>
                    <FontAwesomeIcon icon={faCircle} className={"mr-2"} />{" "}
                    Consulta de usados por mes
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    {
                      subscriptions.find((s) => s.id === idFree)
                        ?.infocreditsPerMonth
                    }{" "}

                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    {
                      subscriptions.find((s) => s.id === idAutoBasico)
                        ?.infocreditsPerMonth
                    }{" "}

                  </td>
                  <td className={"p-3 text-center"}>
                    {
                      subscriptions.find((s) => s.id === idPremium)
                        ?.infocreditsPerMonth
                    }{" "}

                  </td>
                </tr>
                <tr className={" bg_white"}>
                  <td className={"border-right border-dark p-3 text-left"}>
                    <FontAwesomeIcon icon={faCircle} className={"mr-2"} />{" "}
                    Revista Digital
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size={"lg"}
                      className={"gray_icon"}
                    />
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                  <td className={"p-3 text-center"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={" border-right border-dark p-3 text-left"}>
                    <FontAwesomeIcon icon={faCircle} className={"mr-2"} />{" "}
                    Historial de Búsquedas
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size={"lg"}
                      className={"gray_icon"}
                    />
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                  <td className={"p-3 text-center"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                </tr>
                <tr className={"bg_white"}>
                  <td className={" border-right border-dark p-3 text-left"}>
                    <FontAwesomeIcon icon={faCircle} className={"mr-2"} /> Mis
                    Autos Favoritos
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size={"lg"}
                      className={"gray_icon"}
                    />
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size={"lg"}
                      className={"gray_icon"}
                    />
                  </td>
                  <td className={"p-3 text-center"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={" border-right border-dark p-3 text-left"}>
                    <FontAwesomeIcon icon={faCircle} className={"mr-2"} /> Ficha
                    Técnica
                  </td>
                  <td
                    className={
                      "p-3 text-center font-weight-bold border-right border-dark"
                    }
                  >
                    Básica
                  </td>
                  <td
                    className={
                      "p-3 text-center font-weight-bold color_yellow border-right border-dark"
                    }
                  >
                    Completa
                  </td>
                  <td
                    className={"p-3 text-center font-weight-bold color_yellow"}
                  >
                    Completa
                  </td>
                </tr>
                <tr className={"bg_white"}>
                  <td className={"border-right border-dark p-3 text-left"}>
                    <FontAwesomeIcon icon={faCircle} className={"mr-2"} />{" "}
                    Descripción Marca Modelo
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                  <td className={"p-3 text-center"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={"border-right border-dark p-3 text-left"}>
                    <FontAwesomeIcon icon={faCircle} className={"mr-2"} />{" "}
                    Comparador
                  </td>
                  <td
                    className={
                      "p-3 text-center font-weight-bold border-right border-dark"
                    }
                  >
                    2 Modelos
                  </td>
                  <td
                    className={
                      "p-3 text-center font-weight-bold border-right border-dark"
                    }
                  >
                    4 Modelos
                  </td>
                  <td className={"p-3 text-center font-weight-bold"}>
                    4 Modelos
                  </td>
                </tr>
                <tr className={"bg_white"}>
                  <td className={"border-right border-dark p-3 text-left"}>
                    <FontAwesomeIcon icon={faCircle} className={"mr-2"} /> Fotos
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                  <td className={"p-3 text-center"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={" border-right border-dark p-3 text-left"}>
                    <FontAwesomeIcon icon={faCircle} className={"mr-2"} />{" "}
                    Precios Históricos
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size={"lg"}
                      className={"gray_icon"}
                    />
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size={"lg"}
                      className={"gray_icon"}
                    />
                  </td>
                  <td className={"p-3 text-center"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                </tr>
                <tr className={"bg_white"}>
                  <td className={"border-right border-dark p-3 text-left"}>
                    <FontAwesomeIcon icon={faCircle} className={"mr-2"} />{" "}
                    Precios Usados del Año
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size={"lg"}
                      className={"gray_icon"}
                    />
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size={"lg"}
                      className={"gray_icon"}
                    />
                  </td>
                  <td className={"p-3 text-center"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={"border-right border-dark p-3 text-left"}>
                    <FontAwesomeIcon icon={faCircle} className={"mr-2"} /> Guía
                    ÚTIL
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size={"lg"}
                      className={"gray_icon"}
                    />
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                  <td className={"p-3 text-center"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                </tr>
                {pro && (
                  <tr>
                    <td className={"p-3 text-left"}>
                      <FontAwesomeIcon icon={faCircle} className={"mr-2"} />{" "}
                      Info PRO
                    </td>
                    <td className={"p-3 text-center border-right border-dark"}>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        size={"lg"}
                        className={"gray_icon"}
                      />
                    </td>
                    <td className={"p-3 text-center border-right border-dark"}>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        size={"lg"}
                        className={"gray_icon"}
                      />
                    </td>
                    <td className={"p-3 text-center"}>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        size={"lg"}
                        className={"gray_icon"}
                      />
                    </td>
                  </tr>
                )}
                <tr className={"bg_white"}>
                  <td className={" border-right border-dark p-3 text-left"}>
                    <FontAwesomeIcon icon={faCircle} className={"mr-2"} />{" "}
                    Compartir Consulta
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size={"lg"}
                      className={"gray_icon"}
                    />
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                  <td className={"p-3 text-center"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={" border-right border-dark p-3 text-left"}>
                    <FontAwesomeIcon icon={faCircle} className={"mr-2"} />{" "}
                    Búsqueda Visual
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                  <td className={"p-3 text-center border-right border-dark"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                  <td className={"p-3 text-center"}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size={"lg"}
                      className={"color_green"}
                    />
                  </td>
                </tr>
                {!user && (
                  <tr className={"background_gray"}>
                    <td className={"p-3 "}></td>
                    <td className={"p-3 text-center border-right border-dark"}>
                      <SquareButton
                        onClick={() =>
                          dispatch(actions.app.setShowRegister(true))
                        }
                        text="REGISTRATE"
                      />
                    </td>
                    <td className={"p-3 text-center border-right border-dark"}>
                      <SquareButton
                        onClick={() =>
                          dispatch(actions.app.setShowRegister(true))
                        }
                        text="COMENZAR"
                      />
                    </td>
                    <td className={"p-3 text-center"}>
                      <SquareButton
                        onClick={() =>
                          dispatch(actions.app.setShowRegister(true))
                        }
                        text="COMENZAR"
                      />
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
