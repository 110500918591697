import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";

export const FacebookButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="btn facebook_button btn-circle mr-2">
      <FontAwesomeIcon icon={faFacebookF} color={"white"} />
    </button>
  );
};
