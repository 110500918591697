import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppHelper } from 'helpers'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { COMPARATOR, INFOGRID, VISUAL_SEARCH } from 'static/permissions/sections'
import dataFiscalImg from '../../../static/img/data_fiscal.jpg'
import pdpImg from '../../../static/img/PDP_dark.png'
import actions from '../../../store/actions'
import { Logo } from '../../atoms'

const BottomFooter = () => {
	const dispatch = useDispatch()
	const [showServicesMenu, setShowServicesMenu] = useState(false)
	const [showSearchMenu, setShowSearchMenu] = useState(false)
	const user = useSelector((state) => state.user.user)

	const handleServicesClick = () => {
		setShowServicesMenu(!showServicesMenu)
	}

	const handleSearchClick = () => {
		setShowSearchMenu(!showSearchMenu)
	}

	return (
		<div id='bottom_footer' className='container-fluid py-0 pt-4 pt-md-3 px-0'>
			<div className='row no-gutters ml-4 ml-md-0'>
				<div className='col-6 col-md-3 p-2 pt-md-3 px-md-5 pb-md-5'>
					<ul className='text-white text-md-left'>
						<li className='text-uppercase mb-2 link'>
							<a onClick={handleServicesClick}>
								<strong>Servicios</strong>
								<FontAwesomeIcon
									icon={showServicesMenu ? faChevronUp : faChevronDown}
									className='ml-2'
								/>
							</a>
						</li>
						{showServicesMenu && (
							<div>
								<ul>
									<li className='text-uppercase mb-2'>
										<a href={'/particulares'}>
											<strong>Particulares</strong>
										</a>
									</li>
									<li className='text-uppercase mb-2'>
										<a href={'/profesionales'}>
											<strong>Profesionales</strong>
										</a>
									</li>
									<li className='text-uppercase mb-2'>
										<a href={'/empresas'}>
											<strong>Empresas</strong>
										</a>
									</li>
								</ul>
							</div>
						)}
						<li className='text-uppercase mb-2'>
							<a href={'/nosotros'}>
								<strong>Nosotros</strong>
							</a>
						</li>
						<li className='text-uppercase mb-2'>
							<a href={'/noticias'}>
								<strong>Noticias</strong>
							</a>
						</li>
						<li className='text-uppercase mb-2 link'>
							<a onClick={handleSearchClick}>
								<strong>Búsquedas</strong>
								<FontAwesomeIcon
									icon={showSearchMenu ? faChevronUp : faChevronDown}
									className='ml-2'
								/>
							</a>
						</li>
						{showSearchMenu && (
							<div>
								<ul>
									<li className='text-uppercase mb-2'>
										<a href={'/busqueda-asistida'}>
											<strong>Asistida</strong>
										</a>
									</li>
									{AppHelper.hasPermission(user, VISUAL_SEARCH) && (
										<li className='text-uppercase mb-2'>
											<a href={'/busqueda-visual'}>
												<strong>Visual</strong>
											</a>
										</li>
									)}
									{AppHelper.hasPermission(user, INFOGRID) && (
										<li className='text-uppercase mb-2'>
											<a href={'/infogrid'}>
												<strong>InfoGrid</strong>
											</a>
										</li>
									)}
									{AppHelper.hasPermission(user, COMPARATOR) && (
										<li className='text-uppercase mb-2'>
											<a href={'/comparador'}>
												<strong>Comparador</strong>
											</a>
										</li>
									)}
								</ul>
							</div>
						)}
						<li className='text-uppercase mb-2'>
							<a href={'/revista'}>
								<strong>Revista</strong>
							</a>
						</li>
						<li className='text-uppercase mb-2 link'>
							<a onClick={() => dispatch(actions.app.setShowContact(true))}>
								<strong>Contacto</strong>
							</a>
						</li>
					</ul>
				</div>
				<div className='col-6 col-md-3 p-2 pt-md-3 px-md-5 pb-md-5'>
					<ul className='text-white  text-md-left'>
						<li className='mb-2'>
							<a href={'/politicas'}>Políticas de Privacidad</a>
						</li>
						<li className='mb-2'>
							<a href={'/terminos'}>Términos de uso</a>
						</li>
						<li className='mb-2'>
							<a href={'/preguntas-frecuentes'}>Preguntas Frecuentes</a>
						</li>
						<li className='mb-2'>
							<a href={'/calificaciones'}>Calificaciones</a>
						</li>
						<li className='mb-2'>
							<a href={'/profesionales'}>Profesionales</a>
						</li>
						<li className='mb-2'>
							<a href={'/particulares'}>Particulares</a>
						</li>
						<li className='mb-2'>
							<a href={'/empresas'}>Empresas</a>
						</li>
					</ul>
				</div>
				<div className='col-6 col-md-3 p-2 pt-md-3 px-md-5 pb-md-5'>
					<ul className='text-md-left'>
						<li>
							<p>
								Soldado de la Independencia 1130
								<br />
								Piso 2A - (C1426BTP)
								<br />
								Buenos Aires - Argentina{' '}
							</p>
						</li>
						<li>Tel: (011) 4779-9485</li>
					</ul>
				</div>
				<div className='col-6 col-md-3 p-2 pt-md-3 px-md-5 pb-md-5'>
					<ul className=' text-md-left'>
						<li>
							<img src={dataFiscalImg} width='80' alt='dataFiscal' />
						</li>
						<li className='mt-2'>
							<img src={pdpImg} width='80' alt='pdp' />
						</li>
					</ul>
				</div>
			</div>

			<div className='bottom_brand mt-4 px-4 d-flex flex-md-row flex-column align-items-center justify-content-between'>
				<Link
					to={'/'}
					className='d-none d-md-block navbar-brand px-4 py-4 bg-white text-dark align-items-center mb-3 mb-md-0'
					style={{ height: '80px' }}
				>
					<Logo />
				</Link>
				<div
					id='trademark'
					className='text-light d-flex align-items-center justify-content-between'
				>
					<div className='float-left mr-3 '>
						<p>InfoAuto© 2021</p>
					</div>
					<div className='float-right text-right'>
						<p>
							Powered by{' '}
							<a href='https://abstractsolutions.com.ar/'>
								<strong>ABstract Solutions</strong>
							</a>{' '}
							& designed by{' '}
							<a href='https://oslocreative.co/'>
								<strong>Oslo Creative Co.</strong>
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BottomFooter
