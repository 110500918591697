import React, { useState, useEffect } from "react";
import TimeUnit from "./TimeUnit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { ContactModal } from "../../organisms";
import moment from "moment";
import { set } from "numeral";

const CountDown = () => {
  const [time, setTime] = useState(['-', '-', '-', '-']);
  const [showModal, setShowModal] = useState(false)


  useEffect(() => {
    const interval = setInterval(() => {
      let now = new Date();

      let nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      var z = new Date(nextMonth - now);

      var epoch = new Date('1970-01-01T00:00:00');
      var diff_years = z.getYear() - epoch.getYear();
      var diff_month = z.getMonth() - epoch.getMonth();
      var diff_days = z.getDate() - epoch.getDate();
      var diff_hours = z.getHours() - epoch.getHours();
      var diff_minutes = z.getMinutes() - epoch.getMinutes();
      var diff_seconds = z.getSeconds() - epoch.getSeconds();

      setTime([diff_days, diff_hours, diff_minutes, diff_seconds])
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <div className="row mt-5">
      <div className="col-12 col-lg-8 border-right">
        <div className="row">
          <TimeUnit number={time[0]} unit={"días"} />
          <TimeUnit number={time[1]} unit={"horas"} />
          <TimeUnit number={time[2]} unit={"minutos"} />
          <TimeUnit number={time[3]} unit={"segundos"} />
        </div>
      </div>
      <div className="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-start">
        <p className="font-weight-bold mb-0">Para la próxima edición.</p>
        <small className="link" onClick={() => setShowModal(true)}>
          Solicitar tu ejemplar <FontAwesomeIcon icon={faChevronRight} />
        </small>
      </div>
      <ContactModal show={showModal} magazine={true} hide={() => setShowModal(false)} />

    </div>
  );
};

export default CountDown;
