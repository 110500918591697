import React, { useEffect, useState, version } from "react";
import { Select } from "./Select";
import { ApiHelper } from "../../helpers";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../store/actions";
import PaginatedSelect from "./PaginatedSelect";

export const FilterOption = ({ option, placeholder, selectedOptions }) => {
  const token = useSelector((state) => state.user.token);
  const filters = useSelector((state) => state.search.filters);
  const [ values, setValues ] = useState([]);
  const vehicle = useSelector((state) => state.search.vehicle);
  const dispatch = useDispatch();
  const [ hasNewPrice, setHasNewPrice ] = useState(false);


  useEffect(() => {
    const brand = filters.find((filter) => filter.type === "brand");
    const model = filters.find((filter) => filter.type === "model");
    switch (option.type) {
      case "brand":
        getBrands();
        return;
      case "model":
        if (brand) {
          getModels();
        }
        return;
      case "year":
        if (model && brand) {
          getYears();
        }
        return;
      case "version":
        if (model && brand) {
          getVersions();
        }
        return;
      case "doors":
        getDoors();
        return;
      case "vehicleType":
        getVehicles();
        return;
      case "fuel":
        getFuel();
        return;
    }
  }, [ option.type, vehicle ]);

  const onSelect = (value) => {
    let filter = {
      type: option.type,
    };
    setValues([]);
    switch (option.type) {
      case "brand":
        filter.id = value.value;
        filter.name = value.label;
        dispatch(actions.search.setSearch([ filter ]));
        return;
      case "vehicleType":
        filter.id = value.value;
        filter.name = value.name;
        filter.label = value.label;
        dispatch(actions.search.setSearch([ ...filters, filter ]));
        return;
      case "fuel":
        filter.id = value.value;
        filter.name = value.name;
        filter.label = value.label;
        dispatch(actions.search.setSearch([ ...filters, filter ]));
        return;
      default:
        filter.id = value.value;
        filter.name = value.label;
        dispatch(actions.search.setSearch([ ...filters, filter ]));
    }
  };

  const getBrands = async () => {
    const data = {
      hasUsedPrice: true,
      vehicleType: vehicle,
      paginated: false,
    };
    const res = await ApiHelper.getDownloadedBrands(data);

    res.map((car) => {
      car.value = car.id;
      car.label = car.name;

    });
    setValues(res);
  };

  const getModels = async () => {
    const selectedBrand = filters.find((filter) => (filter.type = "brand"));
    const data = {
      hasUsedPrice: true,
      vehicleType: vehicle,
      paginated: false,
    };
    const res = await ApiHelper.getModels(selectedBrand.id, data);
    res.map((brand) => {
      brand.value = brand.id;
      brand.label = brand.name;
    });
    setValues(res);
  };

  const getYears = async () => {
    const selectedBrand = filters.find((filter) => filter.type === "brand");
    const selectedModel = filters.find((filter) => filter.type === "model");
    const data = {
      features: {},
      hasUsedPrice: true,
      vehicleType: vehicle,
    };
    const res = await ApiHelper.getYears(
      selectedBrand.id,
      selectedModel.id,
      data
    );

    const selectedYear = filters.find((filter) => filter.type === "year");
    const dataNew = {
      prices_from: selectedYear && selectedYear.id,
      year_from: selectedYear && selectedYear.id,

      hasUsedPrice: true,
      vehicleType: vehicle,
    };
    const resNew = await ApiHelper.getVersions(
      selectedBrand.id,
      selectedModel.id,
      dataNew
    );
    let foundVersions = [];

    resNew.map((data) => {
      const version = {
        value: data.codia,
        label: data.description,
        newPrice: data.hasNewPrice || data.list_price,
      };
      foundVersions.push(version);
    });

    let years = [];
    res.response.map((data) => {
      data.value = data.year;
      data.label = data.year;
      const year = {
        value: data.year,
        label: data.year,
      };
      years.push(year);
    });
    if (!!foundVersions.find((version) => version.newPrice)) {
      years.push({ value: "0km", label: "0km" });
    }
    years.reverse();
    setValues(years);
  };

  const getVersions = async () => {
    const selectedBrand = filters.find((filter) => filter.type === "brand");
    const selectedModel = filters.find((filter) => filter.type === "model");
    const selectedYear = filters.find((filter) => filter.type === "year");
    const data = {
      hasUsedPrice: true,
      vehicleType: vehicle,
    };
    if (selectedYear && selectedYear.value === "0km") {
      data.newPrice = true;
    } else {
      data.prices_from = selectedYear ? selectedYear.id : null;
      data.year_from = selectedYear ? selectedYear.id : null;
    }
    const res = await ApiHelper.getVersions(
      selectedBrand.id,
      selectedModel.id,
      data
    );


    let foundVersions = [];
    res.map((data) => {
      const version = {
        value: data.codia,
        label: data.description,
        newPrice: data.hasNewPrice || data.list_price,
      };
      foundVersions.push(version);
    });

    setValues(foundVersions);
  };

  const getDoors = async () => {
    const data = {
      vehicleType: vehicle,
    };
    const res = await ApiHelper.post("preciosa", "/features/11/choices", data);
    let options = [];
    res.data.response.map((data) => {
      const option = {
        value: data.id,
        label: data.description,
      };
      options.push(option);
    });
    setValues(options);
  };

  const getVehicles = async () => {
    const data = {
      vehicleType: vehicle,
    };
    const res = await ApiHelper.post("preciosa", `/features/3/choices`, data);
    let options = [];
    res.data.response.map((data) => {
      const option = {
        value: data.id,
        label: data.long_description,
        name: data.description,
      };
      options.push(option);
    });
    setValues(options);
  };

  const getFuel = async () => {
    const data = {
      vehicleType: vehicle,
    };
    const res = await ApiHelper.post(`preciosa`, "/features/1/choices", data);
    let options = [];
    res.data.response.map((data) => {
      const option = {
        value: data.id,
        label: data.long_description,
        name: data.description,
      };
      options.push(option);
    });
    setValues(options);
  };

  return (
    <div className="mb-4">
      <p className="font-weight-bold mb-2">{option.title}</p>
      <Select
        placeholder={placeholder}
        options={values}
        onSelect={(value) => onSelect(value)}
        selectedOption={null}
      />

      {/* <PaginatedSelect
        // key={"brand"}
        value={values}
        // loadOptions={loadBrands}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        onChange={(value) => onSelect(value)}
        isSearchable={false}
        placeholder={placeholder}
        // cacheUniqs={[ vehicleType ]}
        additional={{
          page: 1,
        }}
      /> */}


    </div>
  );
};
