import React, { useState, useEffect } from 'react';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer } from 'recharts';
import numeral from 'numeral';
import { parse } from "@fortawesome/fontawesome-svg-core";

export const PriceChart = ({ prices, small, hidePrices }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let parsedData = [];
    prices.map(price => {
      let number = numeral(price.price * 1000).format('$0,0[.]00');
      number = number.replace(',', '.');
      const data = {
        'anio': price.year,
        'Precio': price.price * 1000,
        precioInt: price.price * 1000
      };
      parsedData.push(data);
    });
    setData(parsedData);
  }, [prices]);

  function CustomTooltip({ payload, label, active }) {
    if (active) {
      return (
        <div className="">
          <p className="label">{`${payload[0].payload.anio} / $${new Intl.NumberFormat('es').format(payload[0].value / 1000)}k`}</p>
        </div>
      );
    }

    return null;
  }

  return (
    <ResponsiveContainer height={small ? 150 : 300} width={'100%'} >
      <LineChart data={data} margin={{ top: 5, right: 50, left: 50, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="fabricación" style={{ stroke: 'black' }} />
        <YAxis hide={hidePrices} tick={{ color: 'white', stroke: 'none' }} style={{ stroke: 'black', color: '#ccc' }} domain={['dataMin - 10000', 'dataMax + 10000']} tickFormatter={(value) => `$${new Intl.NumberFormat('es').format(value)}`} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line type="monotone" dataKey="Precio" stroke="#10ffdc" tickFormatter={(value) => `$${new Intl.NumberFormat('es').format(value)}`} />
      </LineChart>
    </ResponsiveContainer>
  )
};


