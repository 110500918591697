const actionsTypes = {
  SET_USER: "SET_USER",
  FILL_USER_DATA: "FILL_USER_DATA",
  CLEAR_SEARCH: "CLEAR_SEARCH",
  SET_PRIMARY_SEARCH: "SET_PRIMARY_SEARCH",
  REMOVE_SEARCH_ELEMENT: "REMOVE_SEARCH_ELEMENT",
  SET_SEARCH: "SET_SEARCH",
  SET_FILTER_TYPES: "SET_FILTER_TYPES",
  SET_VEHICLE_TYPES: "SET_VEHICLE_TYPES",
  SET_VEHICLE_TYPE: "SET_VEHICLE_TYPE",
  SET_DOLLAR: "SET_DOLLAR",
  SET_LOADING: "SET_LOADING",
  SET_SEARCH_BAR_RESULTS: "SET_SEARCH_BAR_RESULTS",
  SET_SHOW_LOGIN: "SET_SHOW_LOGIN",
  SET_SHOW_REGISTER: "SET_SHOW_REGISTER",
  SET_SHOW_CONTACT: "SET_SHOW_CONTACT",
  SET_SHOW_RESET: "SET_SHOW_RESET",
  SET_SHOW_PAYMENT_PENDING: "SET_SHOW_PAYMENT_PENDING",
  SET_SHOW_RECOVER: "SET_SHOW_RECOVER",
  SET_SHOW_BILLING_MODAL: "SET_SHOW_BILLING_MODAL",
  SET_SHOW_WRONG_PLAN_MODAL: "SET_SHOW_WRONG_PLAN_MODAL",
  SET_SHOW_PAYMENT_MODAL: "SET_SHOW_PAYMENT_MODAL",
  SET_LOGIN_MESSAGE: "SET_LOGIN_MESSAGE",
  SET_SELECTED_PLAN: "SET_SELECTED_PLAN",
  SET_SHOW_SUBSCRIPTION_DISABLED: "SET_SHOW_SUBSCRIPTION_DISABLED",
};

export default actionsTypes;
