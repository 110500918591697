import React from "react";

export const ManufacturingYears = ({ years }) => {
  
  return (

    <div className="row mt-2">
      <div className="col-lg-3">
        <small className="font-weight-bold">Años de comercialización</small>
      </div>
      <div className="col-lg-4 ">
        <small>
          {years?.map((year, index) => `${index > 0 ? " - " : ""}${year}`)}
        </small>
      </div>
      <div className="col-lg-5">
        <small className={'mb-0 w-100 text-right'}>Todos los valores expresados son en <span className='font-weight-bold'>pesos argentinos.</span></small>
      </div>
    </div>
  );
};
