import userActions from "./userActions";
import searchActions from "./searchActions";
import filterTypesActions from "./filterTypesActions";
import vehicleTypesActions from "./vehicleTypesActions";
import dollarActions from "./dollarActions";
import appActions from "./appActions";

const actions = {
  user: userActions,
  search: searchActions,
  filterTypes: filterTypesActions,
  vehicleTypes: vehicleTypesActions,
  dollar: dollarActions,
  app: appActions,
};

export default actions;
