import React, { useEffect, useState } from 'react'

export const DollarPill = ({ price, fullWidth, blue }) => {
	const [date, setDate] = useState(null)

	useEffect(() => {
		const now = new Date()
		const year = now.getFullYear()
		const month = now.getMonth() + 1
		const day = now.getDate()
		const hour = now.getHours() > 9 ? now.getHours() : `0${now.getHours()}`
		const minutes = now.getMinutes() > 9 ? now.getMinutes() : `0${now.getMinutes()}`
		const date = `${day}/${month}/${year} - ${hour}:${minutes}hs.`
		setDate(date)
	}, [])

	return (
		<div
			className={`d-flex p-1 p-md-0 flex-column mr-2 align-items-center dollar_pill_container ${
				fullWidth ? 'w-100 mb-3' : 'w-50'
			}`}
		>
			<div
				className={`w-100 d-flex dollar_pill default_shadow rounded align-items-center justify-content-between mr-2`}
			>
				<div className={'dollar_title'}>
					<p
						className={`font-weight-bolder m-0 text-center text-uppercase ${
							blue ? 'text_blue' : null
						}`}
					>
						{blue ? 'Dólar Blue' : 'Dólar BNA'}
					</p>
				</div>
				<div className={`price_container p-2 ${blue ? 'bg_blue' : 'bg_black'} h-100`}>
					<p className='m-0 text-center'>${typeof price === 'number' ? price.toFixed(2) : '-'}</p>
				</div>
			</div>
			<small className={'color_blue mt-2'}>Última actualización {date}</small>
		</div>
	)
}
