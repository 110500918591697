import React from 'react';
import AdXL from "../components/ads/AdXL";
import { HistoricPriceSearchHeader, HistoricPriceSearchFilters } from "../components/organisms";

export class HistoricPriceSearch extends React.Component {
    render() {
        return (
            <div id="historic_price_search_page" className='container-fluid page_default_margin'>
                <AdXL />
                <div className='p-xl-5'>
                    <HistoricPriceSearchHeader />
                    <HistoricPriceSearchFilters />
                </div>
            </div>
        )
    }
}
