import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ApiHelper } from '../../helpers'
import { auth } from '../../helpers/Firebase'
import actions from '../../store/actions'
import { AppleButton, FacebookButton, GoogleButton, PasswordInput, SquareButton } from '../atoms'
import { BiErrorCircle } from 'react-icons/bi'

export const LoginModal = () => {
	const showLogin = useSelector((state) => state.app.showLogin)
	const loginMessage = useSelector((state) => state.app.loginMessage)
	const [password, setPassword] = useState('')
	const [email, setEmail] = useState('')
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)
	const googleProvider = new firebase.auth.GoogleAuthProvider()
	const facebookProvider = new firebase.auth.FacebookAuthProvider()
	const appleProvider = new firebase.auth.OAuthProvider('apple.com')
	const dispatch = useDispatch()
	const [showSessionMessage, setShowSessionMessage] = useState(false)

	const history = useHistory()

	const intl = useIntl()

	const showRegister = () => {
		dispatch(actions.app.setShowLogin(false))
		dispatch(actions.app.setShowRegister(true))
		dispatch(actions.app.setShowReset(false))
		dispatch(actions.app.setShowRecover(false))
	}

	const showReset = () => {
		dispatch(actions.app.setShowLogin(false))
		dispatch(actions.app.setShowReset(true))
	}

	const hide = () => {
		dispatch(actions.app.setLoginMessage(null))
		dispatch(actions.app.setShowLogin(false))
	}

	useEffect(() => {
		const url = new URL(window.location.href)
		const showSession = url.searchParams.get('showsession')

		if (showSession === 'true') {
			setShowSessionMessage(true)
		}
	}, [])

	const signIn = async (e) => {
		e.preventDefault()
		setError(false)
		if (!loading) {
			setLoading(true)
			try {
				await auth.signInWithEmailAndPassword(email, password)
				const prov = auth.currentUser.providerData[0].providerId
				const token = await auth.currentUser.getIdToken(true)
				let data = new FormData()
				data.append('grant_type', 'firebase')
				data.append('firebase_token_id', token)
				data.append('firebase_social_auth', prov !== 'password' ? 'true' : 'false')
				const res = await ApiHelper.post('auth', '/oauth/token', data)
				localStorage.token = res.data.access_token
				localStorage.user = JSON.stringify({ email: email })
				try {
					const res = await ApiHelper.get('admin', '/user')

					dispatch(actions.user.setUser(res.data))

					if (window.gtag) {
						window.gtag('event', 'sign_in', { social_login: false, email: res.data.email })
					}

					hide()

					const date = new Date().toLocaleString('en-GB', {
						timeZone: 'America/Argentina/Buenos_Aires',
					})
					localStorage.setItem('expiration', date)

					if (!res.data.firstName) {
						window.location.href = window.location.origin
					}

					if (res.data.activeSubscription.name.includes('Moto')) {
						dispatch(actions.search.setVehicleType('motorcycles'))
					}
				} catch (error) {
					console.log(error)
					if (error.status === 412) {
						window.location.href = window.location.origin
						dispatch(actions.user.setUser({ email }))
						hide()
					}
				}
			} catch (err) {
				if (err?.response?.status === 400) {
					dispatch(actions.app.setShowLogin(false))
					history.push(`/activacion?${email}`)
				}
				setLoading(false)
				switch (err.message) {
					case 'The password is invalid or the user does not have a password.':
						setError('La contraseña es incorrecta')
						break

					case 'There is no user record corresponding to this identifier. The user may have been deleted.':
						setError('No existe ningún usuario con esa dirección')
						break

					case 'Password should be at least 6 characters':
						setError('La contraseña debe tener al menos 6 caracteres')
						break

					default:
						setError(err.messsage)
				}
			}
		}
	}

	const providerLogin = async (provider) => {
		try {
			await firebase.auth().signInWithPopup(provider)
			const prov = auth.currentUser.providerData[0].providerId
			const token = await auth.currentUser.getIdToken(true)
			let data = new FormData()
			data.append('grant_type', 'firebase')
			data.append('firebase_token_id', token)
			data.append('firebase_social_auth', prov !== 'password' ? 'true' : 'false')
			const res = await ApiHelper.post('auth', '/oauth/token', data)
			localStorage.token = res.data.access_token
			let newUser
			try {
				const res = await ApiHelper.get('admin', '/user')
				newUser = res.data
			} catch (error) {
				newUser = { email: auth.currentUser.email }
			} finally {
				if (window.gtag) {
					window.gtag('event', 'sign_in', { social_login: true, email: newUser.email })
				}
				localStorage.user = JSON.stringify(newUser)
				dispatch(actions.user.setUser(newUser))
				window.location.href = window.location.origin
			}
		} catch (error) {
			switch (error.code) {
				case 'auth/account-exists-with-different-credential':
					setError('Ya hay una cuenta registrada con el email ingresado')
					break
				default:
					setError('Por favor intentá con otro método de inicio de sesión')
					break
			}
		}
	}

	const facebookLogin = async (provider) => {
		provider.addScope('email')

		try {
			await firebase.auth().signInWithPopup(provider)
			const prov = auth.currentUser.providerData[0].providerId
			if (auth.currentUser.email) {
				const token = await auth.currentUser.getIdToken(true)
				let data = new FormData()
				data.append('grant_type', 'firebase')
				data.append('firebase_token_id', token)
				data.append('firebase_social_auth', prov !== 'password' ? 'true' : 'false')
				console.log(data)
				const res = await ApiHelper.post('auth', '/oauth/token', data)
				localStorage.token = res.data.access_token
				let newUser
				try {
					const { data } = await ApiHelper.get('admin', '/user')
					newUser = data
				} catch (error) {
					newUser = { email: auth.currentUser.email }
				} finally {
					localStorage.user = JSON.stringify(newUser)
					dispatch(actions.user.setUser(newUser))
					hide()
				}
			} else {
				setError('Por favor intentá con otro método de inicio de sesión')
			}
		} catch (error) {
			if (error?.response?.status === 400) {
				const email = auth.currentUser.email
				dispatch(actions.app.setShowLogin(false))
				history.push(`/activacion?${email}`)
			}

			switch (error.code) {
				case 'auth/account-exists-with-different-credential':
					setError('Ya hay una cuenta registrada con el email ingresado')
					break
				default:
					setError('Por favor intentá con otro método de inicio de sesión')
					break
			}
			console.log(error)
		}
	}

	return (
		<>
			<Modal show={showLogin} onHide={hide} size={'sm'} className={'default_modal login-modal'} id={'login_modal'}>
				<Modal.Body className={'p-0'}>
					<div className='row'>
						<div className='col-12 p-5 px-1 d-flex flex-column align-items-center'>
							<p className='font-weight-bold text-login'>Ingresar a mi cuenta</p>

							{loginMessage && <p className='text-center'>{loginMessage}</p>}

							<form onSubmit={signIn} className='w-100'>
								<input
									name={'email'}
									value={email}
									onChange={(event) => setEmail(event.target.value)}
									className={'custom_input default-shadow p-2 w-100 mb-3'}
									placeholder={'Email'}
									type={'text'}
								/>
								<PasswordInput
									name={'password'}
									value={password}
									onChange={(event) => setPassword(event.target.value)}
									className={'custom_input default-shadow p-2 w-100'}
									placeholder={intl.formatMessage({
										id: 'login.modal.input.password',
									})}
								/>
								<SquareButton loading={loading} type='submit' text={intl.formatMessage({ id: 'login.modal.signin' })} />
							</form>
							<p className={'w-100 text-center font-weight-bold text_green mt-2 link'} onClick={showReset}>
								<FormattedMessage id='login.modal.forgotPassword' />
							</p>
							{error && <p className={'text-center color_red'}>{error}</p>}

							<div className='w-100 d-flex justify-content-center'>
								<GoogleButton onClick={() => providerLogin(googleProvider)} />
								<FacebookButton onClick={() => facebookLogin(facebookProvider)} />
								<AppleButton onClick={() => providerLogin(appleProvider)} />
							</div>

							<p className={'mt-3 mb-1'}>
								<FormattedMessage id='login.modal.notRegistered' />{' '}
							</p>

							<h6 className='text_green text-center font-weight-bold link' onClick={showRegister}>
								<FormattedMessage id='login.modal.register' />
							</h6>

							{showSessionMessage && (
								<div className='mt-2 row d-flex text-danger py-2 w-100 justify-content-center align-items-center session-modal '>
									<BiErrorCircle size={15} />
									<span className='ml-2 mb-0'>Su sesión ha expirado.</span>
								</div>
							)}
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}
