import React, { useState, useEffect } from "react";
import { PriceChartOption, Select, Button, Divider } from "../atoms";
import { PriceChart } from "../molecules";
import { ApiHelper, AppHelper } from "../../helpers";
import ReactTooltip from "react-tooltip";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../store/actions";
import { HISTORIC_PRICE } from "static/permissions/sections";
import { InfoCreditsAlertModal, InfoCreditsModal } from "components/organisms";

export const VehicleSheetHistoricPriceChart = ({ vehicle, setShowModal }) => {
  const [ month, setMonth ] = useState(null);
  const [ year, setYear ] = useState(null);
  const [ noMatches, setNoMatches ] = useState(false);
  const [ prices, setPrices ] = useState([]);
  const [ monthOptions, setMonthOptions ] = useState([]);
  const [ yearOptions, setYearOptions ] = useState([]);
  const [ showInfoCreditsModal, setShowInfoCreditsModal ] = useState(false);
  const [ showAlert, setShowAlert ] = useState(false);
  const [ selection, setSelection ] = useState(null);
  const user = useSelector((state) => state.user.user);

  const vehicleType = useSelector((state) => state.search.vehicle);

  const dispatch = useDispatch();
  const months = [
    {
      label: "Enero",
      value: 1,
    },
    {
      label: "Febrero",
      value: 2,
    },
    {
      label: "Marzo",
      value: 3,
    },
    {
      label: "Abril",
      value: 4,
    },
    {
      label: "Mayo",
      value: 5,
    },
    {
      label: "Junio",
      value: 6,
    },
    {
      label: "Julio",
      value: 7,
    },
    {
      label: "Agosto",
      value: 8,
    },
    {
      label: "Septiembre",
      value: 9,
    },
    {
      label: "Octubre",
      value: 10,
    },
    {
      label: "Noviembre",
      value: 11,
    },
    {
      label: "Diciembre",
      value: 12,
    },
  ];

  useEffect(() => {
    getPriceYears();
  }, []);

  const getPriceYears = async () => {
    try {
      const data = {
        codia: vehicle.version.versionCode,
        vehicleType: vehicleType,
      };

      const res = await ApiHelper.get(
        "preciosa",
        `/archives/years?${ApiHelper.URLparams(data)}`
      );

      res.data.map((year) => {
        year.value = year.year;
        year.label = year.year;
        year.type = "year";
        year.id = year.year;
        year.name = year.year;
      });
      res.data.reverse();
      setYearOptions(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (year) {
      setMonth(null);
      const getMonths = async () => {
        const data = {
          codia: vehicle.version.versionCode,
          year: year.id,
          vehicleType: vehicleType,
        };

        const res = await ApiHelper.get(
          "preciosa",
          `/archives/years/${year.id}/months/?${ApiHelper.URLparams(data)}`
        );


        const monthOptions = months.filter((month) =>
          res.data.some((m) => m.month == month.value)
        );

        setMonthOptions(monthOptions);
      };
      getMonths();
    }
  }, [ year ]);

  const getPrices = async () => {
    const data = {
      vehicleType,
      year: year.value,
      month: month.value,
      codia: vehicle.version.versionCode,
    };
    const res = await ApiHelper.getUsedPrices(data);


    setPrices(res.data.response.reverse());
    setSelection({ year: year.value, month: month.label.toLowerCase() });
    const { codiasConsultados } = user;
    if (
      !codiasConsultados.some((c) => c.codia === vehicle.version.versionCode)
    ) {
      codiasConsultados.push({
        codia: vehicle.version.versionCode,
        vehicleType,
        dateConsulted: new Date().toISOString(),
      });
    }
    dispatch(
      actions.user.fillUserData({
        ...user,
        availableInfocredits: res.data.userCredits || user.availableInfocredits,
        codiasConsultados,
      })
    );
  };

  const handleShowPrices = () => {
    if (
      user.codiasConsultados.some((c) => c.codia == vehicle.version.versionCode)
    ) {
      getPrices();
    } else {
      if (user.availableInfocredits === 0) {
        setShowInfoCreditsModal(true);
      } else {
        if (localStorage.hidePriceAlert) {
          getPrices();
        } else {
          setShowAlert(true);
        }
      }
    }
  };

  const showReport = () => {
    localStorage.reportType = "Precio";
    localStorage.reportModelo = vehicle.description;
    setShowModal(true);
  };

  return (
    AppHelper.hasPermission(user, HISTORIC_PRICE) && (
      <div className="row my-4">
        <div className="col-12">
          <Divider />
          <ReactTooltip effect={"solid"} />
          <div className="d-flex align-items-center mb-3">
            <h5
              data-tip="Consulta la valuación histórica de este modelo  por mes y año de publicación"
              className="font-weight-bolder mb-0"
            >
              Precio Histórico
            </h5>
            <small className="chart_contact text-right">
              Tenés algo que informarnos?{" "}
              <strong onClick={showReport} className={"link"}>
                Reportar
              </strong>
            </small>
          </div>

          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-lg-3 py-3">
              <Select
                options={yearOptions}
                onSelect={setYear}
                selectedOption={year}
                placeholder={"Seleccione un año"}
              />
            </div>
            <div className="col-12 col-lg-3 py-3">
              <Select
                options={monthOptions}
                onSelect={setMonth}
                selectedOption={month}
                placeholder={"Seleccione un mes"}
              />
            </div>
            <div className="col-12 col-lg-3 py-3">
              {month && year && (
                <Button
                  dark={true}
                  text={"Ver Precio"}
                  onClick={handleShowPrices}
                />
              )}
            </div>
          </div>
        </div>
        {prices.length > 0 && (
          <div className="col-12 mt-4">
            <div className="row">
              <div className="col-md-6">
                <small className="ml-3 color_blue mb-4">
                  Todos los valores correspondientes a{" "}
                  <strong>
                    {selection?.month} {selection?.year}
                  </strong>
                </small>
                <div className="d-flex justify-content-between mt-4">
                  <h6 className="font-weight-bold">Fabricacíon</h6>
                  <h6 className="font-weight-bold mr-2">Pesos</h6>
                </div>
                <ul className="p-0">
                  {prices.map((price, index) => (
                    <PriceChartOption key={index} price={price} />
                  ))}
                </ul>
              </div>
              <div className="col-md-6">
                <PriceChart prices={prices} />
              </div>
            </div>
          </div>
        )}
        {prices.length === 0 && noMatches && (
          <p className={"text-center w-100 mt-3"}>
            No hay resultados para la búsqueda realizada
          </p>
        )}
        <InfoCreditsModal
          show={showInfoCreditsModal}
          hide={() => setShowInfoCreditsModal(false)}
        />
        <InfoCreditsAlertModal
          getPrices={() => getPrices()}
          hide={() => setShowAlert(false)}
          show={showAlert}
        />
      </div>
    )
  );
};
