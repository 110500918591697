import React, { useEffect, useState } from 'react'
import 'react-credit-cards/es/styles-compiled.css'
import { useDispatch, useSelector } from 'react-redux'
import { ApiHelper } from '../../helpers'
import actions from '../../store/actions'
import { window } from 'globalthis/implementation'
import usePayment from 'hooks/usePayment'

const OneShotPaymentForm = ({ selectedProduct }) => {
	const user = useSelector((state) => state.user.user)

	const [paymentSuccesful, setPaymentSuccesful] = useState(false)

	const { renderForm, renderCard } = usePayment(buyInfocredits)

	const dispatch = useDispatch()

	useEffect(() => {
		if (window.gtag) {
			window.gtag('event', 'begin_checkout')
		}
	}, [])

	async function buyInfocredits(cardToken, paymentMethodId, dni) {
		const data = {
			productId: selectedProduct.id,
			cardToken: cardToken,
			dni: dni,
			paymentMethodId: paymentMethodId,
		}
		await ApiHelper.buyInfocredits(data)

		if (window.gtag) {
			window.gtag('event', 'purchase_ic', {
				type: 'one_shot',
				email: user.email,
				value: selectedProduct.infoCredits,
				available: user.availableInfocredits,
			})
		}

		const updatedUser = {
			...user,
			availableInfocredits: user.availableInfocredits + selectedProduct.infoCredits,
		}
		localStorage.user = JSON.stringify(updatedUser)
		dispatch(actions.user.setUser(updatedUser))
		setPaymentSuccesful(true)

		setTimeout(() => {
			window.location.reload()
		}, 3000)
	}

	const renderContent = () => {
		if (paymentSuccesful) {
			return (
				<div data-aos='fade-in' className='p-5'>
					<h2 className='font-weight-normal text-center'>
						¡Ya se agregaron los infocreditos a tu cuenta!. <strong>¡Comenzá a usarlos ahora!</strong>
					</h2>
				</div>
			)
		} else {
			return (
				<div>
					<div className='col-12 gray_background p-5'>
						<h4 className='font-weight-normal text-center mb-4'>
							Comprar <strong>{selectedProduct.infoCredits}</strong> Info
							<strong>Créditos</strong>
						</h4>
						{renderCard()}
						{renderForm()}
					</div>
				</div>
			)
		}
	}

	return renderContent()
}

export default OneShotPaymentForm
