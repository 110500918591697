import actionsTypes from '../actions/actionTypes'

const initialState = [];

export default function(state = initialState, action){
    switch(action.type){
        case actionsTypes.SET_FILTER_TYPES:
            return action.filters;
        default:
            return state;
    }
};
