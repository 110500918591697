import React, { useEffect, useState, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../../store/actions";
import { ApiHelper } from "../../../helpers";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DebounceSearch } from "../../../hooks/search";

const SearchBar = ({ history }) => {
  const vehicle = useSelector((state) => state.search.vehicle);
  const [results, setResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [resultsQuantity, setResultsQuantity] = useState(0);
  const list = useRef();
  const wrapperRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [searching, setSearching] = useState(false);
  const { inputText, setInputText, search } = DebounceSearch();
  const [searchText, setSearchText] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setHeight(list.current?.clientHeight);
  }, [search.result]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setResults([]);
    }
  };

  useEffect(() => {
    setResults([]);
    setSearchValue([]);
    setSearchText(vehicle === "cars" ? "del auto" : "de la moto");
  }, [vehicle]);

  const query = async (value) => {
    if (value.length > 1 && !searching) {
      const data = {
        features: {},
        page: 1,
        pageSize: 10,
        vehicleType: vehicle,
        filterString: value,
      };
      const res = await ApiHelper.post("preciosa", "/search", data);
      setSearching(false);
      setResults(res.data.response);
      setResultsQuantity(res.data.pagination.totalResults);
    } else {
      setResults([]);
    }
  };

  const navigate = (result) => {
    history.push(`ficha/${vehicle}/${result.versionCode}`);
  };

  const showAll = () => {
    if (inputText && search.result) {
      history.push(
        `/resultado-busqueda?s=${inputText}&q=${search.result.pagination.totalResults}`
      );
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      showAll();
    }
  };

  return (
    <div ref={wrapperRef} id="home_searchbar" className="input-group mb-3">
      <div className="d-flex w-100">
        <FontAwesomeIcon
          onClick={showAll}
          icon={faSearch}
          className={"search_icon_main link"}
        />
        <input
          onKeyPress={handleKeyPress}
          onChange={(e) => setInputText(e.target.value)}
          value={inputText}
          type="text"
          className="form-control search_bar"
          style={{ flexGrow: 1 }}
          placeholder={`${
            window.innerWidth > 768
              ? `Encontrá el precio ${searchText} que buscás, por marca, modelo, año y palabras clave...`
              : `Encontrá el precio ${searchText} que buscás...`
          }`}
        />
      </div>
      {search.result && (
        <div id="home_search_results">
          <ul ref={list}>
            {search.result.response?.map((result, index) => (
              <li onClick={() => navigate(result)} key={index} className="link">
                <span className="font-weight-bolder">{result.brand.name}</span>{" "}
                - {result.versionDescription}
              </li>
            ))}
          </ul>
          {search.result.response?.length > 0 && (
            <p className={"background_gray"} style={{ marginTop: height }}>
              Mostrando {search.result.response?.length} de{" "}
              {search.result.pagination.totalResults}{" "}
              <span
                onClick={showAll}
                className={"ml-2 link color_blue font-weight-bold"}
              >
                Ver todos
              </span>
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(SearchBar);
