import React, { useRef, useState } from 'react'
import { ContactModal } from '../../components/organisms'
import { ApiHelper } from '../../helpers'
import { StockBadge } from '../atoms/StockBadge'
import Counter from './Counter'

export default ({ magazines, month }) => {
	const [ maxHeight, setMaxHeight ] = useState(0)
	const [ showModal, setShowModal ] = useState(false)
	const ref = useRef(null)
	const stock = false

	const hideModal = () => {
		setShowModal(false)
		window.location.reload()
	}

	return (
		<div className='row my-4'>
			<div className='col-md-6 d-flex flex-column justify-content-center'>
				<small className={'text-muted mb-4 text-uppercase'}>
					EDICIÓN {month} - AÑO {magazines[ 0 ]?.year}
				</small>
				<h1 className={'font-weight-bold my-4'}>#RevistaInfoAuto</h1>
				<p className={'mb-4'}>{magazines[ 0 ]?.description}</p>
				<p className='text-muted'>
					NRO. {magazines[ 0 ]?.number} | <strong>{magazines[ 0 ]?.title}</strong>
				</p>
				<div className={'px-3 px-md-0 mt-5'}>
					<p className={'mb-3 font-weight-bold'}>Para la próxima edición</p>
					<Counter />
				</div>
			</div>
			<div className='col-md-6'>
				<div className='row'>
					<div className='col-3 d-none d-md-block'>
						<div className=' d-flex flex-column old_magazines' style={{ maxHeight: maxHeight }}>
							{magazines.map(
								(magazine, index) =>
									index > 0 && (
										<img
											key={magazine.image}
											src={`${ApiHelper.adminRoute}/files/${magazine.image}`}
											alt=''
											className='w-100 mb-2 bw_image'
										/>
									)
							)}
						</div>
						<div className='scroll_overlay' />
					</div>

					<div ref={ref} id={'current_magazine'} className='col-12 col-md-9'>
						<img
							onLoad={() => setMaxHeight(ref.current.clientHeight)}
							src={`${magazines.length ? `${ApiHelper.adminRoute}/files/${magazines[ 0 ]?.image} ` : ''
								}`}
							alt=''
							className='w-100 bw_image'
						/>
					</div>
					{magazines[ 0 ]?.soldOut && (
						<div>
							<StockBadge />
						</div>
					)}
				</div>
			</div>
			<div className='col-12 mt-3 d-flex justify-content-between mb-4 mt-2'>
				<h5 className={'font-weight-normal'}>
					<strong>Precio de tapa:</strong> ${magazines[ 0 ]?.price}
				</h5>
				<h5 onClick={() => setShowModal(true)} className={'font-weight-normal link'}>
					Solicitá <strong>tu ejemplar ></strong>
				</h5>
			</div>
			<ContactModal show={showModal} hide={hideModal} magazine={true} />
		</div>
	)
}
