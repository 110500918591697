import React, { useState, useEffect } from 'react'

export default function useDebounce(value, delay) {
	// Estado y setters para el valor debounceado
	const [debouncedValue, setDebouncedValue] = useState(value)
	useEffect(
		() => {
			// Actualizar valor debounceado despues del delay
			const handler = setTimeout(() => {
				setDebouncedValue(value)
			}, delay)
			// Cancelar el timeout si el valor cambia (tambien después del delay y en el desmontado)
			return () => {
				clearTimeout(handler)
			}
		},
		[value, delay] // Solo llama al hook si el valor se actualiza o cambia
	)
	return debouncedValue
}
