import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { PaginatedSelect, ReportTypePill, Select } from "../atoms";
import { ApiHelper } from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export const AddCarModal = ({ show, hide, addCodia, codias }) => {
  const [reportType, setReportType] = useState("Precio");
  const user = useSelector((state) => state.user.user);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const vehicle = useSelector((state) => state.search.vehicle);
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.phoneNumber);

  useEffect(() => {
    setSelectedVersion(null);
    setSelectedYear(null);
    setSelectedModel(null);
    setSelectedBrand(null);
    setModels([]);
    setYears([]);
    setVersions([]);
  }, [show]);

  useEffect(() => {
    const getBrands = async () => {
      const data = {
        paginated: false,
        hasUsedPrice: true,
        vehicleType: vehicle,
      };

      let res = await ApiHelper.getDownloadedBrands(data);
      res.map((brand) => {
        brand.value = brand.id;
        brand.label = brand.name;
      });
      setBrands(res);
    };
    getBrands();
  }, [vehicle]);

  useEffect(() => {
    setModels([]);
    setYears([]);
    setSelectedModel(null);
  }, [vehicle, selectedBrand]);

  useEffect(() => {
    setYears([]);
    setVersions([]);
    setSelectedYear(null);

    if (selectedModel) {
      const { list_price, prices_from, prices_to } = selectedModel;
      let years = [];
      if (list_price) {
        years.push({ value: "0km", label: "0km" });
      }
      for (let i = prices_to; i >= prices_from; i--) {
        years.push({ value: i, label: i });
      }
      setYears(years);
    }
    return;
  }, [vehicle, selectedModel]);

  useEffect(() => {
    setVersions([]);
    setSelectedVersion(null);
  }, [vehicle, selectedYear]);

  const handleClick = () => {
    if (selectedVersion) {
      addCodia(selectedVersion.codia);
    }
  };

  const loadModels = async (searchQuery, loadedOptions, { page }) => {
    if (selectedBrand) {
      const data = {
        paginated: false,
        hasUsedPrice: true,
        vehicleType: vehicle,
        page,
      };
      try {
        const res = await ApiHelper.getPaginatedModels(selectedBrand.id, data);

        return {
          options: res.data.response,
          hasMore: res.data.pagination.totalPages > page,
          additional: {
            page: searchQuery ? 2 : page + 1,
          },
        };
      } catch {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: 1,
          },
        };
      }
    } else {
      return {
        options: [],
        hasMore: true,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    }
  };

  const loadVersions = async (searchQuery, loadedOptions, { page }) => {
    if (selectedYear) {
      const data = {
        vehicleType: vehicle,
        pageNumber: page,
        features: {},
      };

      if (selectedYear.value === "0km") {
        data.newPrice = true;
      } else {
        data.fromYear = selectedYear.value;
        data.toYear = selectedYear.value;
      }

      try {
        const res = await ApiHelper.getPaginatedVersions(
          selectedBrand.id,
          selectedModel.id,
          data
        );

        return {
          options: res.data.response.filter(
            (version) => !codias.some((codia) => codia.codia === version.codia)
          ),
          hasMore: res.data.pagination.totalPages > page,
          additional: {
            page: searchQuery ? 2 : page + 1,
          },
        };
      } catch {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: 1,
          },
        };
      }
    } else {
      return {
        options: [],
        hasMore: true,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    }
  };

  return (
    <Modal
      show={show}
      onHide={hide}
      size={"md"}
      className={"default_modal comparator_modal"}
    >
      <Modal.Body className={"p-0 background_gray"}>
        <div className={"text-right pr-3 pt-3 link"}>
          <FontAwesomeIcon onClick={hide} icon={faTimes} />
        </div>
        <div className="">
          <div className="col-12 gray_background px-5 pb-4">
            <h4 className="font-weight-bold mb-0 text-center">
              Agrega vehículos para comparar
            </h4>
            <div className="row mt-4">
              <div className="comparator_select col-12">
                <Select
                  placeholder={"Marca"}
                  onSelect={(brand) => setSelectedBrand(brand)}
                  selectedOption={selectedBrand}
                  options={brands}
                />
              </div>
              <div className="comparator_select col-12 mt-4 ">
                <PaginatedSelect
                  key={"models"}
                  value={selectedModel}
                  loadOptions={loadModels}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  onChange={setSelectedModel}
                  isSearchable={false}
                  placeholder="Modelo"
                  cacheUniqs={[selectedBrand]}
                  additional={{
                    page: 1,
                  }}
                />
              </div>
              <div className="comparator_select col-12  mt-4 ">
                <Select
                  placeholder={"Año"}
                  onSelect={(year) => setSelectedYear(year)}
                  selectedOption={selectedYear}
                  options={years}
                />
              </div>
              <div className="comparator_select col-12  mt-4">
                <PaginatedSelect
                  key={"version"}
                  value={selectedVersion}
                  loadOptions={loadVersions}
                  getOptionValue={(option) => option.codia}
                  getOptionLabel={(option) => option.description}
                  onChange={setSelectedVersion}
                  isSearchable={false}
                  placeholder="Versión"
                  cacheUniqs={[selectedYear]}
                  additional={{
                    page: 1,
                  }}
                />
              </div>
              <div className="col-12 text-center mt-3 text-center text-md-right">
                <button
                  onClick={handleClick}
                  className={"btn btn-black text-white"}
                >
                  Seleccionar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
