import React from "react";
import { Navbar } from "react-bootstrap";

export default function NavBar() {
  return (
    <Navbar
      //   fixed={"top"}
      id="header__nav"
      className="navbar-light"
      bg="white"
      expand="xl"
    >
      <a
        href={window.location.origin}
        id="header_img_link"
        className={`navbar-brand px-3 px-lg px-lg-4 py-1 py-lg-4  ${"bg-white"} text-dark`}
      >
        <img
          src={"/assets/img/logo/logo-01.png"}
          style={{ width: 120 }}
          alt="logo"
        />
      </a>
    </Navbar>
  );
}
