import { useSteps } from 'chakra-ui-steps'
import UserType from 'components/welcome/UserType'
import UserInterests from 'components/welcome/UserInterest'
import React, { Component, useEffect, useState } from 'react'
import NavBar from '../components/welcome/NavBar'
import { ProgressBar } from '../components/atoms'
import Navigator from 'components/welcome/Navigator'
import UserData from 'components/welcome/UserData'
import UserPlans from 'components/welcome/UserPlans'
import { ApiHelper } from 'helpers'
import UserBilling from 'components/welcome/UserBilling'
import UserPayment from 'components/welcome/UserPayment'
import { useSelector } from 'react-redux'
import UserSuccess from 'components/welcome/UserSuccess'

export default function Welcome() {
	const loggedUser = useSelector((state) => state.user.user)
	const isFleetUser = loggedUser.activeSubscription?.subscriptionType?.type === 'fleet'
	const [user, setUser] = useState({})
	const isIndividual = user.userType === 'individual'
	const [subscriptions, setSubscriptions] = useState([])
	const { nextStep, prevStep, setStep, activeStep } = useSteps({
		initialStep: 0,
	})

	const usernashi = {
		loggedUser,
		isFleetUser,
		user,
		isIndividual,
	}

	useEffect(() => {
		getSubscriptions()
	}, [])

	useEffect(() => {
		if (isFleetUser) {
			setUser((prev) => ({ ...prev, type: 'professional' }))
		}
	}, [isFleetUser])

	const getSubscriptions = async () => {
		try {
			const { data } = await ApiHelper.get('admin', '/subscription/template')

			setSubscriptions(data)
		} catch (error) {
			console.log(error)
		}
	}

	let steps = []

	if (isFleetUser) {
		steps = [
			{ Component: UserInterests, name: 'Intereses' },
			{ Component: UserData, name: 'Datos' },
		]
	} else {
		steps = [
			{ Component: UserType, name: 'Tipo de usuario' },
			{
				Component: UserInterests,
				name: isIndividual ? 'Intereses' : 'Rubro/Actividad',
			},

			{ Component: UserData, name: 'Datos' },
			{ Component: UserPlans, name: 'Plan' },
			{ Component: UserBilling, name: 'Facturación' },
			{ Component: UserPayment, name: 'Pago' },
			{ Component: UserSuccess, name: 'Bienvenido' },
		]
	}

	return (
		<div style={{ background: '#f7f7f7', minHeight: '100vh' }}>
			<NavBar />
			<ProgressBar progress={((activeStep + 1) / steps.length) * 100} />
			<div className='container py-5'>
				{activeStep !== 3 && activeStep !== 6 && (
					<Navigator prevStep={prevStep} activeStep={activeStep} steps={steps} />
				)}
				{steps.map(
					({ Component }, index) =>
						index === activeStep && (
							<Component
								key={index}
								newUser={user}
								setUser={setUser}
								nextStep={nextStep}
								setStep={setStep}
								subscriptions={subscriptions}
							/>
						)
				)}
			</div>
		</div>
	)
}
