import React, { useState, useEffect } from "react";
import { UserType } from "../atoms";
import { RegisterModalHeader } from "../atoms";
import { Form } from "react-bootstrap";
import { ApiHelper } from "../../helpers";
import { Select } from "../atoms";

export const RegisterBrands = ({
  userType,
  handleBack,
  brands,
  setBrands,
  vehicles,
}) => {
  const [brandOptions, setBrandOptions] = useState([]);

  useEffect(() => {
    getBrands();
  }, []);

  const getBrands = async () => {
    const data = {
      vehicleType: "cars",
    };
    let res = await ApiHelper.getDownloadedBrands(data);

    if (vehicles.includes("Motos")) {
      const data2 = {
        vehicleType: "motorcycles",
      };
      const res2 = await ApiHelper.getDownloadedBrands(data2);
      res = res.concat(res2);
      res.sort((a, b) => a.name.localeCompare(b.name));
    }
    res.map((car) => {
      car.value = car.id;
      car.label = car.name;
      
    });
    setBrandOptions(res);
  };

  const handleSelect = (brand, index) => {
    if (brands.length === 0) {
      setBrands([...brands, brand]);
    } else {
      if (brands.includes(brand)) {
        alert("La opción ingresada ya se encuentra seleccionada");
      } else {
        brands[index] = brand;
        setBrands(brands);
      }
    }
  };

  return (
    <div className="col-12 d-flex flex-column align-items-center">
      <RegisterModalHeader onClick={handleBack} />
      <h5 className="font-weight-bold mb-4">¡Te queda 1 solo paso!</h5>
      <p>
        {userType === "individual"
          ? "4. Selecciona tus marcas favoritas para que accedas a contenidos personalizados."
          : "4.Selecciona tus marcas principales para que accedas a contenidos personalizados"}
      </p>
      <div className={"container py-5 px-2"}>
        <div className="row">
          <div className="col-12 col-md p-1">
            <span className="font-weight-bold">1.</span>
            <Select
              placeholder={"Marca"}
              selectedOption={brands[0]}
              options={brandOptions}
              onSelect={(option) => handleSelect(option, 0)}
            />
          </div>
          <div className="col-12 col-md p-1">
            <span className="font-weight-bold">2.</span>
            <Select
              placeholder={"Marca"}
              selectedOption={brands[1]}
              options={brandOptions.filter((b) => !brands.includes(b))}
              onSelect={(option) => handleSelect(option, 1)}
            />
          </div>
          <div className="col-12 col-md p-1">
            <span className="font-weight-bold">3.</span>
            <Select
              placeholder={"Marca"}
              selectedOption={brands[2]}
              options={brandOptions.filter((b) => !brands.includes(b))}
              onSelect={(option) => handleSelect(option, 2)}
            />
          </div>
          <div className="col-12 col-md p-1">
            <span className="font-weight-bold">4.</span>
            <Select
              placeholder={"Marca"}
              selectedOption={brands[3]}
              options={brandOptions.filter((b) => !brands.includes(b))}
              onSelect={(option) => handleSelect(option, 3)}
            />
          </div>
          <div className="col-12 col-md p-1">
            <span className="font-weight-bold">5.</span>
            <Select
              placeholder={"Marca"}
              selectedOption={brands[4]}
              options={brandOptions.filter((b) => !brands.includes(b))}
              onSelect={(option) => handleSelect(option, 4)}
            />
          </div>
        </div>
      </div>
      <p>
        Deberás seleccionar al menos{" "}
        <strong>una marca favorita para poder continuar el registro.</strong>
      </p>
    </div>
  );
};
