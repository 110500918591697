import React, { useState, useEffect } from "react";
import { CircleGraph, InfoProPill, InfoProSlider } from "../atoms";
import { Measurements } from "../organisms";

export const InfoProCarDetails = ({ vehicle }) => {
  const [speed, setSpeed] = useState(0);
  const [power, setPower] = useState(0);
  const [fuel, setFuel] = useState(0);
  const [capacity, setCapacity] = useState(0);
  const [autonomy, setAutonomy] = useState(false);
  const [acceleration, setAcceleration] = useState(false);
  const [abs, setAbs] = useState(false);
  const [isofix, setIsofix] = useState(false);
  const [screen, setScreen] = useState(false);
  const [gps, setGps] = useState(false);
  const [sensor, setSensor] = useState(false);

  useEffect(() => {
    try {
      setSpeed(
        vehicle?.motorTransmitionFeatures?.find(
          (f) => f.description === "Velocidad máxima"
        )?.value
      );
      setPower(
        vehicle?.motorTransmitionFeatures?.find(
          (f) => f.description === "Potencia HP"
        )?.value
      );
      setFuel(
        vehicle?.specificationFeatures?.find(
          (f) => f.description === "Capacidad tanque combustible (litros)"
        )?.value
      );
      setCapacity(
        vehicle?.specificationFeatures?.find(
          (f) => f.description === "Capacidad baúl (litros)"
        )?.value
      );
      setAutonomy(
        vehicle?.specificationFeatures?.find((f) => f.description === "Autonomía")
          ?.value
      );
      setAcceleration(
        vehicle?.specificationFeatures?.find(
          (f) => f.description === "Aceleración 0-100 km/h (segundos)"
        )?.value
      );
      setAbs(
        vehicle?.securityFeatures?.find((f) => f.description === "Frenos ABS")
          ?.value
      );
      setIsofix(
        vehicle?.securityFeatures?.find(
          (f) => f.description === "Fijación ISOFIX"
        )?.value
      );
      setScreen(
        vehicle?.comfortFeatures?.find((f) => f.description === "Pantalla")?.value
      );
      setGps(
        vehicle?.comfortFeatures?.find(
          (f) => f.description === "Navegador satelital"
        )?.value
      );
      setSensor(
        vehicle?.comfortFeatures?.find(
          (f) => f.description === "Sensor de estacionamiento"
        )?.value
      );
    } catch (error) {
      console.log(error);
    }
  }, [vehicle]);

  return (
    <div className={"mb-5"}>
      <div className="row">
        <div className="col-6 col-md-3 px-4">
          <CircleGraph
            unit={"HP"}
            value={Number(power)}
            label={"Potencia"}
            max={500}
          />
        </div>
        <div className="col-6 col-md-3 px-4">
          <CircleGraph
            unit={"KMPH"}
            value={Number(speed)}
            label={"Velocidad Máxima"}
            max={400}
          />
        </div>
        <div className="col-6 col-md-3 px-4">
          <CircleGraph
            unit={"LITROS"}
            value={fuel ? Number(fuel) : "-"}
            label={"Tanque de combustible"}
            max={500}
          />
        </div>
        <div className="col-6 col-md-3 px-4">
          <CircleGraph
            unit={"LITROS"}
            value={capacity ? Number(capacity) : "-"}
            label={"Capacidad baúl"}
            max={900}
          />
        </div>
      </div>
      <div className="background_gray mt-0 mt-md-5 p-5 pb-2 info_pro_details default_shadow row">
        <div className="col-12 col-md-8">
          <div className="row">
            <div className="col-6">
              <InfoProSlider
                max={1500}
                value={autonomy ? Number(autonomy) : 300}
                min={300}
                title={"Autonomía"}
                unit={"KMS"}
              />
            </div>
            <div className="col-6">
              <InfoProSlider
                max={20}
                value={acceleration ? Number(acceleration) : 2.5}
                min={2.5}
                title={"Aceleración"}
                unit={"SEC"}
                subtitle={"0 - 100 KMPH"}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 details_pills px-5">
          <InfoProPill title={"ABS"} active={abs} />
          <InfoProPill title={"Isofix"} active={isofix} />
          <InfoProPill title={"Pantalla"} active={screen} />
          <InfoProPill title={"GPS"} active={gps} />
          <InfoProPill title={"Sensor de estacionamiento"} active={sensor} />
        </div>
      </div>
      <div className={"mt-0 mt-md-5"}>
        {/* <Measurements vehicle={vehicle} /> */}
      </div>
    </div>
  );
};
