import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApiHelper, AppHelper } from "../../helpers";
import actions from "../../store/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import coveredCarImage from "../../static/img/covered_car.jpg";
import coveredMotoImage from "../../static/img/covered_motorcycle.png";
import {
  COMPARATOR_FOUR_VEHICLES,
  FAVORITES,
} from "static/permissions/sections";

export default ({ vehicles, removeVehicle, setShowModal }) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const vehicleType = useSelector((state) => state.search.vehicle);

  const handleFavoriteClick = async (vehicle) => {
    const isFavorite = user.codiasFavorites.some(
      (c) => c.codia === vehicle.codia
    );

    const id = vehicle.codia;

    if (isFavorite) {
      await ApiHelper.delete("admin", `/favorite/${id}`);

      dispatch(
        actions.user.fillUserData({
          ...user,
          codiasFavorites: user?.codiasFavorites.filter((c) => c.codia !== id),
        })
      );
    } else {
      const data = {
        vehicleType,
      };

      await ApiHelper.post(
        "admin",
        `/favorite?${ApiHelper.URLparams(data)}`,
        id
      );
      dispatch(
        actions.user.fillUserData({
          ...user,
          codiasFavorites: [
            ...user.codiasFavorites,
            { codia: id, vehicleType },
          ],
        })
      );
    }
  };

  const canAddVehicle = () => {
    if (vehicles.length < 2) {
      return true;
    } else if (
      vehicles.length < 4 &&
      AppHelper.hasPermission(user, COMPARATOR_FOUR_VEHICLES)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={"row border_top_black"}>
      <div className="col-2 pt-3">
        <p className="th-comparator">
          <strong>Vehículos</strong>
        </p>
      </div>
      <div className="col-10">
        <div className="row">
          {vehicles.map((vehicle, index) => {
            return (
              <div
                key={index}
                className={`py-3 col-${AppHelper.hasPermission(user, COMPARATOR_FOUR_VEHICLES)
                    ? 3
                    : 6
                  } px-5 ${index % 2 === 1 ? "background_gray" : ""}`}
              >
                <div className={"comparator_image"}>
                  <div className="content px-1">
                    <p className={"text-right"}>
                      <span
                        className={"link"}
                        onClick={() =>
                          removeVehicle(vehicle.version.versionCode)
                        }
                      >
                        x
                      </span>
                    </p>

                    <img
                      className={"w-100"}
                      src={
                        vehicle?.photo_url
                          ? vehicle?.photo_url
                          : vehicle?.vehicleType === "cars"
                            ? coveredCarImage
                            : coveredMotoImage
                      }
                      alt="carImage"
                    />
                  </div>
                </div>
                <div className={"d-flex flex-column mt-1"}>
                  <div className="d-flex justify-content-between">
                    <small className={"font-weight-bold mb-0"}>
                      {vehicle?.version.brand.name}
                    </small>

                    {AppHelper.hasPermission(user, FAVORITES) && (
                      <small
                        onClick={() => handleFavoriteClick(vehicle)}
                        id="like_icon_link"
                        className={"link"}
                      >
                        <FontAwesomeIcon
                          style={{
                            color: user?.codiasFavorites?.some(
                              (c) => c.codia === vehicle?.codia
                            )
                              ? "black"
                              : "#ccc",
                          }}
                          id="like_icon"
                          icon={faHeart}
                        />
                      </small>
                    )}
                  </div>

                  <small className={"font-weight-bold mb-0"}>
                    {vehicle?.version.versionDescription}
                  </small>
                  <small
                    onClick={() =>
                      (window.location.href = `${window.location.origin}/ficha/${vehicle?.vehicleType}/${vehicle?.version.versionCode}`)
                    }
                    className={"link font-weight-bold text_blue"}
                  >
                    Ver Ficha
                  </small>
                </div>
              </div>
            );
          })}
          {canAddVehicle() && (
            <div
              className={`col-${AppHelper.hasPermission(user, COMPARATOR_FOUR_VEHICLES)
                  ? "3"
                  : "6"
                } px-5 py-3`}
            >
              <div className={"comparator_image px-1"}>
                <div className="content d-flex flex-column align-items-center justify-content-center">
                  <h1
                    onClick={() => setShowModal(true)}
                    className={"text-center font-weight-bold link"}
                  >
                    +
                  </h1>
                  <small>Agregar Vehículo</small>
                </div>
              </div>
            </div>
          )}

          {/* {(!user ||
            !user.activeSubscription ||
            user.activeSubscription.name === "Free") &&
            vehicles.length < 2 && (
              <div className={"col-3 px-5 py-3"}>
                <div className={"comparator_image px-1"}>
                  <div className="content d-flex flex-column align-items-center justify-content-center">
                    <h1
                      onClick={() => setShowModal(true)}
                      className={"text-center font-weight-bold link"}
                    >
                      +
                    </h1>
                    <small>Agregar Vehículo</small>
                  </div>
                </div>
              </div>
            )} */}

          {/* {user &&
            user.activeSubscription.name != "Free" &&
            vehicles.length < 4 && (
              <div className={"col-3 px-5 py-3"}>
                <div className={"comparator_image px-1"}>
                  <div className="content d-flex flex-column align-items-center justify-content-center">
                    <h1
                      onClick={() => setShowModal(true)}
                      className={"text-center font-weight-bold link"}
                    >
                      +
                    </h1>
                    <small>Agregar Vehículo</small>
                  </div>
                </div>
              </div>
            )} */}
        </div>
      </div>
    </div>
  );
};
