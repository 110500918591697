import React from 'react';

export const InfoProTableBar = ({feature, setFeature}) => {
    const features = [
        {
            title: 'Precios',
            value: 'prices',
        },
        {
            title: 'Confort',
            value: 'comfort',
        },
        {
            title: 'Datos Técnicos',
            value: 'technical',
        },
        {
            title: 'Motor y Transmisión',
            value: 'motor',
        },
        {
            title: 'Seguridad',
            value: 'security',
        },

    ];

    return(
        <div id={'search_bar'} className="d-flex align-items-center justify-content-start w-100 table-responsive">
            {features.map((f, index) => <small onClick={() => setFeature(f.value)} className={`link p-3 text-uppercase ${feature === f.value && 'selected'}`} key={index}>{f.title}</small>)}
        </div>
    )
};
