import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {ContactModal} from "../../organisms";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../store/actions";

const TopFooter = () => {

  const showModal = useSelector(state => state.app.showContact);
  const dispatch = useDispatch();
  return (
    <div className="w-100 p-3" id="top_footer">
      <div className="container-fluid">
        <div className="row no-gutters">
          <div className="col-12 col-md-6 text-center text-md-left ">
            <p id="footer_social_icons" className="align-items-center">
              <FontAwesomeIcon className="mr-5" icon={faFacebookF} />
              <FontAwesomeIcon className="mr-5" icon={faTwitter} />
              <FontAwesomeIcon className="mr-5" icon={faYoutube} />
              <FontAwesomeIcon className="mr-5" icon={faInstagram} />
              <FontAwesomeIcon className="" icon={faWhatsapp} />
            </p>
          </div>
          <div className="col-12 col-md-6 mt-2">
            <div
              id="footer_suscribe"
              className="text-white d-flex justify-content-center justify-content-md-end  align-items-center mb-0 mr-3 link"
              onClick={() => dispatch(actions.app.setShowContact(true))}
            >
              <FontAwesomeIcon className="mr-2" icon={faEnvelope} />
              <p className="mb-0">Contacto</p>
            </div>
            <ContactModal show={showModal} hide={() => dispatch(actions.app.setShowContact(false))} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopFooter;
