import { ApiHelper, AppHelper } from "helpers";
import React, { useEffect, useState } from "react";
import { default as ReactLoader } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  COMPARATOR,
  COMPARATOR_FOUR_VEHICLES,
} from "static/permissions/sections";
import imgPrice from "../../static/img/VisualSearchPriceColumn.jpg";
import actions from "../../store/actions";
import SearchButtons from "./SearchButtons";

const SubmitColumn = (props) => {
  let {
    values,
    selectedValue,
    onSelect,
    brand,
    model,
    version,
    year,
    history,
    selectedVersion,
    prices,
    getPrices,
    pricesReadyToShow,
    pricesLoading,
  } = props;
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.search.vehicle);
  const [date, setDate] = useState(null);
  const [showPrices, setShowPrices] = useState(false);
  const [showDollar, setShowDollar] = useState(false);
  const dollar = useSelector((state) => state.dollar);
  const user = useSelector((state) => state.user.user);
  const [refreshPrice, setRefreshPrice] = useState(false);
  const [newPrice, setNewPrice] = useState();
  const isDesktop = window.innerWidth > 767;

  useEffect(() => {
    if (
      user?.codiasConsultados?.some((vehicle) => vehicle.codia === version?.id)
    ) {
      setShowPrices(true);
    } else setShowPrices(false);
  }, [selectedVersion]);

  useEffect(() => {
    if (user && refreshPrice) {
      handleClick();
    }
  }, [user]);

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.toLocaleString("es", { month: "long" });
    setDate({ year, month });
  }, []);

  const onClick = () => {
    dispatch(
      actions.search.setPrimarySeach([
        { ...brand, type: "brand" },
        { ...model, type: "model" },
        { ...version, type: "version" },
        { ...year, type: "year" },
      ])
    );

    history.push(`/ficha/${vehicle}/${version.id}`);
  };

  const compare = () => {
    if (AppHelper.hasPermission(user, COMPARATOR)) {
      let codias = localStorage.comparatorCodias
        ? JSON.parse(localStorage.comparatorCodias)
        : [];
      if (
        codias.length === 0 ||
        codias.some((c) => c.vehicleType === vehicle)
      ) {
        if (codias.some((c) => c.codia === version.codia)) {
          history.push(`/comparador`);
        } else if (
          codias.length < 2 ||
          (AppHelper.hasPermission(user, COMPARATOR_FOUR_VEHICLES) &&
            codias.length < 4)
        ) {
          codias = [...codias, { codia: version.codia, vehicleType: vehicle }];
        } else {
          codias[0] = { codia: version.codia, vehicleType: vehicle };
        }
      } else {
        codias = [{ codia: version.codia, vehicleType: vehicle }];
      }

      localStorage.comparatorCodias = JSON.stringify(codias);
      history.push(`/comparador`);
    } else {
      dispatch(
        actions.app.setLoginMessage(
          "Para esta funcionalidad debes estar registrado"
        )
      );
      dispatch(actions.app.setShowRegister(true));
    }
  };

  const handleClick = () => {
    if (user) {
      if (user.infoCreditsActive === false) {
        dispatch(actions.app.setShowSubscriptionDisabled(true));
        return;
      }
    }

    setRefreshPrice(false);

    if (
      user?.activeSubscription?.name.includes("Auto") &&
      vehicle === "motorcycles"
    ) {
      dispatch(actions.app.setShowWrongPlanModal(true));
      return;
    } else if (
      user?.activeSubscription?.name.includes("Moto") &&
      vehicle === "cars"
    ) {
      dispatch(actions.app.setShowWrongPlanModal(true));
      return;
    } else if (!user) {
      setRefreshPrice(true);
      dispatch(actions.app.setShowRegister(true));
    } else {
      getPrices();
    }
  };

  return (
    <div className={`col-3 background_column advanced_search_column`}>
      <h5 className="mt-3 font-weight-bold column_title background_column text-center">
        {props.name}
      </h5>
      {prices.length > 0 && (
        <small className="ml-2 color_blue text-center">
          Valores correspondientes a <br />{" "}
          <strong>
            {date.month} {date.year}
          </strong>
        </small>
      )}
      <div className={"options_container mt-2 pb-5 mb-4"}>
        {pricesLoading ? (
          <div className="d-flex justify-content-center">
            <ReactLoader type="Puff" color="#10ffdc" height={50} width={50} />
          </div>
        ) : pricesReadyToShow && prices.length === 0 ? (
          <>
            {selectedVersion?.newPrice && (
              <div
                className={`btn text-left w-100 d-flex justify-content-between bg_green`}
              >
                <span className={"font-weight-bold"}>0km</span>

                <span>
                  $
                  {new Intl.NumberFormat("es").format(
                    selectedVersion.newPrice * 1000
                  )}
                </span>
              </div>
            )}
            <div className="submit_column_prices">
              <img src={imgPrice} className="price-col" />
              <div className="overlay-vsearch pl-2">
                <h6
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={handleClick}
                >
                  Mostrar precios >
                </h6>
              </div>
            </div>
          </>
        ) : (
          <ul className={"pr-2 pb-5"}>
            {prices.map((value, index) => (
              <li
                key={value.year}
                className={`btn text-left w-100 d-flex justify-content-between ${
                  selectedValue.id === value.year
                    ? "font-weight-bolder text-white bg_black"
                    : "border_bottom_dark"
                } ${value.year === "0km" && "bg_green"}`}
              >
                <span className={"font-weight-bold"}>{value.year}</span>
                {/*<span className={'m-0 dollar_value'}>*/}
                {/*    USD {new Intl.NumberFormat('es').format(Math.floor((value.price*1000)/dollar.bna))}<strong className={'text_blue'}> / USD {new Intl.NumberFormat('es').format(Math.floor((value.price*1000)/dollar.blue))}</strong>*/}
                {/*</span>*/}
                <span>
                  ${new Intl.NumberFormat("es").format(value.price * 1000)}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
      {pricesReadyToShow && (
        <div className="position-absolute fixed-bottom w-100 px-4 background_column mt-2">
          <SearchButtons compare={compare} onClick={onClick} />
        </div>
      )}
    </div>
  );
};

export default withRouter(SubmitColumn);
