import actionTypes from "./actionTypes";

const searchActions = {
  clearSearch: (user) => {
    return {
      type: actionTypes.CLEAR_SEARCH,
    };
  },
  setPrimarySeach: (search) => {
    return {
      type: actionTypes.SET_PRIMARY_SEARCH,
      search,
    };
  },
  removeElement: (element) => {
    return {
      type: actionTypes.REMOVE_SEARCH_ELEMENT,
      element,
    };
  },
  setSearch: (search) => {
    return {
      type: actionTypes.SET_SEARCH,
      search,
    };
  },
  setVehicleType: (vehicleType) => {
    localStorage.vehicleType = vehicleType;
    return {
      type: actionTypes.SET_VEHICLE_TYPE,
      vehicleType,
    };
  },
};

export default searchActions;
