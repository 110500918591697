import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faWhatsapp,
  faInstagram,
  faLinkedin,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from "react-share";

export const ShareBar = () => {
  return (
    <div
      id={"share_bar"}
      className={"d-flex flex-column align-items-center justify-content-center"}
    >
      <a href="https://www.facebook.com/InfoAutoArgentina/" target="blank">
        <FontAwesomeIcon
          className={"link"}
          icon={faFacebookF}
          size={`${window.screen.width > 760 ? "2x" : "1x"}`}
          color={"white"}
        />
      </a>
      <a href="https://twitter.com/infoautoar?lang=es" target="blank">
        <FontAwesomeIcon
          className={"mt-4 link"}
          icon={faTwitter}
          size={`${window.screen.width > 760 ? "2x" : "1x"}`}
          color={"white"}
        />
      </a>
      <a href="https://www.instagram.com/infoautoar/?hl=es-la" target="blank">
        <FontAwesomeIcon
          className={"mt-4 link"}
          icon={faInstagram}
          size={`${window.screen.width > 760 ? "2x" : "1x"}`}
          color={"white"}
        />
      </a>

      <a href="mailto:info@infoauto.com.ar" target="blank">
        <FontAwesomeIcon
          className={"mt-4 link"}
          icon={faEnvelope}
          size={`${window.screen.width > 760 ? "2x" : "1x"}`}
          color={"white"}
        />
      </a>
      <a href="https://www.youtube.com/c/InfoAutoArgentina" target="blank">
        <FontAwesomeIcon
          className={"mt-4 link"}
          icon={faYoutube}
          size={`${window.screen.width > 760 ? "2x" : "1x"}`}
          color={"white"}
        />
      </a>
      <a
        href="https://wa.me/541156385906/?text=Hola+InfoAuto%21+Quisiera+hacer+una+consulta."
        target="blank">
        <FontAwesomeIcon
          className={"mt-4 link"}
          icon={faWhatsapp}
          size={`${window.screen.width > 760 ? "2x" : "1x"}`}
          color={"white"}
        />
      </a>
      <a href="https://ar.linkedin.com/company/grupoinfoauto" target="blank">
        <FontAwesomeIcon
          className={"mt-4 link"}
          icon={faLinkedin}
          size={`${window.screen.width > 760 ? "2x" : "1x"}`}
          color={"white"}
        />
      </a>
    </div>
  );
};
