const barrios = [
  { value: "Chacarita", label: "Chacarita" },
  { value: "Paternal", label: "Paternal" },
  { value: "Villa Crespo", label: "Villa Crespo" },
  { value: "Almagro", label: "Almagro" },
  { value: "Caballito", label: "Caballito" },
  { value: "Villa Santa Rita", label: "Villa Santa Rita" },
  { value: "Monte Castro", label: "Monte Castro" },
  { value: "Villa Real", label: "Villa Real" },
  { value: "Flores", label: "Flores" },
  { value: "Floresta", label: "Floresta" },
  { value: "Constitución", label: "Constitución" },
  { value: "San Cristobal", label: "San Cristobal" },
  { value: "Boedo", label: "Boedo" },
  { value: "Velez Sarsfield", label: "Velez Sarsfield" },
  { value: "Villa Luro", label: "Villa Luro" },
  { value: "Parque Patricios", label: "Parque Patricios" },
  { value: "Mataderos", label: "Mataderos" },
  { value: "Villa Lugano", label: "Villa Lugano" },
  { value: "San Telmo", label: "San Telmo" },
  { value: "Saavedra", label: "Saavedra" },
  { value: "Coghlan", label: "Coghlan" },
  { value: "Villa Urquiza", label: "Villa Urquiza" },
  { value: "Colegiales", label: "Colegiales" },
  { value: "Balvanera", label: "Balvanera" },
  { value: "Villa Gral. Mitre", label: "Villa Gral. Mitre" },
  { value: "Parque Chas", label: "Parque Chas" },
  { value: "Agronomía", label: "Agronomía" },
  { value: "Villa Ortuzar", label: "Villa Ortuzar" },
  { value: "Barracas", label: "Barracas" },
  { value: "Parque Avellaneda", label: "Parque Avellaneda" },
  { value: "Parque Chacabuco", label: "Parque Chacabuco" },
  { value: "Nueva Pompeya", label: "Nueva Pompeya" },
  { value: "Palermo", label: "Palermo" },
  { value: "Villa Riachuelo", label: "Villa Riachuelo" },
  { value: "Villa Soldatti", label: "Villa Soldatti" },
  { value: "Villa Pueyrredon", label: "Villa Pueyrredon" },
  { value: "Villa Devoto", label: "Villa Devoto" },
  { value: "Liniers", label: "Liniers" },
  { value: "Versalles", label: "Versalles" },
  { value: "Puerto Madero", label: "Puerto Madero" },
  { value: "Monserrat", label: "Monserrat" },
  { value: "San Nicolas", label: "San Nicolas" },
  { value: "Belgrano", label: "Belgrano" },
  { value: "Recoleta", label: "Recoleta" },
  { value: "Retiro", label: "Retiro" },
  { value: "Nuñez", label: "Nuñez" },
  { value: "Boca", label: "Boca" },
];

export default barrios;
