// a page with an error cartel and a button to go back to the home page

import React from 'react'
import { Link } from 'react-router-dom'

const ErrorPoster = () => {
	return (
		<div className='page_default_margin'>
			<div className={'container mb-5'}>
				<h4 className='mt-5'>
					<b>Error 429</b>
				</h4>
				<hr />
				<p>
					Hemos detectado una actividad inusual de la plataforma. Espere un
					momento y vuelva a intentar. Ante cualquier duda, comuniquese con el
					soporte de <b>InfoAuto</b>
				</p>
				<Link to='/'>Regresar al inicio</Link>
			</div>
		</div>
	)
}

export default ErrorPoster
