import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider, useDispatch } from 'react-redux'
import store from './store/configureStore'
import './styles/App.scss'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Header from './components/app/header/Header'
import { PaymentLoader } from './components/atoms'
import AOS from 'aos'
import {
	Home,
	AdvancedSearch,
	Contacto,
	Nosotros,
	News,
	Welcome,
	New,
	Servicio,
	SearchResult,
	ProductSheet,
	VisualSearch,
	VisualSearchResult,
	Profile,
	Professionals,
	Individuals,
	Enterprise,
	Magazine,
	Terminos,
	Politicas,
	PreguntasFreq,
	RecuperarCuenta,
	Calificaciones,
	InfoPro,
	Comparator,
	VisualSearchResults,
	AppSubscription,
	Logout,
	Otp,
	AppNew,
	AppGrid,
	ProfessionalActivation,
} from './views'
import GoogleAnalytics from 'components/analytics/GoogleAnalytics'
import { BillingModal, WrongPlanModal, PaymentModal } from './components/organisms'
import Footer from './components/app/footer/Footer'
import { IntlProvider } from 'react-intl'

import en from './lang/en.json'
import es from './lang/es.json'
import { ApiHelper } from 'helpers'
import NewCard from 'views/NewCard'
import SubscriptionCheckerModal from 'components/organisms/SubscriptionCheckerModal'
import actions from 'store/actions'

import ErrorPoster from 'views/ErrorPoster'

function App() {
	const data = {
		es: es,
		en: en,
	}

	useEffect(() => {
		AOS.init({
			easing: 'ease-out-cubic',
			once: true,
			offset: 50,
		})

		const script = document.createElement('script')
		script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`
		document.body.appendChild(script)
	}, [])

	return (
		<Provider store={store}>
			<IntlProvider locale='es' messages={data['es']} defaultLocale='es'>
				<AppRouter />

				<PaymentModal />
				<BillingModal />
				<WrongPlanModal />
				<SubscriptionCheckerModal />
			</IntlProvider>
		</Provider>
	)
}

function AppRouter() {
	const [user, setUser] = useState(null)

	const dispatch = useDispatch()

	const [isLoading, setIsLoading] = useState(true)
	const [showOnboarding, setShowOnboarding] = useState(false)

	useEffect(() => {
		const user = localStorage.user ? JSON.parse(localStorage.user) : null
		setUser(user)
		if (user) {
			getUserData()
			showSubcriptionModal()
		}
	}, [])

	function showSubcriptionModal() {
		const isInactive = user && user.infoCreditsActive === false
		if (isInactive) {
			const infoCreditsAlertDate = localStorage.getItem('infoCreditsAlertDate')
			let shouldAlert = true
			if (infoCreditsAlertDate) {
				const alertDate = new Date(infoCreditsAlertDate)
				const now = new Date()
				if (now.getDay() === alertDate.getDay()) {
					shouldAlert = false
				}
			}
			if (shouldAlert) {
				dispatch(actions.app.setShowSubscriptionDisabled(true))
			}
		} else {
			localStorage.removeItem('infoCreditsAlertDate')
		}
	}

	const getUserData = async () => {
		try {
			const res = await ApiHelper.get('admin', '/user')
			dispatch(actions.user.setUser(res.data))

			if (!res.data.firstName) {
				setShowOnboarding(true)
			} else {
				showSubcriptionModal()
			}

			localStorage.user = JSON.stringify(res.data)
		} catch (error) {
			console.log(error)
			if (error.status === 412) {
				console.log('error 412')
				setShowOnboarding(true)
			}
		} finally {
			setIsLoading(false)
		}
	}

	if (user && isLoading) {
		return <div></div>
	} else if (user && showOnboarding) {
		return (
			<Router>
				<div className='App'>
					<GoogleAnalytics>
						<div
							className='g-recaptcha'
							data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
							data-size='invisible'
						/>
						<Welcome />
					</GoogleAnalytics>
				</div>
			</Router>
		)
	} else {
		return (
			<Router>
				<div className='App'>
					<GoogleAnalytics>
						<div
							className='g-recaptcha'
							data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
							data-size='invisible'
						/>
						<PaymentLoader />

						<Switch>
							<Route exact path='/app-new-card' component={NewCard} />
							<Route exact path='/bienvenido' component={Welcome} />

							<Route exact path='/app-article/:id' component={AppNew} />
							<Route exact path='/app-infogrid' component={AppGrid} />

							<Route exact path='/suscripcion-app' component={AppSubscription} />
							<Route
								path='/'
								component={() => (
									<>
										<Header />
										<Switch>
											<Route exact path='/' component={Home} />
											<Route exact path='/busqueda-asistida' component={AdvancedSearch} />
											<Route exact path='/particulares' component={Nosotros} />
											<Route exact path='/profesionales' component={Professionals} />
											<Route exact path='/empresas' component={Enterprise} />
											<Route exact path='/busqueda-asistida' component={AdvancedSearch} />
											<Route exact path='/contacto' component={Contacto} />
											<Route exact path='/noticias' component={News} />
											<Route exact path='/noticias/:type' component={News} />
											<Route exact path='/noticias/articulo/:id' component={New} />
											<Route exact path='/revista' component={Magazine} />
											<Route exact path='/servicio' component={Servicio} />
											<Route exact path='/resultado-busqueda' component={SearchResult} />
											<Route exact path='/ficha/:vehicleType/:versionCode' component={ProductSheet} />
											<Route exact path='/busqueda-visual' component={VisualSearch} />
											<Route exact path='/busqueda-visual/resultados' component={VisualSearchResults} />
											<Route exact path='/busqueda-visual/:type/:id' component={VisualSearchResult} />
											<Route exact path='/perfil/:section' component={Profile} />
											<Route exact path='/nosotros' component={Individuals} />
											<Route exact path='/infogrid' component={InfoPro} />
											<Route exact path='/comparador' component={Comparator} />
											<Route exact path='/terminos' component={Terminos} />
											<Route exact path='/politicas' component={Politicas} />
											<Route exact path='/preguntas-frecuentes' component={PreguntasFreq} />
											<Route exact path='/activar-cuenta' component={RecuperarCuenta} />

											<Route exact path='/activacion' component={Otp} />
											<Route exact path='/activacion-profesional' component={ProfessionalActivation} />
											<Route exact path='/calificaciones' component={Calificaciones} />
											<Route exact path='/error' component={ErrorPoster} />
											<Route exact path='/logout' component={Logout} />
										</Switch>
										<Footer />
									</>
								)}
							/>
						</Switch>
					</GoogleAnalytics>
				</div>
			</Router>
		)
	}
}

export default App
