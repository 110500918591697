import React, { forwardRef } from "react";
import Loader from "react-loader-spinner";

export const SquareButton = forwardRef(
	(
		{
			className = null,
			onClick = null,
			text,
			loading,
			size = null,
			disabled = null,
			pro = null,
			fullWidth = true,
			...rest
		},
		ref
	) => {
		return (
			<button
				ref={ref}
				className={`p-2 link square_button ${
					disabled ? "disabled" : "bg_black"
				} ${size === "sm" && "btn_sm"} ${
					fullWidth ? "w-100" : ""
				} ${className}`}
				onClick={!disabled && onClick}
				{...rest}
			>
				<div
					className={
						"text-white font-weight-bold text-center text-uppercase mb-0 w-100"
					}
				>
					{loading ? (
						<Loader type='ThreeDots' color='white' height={20} width={20} />
					) : (
						text
					)}
				</div>
			</button>
		);
	}
);
