import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import actions from "../../../store/actions";

const TypeFilter = () => {
    const vehicle = useSelector(state => state.search.vehicle);
    const [types] = useState([
        {
            value: 'cars',
            label: 'Auto'
        },
        {
            value: 'motorcycles',
            label: 'Moto'
        }
    ]);
    const dispatch = useDispatch();

    const setVehicle = (type) => {
        dispatch(actions.search.setVehicleType(type))
    };

    return (
        <div className='mb-3 d-flex vehicle_type_selector default_shadow rounded-pill link mx-auto mx-lg-0'>
            {types.map(type =>
                <div
                    key={type.value}
                    onClick={() => setVehicle(type.value)}
                    className={`py-1 px-3 align-items-center w-100 text-center option text-uppercase ${vehicle === type.value ? 'bg_dark' : 'bg_gray text-white'}`}
                >
                    <small className='spacing_1' style={{ fontSize: '10px' }}>
                        {type.label}
                    </small>
                </div>
            )}
        </div>
    )
};

export default TypeFilter;
