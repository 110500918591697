import React, { useState, useEffect } from "react";
import { UserType } from "../atoms";
import { RegisterModalHeader } from "../atoms";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
export const RegisterInterests = ({
	userType,
	handleBack,
	interests,
	setInterests,
}) => {
	const user = useSelector((state) => state.user.user);
	const regularInterests = ["Comprar", "Vender", "Comparar", "Informarse"];
	const isFleetUser =
		user.activeSubscription?.subscriptionType?.type === "fleet";

	const professionalInterests = [
		"Productor",
		"Concesionaria",
		"Perito",
		"Compra / Venta",
		"Tasador",
		"Inmobiliaria",
		"Empresa de flota de vehículos",
	];

	const [otherChecked, setOtherChecked] = useState(false);
	const [other, setOther] = useState("");
	const handleCheck = (interest) => {
		setInterests(
			interests.includes(interest)
				? interests.filter((i) => i !== interest)
				: [...interests, interest]
		);
	};

	useEffect(() => {
		if (!otherChecked) {
			setInterests(interests.filter((i) => i !== other));
			setOther("");
		}
	}, [otherChecked]);

	const handleOther = (value) => {
		if (otherChecked) {
			setInterests([...interests.filter((i) => i !== other), value]);
			setOther(value);
		}
	};

	return (
		<div className='col-12 d-flex flex-column align-items-center'>
			{!isFleetUser && <RegisterModalHeader onClick={handleBack} />}
			<h5 className='font-weight-bold mb-4'>
				{userType === "individual"
					? "¿Qúe estás buscando?"
					: "Registración de Usuario Profesional"}
			</h5>
			{userType === "individual" ? (
				<p>
					2. Contanos cuáles son tus{" "}
					<span className={"font-weight-bold"}>áreas de interés</span>
				</p>
			) : (
				<p>
					2. Seleccioná tu{" "}
					<span className={"font-weight-bold"}>rubro / actividad</span>
				</p>
			)}
			<div className={"container p-5"}>
				<div className='row d-flex justify-content-md-center align-items-center'>
					{userType === "individual" &&
						regularInterests.map((interest, index) => {
							return (
								<Form.Group
									key={index}
									className={"mr-4"}
									controlId={`${interest}_check`}
								>
									<Form.Check
										type='checkbox'
										checked={interests.includes(interest)}
										label={interest}
										onChange={() => handleCheck(interest)}
									/>
								</Form.Group>
							);
						})}
					{userType === "professional" &&
						professionalInterests.map((interest, index) => {
							return (
								<Form.Group
									key={index}
									className={"mr-4"}
									controlId={`${interest}_check`}
								>
									<Form.Check
										type='checkbox'
										checked={interests.includes(interest)}
										label={interest}
										onChange={() => handleCheck(interest)}
									/>
								</Form.Group>
							);
						})}
					{userType === "professional" && (
						<Form.Group controlId={`otros_check`} className={"mr-2"}>
							<Form.Check
								type='checkbox'
								checked={otherChecked}
								label={"Otros"}
								onChange={() => setOtherChecked(!otherChecked)}
							/>
						</Form.Group>
					)}
					{userType === "professional" && (
						<input
							name={"prefix"}
							disabled={!otherChecked}
							value={other}
							onChange={(event) => handleOther(event.target.value)}
							className={"custom_input default-shadow p-2 mb-3"}
							placeholder={"Otro"}
							type={"text"}
						/>
					)}
				</div>
			</div>
			{userType === "individual" && (
				<p>
					Seleccióna al menos un{" "}
					<strong>area interés para poder continuar el registro.</strong>
				</p>
			)}
			{userType === "professional" && (
				<p>
					Seleccióna al menos{" "}
					<strong>un rubro para continuar con el registro.</strong>
				</p>
			)}
		</div>
	);
};
