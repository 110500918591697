import { AdSQ } from "components/ads";
import AdSM from "components/ads/AdSM";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import AdM from "../components/ads/AdM";
import AdXL from "../components/ads/AdXL";
import { Divider, Loader } from "../components/atoms";
import { DollarWidget } from "../components/molecules";
import {
  CarDetailsTables,
  CarProfile,
  InfoCreditsModal,
  Measurements,
  ReportModal,
  SideMenuSearch,
  VehicleSheetHistoricPriceChart,
  VehicleSheetPriceChart,
} from "../components/organisms";
import { ApiHelper } from "../helpers";
import actions from "../store/actions";

export const ProductSheet = () => {
  const params = useParams();

  const { versionCode, vehicleType } = useParams();
  const location = useLocation();
  const [vehicle, setVehicle] = useState(null);
  const [newPrice, setNewPrice] = useState();
  const [showReportModal, setShowReportModal] = useState(false);
  const [showInfoCreditsModal, setShowInfoCreditsModal] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const isCarProfile = location.pathname.includes("cars");

  useEffect(() => {
    getVehicle();
  }, [versionCode]);

  const getVehicle = async () => {
    dispatch(actions.app.setLoading(true));
    const data = {
      vehicleType: vehicleType,
      codia: versionCode,
      needsDescription: true,
      batch: [versionCode],
    };
    const vehicle = await ApiHelper.getVehicle(data);
    if (vehicle?.hasNewPrice) {
      vehicle.version.years = [...vehicle.version.years, "0km"];
    }
    vehicle.version.years.reverse();
    setVehicle(vehicle);
    dispatch(actions.app.setLoading(false));

    if (vehicle.hasNewPrice) {
      const resPrice = await ApiHelper.get(
        "preciosa",
        `/models/${versionCode}/listPrice?vehicleType=${vehicleType}`
      );
      setNewPrice(resPrice.data);
    }
  };

  return (
    <Loader>
      <div id="product_sheet" className="page_default_margin mb-5">
        <div className="container">
          <AdXL subsection="-banner" />
          <div className="row  mt-4">
            <div className="col-xl-3 order-2 order-xl-1 text-center">
              {vehicle && <SideMenuSearch vehicle={vehicle} />}

              <div
                className={
                  "mt-3 px-4 bordered_dollar pt-3 d-flex align-items-center"
                }
              >
                <DollarWidget vertical={true} />
              </div>

              <div className="mt-2 imagenes">
                <AdM subsection="-cuadrado-1" />
              </div>
              <div className="mt-2">
                <AdSQ subsection="-cuadrado-2" className="" />
              </div>
              <div className="mt-2">
                <AdSM subsection="-cuadrado-3" />
              </div>
            </div>
            {vehicle && (
              <div className="col-xl-9 order-1 order-xl-2">
                <CarProfile vehicle={vehicle} newPrice={newPrice} />
                <Divider />
                <VehicleSheetPriceChart
                  vehicleName={vehicle.description}
                  versionCode={vehicle.codia}
                  setShowModal={setShowReportModal}
                  newPrice={vehicle.newPrice}
                  prices={vehicle.usedPrices}
                  vehicle={vehicleType}
                />

                <VehicleSheetHistoricPriceChart
                  setShowModal={setShowReportModal}
                  vehicle={vehicle}
                />
                <Measurements vehicle={vehicle} />
                <CarDetailsTables
                  setShowModal={setShowReportModal}
                  vehicle={vehicle}
                />

                <ReportModal
                  show={showReportModal}
                  vehicle={vehicle.version}
                  hide={() => setShowReportModal(false)}
                />
                <InfoCreditsModal
                  show={showInfoCreditsModal}
                  hide={() => setShowInfoCreditsModal(false)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Loader>
  );
};
