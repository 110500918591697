import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { InfoCreditsAlertModal } from "../organisms";
import actions from "../../store/actions";
import image1 from "../../static/img/dummy_graph_1.png";
import image2 from "../../static/img/dummy_graph_2.png";

export const BlurredGraph = ({ showModal, vehicleType }) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const handleClick = () => {
    if (user) {
      if (user.infoCreditsActive === false) {
        dispatch(actions.app.setShowSubscriptionDisabled(true));
        return;
      }
    }
    if (
      user.activeSubscription.name.includes("Auto") &&
      vehicleType === "motorcycles"
    ) {
      dispatch(actions.app.setShowWrongPlanModal(true));
      return;
    } else if (
      user.activeSubscription.name.includes("Moto") &&
      vehicleType === "cars"
    ) {
      dispatch(actions.app.setShowWrongPlanModal(true));
      return;
    } else {
      showModal();
    }
  };

  return (
    <div className={"blurred_graph"}>
      <img alt={"blurred graph 1"} src={image1} />
      <img alt={"blurred graph 2"} src={image2} className={"mb-3"} />
      <div className={"overlay w-100 h-100 blurred_graph"}>
        {user ? (
          <h5
            onClick={handleClick}
            className={"font-weight-bold link color_white p-2"}
          >
            Mostrar precios
          </h5>
        ) : (
          <h5
            onClick={() => dispatch(actions.app.setShowRegister(true))}
            className={"font-weight-bold color_white"}
          >
            <span className={"link"}>
              Suscribite para poder visualizar la información real{" "}
            </span>
            <FontAwesomeIcon icon={faChevronRight} />
          </h5>
        )}
      </div>
    </div>
  );
};
