import React, { useState, useEffect } from 'react'
import { Select, Button, VehicleTypeSelector, PaginatedSelect } from '../atoms'
import { ApiHelper } from '../../helpers'
import { withRouter } from 'react-router-dom'
import actions from '../../store/actions'
import { useDispatch, useSelector } from 'react-redux'

const SideMenuSearch = ({ history, vehicle }) => {
	const defaultVersion = vehicle?.version
	const [model, setModel] = useState(null)
	// const [models, setModels] = useState([]);
	const [year, setYear] = useState(null)
	const [years, setYears] = useState([])
	const [version, setVersion] = useState(null)
	const [versions, setVersions] = useState([])
	const vehicleType = useSelector((state) => state.search.vehicle)
	const [brand, setBrand] = useState({
		...defaultVersion?.brand,
		value: defaultVersion?.brand.id,
		label: defaultVersion?.brand.name,
		type: 'brand',
	})
	const dispatch = useDispatch()

	useEffect(() => {
		setBrand(null)
	}, [vehicleType])

	useEffect(() => {
		setModel(null)
	}, [brand])

	useEffect(() => {
		if (model) {
			getYears()
		}
		setYears([])
		setYear(null)
	}, [model])

	useEffect(() => {
		setVersions([])
		setVersion(null)
	}, [year])

	const getYears = async () => {
		const data = {
			paginated: false,
			has0KPrice: true,
			hasUsedPrice: true,
			vehicleType: vehicleType,
		}
		let res = await ApiHelper.getYears(brand.id, model.id, data)

		res.response.map((year) => {
			year.value = year.year
			year.label = year.year
			year.type = 'year'
			year.id = year.year
			year.name = year.year
		})
		res.response.reverse()

		setYears(res.response)
	}

	const loadVersions = async (searchQuery, loadedOptions, { page }) => {
		if (year) {
			const data = {
				vehicleType: vehicleType,
				pageNumber: page,
				features: {},
				atYear: year.id,
			}

			const res = await ApiHelper.getPaginatedVersions(brand.id, model.id, data)

			return {
				options: res.data.response,
				hasMore: res.data.pagination.totalPages > page,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			}
		} else {
			return {
				options: [],
				hasMore: true,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			}
		}
	}

	const search = () => {
		let searchParams = []
		if (brand && model) {
			searchParams.push(brand)
			searchParams.push(model)
			if (year) {
				searchParams.push(year)
			}

			if (version) {
				searchParams.push(version)
				window.location.href = `${window.location.origin}/ficha/${vehicleType}/${version.codia}`
				return
			}

			dispatch(actions.search.setPrimarySeach(searchParams))
			history.push('/resultado-busqueda')
		}
	}

	const loadBrands = async (searchQuery, loadedOptions, { page }) => {
		const data = {
			paginated: true,
			vehicleType,
			page,
		}

		const res = await ApiHelper.getPaginatedBrands(data)

		return {
			options: res.data.response,
			hasMore: res.data.pagination.totalPages > page,
			additional: {
				page: searchQuery ? 2 : page + 1,
			},
		}
	}

	const loadModels = async (searchQuery, loadedOptions, { page }) => {
		if (brand) {
			const data = {
				paginated: false,
				hasUsedPrice: true,
				vehicleType,
				page,
			}
			const res = await ApiHelper.getPaginatedModels(brand.id, data)

			return {
				options: res.data.response,
				hasMore: res.data.pagination.totalPages > page,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			}
		} else {
			return {
				options: [],
				hasMore: true,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			}
		}
	}

	return (
		<div className={'px-3 mb-3'}>
			<div className={'d-flex justify-content-center'}>
				<VehicleTypeSelector />
			</div>

			<div className='py-3 mt-1'>
				<PaginatedSelect
					key={'brand'}
					value={brand}
					loadOptions={loadBrands}
					getOptionValue={(option) => option.id}
					getOptionLabel={(option) => option.name}
					onChange={setBrand}
					isSearchable={false}
					placeholder='Marca'
					cacheUniqs={[vehicleType]}
					additional={{
						page: 1,
					}}
				/>
			</div>
			<div className='py-2'>
				<PaginatedSelect
					key={'model'}
					value={model}
					loadOptions={loadModels}
					getOptionValue={(option) => option.id}
					getOptionLabel={(option) => option.name}
					defaultOptions={false}
					cacheUniqs={[brand, vehicleType]}
					onChange={setModel}
					isSearchable={false}
					placeholder='Modelo'
					additional={{
						page: 1,
					}}
				/>
			</div>
			<div className='py-2'>
				<Select placeholder={'Fabricación'} options={years} onSelect={setYear} selectedOption={year} />
			</div>
			<div className='py-2 mb-3'>
				<PaginatedSelect
					key={'version'}
					value={version}
					loadOptions={loadVersions}
					getOptionValue={(option) => option.codia}
					getOptionLabel={(option) => option.description}
					onChange={setVersion}
					isSearchable={false}
					placeholder='Versión'
					cacheUniqs={[year]}
					additional={{
						page: 1,
					}}
				/>
			</div>
			<Button dark={true} text={'Buscar'} onClick={search} />
		</div>
	)
}

export default withRouter(SideMenuSearch)
