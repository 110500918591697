import {
  DIGITAL_MAGAZINE,
  SEARCH_HISTORY,
  FAVORITES,
  VISUAL_DATASHEET,
  RAW_DATASHEET,
  COMPARATOR,
  COMPARATOR_FOUR_VEHICLES,
  PICTURES,
  HISTORIC_PRICE,
  USED_PRICE,
  USEFUL_GUIDE,
  INFOGRID,
  SHARE_PRICE,
  VISUAL_SEARCH,
  CAR_PRICES,
  MOTORBIKE_PRICES,
  VEHICLE_MEASUREMENTS,
  BUY_INFOCREDITS,
  UNSUSCRIBE,
  SUBSCRIBE_BUTTON,
} from "./sections";

const permissions = {
  unsuscribed: [RAW_DATASHEET, SUBSCRIBE_BUTTON],
  individualFree: [
    RAW_DATASHEET,
    VEHICLE_MEASUREMENTS,
    COMPARATOR,
    VISUAL_SEARCH,
    CAR_PRICES,
    MOTORBIKE_PRICES,
    SUBSCRIBE_BUTTON,
  ],
  individualCar: [
    DIGITAL_MAGAZINE,
    SEARCH_HISTORY,
    RAW_DATASHEET,
    VEHICLE_MEASUREMENTS,
    VISUAL_DATASHEET,
    COMPARATOR,
    COMPARATOR_FOUR_VEHICLES,
    PICTURES,
    USEFUL_GUIDE,
    SHARE_PRICE,
    VISUAL_SEARCH,
    CAR_PRICES,
    BUY_INFOCREDITS,
    UNSUSCRIBE,
  ],
  individualMotorbike: [
    DIGITAL_MAGAZINE,
    SEARCH_HISTORY,
    RAW_DATASHEET,
    VEHICLE_MEASUREMENTS,
    VISUAL_DATASHEET,
    COMPARATOR,
    COMPARATOR_FOUR_VEHICLES,
    PICTURES,
    USEFUL_GUIDE,
    SHARE_PRICE,
    VISUAL_SEARCH,
    MOTORBIKE_PRICES,
    BUY_INFOCREDITS,
    UNSUSCRIBE,
  ],
  individualPremium: [
    DIGITAL_MAGAZINE,
    SEARCH_HISTORY,
    FAVORITES,
    RAW_DATASHEET,
    VEHICLE_MEASUREMENTS,
    VISUAL_DATASHEET,
    COMPARATOR,
    COMPARATOR_FOUR_VEHICLES,
    HISTORIC_PRICE,

    PICTURES,
    USEFUL_GUIDE,
    SHARE_PRICE,
    VISUAL_SEARCH,
    MOTORBIKE_PRICES,
    CAR_PRICES,
    BUY_INFOCREDITS,
    UNSUSCRIBE,
  ],
  professionalFree: [
    RAW_DATASHEET,
    COMPARATOR,
    CAR_PRICES,
    INFOGRID,
    MOTORBIKE_PRICES,
    VEHICLE_MEASUREMENTS,
    SUBSCRIBE_BUTTON,
  ],
  professionalIndividual: [
    DIGITAL_MAGAZINE,
    SEARCH_HISTORY,
    FAVORITES,
    RAW_DATASHEET,
    VEHICLE_MEASUREMENTS,
    COMPARATOR,
    COMPARATOR_FOUR_VEHICLES,
    PICTURES,
    USED_PRICE,
    HISTORIC_PRICE,
    INFOGRID,
    SHARE_PRICE,
    CAR_PRICES,
    MOTORBIKE_PRICES,
    BUY_INFOCREDITS,
    UNSUSCRIBE,
  ],
  professionalFleet: [
    DIGITAL_MAGAZINE,
    SEARCH_HISTORY,
    FAVORITES,
    VEHICLE_MEASUREMENTS,
    RAW_DATASHEET,
    COMPARATOR,
    COMPARATOR_FOUR_VEHICLES,
    PICTURES,
    HISTORIC_PRICE,
    USED_PRICE,
    INFOGRID,
    SHARE_PRICE,
    CAR_PRICES,
    MOTORBIKE_PRICES,
  ],
};

export default permissions;
