import React, { useState } from 'react'

import { FeatureFilter } from '../molecules'

export const HistoricPriceSearchFilters = () => {
  const [features] = useState([
    {
      id: 1,
      placeholder: '',
      title: 'Fabricación',
      values: [
        '2005', '2006'
      ],

    },
    {
      id: 2,
      placeholder: '',
      title: 'Mes',
      values: [
        'Enero', 'Febrero'
      ]
    },
    {
      id: 3,
      placeholder: '',
      title: 'Marca',
      values: [
        'Honda', 'Ford'
      ]

    },
    {
      id: 4,
      placeholder: '',
      title: 'Modelo',
      values: [
        'Civic EX', 'Accord'
      ]

    },
    {
      id: 5,
      placeholder: '',
      title: 'Año',
      values: [
        '2005', '2006'
      ]
    },
    {
      id: 6,
      placeholder: '',
      title: 'Versión',
      values: [
        '2.0 TDI', '1.8 GT'
      ]
    }
  ]);





  return (
    <div id='visual_selected_filters_container' className='mt-2 grey-container'>
      <div id='car_filters_container' className="row ">
        <div className="col">
          <div className="row">
            <div className="col-xl-6 p-3">
              <h3 className='my-5'><span className='font-weight-bold'>Elegí</span> la fecha histórica de consulta.</h3>
              <div className="row">
                <div className="col-xl-3"><FeatureFilter option={features[0]} /></div>
                <div className="col-xl-3"><FeatureFilter option={features[1]} /></div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-9 p-3">
              <h3 className='my-5'>Selecciona el <span className='font-weight-bold'>vehículo</span> que queres consultar.</h3>
              <div className="row">
                <div className="col-xl-2"><FeatureFilter option={features[2]} /></div>
                <div className="col-xl-2"><FeatureFilter option={features[3]} /></div>
                <div className="col-xl-2"><FeatureFilter option={features[4]} /></div>
                <div className="col-xl-2"><FeatureFilter option={features[5]} /></div>
                <div className="col-xl-4">
                  <div className='text-center '>
                    <button type="button" className="btn btn-dark btn-lg text-uppercase px-5 font-weight-bolder ">
                      VER PRECIO
                    </button>
                    <p className='m-3'>Tenes algo que informarnos? <span className='font-weight-bold'>Reportar</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )

}
