import React, { useState, useEffect } from "react";
import { PriceSelectPill } from "components/atoms/PriceSelectPill";
import { SquareButton } from "components/atoms";
import { useDispatch, useSelector } from "react-redux";
import actions from "store/actions";
import arrowImg from "static/img/arrow.gif";

export default ({ onSubscribe, subscriptions }) => {
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState("monthly");
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (subscriptions.length > 0) {
      setSelectedSubscription(
        subscriptions.find(
          (s) => s.name === "Premium" && s.type === "monthly" && !s.professional
        )
      );
    }
  }, [subscriptions]);

  useEffect(() => {
    if (subscriptions) {
      setSelectedSubscription(
        subscriptions.find(
          (s) =>
            s.name === "Premium" && !s.professional && s.type === selectedPeriod
        )
      );
    }
  }, [selectedPeriod]);

  return (
    <div className={"plan_card_container"}>
      {selectedSubscription && (
        <div className={"plan_card default_shadow background_gray"}>
          <h3
            className={`bg_black text-white text-center p-2 font-weight-bold mb-0`}
          >
            Premium
          </h3>
          <div className="body p-4 d-flex flex-column align-items-center">
            <div>
              <p className={"text-center"}>
                La mejor opción para informarte y tomar buenas decisiones
              </p>
              <div className={"border-bottom w-100"}>
                <div className={"d-flex w-100 justify-content-center"}>
                  <h3 className={"font-weight-bold"}>$</h3>
                  <h1 className={"font-weight-bold display-4"}>
                    {selectedSubscription.price}
                  </h1>
                </div>
                <div className="d-flex justify-content-center">
                  <p className="font-weight-bold color_blue">PRECIO</p>
                  <PriceSelectPill
                    selectedPeriod={selectedPeriod}
                    setSelectedPeriod={setSelectedPeriod}
                  />
                </div>
              </div>
              <div
                className={
                  "d-flex border-bottom w-100 py-4 flex-column align-items-center justify-content-center"
                }
              >
                <p className={"font-weight-bold"}>Incluye</p>
                <p className="mb-1">Autos & Motos</p>
                <p className="mb-1">
                  Consulta 0km modo{" "}
                  <span className="font-weight-bold">Ilimitado</span>
                </p>
                <p className="mb-1">
                  Ficha técnica <span className="font-weight-bold">Básica</span>
                </p>
                <p className="mb-1 text-center">
                  Hasta{" "}
                  <strong>{selectedSubscription.infocreditsPerMonth}</strong>{" "}
                  consultas únicas de usados
                </p>
              </div>
              <div
                className={
                  "d-flex w-100 py-4 flex-column align-items-center justify-content-center"
                }
              >
                <p className={"font-weight-bold"}>Características</p>
                <p className="mb-1">Ficha Técnica Ilimitada y Visual</p>
                <p className="mb-1">Descripción Marca / Modelo / Versión</p>
                <p className="mb-1">Comparador hasta 4 Unidades</p>
                <p className="mb-1">Precio Histórico</p>
              </div>
            </div>

            <div className="w-75">
              <SquareButton
                onClick={() => onSubscribe(selectedSubscription)}
                text={"Contratar"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
