import React from "react";
import { ApiHelper, AppHelper } from "../../helpers";
import { Link } from "react-router-dom";

const SecondaryNew = ({ article, carousel }) => {

  return (
    <article
      className={`col-12 col-md-4 mt-2 px-1 ${carousel && "col-md-12 px-0 mt-0"
        }`}
    >
      <Link to={`/noticias/articulo/${AppHelper.articleURL(article)}`}>
        <div
          className="new_background secondary_new"
          style={{
            backgroundImage: article
              ? `url(${ApiHelper.adminRoute}/files/${article.previewImage})`
              : `url(https://www.diariomotor.com/imagenes/2015/11/mazda-3.jpg)`,
          }}
        >
          <div className="background_overlay position-absolute w-100 h-100 p-2 d-flex flex-column justify-content-end fixed-top">

            {article.epigraph && (
              <p
                dangerouslySetInnerHTML={{ __html: article.epigraph }}
                className="text-white mb-2 preview"
              />
            )}
            <h3 className="text-white text-left">
              {article ? article.title : "NOTICIA DE SEGUNDA JERARQUÍA"}
            </h3>
          </div>
        </div>
      </Link>
    </article>
  );
}


export default SecondaryNew
