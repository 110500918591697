import React from "react";
import { ApiHelper } from "../../helpers";

export const NewParagraph = ({ paragraph, index }) => {
  return (
    <div
      className={`col-12 col-lg-6 p-2 ${
        index % 2 === 0 && "border_right"
      } paragraph`}
    >
      <h3
        className={
          "text-center paragraph_title font-weight-bold text-uppercase ="
        }
      >
        {paragraph.title}
      </h3>
      <img
        className={"mt-2 w-100"}
        src={`${ApiHelper.adminRoute}/files/${paragraph.images[0]}`}
        alt={"car"}
      />
      <p
        className={`mt-3 pb-4 release_paragraph ${
          paragraph.content.includes("iframe") ? "video_wrapper" : ""
        }`}
        style={{ lineBreak: "anywhere" }}
      >
        {paragraph.content}
      </p>
    </div>
  );
};
