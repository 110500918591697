import React from "react";
import img from "../../static/img/grid-borde.png";

export default () => {
  return (
    <section className={"background_gray py-0 py-md-5"}>
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center text-center text-md-left">
            <h2 className={"font-weight-bold mb-3"}>
              Tecnología aplicada a tu gestión profesional.
            </h2>
            <p>
              Sabemos lo importante que es contar con la información necesaria
              en el momento justo.
            </p>
            <p>
              Por eso creamos InfoGrid, una herramienta pensada para facilitar
              tu gestión diaria, ganar tiempo y mejorar la calidad de
              información brindada al cliente final. Utilizar la guía de precios
              On Line nunca fue tan sencillo.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src={img} className={"w-100 default_shadow mb-4"} alt="" />
            <p className={"text-uppercase text-center"}>Software InfoGrid</p>
          </div>
        </div>
      </div>
    </section>
  );
};
