import React from 'react'

export const ChartCard = ({title, text}) => {
    return(
        <div className={'price_table_card default_shadow p-2 pl-3 mt-4'}>
            <p className="font-weight-bold mb-0">{title}</p>
            <p className={'mb-0'}>{text}</p>
        </div>
    )
}
