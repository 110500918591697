import React, { useState, useEffect } from "react";
import { PriceSelectPill } from "components/atoms/PriceSelectPill";
import { SquareButton } from "components/atoms";

import logo from "static/img/INFO_GRID_WHITE_LOGO.png";

export default ({ onSubscribe, subscriptions }) => {
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState("monthly");

  useEffect(() => {
    const selectedSubscription = subscriptions.find(
      (s) =>
        s.subscriptionType.type === "professional" && s.type === selectedPeriod
    );
    setSelectedSubscription(selectedSubscription);
  }, [subscriptions, selectedPeriod]);

  return (
    <div className={"plan_card_container"}>
      {selectedSubscription && (
        <div className={"plan_card default_shadow background_gray"}>
          <div className={"bg_blue infogrid_card_header"}>
            <img src={logo} alt="logo" />
          </div>
          <div className="body p-4 d-flex flex-column align-items-center">
            <div>
              <h4 className={"font-weight-bold color_blue text-center"}>
                Plan Individual
              </h4>
              <p className={"text-center"}>
                Funcionalidades desarrolladas específicamente para la gestión profesional
              </p>
              <div className={"border-bottom w-100"}>
                <div className={"d-flex w-100 justify-content-center"}>
                  <h3 className={"font-weight-bold"}>$</h3>
                  <h1 className={"font-weight-bold display-4"}>
                    {selectedSubscription.price}
                  </h1>
                </div>
                <div className="d-flex justify-content-center">
                  <p className="font-weight-bold color_blue">PRECIO</p>
                  <PriceSelectPill
                    selectedPeriod={selectedPeriod}
                    setSelectedPeriod={setSelectedPeriod}
                  />
                </div>
              </div>

              <div
                className={
                  "d-flex border-bottom w-100 py-4 flex-column align-items-center justify-content-center"
                }
              >
                <p className={"font-weight-bold"}>Incluye</p>
                <p className="mb-1">Autos & Motos</p>
                <p className="mb-1">Visualización en formato Grilla</p>
                <p className="mb-1">
                  Hasta{" "}
                  <strong>{selectedSubscription.infocreditsPerMonth}</strong>{" "}
                  consultas únicas
                </p>
                <p className="mb-1">Función búsquedas masivas</p>
              </div>
              <div
                className={
                  "d-flex w-100 py-4 flex-column align-items-center justify-content-center"
                }
              >
                <p className={"font-weight-bold"}>Características</p>
                <p className="mb-1">Ficha Técnica Ilimitada</p>
                <p className="mb-1">Descripción Marca / Modelo / Versión</p>
                <p className="mb-1">Comparador hasta 4 Unidades</p>
              </div>
            </div>

            <div className="w-75">
              <SquareButton
                onClick={() => onSubscribe(selectedSubscription)}
                text={"Contratar"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
