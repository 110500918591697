import React, { useState } from "react";

export default function UserType({
  userType,
  setSelectedUserType,
  selectedUserType,
}) {
  const isProfessional = userType.value === "professional";
  const [showDetails, setShowDetails] = useState(false);

  const optionsProfessional = [
    "PRODUCTORES",
    "PERITOS",
    "CONCESIONARIAS",
    "TASADORES",
    "AGENCIAS MULTIMARCA",
  ];

  const isSelected = selectedUserType === userType.value;

  return (
    <div className={`col-md-6 p-3`}>
      <div
        className={`row h-100 align-items-center ${!isSelected && "user_type_card"} ${isSelected ? "bg_black" : "bg_white"}`}
        style={{
          border: "1px solid #e8e8e8",
        }}
        onClick={() => setSelectedUserType(userType.value)}
      >

        {isProfessional && (
          <div className="col-lg-6 p-0 pt-4">
            <img
              className="card-img-bottom"
              src={userType.image}
              alt="Subscription"
            />
          </div>
        )}

        <div className={`col-12 col-lg-6 py-4`}>
          {userType.bullets.map((bullet, index) => (
            <p key={index} className="" style={{ fontSize: "0.75rem" }}>
              {bullet}
            </p>
          ))}
          {isProfessional && (
            <>
              <hr className="" />
              <div className="d-flex mb-0 mt-0 flex-wrap justify-content-center">
                {optionsProfessional.map((option) => (
                  <p
                    style={{ fontSize: "0.75rem" }}
                    className=" mb-0 text-professional-options ml-2 font-weight-normal"
                  >
                    {option}
                  </p>
                ))}
              </div>
            </>
          )}
        </div>

        {!isProfessional && (
          <div className="col-lg-6 p-0 mt-4">
            <img
              className="card-img-top"
              src={userType.image}
              alt="Subscription"
            />
          </div>
        )}

        <div className="col-12 py-4" style={{ borderTop: "1px solid #f7f7f7" }}>
          <h6 className={`font-weight-normal text-uppercase mb-0 ${isProfessional ? "text-left" : "text-right"}`}>
            {userType.name}
          </h6>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`col-md-6`}>
      {/* <div
        className="link bg-primary w-100"
        // onClick={() => setUserType(subscription.value)}
      > */}
      <div className="row card">
        <div className="col-md-6">
          <div className="embed-responsive embed-responsive-1by1">
            <img
              className="card-img-top embed-responsive-item"
              src={userType.image}
              alt="Subscription"
            />
          </div>
        </div>
        <div className="col-md-6 bg-secondary">
          <div>
            {userType.bullets.map((bullet, index) => (
              <p key={index} className="" style={{ fontSize: "0.7rem" }}>
                {bullet}
              </p>
            ))}

            {isProfessional && (
              <>
                <hr className="" />
                <div className="d-flex mb-0 mt-0 flex-wrap justify-content-center">
                  {optionsProfessional.map((option) => (
                    <p
                      style={{ fontSize: "0.5rem" }}
                      className=" mb-1 text-professional-options ml-2 font-weight-normal"
                    >
                      {option}
                    </p>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
        {/* <div
          className={`card-body ${
            userType.value === selectedUserType ? "bg_black" : null
          }`}
        >
          <h6 className="font-weight-normal text-uppercase mb-0">
            {userType.name}
          </h6>
        </div> */}
      </div>
      {/* </div> */}
    </div>
  );
}
