import React, { useState } from 'react';

export const UserType = props => {
    const { subscription, userType, setUserType } = props;
    const isProfessional = subscription.value === 'professional';
    const [ showDetails, setShowDetails ] = useState(false);

    const optionsProfessional = [
        'PRODUCTORES', 'PERITOS', 'CONCESIONARIAS', 'TASADORES', 'AGENCIAS MULTIMARCA'
    ]
    return (
        <div className={`col-12 col-lg-6 ${!isProfessional ? 'mb-3 mb-md-0' : ''}`}>
            <div className="card w-100 link" onClick={() => setUserType(subscription.value)} onMouseEnter={() => setShowDetails(true)} onMouseLeave={() => setShowDetails(false)} >
                <div className="embed-responsive embed-responsive-1by1 mb-0">
                    <img className="card-img-top embed-responsive-item" src={subscription.image} alt="Subscription" />
                    {showDetails && <div className="embed-responsive-item position-absolute register_subscription p-2 d-flex flex-column ">
                        <ul className={'p-0 mb-0'}>
                            {subscription.bullets.map((bullet, index) => <li key={index} className="mb-2">{bullet}</li>)}
                        </ul>
                        {isProfessional && (
                            <>
                                <hr color='white' className='mx-4' />
                                <div className='d-flex mb-0 mt-0 flex-wrap justify-content-center'>
                                    {optionsProfessional.map((option) => (
                                        <p className='text-white mb-1 text-professional-options ml-2 font-weight-normal'>{option}</p>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>}



                </div>
                <div className={`card-body ${subscription.value === userType ? 'bg_black' : null}`}>
                    <h6 className="font-weight-normal text-uppercase mb-0">{subscription.name}</h6>
                </div>
            </div>
        </div>
    )
};
