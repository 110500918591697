import React, { useEffect, useState } from 'react';
import { Loader } from "components/atoms";
import { AppInfoGridTable } from 'components/molecules'
import { withRouter } from "react-router-dom";


export const AppGrid = withRouter(() => {

	const [ vehicles, setVehicles ] = useState([])
	const [ pinned, setPinned ] = useState([])
	const [ user, setUser ] = useState({})
	const [ prices, setPrices ] = useState([]);


	useEffect(() => {
		document.addEventListener('message', listener)


		window.addEventListener('message', listener)
		window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'loadData' }))
	}, [])


	const loadMoreVersions = () => {
		const loadMoreMessage = {
			type: 'loadMore'
		}
		window.ReactNativeWebView.postMessage(JSON.stringify(loadMoreMessage))
	}


	const listener = ({ data, origin }) => {
		if (!origin && Array.isArray(JSON.parse(data))) {
			const messages = JSON.parse(data)
			messages.forEach(message => {
				switch (message.type) {
					case 'vehicles':
						setVehicles(message.payload)
						break;
					case 'user':
						setUser(message.payload);
						break;
					case 'prices':
						setPrices(message.payload);
						break;
					case 'pinnedVehicles':
						setPinned(message.payload);
						break
					// case 'moreVehicles':
					// 	setVehicles((vehicles) => [ ...vehicles, ...message.payload ])
					default:
						return
				}
			})
		}
	}

	return (

		<Loader>
			<div id='grid_container' className="container-fluid p-0" style={{ width: '100vw', overflow: 'hidden' }}>
				<AppInfoGridTable prices={prices} pinned={pinned} versions={vehicles} quantity={250} user={user} loadMoreVersions={loadMoreVersions} />
			</div>
		</Loader>)
})