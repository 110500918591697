import React, { useState, useEffect } from "react";
import { icons } from "../../constants";

export const DetailTableElement = ({ detail }) => {
  const [ value, setValue ] = useState(null);
  const [ iconName, setIconName ] = useState(null);

  useEffect(() => {
    if (detail.type === "choice") {
      if (detail.value_description.includes("No tiene")) {
        setValue("NO");
      } else {
        setValue(detail.value_description);
      }
    } else if (detail.type === "boolean") {
      setValue(detail.value === "true" ? "SI" : "NO");
    } else {
      setValue(detail.value);
    }
    let iconName = detail.description;
    if (detail.description === "Start/Stop") {
      setIconName("StartStop");
    } else if (detail.description === "Aceleración 0-100 km/h (segundos)") {
      setIconName("Aceleración");
    } else if (detail.description === "Relación peso / potencia (Kg/HP)") {
      setIconName("Relación peso-potencia");
    } else if (detail.description === "Tipo de vehiculo") {
      setIconName("Tipo de vehículo");
    } else setIconName(iconName);
  }, []);

  const icon = icons.find((icon) => icon.description === detail.description);

  return (
    <li className="w-100 row ml-1 p-1 border-bottom d-flex align-items-center justify-content-between">
      <div>
        <img src={`/assets/icons/${iconName}.svg`} style={{ width: 30 }} />
        <small className="ml-2 font-weight-bold">{detail.description}</small>
      </div>
      <small>{value}</small>
    </li>
  );
};
