import { faTimes, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import 'react-credit-cards/es/styles-compiled.css'
import { useDispatch, useSelector } from 'react-redux'
import { ApiHelper } from '../../helpers'
import actions from '../../store/actions'

import usePayment from 'hooks/usePayment'

const PaymentModal = () => {
	const show = useSelector((state) => state.app.showPaymentModal)

	const selectedPlan = useSelector((state) => state.app.selectedPlan)

	const [paymentSuccesful, setPaymentSuccesful] = useState(false)

	const { renderCard, renderForm } = usePayment(suscribeToPlan)

	useEffect(() => {
		if (window.gtag) {
			window.gtag('event', 'begin_checkout')
		}
	}, [])

	const dispatch = useDispatch()
	const hide = () => {
		dispatch(actions.app.setShowPaymentModal(false))
	}

	const goBack = () => {
		dispatch(actions.app.setShowPaymentModal(false))
		dispatch(actions.app.setShowBillingModal(true))
	}

	async function suscribeToPlan(cardToken, paymentMethodId, dni) {
		const data = {
			planId: selectedPlan.id,
			cardToken: cardToken,
			dni: dni,
			paymentMethodId: paymentMethodId,
		}
		await ApiHelper.suscribeToPlan(data)

		if (window.gtag) {
			window.gtag('event', 'purchase', {
				...selectedPlan,
			})
		}
		setPaymentSuccesful(true)
		setTimeout(() => {
			window.location.reload()
		}, 3000)
	}

	const renderContent = () => {
		if (paymentSuccesful) {
			return (
				<div data-aos='fade-in' className='p-5'>
					<h2 className='font-weight-normal text-center'>
						¡Felicitaciones! Ya estas suscripto a tu nuevo plan. <strong>¡Comenzá a disfrutarlo ahora!</strong>
					</h2>
				</div>
			)
		} else {
			return (
				<div>
					<div className={'text-right pt-3 pr-3 link d-flex justify-content-between'}>
						<div onClick={goBack}>
							<FontAwesomeIcon icon={faChevronLeft} className='mx-2' />
							Volver
						</div>
						<FontAwesomeIcon onClick={hide} icon={faTimes} />
					</div>
					<div className='col-12 gray_background p-5'>
						<h4 className='font-weight-normal text-center mb-4'>
							<strong>Pagar suscripción</strong>
						</h4>
						{renderCard()}
						{renderForm()}
					</div>
				</div>
			)
		}
	}

	return (
		<Modal show={show} onHide={hide} size={'lg'} className={'default_modal'}>
			<Modal.Body className={'p-0 background_gray'}>{renderContent()}</Modal.Body>
		</Modal>
	)
}

export default PaymentModal
