import React from "react";

export const SplitButton = (props) => {
  return (
    <div className="text-uppercase font-weight-bolder btn btn-dark btn-lg split_button">
      <div className="row">
        <div className="col d-flex justify-content-around">
          <span
            className={`${props.selected === props.opt1 ? "" : "notSelected"}`}
            onClick={() => props.onClickOpt1}
            style={{
              cursor: "pointer",
            }}
          >
            {props.opt1}
          </span>
          <span>|</span>
          <span
            className={`${props.selected === props.opt2 ? "" : "notSelected"}`}
            onClick={() => props.onClickOpt2}
            style={{
              cursor: "pointer",
            }}
          >
            {props.opt2}
          </span>
        </div>
      </div>
    </div>
  );
};
