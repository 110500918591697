import axios from "axios";
import AppHelper from "./AppHelper";

export default class ApiHelper {
    static routes = {
        DEV: {
            appRoute: "http://181.229.128.64:8080",
        },
        PROD: {
            appRoute: "https://vps-1753991-x.dattaweb.com:7080",
        },
    };

    static defaultTimeout = 10000;

    static appRoute = `${process.env.REACT_APP_API_URL}/lineapp-back`;
    static preciosaRoute = `${process.env.REACT_APP_API_URL}/preciosa-service`;
    static authRoute = `${process.env.REACT_APP_API_URL}/auth-service`;
    static magazineRoute = `${process.env.REACT_APP_API_URL}/revistas-service`;

    static adminRoute = `${process.env.REACT_APP_API_URL}/administracion-service`;

    static newsRoute = `${process.env.REACT_APP_API_URL}/noticias-service`;

    static adsRoute = `${process.env.REACT_APP_API_URL}/ads-service`;

    static newsImageRoute = `${process.env.REACT_APP_API_URL}/noticias-service/files`;

    static magazineImageRoute = `${process.env.REACT_APP_API_URL}/revistas-service/files`;
    static boxfulRoute = `${process.env.REACT_APP_API_URL}/boxful-service`;

    static getAppRoute = () => {
        return this.appRoute;
    };

    static getTimeout = () => {
        return this.defaultTimeout;
    };

    static getPreciosaRoute = () => {
        return this.preciosaRoute;
    };

    static appRoutes = {
        preciosa: this.preciosaRoute,
        auth: this.authRoute,
        admin: this.adminRoute,
        news: this.newsRoute,
        ads: this.adsRoute,
        magazine: this.magazineRoute,
        boxful: this.boxfulRoute,
    };

    static getHeaders = (type, data, path) => {
        if (type === "auth") {
            if (path === "/otp") {
                let headers = {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };
                if (localStorage.reCaptcha) {
                    headers.headers["Captcha-Response"] = localStorage.reCaptcha;
                }

                return headers;
            } else if (data === '/account') {
                return {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.token}`,
                    },
                };
            } else {
                return {
                    headers: {
                        Authorization: "Basic SW5mb2F1dG9DbGllbnRJZDpoczlmb2l3YQ==",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                };
            }
        } else {
            if (localStorage.token) {
                let headers = {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.token}`,
                    },
                };
                if (localStorage.reCaptcha) {
                    headers.headers["Captcha-Response"] = localStorage.reCaptcha;
                }

                return headers;
            } else {
                let headers = {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };
                if (localStorage.reCaptcha) {
                    headers.headers["Captcha-Response"] = localStorage.reCaptcha;
                }

                return headers;
            }
        }
    };

    static handleError = (error) => {
        let {message, status} = error;

        if (!status && error.response) {
            status = error.response.status;
        }
        switch (status) {
            case 429:
                window.location.replace("/error");
            case 400:

            // localStorage.clear();
            // window.location.replace("/");
            // console.log(error)
            // do something when you're unauthenticated
            case 403:
            // do something when you're unauthorized to access a resource
            case 500:
            // do something when your server exploded
            default:
            // handle normal errors with some alert or whatever
        }
        return {message, status}; // I like to get my error message back
    };

    static get = (type, path, data) => {
        return new Promise((resolve, reject) => {
            axios(`${this.appRoutes[type]}${path}`, {
                ...this.getHeaders(type),
                method: "get",
                data: data,
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    // toast with toastify

                    reject(this.handleError(error));
                });
        });
    };

    static getImage = (type, path) => {
        return new Promise((resolve, reject) => {
            axios(`${this.appRoutes[type]}${path}`, {
                ...this.getHeaders(type),
                method: "get",
                responseType: "blob",
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(this.handleError(error));
                });
        });
    };

    static post = (type, path, data) => {
        return new Promise((resolve, reject) => {
            axios(`${this.appRoutes[type]}${path}`, {
                ...this.getHeaders(type, data, path),
                method: "post",
                data: data,
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(this.handleError(error));
                });
        });
    };

    static put = (type, path, data) => {
        return new Promise((resolve, reject) => {
            axios(`${this.appRoutes[type]}${path}`, {
                ...this.getHeaders(type, data, path),
                method: "put",
                data: data,
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(this.handleError(error));
                });
        });
    };

    static patch = (type, path, data) => {
        return new Promise((resolve, reject) => {
            axios(`${this.appRoutes[type]}${path}`, {
                ...this.getHeaders(type, data, path),
                method: "patch",
                data: data,
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(this.handleError(error));
                });
        });
    };

    static delete = (type, path) => {
        return new Promise((resolve, reject) => {
            axios(`${this.appRoutes[type]}${path}`, {
                ...this.getHeaders(type, path),
                method: "delete",
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(this.handleError(error));
                });
        });
    };

    static createInterceptors = () => {
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response.status === 401) {
                }
                return error;
            }
        );
    };

    static URLparams = (data) => {
        if (data.featuresRequest) {
            data.featuresRequest = null;
        } else if (data.fromYear && data.toYear && data.fromYear === data.toYear) {
            data.atYear = data.fromYear || data.toYear;
            delete data.fromYear;
            delete data.toYear;
        }
        return new URLSearchParams(data).toString();
    };

    static getDownloadedBrands = async ({vehicleType}) => {
        const res = await this.get(
            "preciosa",
            `/brands/download?vehicleType=${vehicleType}`
        );
        return res.data;
    };

    static getPaginatedBrands = async (data, features = {}) => {
        const res = await this.post("preciosa", `/brands?${this.URLparams(data)}`, {
            features,
        });
        return res;
    };

    static getPaginatedModels = async (brandId, data, features = {}) => {
        const res = await this.post(
            "preciosa",
            `/brands/${brandId}/groups?${this.URLparams(data)}`,
            {features}
        );
        return res;
    };

    static getPaginatedNews = async (data) => {
        const {pageNumber, pageSize} = data;

        const res = await this.get(
            "admin",
            `/visibleNews?pageNumber=${pageNumber}&pageSize=${pageSize}`
        );
        return res;
    };

    static getPaginatedNewsByCategory = async (category, data) => {
        const {pageNumber, pageSize} = data;
        const {id} = category;

        const res = await this.get(
            "admin",
            `/visibleNews/category/${id}?pageNumber=${pageNumber}&pageSize=${pageSize}`
        );

        return res;
    };

    static getBrands = async (data, features = {}) => {
        const res = await this.post("preciosa", `/brands?${this.URLparams(data)}`, {
            features,
        });
        let promises = [];

        if (!data.pagination) {
            if (res.data.pagination.totalPages > 1) {
                for (let i = 2; i <= res.data.pagination.totalPages; i++) {
                    data.page = i;
                    promises.push(
                        this.post("preciosa", `/brands?${this.URLparams(data)}`, {
                            features,
                        })
                    );
                }
            }
            let brands = [...res.data.response];
            let responses = await Promise.all(promises);
            responses.map((r) => {
                brands = [...brands, ...r.data.response];
            });

            return brands;
        } else {
            return res.data.response;
        }
    };

    static getBrandsList = async (data) => {
        const res = await this.get(
            "preciosa",
            `/brands/download?${this.URLparams(data)}`
        );
        return res.data.response;
    };

    static getModels = async (id, data, featuresData) => {
        const features = featuresData ? featuresData : {};
        const res = await this.post(
            "preciosa",
            `/brands/${id}/groups?${this.URLparams(data)}`,
            {features}
        );
        let promises = [];
        if (!data.pagination) {
            if (res.data.pagination.totalPages > 1) {
                for (let i = 2; i <= res.data.pagination.totalPages; i++) {
                    data.page = i;
                    promises.push(
                        this.post(
                            "preciosa",
                            `/brands/${id}/groups?${this.URLparams(data)}`,
                            {features}
                        )
                    );
                }
            }
            let brands = [...res.data.response];
            let responses = await Promise.all(promises);
            responses.map((r) => {
                brands = [...brands, ...r.data.response];
            });
            return brands;
        } else {
            return res.data.response;
        }
    };

    static getYears = async (brandId, modelId, data) => {
        const res = await this.get(
            "preciosa",
            `/brands/${brandId}/groups/${modelId}/prices?vehicleType=${data.vehicleType}`
        );
        return res.data;
    };

    static getPaginatedVersions = async (brandId, modelId, data) => {
        const {features, ...rest} = data;
        let res = await this.post(
            "preciosa",
            `/brands/${brandId}/groups/${modelId}/models?${this.URLparams(rest)}`,
            {features: this.parseFeatures(features)}
        );
        res.data.response.map((v) => {
            v.photoUrl = v.photo_url;
            v.model = {name: v.name};
            v.years = [];
            for (let i = v.prices_from; i <= v.prices_to; i++) {
                v.years.push(i);
            }
        });
        return res;
    };

    static parseFeatures = (features) => {
        if (features) {
            Object.keys(features).map((key) => {
                const feature = features[key];
                if (!feature) {
                    features[key] = [];
                } else if (typeof feature === "boolean") {
                    features[key] = [feature];
                } else if (!Array.isArray(feature)) {
                    features[key] = feature.split(",");
                }
            });
            return features;
        } else {
            return {};
        }
    };

    static getPagVersions = async (brandId, modelId, {features, ...rest}) => {
        const res = await this.post(
            "preciosa",
            `/brands/${brandId}/groups/${modelId}/models?${this.URLparams(rest)}`,
            {features: features || {}}
        );

        return res;
    };

    static getVersions = async (brandId, modelId, {features, ...rest}) => {
        let res = await this.post(
            "preciosa",
            `/brands/${brandId}/groups/${modelId}/models?${this.URLparams(rest)}`,
            {features: features || {}}
        );
        let versions = res.data.response;
        versions.map((v) => {
            v.id = v.codia;
            v.name = v.description;
        });
        if (!rest.pagination) {
            let promises = [];
            if (res.data.pagination.totalPages > 1) {
                for (let i = 2; i <= res.data.pagination.totalPages; i++) {
                    rest.page = i;
                    promises.push(
                        this.post(
                            "preciosa",
                            `/brands/${brandId}/groups/${modelId}/models?${this.URLparams(
                                rest
                            )}`,
                            {features: features || {}}
                        )
                    );
                }
            }
            let responses = await Promise.all(promises);
            responses.map((r) => {
                versions = [...versions, ...r.data.response];
            });
            return versions;
        } else {
            return versions;
        }
    };

    static getPrices = async (versionId, data) => {
        const res = await this.get(
            "preciosa",
            `/models/${versionId}/prices?${this.URLparams(data)}`
        );

        let newPrice = res.data.response.find((p) => !p.year);
        let usedPrices = res.data.response.filter((p) => p.year).reverse();

        let userCredits = res.data.availableCredits;
        return {
            newPrice,
            usedPrices,
            userCredits,
            codia: versionId,
        };
    };

    static getFeatures = async (data) => {
        const res = await this.get("preciosa", `/features?${this.URLparams(data)}`);
        return res;
    };

    static getMagicLink = async () => {
        const res = await this.get("boxful", "/customers/paymentLink");
        return res;
    };

    static getPaymentStatus = async () => {
        const res = await this.get("boxful", "/subscriptions");
        return res;
    };

    static getInfoGrid = async (data) => {
        const {vehicleType, codia} = data;
        const res = await this.post(
            "preciosa",
            `/models/infogrid?vehicleType=${vehicleType}`,
            data
        );
        res.data.map((v) => {
            v.vehicleType = vehicleType;
            v.model = {
                name: v.description,
            };
            let years = [];
            if (v.list_price > 0) {
                years.push("0km");
            }
            v.prices.map((p) => years.push(p.year));

            v.years = years;
        });
        return res.data;
    };

    static getVehicle = async (data) => {
        const {vehicleType, codia, needsDescription = false} = data;
        const {data: vehicle} = await this.get(
            "preciosa",
            `/models/${codia}?vehicleType=${vehicleType}`
        );
        console.log(vehicle);

        vehicle.hasNewPrice = vehicle.list_price;
        vehicle.vehicleType = vehicleType;
        vehicle.version = {
            photoUrl: vehicle.photo_url,
            years: [],
            brand: vehicle.brand,
            versionDescription: vehicle.description,
            versionCode: codia,
            versionSummary: vehicle.summary,
        };

        if (!vehicle.summary && needsDescription) {
            const brand = await this.getBrand(vehicle.brand, vehicleType);
            vehicle.version.brandSummary = brand.summary;
        }

        for (let i = vehicle.prices_from; i <= vehicle.prices_to; i++) {
            vehicle.version.years.push(i);
        }

        const featuresRes = await this.get(
            "preciosa",
            `/models/${codia}/features?vehicleType=${vehicleType}`
        );

        vehicle.motorTransmitionFeatures = featuresRes?.data?.filter(
            (f) => f.category_name === "Motor y transmisión"
        );
        vehicle.specificationFeatures = featuresRes?.data?.filter(
            (f) => f?.category_name === "Datos técnicos"
        );
        vehicle.comfortFeatures = featuresRes?.data?.filter(
            (f) => f.category_name === "Confort"
        );
        vehicle.securityFeatures = featuresRes?.data?.filter(
            (f) => f.category_name === "Seguridad"
        );

        return vehicle;
    };

    static getBrand = async (brand, vehicleType) => {
        const {id, name} = brand;

        const res = await this.post(
            "preciosa",
            `/brands?queryString=${name}&vehicleType=${vehicleType}`,
            {
                features: {},
            }
        );
        return res.data.response.find((b) => b.id === id);
    };

    static getNewPrice = async ({codia, ...rest}) => {
        const res = await this.get(
            "preciosa",
            `/models/${codia}/listPrice?${new URLSearchParams(rest).toString()}`
        );
        return res.data.list_price;
    };

    static getUsedPrices = async (data) => {
        const {codia, ...rest} = data;

        const res = await this.get(
            "preciosa",
            `/models/${codia}/prices?${new URLSearchParams(rest).toString()}`
        );
        res.data.response.map((price) => {
            if (!price.year) {
                price.year = "0km";
            }
        });
        return res;
    };

    static suscribeToPlan = async ({planId, ...rest}) => {
        const res = await this.post("admin", `/subscriptions/${planId}`, rest);
        return res;
    };

    static buyInfocredits = async ({productId, ...rest}) => {
        const res = await this.post(
            "admin",
            `/payments?productId=${productId}`,
            rest
        );
        return res;
    };

    static sendOTP = async (email) => {
        const res = await this.put("auth", "/otp", email);
        return res;
    };

    static getProvinces = async () => {
        const res = await this.get("admin", "/geo/province");
        return res;
    };

    static getLocalities = async (provinceName) => {
        const res = await this.get("admin", `/geo/province/${provinceName}`);
        return res;
    };
}
