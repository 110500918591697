import React, {useState, useEffect} from 'react';

export const InfoProSlider = ({min, max, value, title, unit, subtitle}) => {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        const percentage = (value - min) * 100 / (max - min);
        setPercentage(percentage);
    }, [value]);

  
  return(
      <div className={'d-flex flex-column align-items-center justify-content-center'}>
          <div className={'d-flex'}>
              <div className={'info_pro_slider p-3 default_shadow'}>
                <div className="bar d-flex align-items-end">
                    <div className="bar_fill" style={{height: `${percentage+3}%`}} />
                    <div className="bar_anchor default_shadow" style={{bottom: `${percentage}%`}} />
                </div>
              </div>
              <div className="ml-2 pl-3 pr-3">
              <div className="info_pro_slider_values h-100 d-flex flex-column justify-content-between">
                    <p className={'mb-0'}>{percentage < 83 && `${max} ${unit}`}</p>
                    <p className="value font-weight-bold mb-0 ml-1" style={{bottom: `${percentage+3}%`}}>{value} {unit}</p>
                    <p className={'px-1 mb-0'}>{percentage > 8 && `${min} ${unit}`}</p>
              </div>
              </div>
          </div>
          <h5 className={'text-uppercase font-weight-bold text-center mt-3'}>{title}</h5>
          <h5 className={'text-uppercase font-weight-normal text-center'}>{subtitle}</h5>
      </div>
  )
};
