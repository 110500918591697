import HorizontalOverlay from "components/molecules/HorizontalOverlay";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { COMPARATOR } from "static/permissions/sections";
import AdXL from "../components/ads/AdXL";
import { Loader } from "../components/atoms";
import { FeaturesRow, ImageRow, PriceRow } from "../components/comparator";
import { SectionTitleWithSelector } from "../components/molecules";
import { AddCarModal } from "../components/organisms";
import { ApiHelper, AppHelper } from "../helpers";

export const Comparator = () => {
  const vehicleType = useSelector((state) => state?.search.vehicle);
  const [showModal, setShowModal] = useState(false);
  const [codias, setCodias] = useState(
    localStorage.comparatorCodias
      ? JSON.parse(localStorage.comparatorCodias)
      : []
  );
  const [vehicles, setVehicles] = useState([]);
  const [prices, setPrices] = useState([]);
  const [features, setFeatures] = useState();
  const user = useSelector((state) => state.user.user);
  const firstUpdate = useRef(true);
  const [hasPermission, setHasPermission] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const showOverlay = localStorage.getItem("showOverlay");
    if (!showOverlay) {
      localStorage.setItem("showOverlay", "true");
    }
  }, []);

  const showOverlay = localStorage.getItem("showOverlay");

  useLayoutEffect(() => {
    checkPermission();
  }, []);

  function checkPermission() {
    setHasPermission(AppHelper.hasPermission(user, COMPARATOR));
  }

  useEffect(() => {
    getFeatures();
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    } else {
      removeVehicles();
    }
  }, [vehicleType]);

  useEffect(() => {
    if (codias.length === 0) {
      setShowModal(true);
    } else if (codias.length > 0) {
      let promises = [];

      codias.map((c) => {
        promises.push(getVehicle(c));
      });
      Promise.all(promises).then((res) => {
        setVehicles(res);
      });
    }
  }, [codias]);

  const getVehicle = async (codia) => {
    try {
      const vehicle = await ApiHelper.getVehicle(codia);
      try {
        if (
          !user.codiasConsultados?.some((c) => c.codia === codia) &&
          vehicle.list_price
        ) {
          const newPrice = await ApiHelper.getNewPrice(codia);
          vehicle.list_price = newPrice;
        }
      } finally {
        return vehicle;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addCodia = (codia) => {
    let newCodia = { codia, vehicleType };
    localStorage.comparatorCodias = JSON.stringify([...codias, newCodia]);
    setCodias([...codias, newCodia]);
    setShowModal(false);
  };

  const removeVehicles = () => {
    setCodias([]);
    setVehicles([]);
    localStorage.comparatorCodias = [];
  };

  const removeVehicle = (codia) => {
    const filteredVehicles = vehicles.filter(
      (vehicle) => vehicle.version.versionCode != codia
    );

    setVehicles(filteredVehicles);
    localStorage.comparatorCodias = JSON.stringify(
      codias.filter((c) => c.codia != codia)
    );
    setCodias(codias.filter((c) => c.codia !== codia));
  };

  const getFeatures = async () => {
    const data = {
      vehicleType,
    };
    try {
      const res = await ApiHelper.post("preciosa", `/features`, data);
      setFeatures(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Loader>
      {hasPermission && (
        <section id="infopro_section" className="page_default_margin">
          {showOverlay === "true" && windowWidth < 770 && (
            <HorizontalOverlay
              text={"Te recomendamos utilizar el celular de forma horizontal"}
            />
          )}
          <div className="container">
            <AdXL subsection="" />

            <div className="my-4" style={{ overflowX: "scroll" }}>
              <SectionTitleWithSelector
                title={"Comparador"}
                showSelector={true}
              />
              <div className={"mt-4 comparator_table"}>
                <ImageRow
                  vehicles={vehicles}
                  removeVehicle={removeVehicle}
                  setShowModal={setShowModal}
                />

                <PriceRow
                  vehicles={vehicles}
                  prices={prices}
                  setPrices={setPrices}
                />
                <FeaturesRow
                  value={"specificationFeatures"}
                  title={"Especificaciones"}
                  vehicles={vehicles}
                  features={features?.technical}
                />
                <FeaturesRow
                  value={"comfortFeatures"}
                  title={"Confort"}
                  vehicles={vehicles}
                  features={features?.comfort}
                />
                <FeaturesRow
                  value={"motorTransmitionFeatures"}
                  title={"Motor y Transmisión"}
                  vehicles={vehicles}
                  features={features?.motor}
                />
                <FeaturesRow
                  value={"securityFeatures"}
                  title={"Seguridad"}
                  vehicles={vehicles}
                  features={features?.security}
                />
              </div>
            </div>
            <AddCarModal
              show={showModal}
              hide={() => setShowModal(false)}
              addCodia={addCodia}
              codias={codias}
            />
          </div>
        </section>
      )}
    </Loader>
  );
};
