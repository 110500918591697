import React, { useState } from "react";
import { DetailTableElement } from "../atoms";

export const DetailsTable = ({ title, data }) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <div className="col-12 col-md-6 mb-5 pr-lg-3">
      <h6 className="font-weight-bolder">{title}</h6>
      <ul className="p-0 pr-lg-5">
        {data.map((detail, index) =>
          index < 5 || showAll ? (
            <DetailTableElement key={index} detail={detail} />
          ) : null
        )}
      </ul>
      <div className="text-center" onClick={() => setShowAll(!showAll)}>
        <button
          id="see_more_button"
          type="button"
          className="btn btn-dark btn-md text-uppercase mt-4 px-5 font-weight-bolder"
        >
          VER {showAll ? "MENOS" : "MÁS"}
        </button>
      </div>
    </div>
  );
};
