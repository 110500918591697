import React, { useState, useEffect } from "react";
import { Loader } from "../components/atoms";
import { ReleaseNew, RegularNew } from "../components/organisms";
import { useParams, withRouter } from "react-router-dom";
import { ApiHelper, AppHelper } from "../helpers";
import { useDispatch } from "react-redux";
import actions from "../store/actions";

export const New = withRouter(() => {
  const [ article, setArticle ] = useState({});
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const [ categories, setCategories ] = useState([
    {
      id: 1,
      name: "Últimas Noticias",
      articles: [],
      link: "",
      selected: false,
    },
    {
      id: 2,
      name: "Salones",
      articles: [],
      link: "salones",
      selected: type === "salones",
    },
    {
      id: 3,
      name: "Lanzamientos",
      articles: [],
      link: "lanzamientos",
      selected: type === "lanzamientos",
    },
    {
      id: 4,
      name: "Notas",
      articles: [],
      link: "notas",
      selected: type === "notas",
    },
    {
      id: 5,
      name: "Historial",
      articles: [],
      link: "historial",
      selected: type === "historial",
    },
    {
      id: 6,
      name: "Prototipos",
      articles: [],
      link: "prototipos",
      selected: type === "prototipos",
    },
    {
      id: 7,
      name: "Ecología",
      articles: [],
      link: "ecologia",
      selected: type === "ecologia",
    },
  ]);

  useEffect(() => {
    getArticle();
  }, []);

  const getArticle = async () => {
    dispatch(actions.app.setLoading(true));
    try {
      const newId = id.split("_")[ 1 ];

      const { data } = await ApiHelper.get("admin", `/news/${newId}`);
      setArticle(data);

    } catch (err) {
      console.log(err);
    }
    dispatch(actions.app.setLoading(false));
  };

  const selectCategory = (link) => {
    window.location.href = `/noticias/${link}`;
  };

  return (
    <Loader>
      <div className="p-0">
        <div id="news_container" className={"container-fluid p-0"}>
          {article?.type === "COMMON_NEWS" && (
            <RegularNew article={article} />
          )}
          {article?.type === "LAUNCH_NEWS" && (
            <ReleaseNew article={article} />
          )}

        </div>
      </div>
    </Loader>
  );
});
