import React, {useState} from "react";
import {ActiveFilter, VehicleTypeSelector} from "../atoms";
import {SearchResultFilterOptions} from "../molecules";

export const SearchResultFilters = ({filters}) => {
    return(
        <div className="col-12 col-md-3 mt-2">
            <h5 className="font-weight-bold mb-3">Filtro</h5>
            <div className={'mb-4 d-flex justify-content-center'}>
                <VehicleTypeSelector/>
            </div>
            {filters.map((filter, index) => <ActiveFilter key={index} value={filter} />)}
            <SearchResultFilterOptions/>
        </div>
    )
};
