import React, { useState } from 'react';
import { useSelector } from "react-redux";

export const PriceChartOption = ({ price, small, hideDollar }) => {
    const [showDollar, setShowDollar] = useState(false);
    const dollar = useSelector(state => state.dollar);


    if (small) {
        return (
            <li className={`px-2 w-100 d-flex align-items-center justify-content-between py-2 chart_price_option ${price.year === '0km' && 'new_price_option'}`} onMouseEnter={() => setShowDollar(true)} onMouseLeave={() => setShowDollar(false)}>
                <small className="font-weight-bolder">{price.year}</small>
                <small >${new Intl.NumberFormat('es').format(price.price * 1000)}</small>
                {showDollar && !hideDollar ?
                    <div className="chart_price_dollar p-2">
                        <small className={'m-0'}>

                            USD {dollar.bna ? new Intl.NumberFormat('es').format(Math.floor((price.price * 1000) / dollar.bna)) : ' -'}<strong className={'text_blue'}> / USD {dollar.blue ? new Intl.NumberFormat('es').format(Math.floor((price.price * 1000) / dollar.blue)) : ' -'}</strong>
                        </small>
                    </div> :
                    null
                }
            </li>
        )
    } else {
        return (
            <li className={`px-2 w-100 d-flex align-items-center justify-content-between py-2 chart_price_option ${price.year === '0km' && 'new_price_option'}`} onMouseEnter={() => setShowDollar(true)} onMouseLeave={() => setShowDollar(false)}>
                <span className="font-weight-bolder">{price.year}</span>
                <span >${new Intl.NumberFormat('es').format(price.price * 1000)}</span>
                {showDollar ?
                    <div className="chart_price_dollar p-2">
                        <p className={'m-0'}>
                            USD {dollar.bna ? new Intl.NumberFormat('es').format(Math.floor((price.price * 1000) / dollar.bna)) : ' -'}<strong className={'text_blue'}> / USD {dollar.blue ? new Intl.NumberFormat('es').format(Math.floor((price.price * 1000) / dollar.blue)) : ' -'}</strong>
                        </p>
                    </div> :
                    null
                }
            </li>
        )
    }
};
