import actionTypes from "./actionTypes";

const dollarActions = {
    setDollar: dollar => {
        return {
            type: actionTypes.SET_DOLLAR,
            dollar: dollar
        }
    }
};

export default dollarActions;
