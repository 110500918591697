import React from "react";

export const PriceSelectPill = ({ selectedPeriod, setSelectedPeriod }) => {
  return (
    <div className={"w-75 mb-3"}>
      <div
        className={
          "w-100 ml-2 price_selector default_shadow d-flex align-items-center justify-content-center"
        }
      >
        <div
          onClick={() => setSelectedPeriod("monthly")}
          className={`w-50 button py-2 d-flex align-items-center justify-content-center ${
            selectedPeriod === "monthly" ? "bg_blue" : "link text-muted"
          } font-weight-bold text-uppercase`}
        >
        Mensual
        </div>
        <div
          onClick={() => setSelectedPeriod("anual")}
          className={`w-50 button py-2 d-flex align-items-center font-weight-bold text-uppercase justify-content-center ${
            selectedPeriod === "anual" ? "bg_blue" : "link text-muted"
          }`}
        >
          Anual
        </div>
      </div>
      <div className="w-100 mt-2 d-flex justify-content-end">
        <div className={"w-50"}>
          <p className="mb-0 text_blue text-center">
            <strong>10%</strong> OFF
          </p>
        </div>
      </div>
    </div>
  );
};
