import React, { useState, useEffect, Fragment, useRef } from 'react'
import { Carousel } from 'react-responsive-carousel'
import ApiHelper from '../../helpers/ApiHelper'
import { useHistory } from 'react-router-dom'

const AdSQ = ({ className, subsection }) => {
	let history = useHistory()
	const [section, setSection] = useState()
	const [images, setImages] = useState([])

	useEffect(() => {
		if (section) {
			getImages()
		}
	}, [section])

	useEffect(() => {
		if (history.location.pathname === '/') {
			setSection('home')
		} else {
			let temp = history.location.pathname.slice(1)

			if (temp.includes('/')) {
				temp = temp.substring(0, temp.indexOf('/'))
			}
			setSection(temp)
		}
	}, [])

	const getImages = async () => {
		try {
			const res = await ApiHelper.get('admin', `/advertisements/users?size=BANNER_V_S&section=${section}${subsection}`)
			setImages(shuffle(res.data))
		} catch (error) {
			console.log(error)
		}
	}

	function shuffle(array) {
		var currentIndex = array.length,
			temporaryValue,
			randomIndex

		while (0 !== currentIndex) {
			randomIndex = Math.floor(Math.random() * currentIndex)
			currentIndex -= 1

			temporaryValue = array[currentIndex]
			array[currentIndex] = array[randomIndex]
			array[randomIndex] = temporaryValue
		}
		return array
	}

	const aumentar = async (e, img) => {
		e.preventDefault()
		try {
			if (window.gtag) {
				window.gtag('event', 'click-publicidad', {
					event_category: subsection,
					event_label: img.link,
					value: img.link,
				})
			}
			await ApiHelper.patch('admin', `/advertisements/${img.id}/clicks/`, '1')
			window.open(img.link, '_blank')
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<Fragment>
			{images.length > 0 && (
				<div className={className}>
					<div className='container-fluid p-0 new_background'>
						<div className='w-100 h-100'>
							<Carousel
								infiniteLoop
								autoPlay
								showIndicators={false}
								showArrows={false}
								showThumbs={false}
								showStatus={false}
								transitionTime={400}
								interval={3000}
								dynamicHeight={false}
							>
								{images.map((img) => {
									return (
										<div key={img.image}>
											<a href={img.link} onClick={(e) => aumentar(e, img)}>
												<div>
													<img
														src={`${ApiHelper.adminRoute}/files/${img.image}`}
														className='default_shadow adSQ'
														alt='Imagen Pub'
													/>
												</div>
											</a>
										</div>
									)
								})}
							</Carousel>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	)
}

export default AdSQ
