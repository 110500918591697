import React from "react";
import img from "../../../static/img/header-particulares.jpg";

export default () => {
  return (
    <div id="nosotros_banner" className="w-100">
      <img alt={"nosotros"} src={img} className={"w-100"} />
    </div>
  );
};
