import React, { useState } from "react";
import { DollarWidget } from "../molecules";
import { VehicleTypeSelector } from "../atoms";
import { ContactModal } from "../organisms";

const SectionTitleWithSelector = ({ title, showSelector }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="row">
      <div className="col-12 col-lg-3 d-flex align-items-center">
        <h6 className="font-weight-bold mt-4 mt-lg-0">{title}</h6>
      </div>
      {showSelector && (
        <div className="col-12 col-lg-2 d-flex align-items-center mt-3 mt-lg-0">
          <VehicleTypeSelector />
        </div>
      )}
      <div className="col-12 col-lg-6 d-flex align-items-center mt-3 mt-lg-0">
        <DollarWidget />
      </div>
      <div className="col-12 col-lg-3 d-flex align-items-center mt-2 mt-lg-0">
        <small className="text-right">
          ¿No encontrás lo que buscás?{" "}
          <strong className={"link"} onClick={() => setShowModal(true)}>
            Contactanos
          </strong>
        </small>
      </div>
      <ContactModal show={showModal} hide={() => setShowModal(false)} />
    </div>
  );
};

export default SectionTitleWithSelector;
