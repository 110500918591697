import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SquareButton } from "components/atoms";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import actions from "store/actions";

const SubscriptionCheckerModal = () => {
	const show = useSelector((state) => state.app.showSubscriptionDisabled)
	const dispatch = useDispatch()
	const hide = () => {
		dispatch(actions.app.setShowSubscriptionDisabled(false))
	}

	function handleClick() {
		const now = new Date()
		localStorage.setItem('infoCreditsAlertDate', now.toISOString())
		hide()
	}

	return (
		<Modal size={'md'} className={'default_modal'} show={show}>
			<Modal.Body className={'p-0 background_gray'}>
				{/* <div className={'text-right pt-3 pr-3 link'}>
                    <FontAwesomeIcon onClick={hide} icon={faTimes} />
                </div> */}
        <div className={"m-5"}>
          <h5 className="font-weight-bold mb-3 text-center">
            Suscripción pausada por rechazo en tu método de pago.
          </h5>
          <hr></hr>
          <div className={"font-weight-normal text-center"}>
            <p className="font-weight-bold mb-3 text-center ">
              {" "}
              Te informamos que debido a un problema con tu método de pago, no hemos podido cobrar tu cuota<br></br> de suscripción mensual.
            </p>{" "}
            Por favor verifica el método de pago<br></br>
            y contactate con nosotros.
          </div>

        </div>
        <div className="mx-5">
          <SquareButton text="Continuar" onClick={handleClick} />
        </div>
        <br></br>
        <div className="text-center mt-3 mb-5 ">
          <a href="https://wa.me/541156385906/?text=Hola+InfoAuto%21+Quisiera+hacer+una+consulta" target={'_blank'} className={'text-dark'}>
            <FontAwesomeIcon
              icon={faWhatsapp}
              className={"ml-3 pointer text-dark"}
              size={"lg"}
            /> Contactanos via WhatsApp{" "}
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SubscriptionCheckerModal
