import axios from 'axios'
import InputField from 'components/atoms/InputField'
import { register } from 'numeral'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useSelector } from 'react-redux'
import { ApiHelper } from '../../helpers'
import { SquareButton } from '../atoms'
import { Select } from '../atoms/Select'
import { AlertModal } from '../organisms'

const barrios = [
  {
    name: "Chacarita",
    comuna: 15,
    perimetro: 7724.8529545700003,
    area: 3115707.1062699999,
  },
  {
    name: "Paternal",
    comuna: 15,
    perimetro: 7087.5132953299999,
    area: 2229829.03363,
  },
  {
    name: "Villa Crespo",
    comuna: 15,
    perimetro: 8131.8570753200001,
    area: 3615977.5563400001,
  },
  {
    name: "Almagro",
    comuna: 5,
    perimetro: 8537.9013684900001,
    area: 4050752.2452400001,
  },
  {
    name: "Caballito",
    comuna: 6,
    perimetro: 10990.964471499999,
    area: 6851028.9135699989,
  },
  {
    name: "Villa Santa Rita",
    comuna: 11,
    perimetro: 5757.7523347099996,
    area: 2153690.81329,
  },
  {
    name: "Monte Castro",
    comuna: 10,
    perimetro: 7361.0364175499999,
    area: 2627481.3250799999,
  },
  {
    name: "Villa Real",
    comuna: 10,
    perimetro: 5070.9656524399998,
    area: 1334146.08283,
  },
  {
    name: "Flores",
    comuna: 7,
    perimetro: 15772.439509600001,
    area: 8590784.1312899999,
  },
  {
    name: "Floresta",
    comuna: 10,
    perimetro: 7519.6540918000001,
    area: 2320640.73551,
  },
  {
    name: "Constitución",
    comuna: 1,
    perimetro: 6326.8413747300001,
    area: 2101994.9981300002,
  },
  {
    name: "San Cristobal",
    comuna: 3,
    perimetro: 5987.2264787900003,
    area: 2043711.1216599999,
  },
  {
    name: "Boedo",
    comuna: 5,
    perimetro: 6602.5102659300001,
    area: 2609850.8792099999,
  },
  {
    name: "Velez Sarsfield",
    comuna: 10,
    perimetro: 7045.5783317200003,
    area: 2400518.32602,
  },
  {
    name: "Villa Luro",
    comuna: 10,
    perimetro: 8652.9300312699997,
    area: 2566936.8725100001,
  },
  {
    name: "Parque Patricios",
    comuna: 4,
    perimetro: 7878.4349931099996,
    area: 3743439.99645,
  },
  {
    name: "Mataderos",
    comuna: 9,
    perimetro: 11124.6357921,
    area: 7398178.8403500002,
  },
  {
    name: "Villa Lugano",
    comuna: 8,
    perimetro: 15057.468389400001,
    area: 9296142.3614000008,
  },
  {
    name: "San Telmo",
    comuna: 1,
    perimetro: 5178.0328392800002,
    area: 1232270.1587400001,
  },
  {
    name: "Saavedra",
    comuna: 12,
    perimetro: 11132.139345600001,
    area: 5538676.8328200001,
  },
  {
    name: "Coghlan",
    comuna: 12,
    perimetro: 4627.5631313800004,
    area: 1279951.37256,
  },
  {
    name: "Villa Urquiza",
    comuna: 12,
    perimetro: 10021.459664800001,
    area: 5449058.9228499997,
  },
  {
    name: "Colegiales",
    comuna: 13,
    perimetro: 6374.6190450699996,
    area: 2289774.1042800001,
  },
  {
    name: "Balvanera",
    comuna: 3,
    perimetro: 8375.8218108100009,
    area: 4342280.2680400005,
  },
  {
    name: "Villa Gral. Mitre",
    comuna: 11,
    perimetro: 6290.4405605600004,
    area: 2162275.1153500001,
  },
  {
    name: "Parque Chas",
    comuna: 15,
    perimetro: 4531.2533962799998,
    area: 1385411.5137400001,
  },
  {
    name: "Agronomía",
    comuna: 15,
    perimetro: 6556.1677718700003,
    area: 2122169.3421200002,
  },
  {
    name: "Villa Ortuzar",
    comuna: 15,
    perimetro: 5684.9952111399998,
    area: 1853802.0520299999,
  },
  {
    name: "Barracas",
    comuna: 4,
    perimetro: 12789.7917712,
    area: 7953453.4314299999,
  },
  {
    name: "Parque Avellaneda",
    comuna: 9,
    perimetro: 10165.614027699999,
    area: 4732708.2093099998,
  },
  {
    name: "Parque Chacabuco",
    comuna: 7,
    perimetro: 8406.9143207500001,
    area: 3832116.6992199998,
  },
  {
    name: "Nueva Pompeya",
    comuna: 4,
    perimetro: 11033.5103019,
    area: 4951510.8742699996,
  },
  {
    name: "Palermo",
    comuna: 14,
    perimetro: 22126.531858400002,
    area: 15845871.288000001,
  },
  {
    name: "Villa Riachuelo",
    comuna: 8,
    perimetro: 10596.8267392,
    area: 4232595.9247899996,
  },
  {
    name: "Villa Soldatti",
    comuna: 8,
    perimetro: 12441.695877300001,
    area: 8688163.5582800005,
  },
  {
    name: "Villa Pueyrredon",
    comuna: 12,
    perimetro: 7522.3603824299998,
    area: 3303239.50936,
  },
  {
    name: "Villa Devoto",
    comuna: 11,
    perimetro: 11562.0068,
    area: 6404480.8537100013,
  },
  {
    name: "Liniers",
    comuna: 9,
    perimetro: 9256.9273212900007,
    area: 4374418.7367700003,
  },
  {
    name: "Versalles",
    comuna: 10,
    perimetro: 5650.4662864299999,
    area: 1406833.5958,
  },
  {
    name: "Puerto Madero",
    comuna: 1,
    perimetro: 17725.720821200001,
    area: 5040970.8851399999,
  },
  {
    name: "Monserrat",
    comuna: 1,
    perimetro: 6739.2223110200002,
    area: 2198621.9408900002,
  },
  {
    name: "San Nicolas",
    comuna: 1,
    perimetro: 6548.0847539599999,
    area: 2289007.5825899998,
  },
  {
    name: "Belgrano",
    comuna: 13,
    perimetro: 20609.7753969,
    area: 7999239.81384,
  },
  {
    name: "Recoleta",
    comuna: 2,
    perimetro: 21452.838647799999,
    area: 6317264.5605600001,
  },
  {
    name: "Retiro",
    comuna: 1,
    perimetro: 18837.988687000001,
    area: 4512710.9070300004,
  },
  {
    name: "Nuñez",
    comuna: 13,
    perimetro: 10785.2610814,
    area: 4424199.2650899999,
  },
  {
    name: "Boca",
    comuna: 4,
    perimetro: 20060.003939400001,
    area: 5036240.4544099998,
  },
];

export const ProfileBilling = () => {
  const user = useSelector((state) => state.user.user);

  const [provinces, setProvinces] = useState([]);
  const [localities, setLocalities] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [defaultProfile, setDefaultProfile] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const {
    handleSubmit,
    register,
    setValue,
    control,
    getValues,
    resetField,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      taxType: "",
      taxStatus: "",
      taxId: "",
      province: "",
      locality: "",
      address: "",
      zipCode: "",
      enterpriseName: "",
      email: "",
      fullName: "",
    },
  });

  const province = watch("province");
  const taxType = watch("taxType");
  const taxStatus = watch("taxStatus");
  const taxId = watch("taxId");
  const locality = watch("locality");
  const address = watch("address");
  const zipCode = watch("zipCode");
  const enterpriseName = watch("enterpriseName");
  const email = watch("email");
  const fullName = watch("fullName");
  const taxTypeRegex =
    taxType?.value === "DNI"
      ? /^\d{8}(?:[-\s]\d{4})?$/
      : taxType?.value === "PASAPORTE"
        ? /^[a-zA-Z]{3}[0-9]{6}$/
        : /^(20|23|24|27|30|33|34)(\-)?[0-9]{8}(\-)?[0-9]$/g;

  useEffect(() => {
    getProvinces();
    setFirstName(user.firstName);
    setLastName(user.lastName);
    getBillingInfo();
  }, []);

  const getBillingInfo = async () => {
    try {
      let { data } = await ApiHelper.get("admin", "/user/billingInformation");
      setDefaultProfile(data);

      setValue(
        "taxType",
        taxTypes.find((type) => type.value === data.taxType)
      );
      setValue(
        "taxStatus",
        taxStatusList.find((status) => status.value === data.taxStatus)
      );
      setValue("taxId", data.taxId);
      setValue("enterpriseName", data.enterpriseName);
      setValue("email", data.email);
      setValue("zipCode", data.enterpriseZipCode);
      setValue("address", data.enterpriseAddress);

      // setValue('province', provinces.find((p) => p.value === data.enterpriseProvince));
      // setValue('locality', localities.find((l) => l.label === data.enterpriseLocality));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (provinces.length && defaultProfile) {
      const selectedProvince = provinces.find(
        (p) => p.name === defaultProfile.enterpriseProvince
      );
      setValue("province", selectedProvince);
      //
    }
  }, [defaultProfile, provinces]);

  const getProvinces = async () => {
    try {
      let res = await ApiHelper.getProvinces();
      res.data.map((provincia) => {
        provincia.value = provincia.id;
        provincia.label = provincia.name;
      });
      res.data.sort(function (a, b) {
        let textA = a.label.toUpperCase();
        let textB = b.label.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setProvinces(res.data);
      // setValue('province', res.data.find((p) => p.value === data.enterpriseProvince));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getLocalities = async () => {
      let localities = [];

      if (province?.label.includes("Ciudad")) {
        localities = barrios;
      } else {
        let { data } = await ApiHelper.getLocalities(province.label);
        localities = data;
      }

      localities.map((localidad) => {
        localidad.label = localidad.name;
        localidad.value = localidad.name;
      });

      localities.sort(function (a, b) {
        let textA = a.label.toUpperCase();
        let textB = b.label.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      if (province.name === defaultProfile.enterpriseProvince) {
        setValue(
          "locality",
          localities.find((l) => l.name === defaultProfile.enterpriseLocality)
        );
      }
      setLocalities(localities);
    };
    if (province) {
      getLocalities();
    }
  }, [province]);

  let taxTypes = [
    { label: "CUIT", value: "CUIT" },
    { label: "CUIL", value: "CUIL" },
    { label: "DNI", value: "DNI" },
    { label: "PASAPORTE", value: "PASAPORTE" },
  ];

  const taxStatusList = [
    { label: "IVA Responsable Inscripto", value: "IVA Responsable Inscripto" },
    { label: "IVA no Responsable", value: "IVA no Responsable" },
    { label: "IVA Sujeto Exento", value: "IVA Sujeto Exento" },
    { label: "Consumidor Final", value: "Consumidor Final" },
    { label: "Responsable Monotributo", value: "Responsable Monotributo" },
    { label: "Proveedor del Exterior", value: "Proveedor del Exterior" },
    { label: "Cliente del Exterior", value: "Cliente del Exterior" },
    {
      label: "IVA Liberado – Ley Nº 19.640",
      value: "IVA Liberado – Ley Nº 19.640",
    },
    {
      label: "CUIVA Responsable Inscripto – Agente de PercepciónIT",
      value: "IVA Responsable Inscripto – Agente de Percepción",
    },
    {
      label: "Pequeño Contribuyente Eventual",
      value: "Pequeño Contribuyente Eventual",
    },
    { label: "Monotributista Social", value: "Monotributista Social" },
    {
      label: "Pequeño Contribuyente Eventual Social",
      value: "Pequeño Contribuyente Eventual Social",
    },
  ];

  if (taxStatus?.value === "Consumidor Final") {
    taxTypes = [taxTypes.find((type) => type.value === "DNI")];
  } else if (
    taxStatus?.value === "IVA no Responsable" ||
    taxStatus?.value === "IVA Sujeto Exento" ||
    taxStatus?.value === "Responsable Monotributo" ||
    taxStatus?.value === "IVA Responsable Inscripto"
  ) {
    taxTypes = [taxTypes.find((type) => type.value === "CUIT")];
  }

  const onSubmit = async () => {
    await saveBillingInfo();
  };

  const saveBillingInfo = async (userData) => {
    const {
      taxType,
      taxStatus,
      taxId,
      province,
      locality,
      address,
      zipCode,
      enterpriseName,
      email,
      fullName,
    } = getValues();

    const data = {
      enterpriseName: enterpriseName || fullName,
      email,
      taxStatus: taxStatus.value,
      taxType: taxType.label,
      taxId,
      enterpriseAddress: address,
      enterpriseProvince: province.name,
      enterpriseLocality: locality.label,
      enterpriseZipCode: zipCode,
    };

    await ApiHelper.post("admin", "/user/billingInformation", data);
    window.location.href = `${window.location.origin}${window.location.pathname}`;
  };

  return (
    <>
      <div className="background_gray">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={"container py-5"}>
            <div className="row">
              <div className="col-12 mb-2">
                <h5 className={"mb-4 font-weight-bold"}>
                  Información de facturación
                </h5>
              </div>
              <>
                <div className="col-12 col-md-4">
                  <Controller
                    control={control}
                    name="taxStatus"
                    render={({ field, fieldState }) => {
                      return (
                        <InputField
                          label="Condición IVA"
                          error={fieldState?.error}
                        >
                          <Select
                            placeholder={"Seleccionar"}
                            options={taxStatusList}
                            selectedOption={field.value}
                            onSelect={(value) => {
                              field.onChange(value);
                              setValue("taxType", "");
                            }}
                          />
                        </InputField>
                      );
                    }}
                    rules={{ required: "Ingrese una opción" }}
                  />
                </div>

                <div className="col-12 col-md-4 ">
                  <Controller
                    control={control}
                    name="taxType"
                    disabled={true}
                    render={({ field, fieldState }) => (
                      <InputField label="Tipo Doc." error={fieldState?.error}>
                        <Select
                          placeholder={"Seleccionar"}
                          options={taxTypes}
                          onSelect={(value) => field.onChange(value)}
                          selectedOption={field.value}
                        />
                      </InputField>
                    )}
                    rules={{ required: "Ingrese una opción" }}
                  />
                </div>
                <div className="col-12 col-md-4 ">
                  <InputField label="Nro. Doc." error={errors.taxId}>
                    <input
                      placeholder={"Número"}
                      type={"text"}
                      name={"taxId"}
                      className={"custom_input default-shadow p-2 w-100 "}
                      {...register("taxId", {
                        required: "Ingrese número",
                        pattern: {
                          value: taxTypeRegex,
                          message: `Ingrese un ${taxType?.value || "numero"
                            } válido`,
                        },
                      })}
                    />
                  </InputField>
                </div>
              </>

              <>
                {taxStatus?.value !== "Consumidor Final" &&
                  taxStatus?.value !== "Responsable Monotributo" ? (
                  <div className="col-12 col-md-6 mt-3">
                    <InputField
                      label="Razón Social"
                      error={errors.enterpriseName}
                    >
                      <input
                        name={"razonSocial"}
                        className={"custom_input default-shadow p-2 w-100 my-3"}
                        placeholder={"Razón Social"}
                        type={"text"}
                        {...register("enterpriseName", {
                          required: "Ingrese razón social",
                          pattern: {
                            value: /^[a-zA-Z\s]*$/,
                            message: "Ingrese únicamente letras y espacios",
                          },
                        })}
                      />
                    </InputField>
                  </div>
                ) : (
                  <div className="col-12 col-md-6 mt-3">
                    <InputField
                      label="Nombre y Apellido"
                      error={errors.fullName}
                    >
                      <input
                        name={"fullName"}
                        className={"custom_input default-shadow p-2 w-100 my-3"}
                        placeholder={"Nombre y Apellido"}
                        type={"text"}
                        {...register("fullName", {
                          required: "Ingrese nombre y apellido",
                        })}
                      />
                    </InputField>
                  </div>
                )}
                <div className="col-12 col-md-6 mt-3">
                  <InputField label="Email" error={errors.email}>
                    <input
                      name={"email"}
                      className={"custom_input default-shadow p-2 w-100 my-3"}
                      placeholder={"Email"}
                      type={"email"}
                      {...register("email", {
                        required: "Ingrese email",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Ingrese un email válido",
                        },
                      })}
                    />
                  </InputField>
                </div>
              </>
            </div>

            {user.activeSubscription.subscriptionType.type != "fleet" && (
              <div className="row">
                <div className="col-12 mt-4">
                  <h5 className="mb-4 font-weight-bold">
                    Dirección de Facturación
                  </h5>
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <InputField label="Código postal" error={errors.zipCode}>
                    <input
                      name={"zipcode"}
                      className={"custom_input default-shadow p-2 w-100 "}
                      placeholder={"Código Postal"}
                      type={"text"}
                      {...register("zipCode", {
                        required: "Ingrese código postal",
                      })}
                    />
                  </InputField>
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <InputField label="Dirección" error={errors.address}>
                    <input
                      name={"address"}
                      className={"custom_input default-shadow p-2 w-100 "}
                      placeholder={"Dirección"}
                      type={"text"}
                      {...register("address", {
                        required: "Ingrese Dirección",
                        pattern: {
                          value: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
                          message:
                            "La dirección sólo puede estar formada por letras y números",
                        },
                      })}
                    />
                  </InputField>
                </div>

                <div className="col-12 col-md-6  mb-3">
                  <Controller
                    control={control}
                    name="province"
                    render={({ field, fieldState }) => {
                      return (
                        <InputField label="Provincia" error={fieldState?.error}>
                          <Select
                            className="select-modal"
                            onSelect={(e) => field.onChange(e)}
                            selectedOption={field.value}
                            placeholder={"Provincia"}
                            options={provinces}
                          />
                        </InputField>
                      );
                    }}
                    rules={{ required: "Ingrese una opción" }}
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <Controller
                    control={control}
                    name="locality"
                    render={({ field, fieldState }) => {
                      return (
                        <InputField label="Localidad" error={fieldState?.error}>
                          <Select
                            className="select-modal "
                            onSelect={(e) => field.onChange(e)}
                            selectedOption={field.value}
                            placeholder={"Localidad"}
                            options={localities}
                          />
                        </InputField>
                      );
                    }}
                    rules={{ required: "Ingrese una opción" }}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center mt-3">
                  <SquareButton
                    text="Guardar"
                    fullWidth={false}
                    className="px-5"
                  />
                </div>
                <AlertModal
                  message={"¿Seguro desea guardar los cambios?"}
                  onClick={saveBillingInfo}
                  show={showAlert}
                  hide={() => setShowAlert(false)}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
