import React, { useState, useEffect } from "react";
import CountDown from "./CountDown";
import AdXL from "../../ads/AdXL";
import { ApiHelper } from "../../../helpers";
import { StockBadge } from "../../atoms/StockBadge";

const Magazine = () => {
  const [magazine, setMagazine] = useState();
  const [month, setMonth] = useState("");

  

  useEffect(() => {
    getMagazine();
  }, []);

  const getMagazine = async () => {
    const res = await ApiHelper.get("admin", "/magazine?latest=true");
    const date = new Date(2020, res.data.reverse()[0]?.month - 1);
    const month = date.toLocaleString("es", { month: "long" });

    setMonth(month);
    setMagazine(res.data[0]);
  };

  return (
    <section className="container-lg mb-5" id="magazine_section">
      <div className="row py-5 px-md-3 no-gutters">
        <div className="col-12 col-lg-6">
          <p className="text-muted text-uppercase">
            Número {magazine?.number} | Edición {month} - Año {magazine?.year}
          </p>
          <h1 className="font-weight-bolder mb-4">La Revista</h1>
          <p>{magazine?.description}</p>
          <CountDown />
          <div className="my-5">
            <AdXL subsection="-revista-1" />
          </div>
        </div>
        <div className="col-12 col-lg-6 mt-5 mt-lg-0 pl-5 pb-5 revista-mobile">
          <div className="px-3 pb-3">
            {magazine && (
              <img
                className="p-5"
                src={`${ApiHelper.adminRoute}/files/${magazine?.image}`}
                className="w-100 bw_image"
                alt="Imágen Perfil"
              />
            )}
          </div>
        </div>
        {magazine?.soldOut && (
          <div>
            <StockBadge />
          </div>
        )}
      </div>
      <AdXL subsection="-revista-2" />
    </section>
  );
};

export default Magazine;
