import React, { useEffect, useRef, useState } from "react";
import { InfoProTableBar, AppInfoGridPriceRow } from "../atoms";
import { faThumbtack, faBan } from "@fortawesome/free-solid-svg-icons";
import { ApiHelper } from "../../helpers";
import ReactTooltip from "react-tooltip";
import ScrollHint from 'scroll-hint';



export default ({ versions, quantity, user = {}, vehicleType = 'cars', prices, pinned = [], loadMoreVersions }) => {
  const [ feature, setFeature ] = useState("prices")
  const [ years, setYears ] = useState([])
  const [ features, setFeatures ] = useState([])
  const [ viewedModels, setViewedModels ] = useState([])
  const listInnerRef = useRef()



  useEffect(() => {
    const now = new Date().getFullYear();
    let years = [ "0km" ];
    for (let i = now; i >= now - 30; i--) {
      years.push(i);
    }
    getFeatures();
    setYears(years);
  }, []);


  useEffect(() => {
    new ScrollHint('.js-scrollable', {
      suggestiveShadow: true,
      i18n: {
        scrollable: 'Desliza para mas información'
      },
    })
  }, [ versions ])


  const handleView = (model) => {
    const { viewedModels } = this.state;
    const viewedModel = viewedModels.find((m) => m.codia === model.codia);
    if (!viewedModel) {
      setViewedModels([ ...viewedModels, model ]);
    }
  };

  const getFeatures = async () => {
    const data = {
      vehicleType,
    };
    const res = await ApiHelper.post("preciosa", `/features`, data);
    setFeatures(res.data);
  };

  const onScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.ceil(scrollTop) + clientHeight >= scrollHeight) {
        loadMoreVersions()
      }
    }
  };


  return (
    <div id="infopro_section" className={"row"}>
      <div className="col-12 p-0">
        <InfoProTableBar feature={feature} setFeature={setFeature} />
        <div className="infogrid_container">
          <table
            id={"infopro_table"}
            className={`${versions.length > 0 ? 'js-scrollable' : ''} mt-4 w-100 inner_shadow testCol`}
            ref={listInnerRef}
            onScroll={onScroll}
          >
            <thead>
              <th
                className={`table-light`}
              ></th>
              <th
                className={`table-light`}
              ></th>
              {feature === 'prices' && <th
                className={`table-light`}
              ></th>}

              {user?.muestraCodia && <th className="" />}

              {feature === "prices" &&
                years.map((year) => (
                  <th
                    className={`text-center table-light`}>
                    {year}
                  </th>
                ))}
              {feature !== "prices" &&
                features[ feature ].map((f) => (
                  <th className={"text-center"}>
                    {" "}
                    <ReactTooltip effect={"solid"} />{" "}
                    <img
                      data-tip={f.description}
                      className={"mb-1"}
                      src={`/assets/icons/${f.description}.svg`}
                      style={{ width: 30 }}
                    />
                  </th>
                ))}
            </thead>
            <tbody >
              {pinned.map((model, index) => (
                <AppInfoGridPriceRow
                  last={index === pinned.length - 1}
                  feature={feature}
                  index={index}
                  features={features}
                  viewed={!!viewedModels.find((m) => m.codia === model.codia)}
                  icon={faBan}
                  key={model.codia}
                  model={model}
                  years={years}
                  user={user}
                  prices={prices.find(p => p.codia === model.codia)?.prices}
                />
              ))}
              {versions.map((model, index) => {

                if (!pinned.find((m) => m.codia === model.codia) && (index < quantity || quantity === 'Todo')) {
                  return (
                    <AppInfoGridPriceRow
                      features={features}
                      feature={feature}
                      viewed={!!viewedModels.find((m) => m.codia === model.versionCode)}
                      prices={prices.find(p => p.codia === model.codia)?.prices}
                      handleView={handleView}
                      icon={faThumbtack}
                      key={model.versionCode}
                      model={model}
                      years={years}
                      user={user}
                    />
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
