import React, { useEffect } from 'react'
import { default as ReactLoader } from 'react-loader-spinner';
import { useSelector } from "react-redux";

export const Loader = ({ children }) => {
    const loading = useSelector(state => state.app.loading);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div id={'app_container'}>
            {loading && <div id="loader_container" className={'d-flex align-items-center justify-content-center'}>
                <ReactLoader
                    type="ThreeDots"
                    color="#10ffdc"
                    height={50}
                    width={50}
                />
            </div>}
            {children}
        </div>

    )
}
