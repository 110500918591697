import React, { useState } from "react";

import {
	IoBarChart,
	IoBarChartOutline,
	IoCartOutline,
	IoChatboxEllipsesOutline,
	IoLayers,
	IoLayersOutline,
} from "react-icons/io5";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { SquareButton } from "components/atoms";
export default function UserType({ newUser, setUser, nextStep }) {
	const user = useSelector((state) => state.user.user);
	const [interests, setInterests] = useState([]);
	const [otherInterest, setOtherInterest] = useState("");

	const isFleetUser =
		user.activeSubscription?.subscriptionType?.type === "fleet";
	const isIndividual = newUser.userType === "individual";

	const interestOptions = {
		individual: [
			{ value: "Comprar", Icon: IoCartOutline },
			{ value: "Vender", Icon: IoChatboxEllipsesOutline },
			{ value: "Comparar", Icon: IoBarChartOutline },
			{ value: "Informarse", Icon: IoLayersOutline },
		],
		professional: [
			{ value: "Productor" },
			{ value: "Concesionaria" },
			{ value: "Perito" },
			{ value: "Compra / Venta" },
			{ value: "Tasador" },
			{ value: "Inmobiliaria" },
			{ value: "Empresa de flota de vehiculos" },
			{ value: "Otros" },
		],
	};

	function handleContinue() {
		const newInterests = [
			...interests.map((interest) =>
				interest === "Others" ? otherInterest : interest
			),
		];
		setUser((prev) => ({ ...prev, interests: newInterests }));
		console.log([
			...interests.map((interest) =>
				interest === "Otros" ? otherInterest : interest
			),
		]);
		nextStep();
	}

	function handleCheck(interest) {
		if (interests.includes(interest)) {
			setInterests((prev) => prev.filter((i) => i !== interest));
		} else {
			setInterests((prev) => [...prev, interest]);
		}
	}

	return (
		<div className='col-12 d-flex flex-column align-items-center'>
			<div className='container text-center bg-white p-5'>
				<h5 className='font-weight-bold mb-4'>
					{isIndividual
						? "¿Qúe estás buscando?"
						: "Registración de Usuario Profesional"}
				</h5>
				{isIndividual ? (
					<p>
						2. Contanos cuáles son tus{" "}
						<span className={"font-weight-bold"}>áreas de interés</span>
					</p>
				) : (
					<p>
						2. Seleccioná tu{" "}
						<span className={"font-weight-bold"}>rubro / actividad</span>
					</p>
				)}
				<div className='d-flex flex-wrap justify-content-center mt-5'>
					{interestOptions[isFleetUser ? "professional" : newUser.userType].map(
						({ Icon, value }, index) => (
							<Form.Group
								key={index}
								className={"mr-4"}
								controlId={`${value}_check`}
							>
								{Icon && <Icon size='30px' />}
								{value === "Otros" ? (
									<div className='d-flex'>
										<Form.Check
											className='mt-2 mr-2 custom-checkbox'
											type='checkbox'
											checked={interests.includes(value)}
											label={value}
											onChange={() => handleCheck(value)}
										/>
										{interests.includes(value) && (
											<Form.Control
												type='text'
												className='h-25'
												placeholder='Escribí tu rubro'
												value={otherInterest}
												onChange={({ target }) =>
													setOtherInterest(target.value)
												}
											/>
										)}
									</div>
								) : (
									<Form.Check
										className='mt-2 custom-checkbox'
										type='checkbox'
										checked={interests.includes(value)}
										label={value}
										onChange={() => handleCheck(value)}
									/>
								)}
							</Form.Group>
						)
					)}
				</div>

				<div className={"container px-md-5 pt-2 pb-4"}>
					{isIndividual ? (
						<p>
							Seleccióna al menos un{" "}
							<strong>área de interés para poder continuar el registro.</strong>
						</p>
					) : (
						<p>
							Seleccióna al menos{" "}
							<strong>un rubro para continuar con el registro.</strong>
						</p>
					)}

					<div className='d-flex mt-4 justify-content-center'>
						<SquareButton
							fullWidth={false}
							disabled={interests.length === 0}
							onClick={handleContinue}
							text={"Continuar"}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
