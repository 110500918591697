import React, { useEffect, useState } from 'react'
import { ApiHelper } from '../../../helpers'
import { AdSQ, AdXL } from '../../ads'
import { PrimaryNew, SecondaryNew } from '../../atoms'
import { CarouselNew } from '../../molecules'

const News = () => {
	const [ categories, setCategories ] = useState([])
	const [ category, setCategory ] = useState(null)
	const [ news, setNews ] = useState([])
	const [ primaryNew, setPrimaryNew ] = useState(null)
	const [ readyToShow, setReadyToShow ] = useState(true)

	const selectCategory = (id) => {
		const modifiedCategories = categories.map((category) => {
			return {
				...category,
				selected: category.id === id,
			}
		})
		setCategories(modifiedCategories)
	}

	useEffect(() => {
		getNews()
	}, [ category ])

	useEffect(() => {

		getCategories()
	}, [])

	const getNews = async () => {
		try {
			if (category) {
				const res = await ApiHelper.get('admin', `/visibleNews/category/${category.id}`)

				let primaryNew = null
				const news = res.data.pageElements.filter((n) => n.hierarchy === 'Alta')
				res.data.pageElements.map((n) => {
					if (n.hierarchy === 'Alta') {
						primaryNew = n
					}
				})
				setPrimaryNew(primaryNew)
				setNews(news)
				setReadyToShow(true)
	

			} else {
				const res = await ApiHelper.get('admin', '/visibleNews')
				let primaryNew = null
				const news = res.data.pageElements.filter((n) => n.hierarchy === 'Alta')
				res.data.pageElements.map((n) => {
					if (n.hierarchy === 'Alta') {
						primaryNew = n
					}
				})
				setPrimaryNew(primaryNew)
				setNews(news)
				setReadyToShow(true)
			}
		} catch (error) {
			console.log(error)
		}
	}

	const getCategories = async () => {
		const res = await ApiHelper.get('admin', '/category')
		setCategories(res.data)
	}

	return (
		<section id='news_section' className='container-fluid background_gray py-5'>
			<div className={'container'}>
				<div className='row mb-3'>
					<div className='pl-sm-2 col-12 col-md-3 d-flex justify-content-between'>
						<h2 className='font-weight-bolder'>Noticias</h2>
						<div></div>
					</div>
					<div className='col-12 col-md-9 d-flex align-items-center'>
						<ul className='d-flex flex-wrap newsmobile'>
							{categories.length > 0 && (
								<li
									onClick={() => setCategory(null)}
									className={`${!category && 'bg-dark text-white'
										} px-3 py-1 text-uppercase font-weight-bold d-flex align-items-center link rounded-0`}
								>
									Últimas Noticias
								</li>
							)}
							{categories.map((c, index) => {
								return (
									<li
										onClick={() => setCategory(c)}
										className={`${c === category && 'bg-dark text-white'
											} border-left px-3 py-1 text-uppercase font-weight-bold d-flex align-items-center link rounded-0`}
										key={c.id}
									>
										{c.name}
									</li>
								)
							})}
						</ul>
					</div>
				</div>
				<div id='news_container' className='mb-5'>
					<div className='row'>
						{primaryNew && <PrimaryNew article={primaryNew} />}
						<AdSQ subsection='-noticias-cuadrado' className='col-12 col-md-4 px-1' />
						{news.map((n, index) => index < 2 && <SecondaryNew key={n.id} article={n} />)}
						<CarouselNew
							news={news.filter((n, i) => {
								if (i <= 7 && i >= 2) {
									return n
								}
							})}
						/>
						{/* {news.length >= 7 && <SecondaryNew article={news[ 6 ]} />} */}
					</div>
				</div>
				<AdXL subsection='-noticias-banner' />
			</div>
		</section>
	)
}

export default News
