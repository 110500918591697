import React from "react";

export default function InputField({
  error = null,
  label = null,
  children,
  ...rest
}) {
  return (
    <div {...rest}>
      {label && <label className={"font-weight-bold"}>{label}</label>}
      {children}
      {error && (
        <div className="alert alert-danger" role="alert">
          {error.message}
        </div>
      )}
    </div>
  );
}
