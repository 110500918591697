import { faFacebookF, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import {
	EmailShareButton,
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
} from 'react-share'

export const NewsSocialBar = ({ borderTop, tags, title, date, source }) => {
	const url = window.location.href
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)

	useEffect(() => {
		window.addEventListener('resize', () => {
			setWindowWidth(window.innerWidth)
		})
	}, [])


	const formattedDate = new Date(date)
	const day = formattedDate.getDate()
	const month = formattedDate.getMonth()
	const year = formattedDate.getFullYear()

	const months = [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
	]

	return (
		<div>
			{windowWidth > 975 && (
				<div id={'news_social_bar'}>
					<div
						className={`col-12 d-lg-flex justify-content-between align-items-center pb-3 ${borderTop ? 'border_top pt-3' : ''
							}`}
					>
						<div>
							<p className='mb-0'>
								{date && `${day} de ${months[month]} ${year}`} | {source}
							</p>
						</div>

						<div className='share'>
							<FontAwesomeIcon icon={faShareAlt} size={'lg'} />
							<span className={'font-weight-bold ml-1 mr-5 compartir'}>Compartir</span>
							<FacebookShareButton url={url} quote={title} hashtag='#InfoAuto'>
								<FontAwesomeIcon className={'mr-3'} icon={faFacebookF} size={'lg'} />
							</FacebookShareButton>
							<TwitterShareButton url={url} title={title}>
								<FontAwesomeIcon className={'mr-3'} icon={faTwitter} size={'lg'} />
							</TwitterShareButton>
							<WhatsappShareButton url={url} windowWidth={800} windowHeight={600} title={title}>
								<FontAwesomeIcon className={'mr-3'} icon={faWhatsapp} size={'lg'} />
							</WhatsappShareButton>

							<EmailShareButton url={url} subject={'Title'} body={'Mirá este artículo en InfoAuto'}>
								<FontAwesomeIcon className={'mr-3'} icon={faEnvelope} size={'lg'} />
							</EmailShareButton>
						</div>
					</div>
					<div>
						<div id='tags_container' className={'ml-3 mb-2 '} style={{ overflowX: 'auto' }}>
							{tags.map((tag, index) => (
								<span key={index} className={'mr-3 new_tag text-uppercase'}>
									#{tag}
								</span>
							))}
						</div>
					</div>
				</div>
			)}

			{windowWidth < 975 && (
				<div className='mb-4'>
					<div className='d-flex justify-content-between'>
						<div className={'d-lg-flex align-items-center likes'}>
							{/* <FontAwesomeIcon icon={faThumbsUp} color={'#777'} size={'lg'} />
							<span className={'font-weight-bold ml-1 mr-2'}>12</span>
							<FontAwesomeIcon icon={faThumbsDown} color={'#777'} size={'lg'} />
							<span className={'font-weight-bold ml-1 mr-2'}>3</span> */}
						</div>

						<div className='share'>
							<FontAwesomeIcon icon={faShareAlt} size={'lg'} />
							<span className={'font-weight-bold ml-1 mr-5 compartir'}>Compartir</span>
							<FacebookShareButton href={url} url={url} quote={title} hashtag='#InfoAuto'>
								<FontAwesomeIcon className={'mr-3'} icon={faFacebookF} size={'lg'} />
							</FacebookShareButton>
							<TwitterShareButton url={url} title={title}>
								<FontAwesomeIcon className={'mr-3'} icon={faTwitter} size={'lg'} />
							</TwitterShareButton>
							<WhatsappShareButton url={url} windowWidth={800} windowHeight={600} title={title}>
								<FontAwesomeIcon className={'mr-3'} icon={faWhatsapp} size={'lg'} />
							</WhatsappShareButton>

							<EmailShareButton url={url} subject={'Title'} body={'Mirá este artículo en InfoAuto'}>
								<FontAwesomeIcon className={'mr-3'} icon={faEnvelope} size={'lg'} />
							</EmailShareButton>
						</div>
					</div>

					<div className='mt-3' style={{ overflow: 'hidden' }}>
						<div id='tags_container' className={'ml-3 mb-0 pb-2'} style={{ overflowX: 'scroll' }}>
							{tags.map((tag, index) => (
								<span key={index} className={'mr-3 new_tag text-uppercase'}>
									#{tag}
								</span>
							))}
						</div>
					</div>
					<div>
						<p className='mb-0 mt-2'>
							{date && `${day} de ${months[month]} ${year}`} | {source}
						</p>
					</div>
				</div>
			)}
		</div>
	)
}
