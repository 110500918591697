import React, { useState } from 'react'
import useConstant from 'use-constant'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { useAsyncAbortable } from 'react-async-hook'
import { ApiHelper } from '../helpers'
import { useSelector } from 'react-redux'
import { setLogLevel } from 'firebase'

const getVehicles = async (value, vehicleType) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (value.length >= 3 && value.length < 30) {
				const data = {
					features: {},
					page: 1,
					pageSize: 10,
					vehicleType,
					filterString: value,
				}
				window.grecaptcha.ready(function () {
					window.grecaptcha
						.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
							action: 'submit',
						})
						.then(async function (token) {
							localStorage.reCaptcha = token
							const res = await ApiHelper.post('preciosa', '/search', data)

							resolve(res.data)
						})
				})
			}
		} catch (e) {
			reject(e)
		}
	})
}

export const DebounceSearch = () => {
	const vehicleType = useSelector((state) => state.search.vehicle)
	const [inputText, setInputText] = useState('')
	const debouncedSearch = useConstant(() => AwesomeDebouncePromise(getVehicles, 300))

	const search = useAsyncAbortable(
		async (abortSignal, text) => {
			if (text.length === 0) {
				return []
			} else {
				return debouncedSearch(text, vehicleType, abortSignal)
			}
		},
		[inputText]
	)

	const close = () => {
		setInputText('')
	}

	return {
		inputText,
		setInputText,
		search,
		close,
	}
}
