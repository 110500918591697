import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppHelper } from 'helpers'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { NavSubMenu } from '../atoms'

export const NavItem = ({ item, mobile }) => {
	const [showMenu, setShowMenu] = useState(false)
	const [showMobileMenu, setShowMobileMenu] = useState(false)
	const user = useSelector((state) => state.user.user)

	const handleClick = () => {
		if (mobile) {
			setShowMobileMenu(!showMobileMenu)
		}
	}

	return (
		<li
			key={`${item.id}_sub_menu`}
			className='spacing_1 text-uppercase font-weight-bold text_small'
			onMouseEnter={() => setShowMenu(true)}
			onMouseLeave={() => setShowMenu(false)}
		>
			<a onClick={handleClick} className='nav-link text-uppercase text_small'>
				{item.name} <FontAwesomeIcon icon={faChevronDown} /> 
			</a>
			{showMenu && !mobile ? <NavSubMenu items={item.child} /> : null}
			{showMobileMenu && (
				<ul>
					{item.child.map((item, index) => {
						if (AppHelper.hasPermission(user, item.permission)) {
							return (
								<li
									key={`${item.id}_sub_menu_children${index}`}
									className={`p-2 pb-1 font-weight-bold ${!item.pro ? 'hide' : ''}`}
								>
									<a className={'text-white'} href={item.route}>
										{item.name}
									</a>
								</li>
							)
						}
					})}
				</ul>
			)}
		</li>
	)
}
