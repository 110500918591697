import React, { useEffect } from "react";
import { default as ReactLoader } from "react-loader-spinner";

const Logout = () => {
  useEffect(() => {
    localStorage.clear();
    window.location.href = window.location.origin;
  });
  return (
    <div className="logout_screen">
      <ReactLoader type="Puff" color="#10ffdc" height={50} width={50} />
    </div>
  );
};

export default Logout;
