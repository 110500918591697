import React from 'react'

export class SmallSecondaryNew extends React.Component {
  render() {
    return (
      <div className="col-xl-3 mt-4">
        <div className="new_background secondary_new" style={{ backgroundImage: 'url(https://www.diariomotor.com/imagenes/2015/11/mazda-3.jpg)' }}>
          <div className="background_overlay position-absolute w-100 h-100 p-2 d-flex flex-column justify-content-end fixed-top">
            <p className="text-white mb-0 font-weight-bold">NOTICIA SEGUNDA JERARQUÍA</p>
          </div>
        </div>
      </div>
    )
  }
}
