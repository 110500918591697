import React from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppHelper } from "helpers";

const NavSubMenu = ({ items, history }) => {
  const user = useSelector((state) => state.user.user);

  return (
    <div className="nav_submenu">
      <ul>
        {items.map((item, index) => {
          if (AppHelper.hasPermission(user, item.permission)) {
            return (

              <a className={"link-submenu"} href={item.route}>
                <li
                  key={`${item.id}_sub_menu_children${index}`}
                  className={`p-2 pb-1 font-weight-bold ${!item.pro ? "hide" : ""
                    }`}
                >
                  {item.name}
                </li>
              </a>

            );
          }
        })}
      </ul>
    </div>
  );
};

export default withRouter(NavSubMenu);
