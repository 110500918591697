import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronRight, faChevronLeft, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {Logo} from "../atoms";
import {SectionSeparator} from "../atoms";


export default () => {
    return(
        <section className={'background_gray api_section'}>
            <div className={'p-5'}>
            <div className="container py-5 d-flex flex-column flex-md-row align-items-center justify-content-between">
                <h2 className={'font-weight-normal text-center text-md-right'}>Nuestro objetivo es integrar <br /> <strong>a nuestros clientes.</strong></h2>
                <FontAwesomeIcon className={'d-none d-md-block'} icon={faChevronRight} color={'#ccc'} />
                <FontAwesomeIcon className={'d-block d-md-none my-3'} icon={faChevronDown} color={'#ccc'} />
                <div className={'d-flex flex-column justify-content-center align-items-center'}>
                    <h1 className="display-4 font-weight-bold">API</h1>
                    <Logo />
                    {/*<img src={require('../../static/img/api.png')} alt="api"/>*/}
                </div>
                <div className={'d-none d-md-flex flex-column justify-content-center align-items-center'}>
                    <FontAwesomeIcon icon={faChevronLeft} color={'#ccc'} />
                    <FontAwesomeIcon icon={faChevronRight} color={'#ccc'} />
                </div>
                <div className={'d-flex mt-3 mb-4 d-md-none justify-content-center align-items-center'}>
                    <FontAwesomeIcon icon={faChevronDown} color={'#ccc'} />
                    <FontAwesomeIcon icon={faChevronUp} color={'#ccc'} />
                </div>
                <ul>
                    <li><span className={'default_shadow'}>Bancos</span></li>
                    <li><span className={'default_shadow'}>Financieras</span></li>
                    <li><span className={'default_shadow'}>Empresas</span></li>
                    <li><span className={'default_shadow'}>Aseguradoras</span></li>
                    <li><span className={'default_shadow'}>Brokers</span></li>
                    <li><span className={'default_shadow'}>Insuretech</span></li>
                </ul>
            </div>
            </div>
            <SectionSeparator />
        </section>
    )
}
