import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from 'react-redux';
import actions from "../../store/actions";

export const ActiveFilter = ({value}) => {

    const options = useSelector(state => state.search.filters);
    const dispatch = useDispatch();

    const removeElement = () => {
        switch(value.type){
            case 'brand':
                dispatch(actions.search.setSearch([]));
                break;
            case 'model':
                dispatch(actions.search.setSearch(options.filter(option => option.type === 'brand')));
                break;
            default:
                dispatch(actions.search.removeElement(value));
        }
    };

    return(
        <div className="default_shadow p-2 pl-3 pr-3 mb-2 d-flex align-items-center justify-content-between rounded-lg">
            <p className="p-0 m-0 text-muted">{value.label ? value.label : value.name}</p>
            <FontAwesomeIcon icon={faTimes} className="pointer" onClick={removeElement}/>
        </div>
    )
};
