import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Loader, ReportTypePill, Select, SquareButton } from "../atoms";
import { ApiHelper } from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarAndCrescent, faTimes } from "@fortawesome/free-solid-svg-icons";
import { default as ReactLoader } from "react-loader-spinner";
import logo from "static/img/INFO_GRID_BLUE_LOGO.png";

const ContactModal = ({ show, hide }) => {
  const contactTypes = [
    {
      label: "Consulta",
      value: "Consulta",
    },
    {
      label: "Solicitar Revista",
      value: "Petición Revista",
    },
  ];

  const [contactType, setContactType] = useState(contactTypes[1]);
  const [validateContactType, setValidateContactType] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.phoneNumber);
  const [social, setSocial] = useState();
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setMessage("");
    setTimeout(() => {
      setSent(false);
    }, 1000);
  }, [show]);

  const submitForm = async (e) => {
    e.preventDefault();

    if (!contactType) {
      setValidateContactType(true);
      return;
    }

    const data = {
      email,
      subject: `${contactType.value} ${firstName} ${lastName}`,
      message: `
            ${contactType.value}: ${firstName} ${lastName}
            Email: ${email}
            Telefono: ${phone}
            Social: ${social}
            Mensaje: ${message}`,
    };

    try {
      setIsFetching(true);
      const res = await ApiHelper.post("admin", "/mail", data);

      setSent(true);
      setIsFetching(false);
      setTimeout(() => {
        hide();
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={hide}
      size={sent ? "sm" : "lg"}
      className={"default_modal"}
    >
      <Modal.Body className={"p-0 background_gray"}>
        <div className={"text-right pt-3 pr-3 link"}>
          <FontAwesomeIcon
            onClick={hide}
            icon={faTimes}
          />
        </div>
        {sent ? (
          <div>
            <div className="row">
              <div className="col-12 gray_background p-5 text-center">
                <h4 className="font-weight-normal mb-0">
                  <strong>Mensaje enviado</strong>!
                </h4>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 gray_background p-5">
              <h1 className="font-weight-normal mb-0 border-bottom-1 pb-3">
                <strong>Soluciones</strong> a tu medida
              </h1>
              <form id="contact-form" onSubmit={submitForm}>
                <div className="row mt-3 flex-column-reverse flex-md-row">
                  <div className="col-12 col-md-6 d-flex flex-column">
                    <div className="mt-2">
                      <label className="font-weight-bold">Nombre</label>
                      <input
                        required
                        name={"firstName"}
                        defaultValue={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        className={"custom_input default-shadow p-2 w-100 mb-3"}
                        placeholder={"Nombre"}
                        type={"text"}
                      />
                    </div>
                    {/* <div className="mt-2 col-12 col-md-6 ">
                    <label className="font-weight-bold">Apellido</label>
                    <input
                      required
                      name={"lastName"}
                      defaultValue={lastName}
                      onChange={(event) => setLastName(event.target.value)}
                      className={"custom_input default-shadow p-2 w-100 mb-3"}
                      placeholder={"Apellido"}
                      type={"text"}
                    />
                  </div> */}
                    <div className="mt-2">
                      <label className="font-weight-bold">Email</label>
                      <input
                        required
                        name={"email"}
                        defaultValue={email}
                        onChange={(event) => setEmail(event.target.value)}
                        className={"custom_input default-shadow p-2 w-100 mb-3"}
                        placeholder={"Email"}
                        type={"email"}
                      />
                    </div>
                    <div className="mt-2">
                      <label className="font-weight-bold">
                        Nombre de la Empresa
                      </label>
                      <input
                        name={"phone"}
                        defaultValue={phone}
                        onChange={(event) => setPhone(event.target.value)}
                        className={"custom_input default-shadow p-2 w-100 mb-3"}
                        placeholder={"Nombre de la Empresa"}
                        type={"tel"}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                    <img
                      src={logo}
                      alt="logo"
                      className="img-fluid contact_pro_logo"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="mt-2 col-12 col-md-6">
                    <label className="font-weight-bold">Rubro</label>
                    <input
                      name={"social"}
                      defaultValue={social}
                      onChange={(event) => setSocial(event.target.value)}
                      className={"custom_input default-shadow p-2 w-100 mb-3"}
                      placeholder={"Rubro de la empresa"}
                      type={"text"}
                    />
                  </div>
                  <div className="mt-2 col-12 col-md-6">
                    <label className="font-weight-bold">
                      Cantidad de usuarios
                    </label>
                    <input
                      name={"social"}
                      defaultValue={social}
                      onChange={(event) => setSocial(event.target.value)}
                      className={"custom_input default-shadow p-2 w-100 mb-3"}
                      placeholder={"Número de usuarios"}
                      type={"number"}
                    />
                  </div>
                </div>
                <div class="row">
                  <div className="col-12 mb-4 d-flex flex-column">
                    <label className={"font-weight-bold"}>Mensaje</label>
                    <textarea
                      required
                      defaultValue={message}
                      onChange={(event) => setMessage(event.target.value)}
                      placeholder={"Escriba su mensaje"}
                      className={"custom_input text_area w-100 p-2 px-3"}
                    />
                  </div>

                  <div className="col-12 text-center mt-3 text-center text-md-right">
                    <SquareButton
                      loading={isFetching}
                      onClick={submitForm}
                      text={"ENVIAR"}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ContactModal;
