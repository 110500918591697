import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApiHelper } from '../../helpers'
import actions from '../../store/actions'
import { DollarPill } from '../atoms'
import axios from 'axios'

export const DollarWidget = ({ vertical }) => {
	const dollar = useSelector((state) => state.dollar)
	const dispatch = useDispatch()

	useEffect(() => {
		getPrice()
	}, [])

	const getPrice = async () => {
		const { data:dataBlue } = await axios.get('https://mercados.ambito.com/dolar/informal/variacion')

		const { data: dataBna } = await axios.get('https://mercados.ambito.com/dolar/oficial/variacion')

		const dollar = {blue: Number(dataBlue.venta.replace(",",".")), bna: Number(dataBna.venta.replace(",",".")) }

		dispatch(actions.dollar.setDollar(dollar))
	}


	return (
		<div className={`w-100 d-flex flex-row ${vertical ? 'flex-column' : 'flex-row'}`}>

			<DollarPill price={dollar.bna} fullWidth={vertical} />
			<DollarPill price={dollar.blue} fullWidth={vertical} blue={true} />
		</div>
	)
}
