import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

export default function GoogleAnalytics({ children }) {
	let location = useLocation()

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS)
		ReactGA.send({ hitType: 'pageview', page: location.pathname, title: location.pathname })
	}, [location])

	return <>{children}</>
}
