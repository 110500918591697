import { faCheckCircle, faCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApiHelper } from 'helpers'
import logo from 'static/img/INFO_GRID_BLUE_LOGO.png'
import actions from 'store/actions'
import { SquareButton } from '../atoms'

export default () => {
	const [subscriptions, setSubscriptions] = useState([])
	const [showTable, setShowTable] = useState(false)
	const [idFree, setIdFree] = useState()
	const [idProIndividual, setIdProIndividual] = useState()
	const user = useSelector((state) => state.user.user)
	const dispatch = useDispatch()


	useEffect(() => {
		getSubscriptions()
	}, [])

	const getSubscriptions = async () => {
		try {
			const { data } = await ApiHelper.get('admin', '/subscription/template')

			const freePlan = data.filter(plan => plan.subscriptionType.subscriptionCode === "professionalFree")
			setIdFree(freePlan[0].id)

			const proPlan = data.filter(plan => plan.subscriptionType.subscriptionCode === "professionalIndividual")
			setIdProIndividual(proPlan[0]?.id)
			setSubscriptions(data)
		} catch (err) {
			console.log(err)
		}
	}


	return (
		<div className={'row mt-5 pb-5 d-flex justify-content-center'}>
			<div className='col-12 col-md-4'>
				<SquareButton
					onClick={() => setShowTable(!showTable)}
					text={showTable ? 'Ocultar características' : 'Ver todas las características'}
					className='col-md-12 col-5'
				/>
			</div>
			{showTable && (
				<div className='col-12' style={{ overflowX: 'scroll' }}>
					<div className='row d-flex justify-content-center'>
						<div className='col-12 col-md-10 bg_white mt-4 p-4'>
							<div className='row px-3'>
								<div className='col-4 bg_black p-3 font-weight-bold'>Opciones del plan</div>
								<div className='col-8 bg_blue p-3 font-weight-bold'>Profesional</div>
							</div>
							<table className={'w-100'}>
								<col style={{ width: '33%' }} />
								<col style={{ width: '33%' }} className='border-left border-right border-dark' />
								<col style={{ width: '33%' }} />

								<tr>
									<td className={'p-3 font-weight-bold'}>Categoría</td>
									<td className={'p-3 text-center font-weight-bold'}>Gratis</td>
									<td className={'p-3 text-center font-weight-bold'}>
										<img style={{ width: 100 }} src={logo} alt='logo' />
									</td>
								</tr>
								<tr className={'background_gray'}>
									<td className={'p-3 text-left'}>
										<FontAwesomeIcon icon={faCircle} className={'mr-2'} /> Consulta de 0km
									</td>
									<td className={'p-3 text-center'}>Ilimitado</td>
									<td className={'p-3 text-center'}>Ilimitado</td>
								</tr>
								<tr>
									<td className={'p-3 text-left'}>
										<FontAwesomeIcon icon={faCircle} className={'mr-2'} /> Consulta de usados por mes
									</td>
									<td className={'p-3 text-center'}>
										{subscriptions?.find((s) => s.id === idFree)?.infocreditsPerMonth}{" "}
									</td>
									<td className={'p-3 text-center'}>
										{subscriptions?.find((s) => s.id === idProIndividual)?.infocreditsPerMonth}{" "}
									</td>
								</tr>

								<tr>
									<td className={'p-3 text-left'}>
										<FontAwesomeIcon icon={faCircle} className={'mr-2'} /> Revista Digital
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faTimesCircle} size={'lg'} className={'gray_icon'} />
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faCheckCircle} size={'lg'} className={'color_green'} />
									</td>
								</tr>
								<tr className={'background_gray'}>
									<td className={'p-3 text-left'}>
										<FontAwesomeIcon icon={faCircle} className={'mr-2'} /> Historial de Búsquedas
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faTimesCircle} size={'lg'} className={'gray_icon'} />
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faCheckCircle} size={'lg'} className={'color_green'} />
									</td>
								</tr>
								<tr>
									<td className={'p-3 text-left'}>
										<FontAwesomeIcon icon={faCircle} className={'mr-2'} /> Mis Autos Favoritos
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faTimesCircle} size={'lg'} className={'gray_icon'} />
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faCheckCircle} size={'lg'} className={'color_green'} />
									</td>
								</tr>
								<tr className={'background_gray'}>
									<td className={'p-3 text-left'}>
										<FontAwesomeIcon icon={faCircle} className={'mr-2'} /> Ficha Técnica
									</td>
									<td className={'p-3 text-center font-weight-bold color_yellow'}>Básica</td>
									<td className={'p-3 text-center font-weight-bold color_blue'}>Completa</td>
								</tr>
								<tr>
									<td className={'p-3 text-left'}>
										<FontAwesomeIcon icon={faCircle} className={'mr-2'} /> Descripción Marca Modelo
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faCheckCircle} size={'lg'} className={'color_green'} />
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faCheckCircle} size={'lg'} className={'color_green'} />
									</td>
								</tr>
								<tr className={'background_gray'}>
									<td className={'p-3 text-left'}>
										<FontAwesomeIcon icon={faCircle} className={'mr-2'} /> Comparador
									</td>
									<td className={'p-3 text-center font-weight-bold'}>2 Modelos</td>
									<td className={'p-3 text-center font-weight-bold'}>4 Modelos</td>
								</tr>
								<tr>
									<td className={'p-3 text-left'}>
										<FontAwesomeIcon icon={faCircle} className={'mr-2'} /> Fotos
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faCheckCircle} size={'lg'} className={'color_green'} />
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faCheckCircle} size={'lg'} className={'color_green'} />
									</td>
								</tr>
								<tr className={'background_gray'}>
									<td className={'p-3 text-left'}>
										<FontAwesomeIcon icon={faCircle} className={'mr-2'} /> Precios Históricos
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faTimesCircle} size={'lg'} className={'gray_icon'} />
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faCheckCircle} size={'lg'} className={'color_green'} />
									</td>
								</tr>
								<tr>
									<td className={'p-3 text-left'}>
										<FontAwesomeIcon icon={faCircle} className={'mr-2'} /> Precios Usados del Año
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faTimesCircle} size={'lg'} className={'gray_icon'} />
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faCheckCircle} size={'lg'} className={'color_green'} />
									</td>
								</tr>

								<tr>
									<td className={'p-3 text-left'}>
										<FontAwesomeIcon icon={faCircle} className={'mr-2'} /> InfoGrid
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faTimesCircle} size={'lg'} className={'gray_icon'} />
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faCheckCircle} size={'lg'} className={'color_green'} />
									</td>
								</tr>
								<tr className={'background_gray'}>
									<td className={'p-3 text-left'}>
										<FontAwesomeIcon icon={faCircle} className={'mr-2'} /> Compartir Consulta
									</td>
									<td className={'p-3 text-center'}>
										<FontAwesomeIcon icon={faTimesCircle} size={'lg'} className={'gray_icon'} />
									</td>
									<td className={'p-3 text-center'}>PDF</td>
								</tr>
								{!user && (
									<tr className={'background_gray'}>
										<td className={'p-3 '}></td>
										<td className={'p-3 text-center'}>
											<SquareButton
												onClick={() => dispatch(actions.app.setShowRegister(true))}
												text='REGISTRATE'
											/>
										</td>
										<td className={'p-3 text-center'}>
											<SquareButton
												onClick={() => dispatch(actions.app.setShowLogin(true))}
												text='COMENZAR'
											/>
										</td>
									</tr>
								)}

							</table>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
