import actionTypes from "./actionTypes";

const userActions = {
    setUser: user => {
        return {
            type: actionTypes.SET_USER,
            user: user,
        }
    },
    fillUserData: user => {
        return {
            type: actionTypes.FILL_USER_DATA,
            user: user
        }
    },
    setSearchBarResults: results => {
        return {
            type: actionTypes.SET_SEARCH_BAR_RESULTS,
            results: results,
        }
    }
};

export default userActions;
