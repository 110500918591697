import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FacebookButton, SquareButton, Logo } from "../components/atoms";
import { ApiHelper } from "../helpers";
import { useDispatch } from "react-redux";
import firebase from "firebase";
import actions from "../store/actions";
import { auth } from "../helpers/Firebase";
import { Form } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { RegisterDataModal } from "../components/organisms";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

export const ProfessionalActivation = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [otp, setOtp] = useState();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [accountRecovered, setAccountRecovered] = useState(false);

  useEffect(() => {
    if (localStorage.token) {
      window.location.href = "/404";
    }
  }, []);

  const activateAccount = async (e) => {
    e.preventDefault();
    setError(false);

    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    setLoading(true);
    if (
      email &&
      password &&
      password.length >= 6 &&
      password === passwordConfirm
    ) {
      try {
        const data = {
          otp: otp,
          email: email,
          password: password,
        };
        if (window.grecaptcha) {
          window.grecaptcha.ready(function () {
            window.grecaptcha
              .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
                action: "submit",
              })
              .then(async function (tokenCaptcha) {
                localStorage.reCaptcha = tokenCaptcha;
                await ApiHelper.post("admin", "/user/otp", data);
                setSuccess("La cuenta ha sido activada con éxito");
                await auth.signInWithEmailAndPassword(email, password);
                const token = await auth.currentUser.getIdToken(true);
                let userData = new FormData();
                userData.append("grant_type", "firebase");
                userData.append("firebase_token_id", token);
                const res2 = await ApiHelper.post(
                  "auth",
                  "/oauth/token",
                  userData
                );
                localStorage.token = res2.data.access_token;
                localStorage.user = JSON.stringify({ email: email });
                dispatch(actions.user.setUser({ email: email }));
                const user = await ApiHelper.get("admin", "/user");
                window.location.href = window.location.origin;
              });
          });
        }
      } catch (error) {
        setError(
          "El código ingresado no es válido, por favor intentalo nuevamente"
        );
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      if (password.length < 6) {
        setError("La contraseña debe tener al menos 6 caracteres");

        return;
      } else if (password !== passwordConfirm) {
        setError("Las contraseñas deben coincidir");

        return;
      }
    }
  };

  return (
    <div className="page_default_margin">
      <div className={"container mb-5"}>
        <h2 className="font-weight-bolder">Activar Cuenta</h2>
        <div className="row justify-content-center">
          <div class="col-12 col-md-6">
            <div class="card mt-4">
              <div class="card-body">
                <div className="row">
                  <div className="col-12 p-5 d-flex flex-column align-items-center">
                    <h5 className="font-weight-bold mb-4 text-center">
                      Activá tu cuenta
                    </h5>

                    <>
                      {accountRecovered ? (
                        <>
                          <p className={"text-center text-success mt-2"}>
                            {success}
                          </p>
                          <a href={"/"}>
                            <SquareButton text={"Comenzar"} />
                          </a>{" "}
                        </>
                      ) : (
                        <form onSubmit={activateAccount}>
                          <p className={"mt-2 crossed"}>
                            <span>Ingresá el código recibido</span>
                          </p>
                          <div className="p-2 mb-4 d-flex justify-content-center">
                            <OtpInput
                              value={otp}
                              onChange={(e) => setOtp(e)}
                              numInputs={4}
                              separator={<span>-</span>}
                              isInputNum={true}
                              inputStyle={{
                                width: "2rem",
                                height: "2rem",
                                margin: "0 10px",
                                fontSize: "14px",
                                padding: "5px",
                                borderRadius: 4,
                                border: "1px solid rgba(0,0,0,0.3)",
                              }}
                              focusStyle={{
                                border: "2px solid #5FFFE8",
                              }}
                            />
                          </div>

                          <input
                            name={"password"}
                            value={password}
                            onChange={(event) =>
                              setPassword(event.target.value)
                            }
                            className={
                              "mt-2 custom_input default-shadow p-2 w-100 mb-3"
                            }
                            placeholder={"Contraseña"}
                            type={"password"}
                          />
                          <input
                            name={"password2"}
                            value={passwordConfirm}
                            onChange={(event) =>
                              setPasswordConfirm(event.target.value)
                            }
                            className={
                              "mt-1 custom_input default-shadow p-2 w-100 mb-3"
                            }
                            placeholder={"Confirmar contraseña"}
                            type={"password"}
                          />
                          {!success && (
                            <div className="w-100">
                              <SquareButton
                                loading={loading}
                                type="submit"
                                text={"Activar"}
                              />
                            </div>
                          )}
                          {error && (
                            <p className={"text-center color_red mt-2"}>
                              {error}
                            </p>
                          )}
                        </form>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>

        {/* {showRegisterModal && <RegisterDataModal />} */}
      </div>
    </div>
  );
};
