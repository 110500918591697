import React, { useState } from "react";
// import { UserType } from "../atoms";
import particularesImg from "../../static/img/particulares.jpg";
import profesionalesImg from "../../static/img/profesionales.jpg";
import { useDispatch, useSelector } from "react-redux";
import UserTypeCard from "./UserTypeCard";
import { Logo, SquareButton } from "components/atoms";
import { IoHappySharp } from "react-icons/io5";

export default function UserSuccess({ newUser, setUser, nextStep }) {
  const user = useSelector((state) => state.user.user);
  const [selectedUserType, setSelectedUserType] = useState(newUser.userType);

  const userTypes = [
    {
      id: 1,
      name: "Particulares",
      value: "individual",
      image: particularesImg,
      bullets: [
        "Descubrí toda la información de tu próximo auto o moto.",
        "Guía de precios con fotos de Usados y 0Km",
        "Compara, decidí y gana tiempo",
        "Fichas Técnicas confiables y fáciles de interpretar",
      ],
    },
    {
      id: 2,
      name: "Profesionales",
      value: "professional",
      image: profesionalesImg,
      bullets: [
        "Guía de precios en formato grilla de Usados y 0Km similar a la revista",
        "Precios Históricos y Usado del Año",
        "Historial de Consultas",
        "Comparador hasta 4 vehículos.",
      ],
    },
  ];

  function handleContinue() {
    window.location.href = window.location.origin;
  }

  return (
    <div className="col-12 d-flex flex-column align-items-center bg-white p-5">
      <h2>
        <IoHappySharp />
      </h2>
      <h5 className="font-weight-bold mb-4">¡Listo!</h5>
      <p>
        Ya podes comenzar a disfrutar de <Logo />
      </p>
      <div className={"container px-md-5 pt-2 "}>
        <div className="d-flex mt-4 justify-content-center">
          <SquareButton
            fullWidth={false}
            onClick={handleContinue}
            text={"Continuar"}
          />
        </div>
      </div>
    </div>
  );
}
