import actionsTypes from "../actions/actionTypes";

const initialState = {
  loading: false,
  showLogin: false,
  showRegister: false,
  showContact: false,
  showReset: false,
  showPaymentPending: false,
  showInfocreditsModal: false,
  showRecover: false,
  showBillingModal: false,
  showWrongPlanModal: false,
  showPaymentModal: false,
  loginMessage: null,
  selectedPlan: null,
  showSubscriptionDisabled: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionsTypes.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.payload,
      });
    case actionsTypes.SET_SHOW_REGISTER:
      return Object.assign({}, state, {
        showRegister: action.payload,
      });
    case actionsTypes.SET_SHOW_LOGIN:
      return Object.assign({}, state, {
        showLogin: action.payload,
      });
    case actionsTypes.SET_SHOW_RECOVER:
      return Object.assign({}, state, {
        showRecover: action.payload,
      });
    case actionsTypes.SET_SHOW_CONTACT:
      return Object.assign({}, state, {
        showContact: action.payload,
      });
    case actionsTypes.SET_SHOW_RESET:
      return Object.assign({}, state, {
        showReset: action.payload,
      });
    case actionsTypes.SET_SHOW_PAYMENT_PENDING:
      return Object.assign({}, state, {
        showPaymentPending: action.payload,
      });
    case actionsTypes.SET_SHOW_BILLING_MODAL:
      return Object.assign({}, state, {
        showBillingModal: action.payload,
      });
    case actionsTypes.SET_SHOW_WRONG_PLAN_MODAL:
      return Object.assign({}, state, {
        showWrongPlanModal: action.payload,
      });
    case actionsTypes.SET_SHOW_PAYMENT_MODAL:
      return Object.assign({}, state, {
        showPaymentModal: action.payload,
      });
    case actionsTypes.SET_LOGIN_MESSAGE:
      return Object.assign({}, state, {
        loginMessage: action.payload,
      });
    case actionsTypes.SET_SELECTED_PLAN:
      return Object.assign({}, state, {
        selectedPlan: action.payload,
      });
    case actionsTypes.SET_SHOW_SUBSCRIPTION_DISABLED:
      return Object.assign({}, state, {
        showSubscriptionDisabled: action.payload,
      });
    default:
      return state;
  }
}
