import React, { useState, useEffect } from "react";
import AdXL from "components/ads/AdXL";
import SectionTitle from "components/searchResult/SectionTitle";
import { SearchResultFilters, ResultCard } from "components/organisms";
import { useSelector } from "react-redux";
import { ApiHelper } from "helpers";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { default as ReactLoader } from "react-loader-spinner";

export const SearchResult = (props) => {
  const filters = useSelector((state) => state.search.filters);
  const [ loading, setLoading ] = useState(false);
  const [ cars, setCars ] = useState([]);
  const comfortFilters = useSelector((state) => state.filterTypes);
  const vehicle = useSelector((state) => state.search.vehicle);
  const [ lastSearchType, setLastSearchType ] = useState(vehicle);
  const vehicleType = useSelector((state) => state.search.vehicle);
  const [ totalResults, setTotalResults ] = useState(0)
  const [ offset, setOffset ] = useState(0);
  const perPage = 10;

  const [ pageCount, setPageCount ] = useState(0);
  const [ datos, setDatos ] = useState([]);


  useEffect(() => {
    const selectedBrand = filters.find((filter) => filter.type === "brand");
    const selectedModel = filters.find((filter) => filter.type === "model");
    if (props.location.search && !selectedBrand && !selectedModel) {
      getQuery();
    } else if (selectedBrand && selectedModel) {
      getVehicles();
    }
  }, [ props.location.search, offset, window.grecaptcha ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getQuery = async () => {
    setLoading(true);

    try {
      const params = new URLSearchParams(props.location.search);
      const search = params.get("s");
      const quantity = params.get("q");

      const data = {
        features: {},
        page: offset + 1,
        pageSize: 10,
        vehicleType,
        filterString: search,
      };

      let res;
      if (window.grecaptcha) {
        window.grecaptcha.ready(function () {
          window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
              action: "submit",
            })
            .then(async function (token) {
              localStorage.reCaptcha = token;
              res = await ApiHelper.post("preciosa", "/search", data);

              setCars(res.data.response);
              setTotalResults(res.data.pagination.totalResults);
              setPageCount(res.data.pagination.totalPages);
            })
        })
      }
    } finally {
      setLoading(false);
    }
  }

  const handlePageClick = ({ selected }) => {
    setOffset(selected);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ offset ]);

  useEffect(() => {
    setOffset(0);
    getVehicles();
  }, [ filters ]);

  const getVehicles = async () => {
    const selectedBrand = filters.find((filter) => filter.type === "brand");
    const selectedModel = filters.find((filter) => filter.type === "model");
    const selectedYear = filters.find((filter) => filter.type === "year");
    const selectedVersion = filters.find((filter) => filter.type === "version");
    const selectedFuel = filters.find((filter) => filter.type === "fuel");
    const selectedType = filters.find(
      (filter) => filter.type === "vehicleType"
    );
    const selectedDoors = filters.find((filter) => filter.type === "doors");
    const selectedYearsRange = filters.find(
      (filter) => filter.type === "yearsRange"
    );

    if (selectedBrand && selectedModel) {
      setLoading(true);
      try {
        let features = {};
        filters.map((filter) => {
          const activeFilter = comfortFilters.find(
            (f) => f.description === filter.type
          );
          if (activeFilter) {
            features[ `feature_${activeFilter.id}` ] = true;
          }
        });
        if (selectedDoors) {
          features.feature_11 = selectedDoors.name;
        } else if (selectedType) {
          features.feature_3 = selectedType.name;
        } else if (selectedFuel) {
          features.feature_1 = selectedFuel.name;
        }

        let data = {
          vehicleType: vehicle,
          features,

          page: offset + 1,
          pageSize: 10,
        };
        if (selectedVersion) {
          data.stringSearch = selectedVersion.name;
        }

        if (selectedYear?.id === "0km") {
          data.newPrice = true;
        } else {
          if (selectedYearsRange) {
            data.fromYear = selectedYearsRange.value[ 0 ];
            data.toYear = selectedYearsRange.value[ 1 ];
          } else if (selectedYear) {
            data.fromYear = selectedYear.id;
            data.toYear = selectedYear.id;
          }
        }

        const res = await ApiHelper.getPaginatedVersions(
          selectedBrand.id,
          selectedModel.id,
          data
        );

        setTotalResults(res.data.pagination.totalResults)
        setLastSearchType(vehicle);
        setCars(res.data.response);
        setPageCount(res.data.pagination.totalPages);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div id="search_result_page" className="page_default_margin">
      <div className="container">
        <AdXL subsection="" />
        <div className="mt-4">
          <SectionTitle title="Resultados" />
          <div id="result_section" className="mt-2 row pt-4">
            <SearchResultFilters filters={filters} />
            <div className="col d-flex flex-column align-items-center">
              {loading && (
                <ReactLoader
                  type="TailSpin"
                  color="#10ffdc"
                  height={50}
                  width={50}
                />
              )}
              {cars.map(
                (car, index) =>

                  <ResultCard
                    lastSearch={lastSearchType}
                    car={car}
                    key={index}
                    vehicleType={vehicleType}
                  />

              )}
              {cars.length && (
                <div>
                  <p className="text-right mt-3">
                    {/* Mostrando {offset + 1} a{" "}
                    {offset + 10 < totalResults ? offset + 10 : totalResults} de{" "}
                    {totalResults} resultados */}
                    Mostrando página {offset + 1} de {pageCount}

                  </p>
                  <ReactPaginate
                    data={cars}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={4}
                    onPageChange={handlePageClick}
                    activeClassName={"active"}
                    nextLabel={<FontAwesomeIcon icon={faAngleDoubleRight} />}
                    previousLabel={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                  />
                </div>
              )}

              {cars.length === 0 && filters.length > 2 ? (
                <h4 className={"text-center font-weight-bold mt-4"}>
                  No existen modelos con las características seleccionadas
                </h4>
              ) : cars.length === 0 && filters.length < 2 ? (
                <h4 className={"text-center font-weight-bold mt-4"}>
                  Seleccione filtros para realizar una búsqueda
                </h4>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
