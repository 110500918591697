import React from "react";
import { Button } from "../atoms";

export const PriceOptions = ({ price, compare }) => {
  return (
    <div>
      <div className="row mt-4 text-sm-left text-center">
        <div className="col-xl-12">
          {price && <small className="font-weight-bold">Valor 0Km</small>}
          <div className={"px-4"}>
            {price && (
              <h1 className="font-weight-bold">
                ${new Intl.NumberFormat("es").format(price * 1000)}
              </h1>
            )}

            <Button text={"Comparar"} onClick={compare} />
          </div>
        </div>
      </div>
    </div>
  );
};
