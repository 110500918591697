import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FacebookButton, SquareButton, Logo } from "../components/atoms";
import { ApiHelper } from "../helpers";
import { useDispatch } from "react-redux";
import firebase from "firebase";
import actions from "../store/actions";
import { auth } from "../helpers/Firebase";
import { Form } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { RegisterDataModal } from "../components/organisms";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

export const RecuperarCuenta = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [otp, setOtp] = useState();
  const [step, setStep] = useState(1);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [accountRecovered, setAccountRecovered] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (localStorage.token) {
      window.location.href = "/404";
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleEnter);
    return () => document.removeEventListener("keydown", handleEnter);
  }, [email, step]);

  const recover = async () => {
    try {
      setError(null);
      setLoading(true);
      setStep(2);

      if (window.grecaptcha) {
        window.grecaptcha.ready(function () {
          window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
              action: "submit",
            }).then(async function (tokenCaptcha) {
              localStorage.reCaptcha = tokenCaptcha;
              await ApiHelper.sendOTP(email);
            })
        })
      }
    } catch (error) {
      setError("La dirección de email no corresponde a un usuario");
    } finally {
      setLoading(false);
    }
  };

  const recoverAccount = async () => {
    setError(false);
    setCodeSent(false);

    if (!loading) {
      if (
        email &&
        password1 &&
        password1.length >= 6 &&
        password1 === password2
      ) {
        try {
          setLoading(true);
          const data = {
            otp: otp,
            email: email,
            password: password1,
          }
          if (window.grecaptcha) {
            window.grecaptcha.ready(function () {
              window.grecaptcha
                .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
                  action: "submit",
                }).then(async function (tokenCaptcha) {
                  localStorage.reCaptcha = tokenCaptcha;
                  await ApiHelper.post("admin", "/user/otp", data);
                  setSuccess("La cuenta ha sido activada con éxito");
                  await auth.signInWithEmailAndPassword(email, password1);
                  const token = await auth.currentUser.getIdToken(true);
                  let userData = new FormData();
                  userData.append("grant_type", "firebase");
                  userData.append("firebase_token_id", token);
                  const res2 = await ApiHelper.post("auth", "/oauth/token", userData);
                  localStorage.token = res2.data.access_token;
                  localStorage.user = JSON.stringify({ email: email });
                  dispatch(actions.user.setUser({ email: email }));
                  const user = await ApiHelper.get("admin", "/user");
                  setAccountRecovered(true);
                  setShowRegisterModal(true);

                })
            })
          }

        } catch (error) {
          console.log(error);
          setError(
            "El código ingresado no es válido, por favor intentalo nuevamente"
          );
        } finally {
          setLoading(false);
        }
      } else {
        if (password1.length < 6) {
          setError("La contraseña debe tener al menos 6 caracteres");
          return;
        } else if (password1 !== password2) {
          setError("Las contraseñas deben coincidir");
          setLoading(false);
          return;
        }
      }
    }
  };

  const handleEnter = (key) => {
    if (username === "Enter") {
      recover();
    }
  };

  const sendCode = async () => {
    setIsSendingCode(true);
    setError(false);
    try {
      if (window.grecaptcha) {
        window.grecaptcha.ready(function () {
          window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
              action: "submit",
            }).then(async function (token) {
              localStorage.reCaptcha = token;
              await ApiHelper.sendOTP(email);
              setCodeSent(true);
            })


        })
      }
    } catch (err) {
      setError("Hubo un error al enviar el código");
    } finally {
      setIsSendingCode(false);
    }
  };

  return (
    <div className="page_default_margin">
      <div className={"container mb-5"}>
        <h2 className="font-weight-bolder">Activar Cuenta</h2>
        <div className="row justify-content-center">
          <div class="col-12 col-md-6">
            <div class="card mt-4">
              <div class="card-body">
                <div className="row">
                  <div className="col-12 p-5 d-flex flex-column align-items-center">
                    <h5 className="font-weight-bold mb-4 text-center">
                      Activá tu cuenta
                    </h5>
                    {step === 1 && (
                      <>
                        <p className={"mt-2 crossed"}>
                          <span>Ingresá el mail de la cuenta</span>
                        </p>

                        <input
                          name={"email"}
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                          className={
                            "mt-2 custom_input default-shadow p-2 w-100 mb-3"
                          }
                          placeholder={"Correo electrónico"}
                          type={"text"}
                        />

                        {!success && (
                          <SquareButton
                            loading={loading}
                            onClick={recover}
                            text={"Enviar"}
                          />
                        )}
                        {error ? (
                          <p className={"text-center color_red mt-2"}>
                            {error}
                          </p>
                        ) : null}
                        {success ? (
                          <p className={"text-center text-success mt-2"}>
                            {success}
                          </p>
                        ) : null}
                      </>
                    )}
                    {step === 2 && (
                      <>
                        <p className={"mt-2"}>
                          <span>
                            <b>{username}</b>
                          </span>
                        </p>
                        <p className={"mt-2 crossed"}>
                          <span>Ingresá el código recibido</span>
                        </p>
                        <div className="p-2 mb-4">
                          <OtpInput
                            value={otp}
                            onChange={(e) => setOtp(e)}
                            numInputs={4}
                            separator={<span>-</span>}
                            isInputNum={true}
                            inputStyle={{
                              width: "2rem",
                              height: "2rem",
                              margin: "0 10px",
                              fontSize: "14px",
                              padding: "5px",
                              borderRadius: 4,
                              border: "1px solid rgba(0,0,0,0.3)",
                            }}
                            focusStyle={{
                              border: "2px solid #5FFFE8",
                            }}
                          />
                        </div>

                        <input
                          name={"password1"}
                          value={password1}
                          onChange={(event) => setPassword1(event.target.value)}
                          className={
                            "mt-2 custom_input default-shadow p-2 w-100 mb-3"
                          }
                          placeholder={"Contraseña"}
                          type={"password"}
                        />
                        <input
                          name={"password2"}
                          value={password2}
                          onChange={(event) => setPassword2(event.target.value)}
                          className={
                            "mt-1 custom_input default-shadow p-2 w-100 mb-3"
                          }
                          placeholder={"Confirmar contraseña"}
                          type={"password"}
                        />

                        {!success && (
                          <div className="w-100">
                            <SquareButton
                              loading={loading}
                              onClick={recoverAccount}
                              text={"Activar"}
                            />

                            {error && !codeSent && (
                              <p
                                onClick={sendCode}
                                className="mt-4 text-center pointer"
                              >
                                Reenviar código
                              </p>
                            )}
                            {isSendingCode && (
                              <div className="d-flex justify-content-center mt-4">
                                <Loader
                                  type="ThreeDots"
                                  color="#10ffdc"
                                  height={20}
                                  width={20}
                                />
                              </div>
                            )}
                            {codeSent && !error && (
                              <p className="code_sent mt-4">
                                Te hemos enviado un nuevo código a tu casilla de
                                correo
                              </p>
                            )}
                          </div>
                        )}
                        {error ? (
                          <p className={"text-center color_red mt-2"}>
                            {error}
                          </p>
                        ) : null}
                        {success ? (
                          <p className={"text-center text-success mt-2"}>
                            {success}
                          </p>
                        ) : null}

                        {accountRecovered && (
                          <a href={"/"}>
                            <SquareButton text={"Comenzar"} />
                          </a>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>

        {/* {showRegisterModal && <RegisterDataModal />} */}
      </div>
    </div>
  );
};
