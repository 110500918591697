import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SquareButton } from "components/atoms";

export default () => {
	return (
		<div className={"plan_card_container"}>
			<div className={"plan_card default_shadow background_gray"}>
				<h3
					className={`bg_black text-white text-center p-2 font-weight-bold mb-0`}
				>
					Gratis
				</h3>
				<div className='body p-4 d-flex flex-column align-items-center'>
					<div>
						<p className={"text-center"}>
							Registrate <span className='font-weight-bold'>sin cargo</span> y
							conocé nuestra herramienta para Profesionales InfoGrid
						</p>
						<div
							className={
								"d-flex border-bottom w-100 justify-content-center free_price"
							}
						>
							<h3 className={"font-weight-bold"}>$</h3>
							<h1 className={"font-weight-bold display-2"}>0</h1>
						</div>
						<div
							className={
								"d-flex border-bottom w-100 py-4 flex-column align-items-center justify-content-center"
							}
						>
							<p className={"font-weight-bold"}>Incluye</p>
							<p className='mb-1'>Autos & Motos</p>
							<p className='mb-1'>Visualizacion en formato Grilla</p>
							<p className='mb-1'>
								Hasta <strong>3</strong> consultas únicas de usados
							</p>
							<p className='mb-1'>Función Busquedas Masivas</p>
						</div>
						<div
							className={
								"d-flex w-100 py-4 flex-column align-items-center justify-content-center"
							}
						>
							<p className={"font-weight-bold"}>Características</p>
							<p className='mb-1'>Ficha Técnica Básica</p>
							<p className='mb-1'>Descripción Marca / Modelo / Versión</p>
							<p className='mb-1'>Comparador hasta 2 Unidades.</p>
						</div>
					</div>

					<div className='w-75'>
						<SquareButton
							onClick={() => (window.location.href = "/")}
							text={"Comenzar"}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
