import React from 'react'
import { useSelector } from 'react-redux'
import img0km from '../../static/img/0km.jpg'
import imgFirstCar from '../../static/img/firstCar.jpg'
import imgSecondHand from '../../static/img/secondHand.jpg'
import pdf0km from '../../static/pdf/Tips_para_comprar_un_0km.pdf'
import pdfSecondHand from '../../static/pdf/Tips_para_comprar_un_usado.pdf'
import pdfFirstCar from '../../static/pdf/Tips_para_elegir_tu_primer_auto.pdf'

export const ProfileGuide = () => {
	return (
		<div id='search_result_page' className='py-5'>
			<div className='container mb-4'>
				<div className='row mb-4'>
					<div className={'col-12 col-lg-4 plan_card_container'}>
						<div className={'plan_card default_shadow background_gray'}>
							<a className='guide-preview' href={pdfFirstCar}>
								<img className='guide-preview' src={imgFirstCar} alt='' />
							</a>

							<h4 className={`bg_black text-white text-center p-2 font-weight-bold mb-0`}>
								Guía 1
							</h4>
						</div>
						<div></div>
					</div>
					<div className={'col-12 col-lg-4 plan_card_container'}>
						<div className={'plan_card default_shadow background_gray'}>
							<a href={pdf0km}>
								<img className='guide-preview' src={img0km} alt='' />
							</a>
							<h4 className={`bg_black text-white text-center p-2 font-weight-bold mb-0`}>
								Guía 2
							</h4>
						</div>
						<div></div>
					</div>
					<div className={'col-12 col-lg-4 plan_card_container text-center'}>
						<div className={'plan_card default_shadow background_gray'}>
							<a href={pdfSecondHand}>
								<img className='guide-preview' src={imgSecondHand} alt='' />
							</a>
							<h4 className={`bg_black text-white text-center p-2 font-weight-bold mb-0`}>
								Guía 3
							</h4>
						</div>
						<div></div>
					</div>
				</div>
			</div>
		</div>
	)
}
