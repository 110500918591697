import { SquareButton } from 'components/atoms'
import React, { useState } from 'react'
import { AiOutlineRedo } from "react-icons/ai";

const HorizontalOverlay = ({ text }) => {
	const [show, setShow] = useState(true)

	const toggleOverlay = () => {
		localStorage.setItem(`showOverlay`, 'false')
		setShow(false)
	}

	return (
		<div className="d-flex justify-content-center">÷
			{show && (<div className='visual-overlay col-12'>
				<p  > {text} </p>
				<AiOutlineRedo size={'30px'} className='mb-3 rotate-right' />
				<SquareButton text={'Aceptar'} onClick={toggleOverlay} fullWidth={false} />
			</div>)}
		</div>
	)
}

export default HorizontalOverlay
