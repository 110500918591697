import { faArrowAltCircleDown, faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApiHelper } from '../../../helpers'
import actions from '../../../store/actions'
import { ContactModal } from '../../organisms'
import {
	IndividualCarMotoSubscription,
	IndividualPremiumSubscription,
	IndividualSubscriptionDetails,
	IndvidualFreeSubscription,
	ProFreeSubscription,
	ProGroupSubscription,
	ProIndividualSubscription,
	ProSubscriptionDetails,
} from '../../subscriptions'

export default ({ pro }) => {
	const [ subscriptions, setSubscriptions ] = useState([])
	const [ showModal, setShowModal ] = useState(false)
	const user = useSelector((state) => state.user.user)
	const activeSubscription = user?.activeSubscription
	const [ windowWidth, setWindowWidth ] = useState(window.innerWidth)
	const dispatch = useDispatch()

	useEffect(() => {
		getSubscriptions()
	}, [])

	useEffect(() => {
		window.addEventListener('resize', () => {
			setWindowWidth(window.innerWidth)
		})
	}, [])

	const getSubscriptions = async () => {
		try {
			const { data } = await ApiHelper.get('admin', '/subscription/template')

			setSubscriptions(data)

		} catch (error) {
			console.log(error)
		}

	}

	const onSubscribe = async (subscription) => {
		dispatch(actions.app.setSelectedPlan(subscription))
		const { data } = await ApiHelper.get("admin", "/user/billingInformation")
		if (!data.complete) {
			dispatch(actions.app.setShowBillingModal(true));
		} else {
			dispatch(actions.app.setShowPaymentModal(true));
		}
	}

	const defaultProps = {
		canSuscribe: false,
		subscriptions,
		activeSubscription,
	}

	const subscriptionProfiles = {
		individual: [
			{
				requiredToShow: [ 'individualFree' ],
				renderSubscription: () => <IndvidualFreeSubscription landingPage={true} />,
			},
			{
				requiredToShow: [ 'individualCars', 'individualMotorbikes' ],
				renderSubscription: () => <IndividualCarMotoSubscription {...defaultProps} />,
			},
			{
				requiredToShow: [ 'individualCars', 'individualMotorbikes' ],
				renderSubscription: () => <IndividualCarMotoSubscription {...defaultProps} />,
			},
		],
	}

	return (
		<div className={pro && 'gray_background'} id='planes'>
			<div className={`px-4 pt-3`}>
				<h2 className='text-center font-weight-normal mt-5'>
					Nuestro método, <strong>tu respaldo.</strong>
				</h2>
				<p className='text-center mb-5'>
					Usá Infoauto para saber realmente cuál es el <strong>precio real</strong> del auto o moto
					que quieras.
				</p>
				<div className='row mt-5 d-flex justify-content-center mb-5'>
					{!user && !pro && (
						<>
							<div className='d-flex flex-md-row flex-column justify-content-center align-items-center'>
								<IndvidualFreeSubscription landingPage={true} />

								<IndividualCarMotoSubscription
									canSuscribe={false}
									subscriptions={subscriptions}
									activeSubscription={activeSubscription}
								/>

								<IndividualPremiumSubscription
									canSuscribe={false}
									subscriptions={subscriptions}
									activeSubscription={activeSubscription}
								/>
							</div>
						</>
					)}

					{!user && pro && (
						<>
							<div className='d-flex flex-md-row flex-column justify-content-center align-items-center'>
								<ProFreeSubscription landingPage={true} />

								<ProIndividualSubscription
									canSuscribe={false}
									subscriptions={subscriptions}
									activeSubscription={activeSubscription}
								/>

								<ProGroupSubscription
									canSuscribe={false}
									subscriptions={subscriptions}
									activeSubscription={activeSubscription}
								/>
							</div>
						</>
					)}

					{user && user.type === 'individual' && !pro && (
						<div className='row d-flex justify-content-center mb-mobile px-4'>
							<div className='col-12 mb-5 mb-5'>
								<h2 className={'font-weight-bold text-center'}>¿Querés mejorar tu plan?</h2>
							</div>
							<div className='d-flex flex-md-row flex-column justify-content-center align-items-center'>
								{user.activeSubscription?.name === 'Free' && (
									<IndvidualFreeSubscription landingPage={true} />
								)}

								{user.activeSubscription?.name === 'Free' && windowWidth > 749 && (
									<div>
										<FontAwesomeIcon
											icon={faArrowAltCircleRight}
											size='8x'
											className='plan-arrow'
										/>
									</div>
								)}
								{user.activeSubscription?.name === 'Free' && windowWidth < 750 && (
									<div className='mt-4'>
										<FontAwesomeIcon icon={faArrowAltCircleDown} size='4x' className='plan-arrow' />
									</div>
								)}

								<IndividualCarMotoSubscription
									onSubscribe={onSubscribe}
									canSuscribe={true}
									subscriptions={subscriptions}
									activeSubscription={activeSubscription}
								/>

								{user.activeSubscription?.name !== 'Free' &&
									user.activeSubscription?.name !== 'Premium' &&
									windowWidth > 749 && (
										<div>
											<FontAwesomeIcon
												icon={faArrowAltCircleRight}
												size='8x'
												className='plan-arrow'
											/>
										</div>
									)}
								{user.activeSubscription?.name !== 'Free' &&
									user.activeSubscription?.name !== 'Premium' &&
									windowWidth < 750 && (
										<div className='mt-4'>
											<FontAwesomeIcon
												icon={faArrowAltCircleDown}
												size='4x'
												className='plan-arrow'
											/>
										</div>
									)}

								<IndividualPremiumSubscription
									onSubscribe={onSubscribe}
									canSuscribe={true}
									subscriptions={subscriptions}
									activeSubscription={activeSubscription}
								/>
							</div>
						</div>
					)}

					{user && subscriptions.length > 0 && user.type === 'professional' && !pro && (
						<div className='row d-flex justify-content-center mb-mobile px-4'>
							<div className='col-12 mb-5 mb-5'>
								<h2 className={'font-weight-bold text-center'}>¿Querés mejorar tu plan?</h2>
							</div>

							<div className='d-flex flex-md-row flex-column justify-content-center align-items-center'>
								<IndvidualFreeSubscription landingPage={true} />

								<IndividualCarMotoSubscription
									canSuscribe={false}
									subscriptions={subscriptions}
									activeSubscription={activeSubscription}
								/>

								<IndividualPremiumSubscription
									canSuscribe={false}
									subscriptions={subscriptions}
									activeSubscription={activeSubscription}
								/>
							</div>
						</div>
					)}

					{user && user.type === 'individual' && pro && (
						<div className='row p-5 d-flex justify-content-center'>
							<div className='col-12 mb-5 mb-5'>
								<h2 className={'font-weight-bold text-center'}>¿Querés mejorar tu plan?</h2>
							</div>
							<div className='d-flex flex-md-row flex-column justify-content-center align-items-center'>
								<ProFreeSubscription landingPage={true} />

								<ProIndividualSubscription
									onSubscribe={onSubscribe}
									canSuscribe={true}
									subscriptions={subscriptions}
									activeSubscription={activeSubscription}
								/>
								<ProGroupSubscription
									canSuscribe={false}
									subscriptions={subscriptions}
									activeSubscription={activeSubscription}
								/>
							</div>
						</div>
					)}

					{user && subscriptions.length > 0 && user.type === 'professional' && pro && (
						<div className='row p-5 d-flex justify-content-center'>
							<div className='col-12 mb-5 mb-5'>
								<h2 className={'font-weight-bold text-center'}>¿Querés mejorar tu plan?</h2>
							</div>
							<div className='d-flex flex-md-row flex-column justify-content-center align-items-center'>
								{user.activeSubscription?.name === 'Free' && (
									<ProFreeSubscription landingPage={true} />
								)}
								{user.activeSubscription?.name === 'Free' && windowWidth > 749 && (
									<div>
										<FontAwesomeIcon
											icon={faArrowAltCircleRight}
											size='8x'
											className='plan-arrow'
										/>
									</div>
								)}
								{user.activeSubscription?.name === 'Free' && windowWidth < 750 && (
									<div className='mt-4'>
										<FontAwesomeIcon icon={faArrowAltCircleDown} size='4x' className='plan-arrow' />
									</div>
								)}

								<ProIndividualSubscription
									onSubscribe={onSubscribe}
									canSuscribe={true}
									subscriptions={subscriptions}
									activeSubscription={activeSubscription}
								/>

								{user.activeSubscription?.name !== 'Free' &&
									user.activeSubscription?.name !== 'Premium' &&
									windowWidth > 749 && (
										<div>
											<FontAwesomeIcon
												icon={faArrowAltCircleRight}
												size='8x'
												className='plan-arrow'
											/>
										</div>
									)}
								{user.activeSubscription?.name !== 'Free' &&
									user.activeSubscription?.name !== 'Premium' &&
									windowWidth < 750 && (
										<div className='mt-4'>
											<FontAwesomeIcon
												icon={faArrowAltCircleDown}
												size='4x'
												className='plan-arrow'
											/>
										</div>
									)}

								<ProGroupSubscription
									canSuscribe={false}
									subscriptions={subscriptions}
									activeSubscription={activeSubscription}
								/>
							</div>
						</div>
					)}
				</div>
				{pro ? (
					<ProSubscriptionDetails pro={true} />
				) : (
					<IndividualSubscriptionDetails pro={false} />
				)}

				<h4 className={'text-center font-weight-normal my-3 find-mobile'}>
					¿No encontrás lo que buscás?{' '}
					<strong className={'link'} onClick={() => setShowModal(true)}>
						Contactanos
					</strong>
				</h4>
				<ContactModal show={showModal} hide={() => setShowModal(false)} />
				{/*{!pro && <h4 className={'text-center font-weight-normal pt-5 find-mobile'}>No encontrás lo que buscás? <strong>Contactanos</strong></h4>}*/}
			</div>
		</div>
	)
}
