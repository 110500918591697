import React, { useEffect, useState } from "react";
import { RatingBox } from "../molecules";
import { Button } from "../atoms";
import { withRouter } from "react-router-dom";
import coveredCarImg from "../../static/img/covered_car.jpg";
import coveredMotoImg from "../../static/img/covered_motorcycle.png";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";
import { AppHelper } from "helpers";
import {
  COMPARATOR,
  COMPARATOR_FOUR_VEHICLES,
} from "static/permissions/sections";

const ResultCard = ({ car, history, lastSearch, vehicleType }) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const customYears = car.hasNewPrice && car.years !== null ? [ 'Okm', ...car?.years ] : car?.years === null ? [ '0km' ] : car?.years


  const compare = () => {
    if (AppHelper.hasPermission(user, COMPARATOR)) {
      let codias = localStorage.comparatorCodias
        ? JSON.parse(localStorage.comparatorCodias)
        : [];
      if (
        codias.length === 0 ||
        codias.some((c) => c.vehicleType == vehicleType)
      ) {
        if (codias.some((c) => c.codia == car.codia)) {
          history.push(`/comparador`);
        } else if (
          codias.length < 2 ||
          (AppHelper.hasPermission(user, COMPARATOR_FOUR_VEHICLES) &&
            codias.length < 4)
        ) {
          codias = [ ...codias, { codia: car.codia, vehicleType } ];
        } else {
          codias[ 0 ] = { codia: car.codia, vehicleType };
        }
      } else {
        codias = [ { codia: car.codia, vehicleType } ];
      }
      localStorage.comparatorCodias = JSON.stringify(codias);
      history.push(`/comparador`);
    } else {
      dispatch(
        actions.app.setLoginMessage(
          "Para esta funcionalidad debes estar registrado"
        )
      );
      dispatch(actions.app.setShowRegister(true));
    }
  };

  return (
    <div className="row result_card p-2">
      <div className="col-12 col-md-6 col-lg-3 p-4 d-flex align-items-center justify-content-center">
        <img
          className="w-100"
          src={
            car.photoUrl
              ? car.photoUrl
              : car.photo_url
                ? car.photo_url
                : vehicleType === "cars"
                  ? coveredCarImg
                  : coveredMotoImg
          }
          alt={car.model.name}
        />
      </div>
      <div className="col-12 col-md-5 d-flex flex-column justify-content-center">
        <h5 className="font-weight-bold mb-1">
          {car.versionDescription ? car.versionDescription : car.description}
        </h5>
        <small className="font-weight-bold">{car.brand.name}</small>
        {/* <RatingBox rating={4.1} /> */}
        <small className="font-weight-bold">Años de comercialización</small>
        <div className="d-flex car_years w-100 flex-wrap">
          {customYears?.map((year, index) => (
            <p key={index} className="mr-3 mb-1">
              {year}
            </p>
          ))}
        </div>
      </div>
      <div className="col col-md-4 d-flex flex-column justify-content-center align-items-center">
        <Button onClick={compare} text={"Comparar"} />
        <Button
          text={"Ver Ficha"}
          dark={true}
          onClick={() =>
            history.push(
              `/ficha/${lastSearch}/${car.versionCode ? car.versionCode : car.codia
              }`
            )
          }
        />
      </div>
    </div>
  );
};

export default withRouter(ResultCard);
