import React from "react";

const TimeUnit = props => {
    const {number, unit} = props;
    return(
        <div className="col-3 col-lg-3 col-xl-3 countdown_time">
            <h1 className="font-weight-bolder text-center">{number}</h1>
            <h5 className="text-center font-weight-normal">{unit}</h5>
        </div>
    )
};

export default TimeUnit;
