import { faBan, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import ScrollHint from 'scroll-hint';
import { ApiHelper } from "../../helpers";
import { InfoProPriceRow, InfoProTableBar } from "../atoms";



export const InfoProTable = ({ versions, quantity, loadMoreVersions }) => {
  const [ feature, setFeature ] = useState("prices");
  const [ years, setYears ] = useState([]);
  const [ pinned, setPinned ] = useState(
    localStorage.pinnedModels ? JSON.parse(localStorage.pinnedModels) : []
  );
  const [ features, setFeatures ] = useState([]);
  const [ viewedModels, setViewedModels ] = useState([]);
  const vehicleType = useSelector((state) => state.search.vehicle);
  const user = useSelector((state) => state.user.user);
  const listInnerRef = useRef()


  useEffect(() => {
    let now = new Date().getFullYear();

    if (vehicleType === 'motorcycles') {
      now--
    }

    let years = [ "0km" ];
    for (let i = now; i >= now - 30; i--) {
      years.push(i);
    }
    getFeatures();
    setYears(years);
  }, [ vehicleType ]);

  useEffect(() => {
    new ScrollHint('.js-scrollable', {
      suggestiveShadow: true,
      i18n: {
        scrollable: 'Desliza para mas información'
      },
    })

  }, [ versions ])

  const handlePin = (model) => {
    let pinnedModel = pinned.find((m) => m.codia === model.codia);

    let pinnedModels;
    if (pinnedModel) {
      pinnedModels = pinned.filter((m) => m.codia !== model.codia);
    } else {
      pinnedModels = [ ...pinned, model ];
    }
    localStorage.pinnedModels = JSON.stringify(pinnedModels);
    setPinned(pinnedModels);
  };

  const handleView = (model) => {
    const { viewedModels } = this.state;
    const viewedModel = viewedModels.find((m) => m.codia === model.codia);
    if (!viewedModel) {
      setViewedModels([ ...viewedModels, model ]);
    }
  };

  const getFeatures = async () => {
    const data = {
      vehicleType,
    };
    const res = await ApiHelper.post("preciosa", `/features`, data);

    setFeatures(res.data);
  };

  const onScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.ceil(scrollTop) + clientHeight >= scrollHeight) {
        await loadMoreVersions();
      }
    }
  };

  return (
    <div className={"row mt-4"}>
      <div className="col-12">
        <InfoProTableBar feature={feature} setFeature={setFeature} />
        {versions.length > 0 && <div className='d-flex w-100 justify-content-end'><p className='mt-2'> *Los precios se encuentran expresados en miles de pesos argentinos.</p></div>}
        <div
          className="infogrid_container"
        >
          <table id={"infopro_table"} className={`${versions.length > 0 ? 'js-scrollable' : ''} mt-4 w-100 inner_shadow testCol`}
            ref={listInnerRef}
            onScroll={onScroll}
          >
            <thead>
              <th
                className={`table-light`}
              ></th>
              <th
                className={`table-light`}
              ></th>
              {feature === 'prices' && <th
                className={`table-light`}
              ></th>}

              {user.muestraCodia && <th className="" />}

              {feature === "prices" &&
                years.map((year, index) => (
                  <th
                    className={`text-center table-light`}>
                    {year}
                  </th>
                ))}
              {feature !== "prices" &&
                features[ feature ].map((f) => {
                  return (
                    <th className={"text-center"}>
                      {" "}
                      <ReactTooltip effect={"solid"} />{" "}
                      <img
                        data-tip={f.description}
                        className={"mb-1"}
                        src={`/assets/icons/${f.description}.svg`}
                        style={{ width: 30 }}
                      />
                    </th>
                  )
                })}
            </thead>
            <tbody>
              {pinned.map((model, index) => (
                <InfoProPriceRow
                  last={index === pinned.length - 1}
                  feature={feature}
                  index={index}
                  features={features}
                  viewed={!!viewedModels.find((m) => m.codia === model.codia)}
                  // handleView={handleView}
                  icon={faBan}
                  handlePin={handlePin}
                  key={model.codia}
                  model={model}
                  years={years}
                />
              ))}
              {versions.map((model, index) => {
                if (!pinned.find((m) => m.codia === model.codia) && (index < quantity || quantity === 'Todo')) {
                  return (
                    <InfoProPriceRow
                      features={features}
                      feature={feature}
                      viewed={
                        !!viewedModels.find((m) => m.codia === model.versionCode)
                      }
                      handleView={handleView}
                      icon={faThumbtack}
                      handlePin={handlePin}
                      key={model.versionCode}
                      model={model}
                      years={years}
                    />
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
