import React from 'react';
import { useDispatch } from "react-redux";
import actions from "../../../store/actions";

const Access = () => {

    const dispatch = useDispatch();

    return (
        <div className={'d-lg-flex align-items-center btn'}>
            <p className="text-uppercase text-white mb-0 link" onClick={() => dispatch(actions.app.setShowLogin(true))}><small>Acceder / Registrarse</small></p>
            
        </div>
    )
};

export default Access;
