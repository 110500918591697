import React, { useState, useEffect } from "react";

import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Logo, Select, SquareButton } from "components/atoms";
import { useForm } from "react-hook-form";
import { ApiHelper } from "helpers";
import barrios from "constants/barrios";
import Subscriptions from "./Subscriptions";
export default function UserPlans(props) {
  return (
    <div className="col-12 d-flex flex-column align-items-center">
      <div className="container text-center bg-white p-5">
        <h5 className="font-weight-bold mb-4">
          ¡Bienvenido! ya sos parte de <Logo />
        </h5>
        <p className="mb-0">¿ A qué plan deseas sumarte?</p>

        <Subscriptions {...props} />
      </div>
    </div>
  );
}
