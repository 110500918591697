import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../store/actions'

export const VehicleTypeSelector = ({ className }) => {
	const vehicle = useSelector((state) => state.search.vehicle)
	const [types] = useState([
		{
			value: 'cars',
			label: 'Auto',
		},
		{
			value: 'motorcycles',
			label: 'Moto',
		},
	])
	const dispatch = useDispatch()

	const setVehicle = (type) => {
		dispatch(actions.search.setVehicleType(type))
	}

	return (
		<div className={`w-100 d-flex vehicle_type_selector pl-0 default_shadow link ${className}`}>
			{types.map((type) => (
				<div
					key={type.value}
					onClick={() => setVehicle(type.value)}
					className={`w-100 text-center py-2 text-uppercase font-weight-bolder ${
						vehicle === type.value ? 'bg_dark' : ''
					}`}
				>
					<span>{type.label}</span>
				</div>
			))}
		</div>
	)
}
