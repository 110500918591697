import React from 'react'
import comparadorImg1 from '../../static/img/comparador1.png'
import comparadorImg2 from '../../static/img/comparador2.png'
import comparadorImg3 from '../../static/img/comparador3.png'

export default () => {
	return (
		<section className={'p-0 p-md-3 background_gray container'}>
			<div className='container p-3'>
				<h2 className='font-weight-bold text-center'>Sabemos que no solo necesitás precios</h2>
				<p className='text-center'>
					Por eso desarrollamos nuevas funcionalidades para que accedas a un servicio integral.
				</p>
				<div className='row mt-5 no-gutters'>
					<div className='col-12 col-md-4 px-4 mb-4'>
						<h6 className='font-weight-bold text-center mb-4'>
							Compará hasta <br /> 4 modelos a la vez
						</h6>
						<img alt={'comparador-1'} src={comparadorImg1} className={'w-100 default_shadow'} />
						<p className={'text-center my-4'}>COMPARADOR</p>
					</div>
					<div className='col-12 col-md-4 px-4 text-center mb-4'>
						<h6 className='font-weight-bold text-center mb-4'>
							Armá tu lista <br /> de favoritos
						</h6>
						<img alt={'comparador-1'} src={comparadorImg2} className={'w-75 default_shadow'} />
						<p className={'text-center my-4'}>LISTA DE FAVORITOS</p>
					</div>
					<div className='col-12 col-md-4 px-4 mb-4'>
						<h6 className='font-weight-bold text-center mb-4'>
							Utilizá el buscador <br /> que te resulte más fácil
						</h6>
						<img alt={'comparador-1'} src={comparadorImg3} className={'w-100 default_shadow'} />
						<p className={'text-center  my-4'}>BÚSQUEDA ASISTIDA</p>
					</div>
				</div>
				{/* <div className='row d-none d-md-flex'>
					<div className='col-md-4'>
						<p className={'text-center mt-4'}>COMPARADOR</p>
					</div>
					<div className='col-md-4'>
						<p className={'text-center mt-4'}>LISTA DE FAVORITOS</p>
					</div>
					<div className='col-md-4'>
						<p className={'text-center mt-4'}>BÚSQUEDA ASISTIDA</p>
					</div>
				</div> */}
			</div>
		</section>
	)
}
