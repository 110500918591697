import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { AppHelper } from "helpers";
import { COMPARATOR_FOUR_VEHICLES } from "static/permissions/sections";

export default ({ vehicles, features, title, value }) => {
  const user = useSelector((state) => state?.user?.user);
  const [ showData, setShowData ] = useState(false);
  const [ parsedFeatures, setParsedFeatures ] = useState([]);


  useEffect(() => {
    setParsedFeatures(
      features?.map((f) => {
        if (f?.description === "Start/Stop") {
          f.icon = "StartStop";
        } else if (f?.description === "Aceleración 0-100 km/h (segundos)") {
          f.icon = "Aceleración";
        } else if (f?.description === "Relación peso / potencia (Kg/HP)") {
          f.icon = "Relación peso-potencia";
        } else if (f?.description === "Tipo de vehiculo") {
          f.icon = "Tipo de vehículo";
        } else {
          f.icon = f.description;
        }
        return f
      })
    );

  }, [ features ]);

  return (
    <div className={"row border_top_black "}>
      <div className="col-12">
        <div className="row">
          <div className="col-2 d-flex flex-column align-items-start justify-content-center pt-3">
            <p className="th-comparator">
              <strong>{title}</strong>{" "}
              <FontAwesomeIcon
                onClick={() => setShowData(!showData)}
                icon={showData ? faChevronUp : faChevronDown}
              />
            </p>
          </div>
          <div className="col-10">
            <div className="row">
              <div
                className={`${AppHelper.hasPermission(user, COMPARATOR_FOUR_VEHICLES)
                  ? "col-3"
                  : "col-6"
                  } pt-3`}
              >
                <p className="v-hidden">asd</p>
              </div>
              <div
                className={`${AppHelper.hasPermission(user, COMPARATOR_FOUR_VEHICLES)
                  ? "col-3"
                  : "col-6"
                  } pt-3 ${vehicles.length > 1 ? "background_gray" : ""}`}
              >
                <p></p>
              </div>
              {AppHelper.hasPermission(user, COMPARATOR_FOUR_VEHICLES) && (
                <>
                  <div className="col-3 pt-3">
                    <p className=""></p>
                  </div>
                  <div
                    className={`col-3 ${vehicles.length > 3 ? "background_gray" : ""
                      } pt-3`}
                  >
                    <p></p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-10" />
      {showData &&
        parsedFeatures?.map((f, index) => {
          return (
            <div key={index} className="col-12">
              <div className="row">
                <div className="col-2 d-flex h-100">
                  <div className={"my-2 d-flex align-items-center"}>
                    <img
                      data-tip={f?.description}
                      src={`/assets/icons/${f?.icon}.svg`}
                      style={{ width: 30 }}
                    />
                    <small className={"ml-1"}>{f?.description}</small>
                  </div>
                </div>
                <div className="col-10">
                  <div className="row h-100">
                    {vehicles?.map((vehicle, index) => {
                      let v = vehicle[ value ]?.find(
                        (ef) => ef?.description === f?.description
                      );
                      if (v?.type === "boolean") {
                        v.value =
                          v?.value === "false"
                            ? "NO"
                            : v?.value === "true"
                              ? "SI"
                              : v?.value;
                      }
                      return (
                        <div
                          className={`col-${AppHelper.hasPermission(
                            user,
                            COMPARATOR_FOUR_VEHICLES
                          )
                            ? "3"
                            : "6"
                            } h-100 d-flex align-items-center px-5 d-flex justify-content-center align-items-center ${index % 2 === 1 ? "background_gray" : ""
                            }`}
                        >
                          <small key={index} className={"text-center"}>
                            <span data-tip={v?.value_description}>
                              {v
                                ? v?.type === "choice"
                                  ? v?.value_description
                                  : v?.value
                                : "-"}
                            </span>
                          </small>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
