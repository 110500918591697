import React from "react";
import { SectionSeparator } from "../../atoms";
import img from "../../../static/img/sheet.png";

export default () => {
  return (
    <div className={"pt-5"}>
      <div className="container p-5 mb-5">
        <h2 className="text-center font-weight-bold">
          Fichas técnicas confiables y fáciles de interpretar
        </h2>
        <p className={"text-center mt-3"}>
          El 69% de las personas consideran que las fichas técnicas son
          complejas y con mucha información especializada.
        </p>
        <div className="px-md-5">
          <img className={"w-100 mt-4 w-100"} alt={"comparador"} src={img} />
        </div>
        <p className="text-center text-uppercase mt-4">Ficha Técnica Visual</p>
      </div>
      <SectionSeparator right={true} />
    </div>
  );
};
