import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ApiHelper } from '../../helpers'
import actions from '../../store/actions'
import { ProgressBar, SquareButton } from '../atoms'
import { RegisterAddress, RegisterBrands, RegisterInterests, RegisterSelectType, RegisterVehicles } from '../molecules'

export const RegisterDataModal = () => {
	const history = useHistory()
	const [step, setStep] = useState(0)
	const [error, setError] = useState(false)

	const [interests, setInterests] = useState([])
	const [vehicles, setVehicles] = useState([])
	const [brands, setBrands] = useState([])
	const [location, setLocation] = useState({})
	const [name, setName] = useState()
	const [lastName, setLastName] = useState()
	const [company, setCompany] = useState()
	const [buttonEnabled, setButtonEnabled] = useState(false)
	const registerCompleted = useSelector((state) => state.user.registerCompleted)
	const user = useSelector((state) => state.user.user)
	const dispatch = useDispatch()
	const [userType, setUserType] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const [buttonDisabled, setButtonDisabled] = useState(true)

	useEffect(() => {
		if (user) {
			getUserData()
		}
	}, [user])

	useEffect(() => {
		switch (step) {
			case 0:
				return setButtonDisabled(!userType)
			case 1:
				return setButtonDisabled(interests.length === 0)
			case 2:
				return setButtonDisabled(vehicles.length === 0)
			case 3:
				return setButtonDisabled(brands.length === 0)
			case 4:
				const { phone, phonePrefix, province, locality, zipCode } = location

				if (userType === 'professional') {
					if (!name || !lastName || !phone || !phonePrefix || !province || !locality) {
						setButtonDisabled(true)
					} else {
						setButtonDisabled(false)
					}
				} else {
					if (!name || !lastName || !phone || !phonePrefix || !province || !locality) {
						setButtonDisabled(true)
					} else {
						setButtonDisabled(false)
					}
				}
				break
			default:
				return true
		}
	}, [step, userType, interests, vehicles, brands, name, lastName, location, company])

	const getUserData = async () => {
		try {
			const res = await ApiHelper.get('admin', '/user')
			localStorage.user = JSON.stringify(res.data)
			if (user && user.type === 'professional' && !user.firstName) {
				// setShowModal(true);
				setUserType('professional')
				setStep(1)
			}
		} catch (error) {
			if (error.status === 412) {
				// setShowModal(true);
			}
		}
	}

	const logout = () => {
		localStorage.clear()
		window.location.href = window.location.origin
	}

	const handleContinue = async () => {
		setError(null)

		if (!buttonDisabled) {
			switch (step) {
				case 0:
					if (userType) {
						setStep(1)
					} else {
						setError('Seleccioná un tipo de usuario')
					}
					return
				case 1:
					if (interests.length > 0) {
						setStep(2)
					} else {
						setError('Seleccioná al menos una opción')
					}
					return
				case 2:
					if (vehicles.length > 0) {
						setStep(3)
					} else {
						setError('Seleccioná al menos una opción')
					}
					return
				case 3:
					if (brands.length > 0) {
						setStep(4)
					} else {
						setError('Seleccioná al menos una opción')
					}
					return
				case 4:
					try {
						if (location.province && location.locality && location.phonePrefix && location.phone && name && lastName) {
							const data = {
								type: userType,
								activity: [],
								areasOfInterest: interests,
								vehiclesOfInterest: vehicles,
								brandsOfInterest: brands.map((brand) => brand.name),
								phoneNumber: location.phone,
								prefix: location.phonePrefix,
								province: location.province.name,
								locality: location.locality.name,
								zipCode: location.zipCode,
								firstName: name,
								lastName: lastName,
								company: company,
							}

							user.activeSubscription?.subscriptionType.type === 'fleet'
								? await ApiHelper.patch('admin', '/user', data)
								: await ApiHelper.post('admin', '/user', data)

							const res = await ApiHelper.get('admin', '/user')

							if (window.gtag) {
								window.gtag('event', 'sign_up', { profile_complete: true, email: res.data.email })
							}

							dispatch(actions.user.fillUserData(res.data))
							setShowModal(false)

							history.push('/')
						} else {
							setError('Complete todos los datos')
						}
					} catch (e) {
						console.log(e)
					}
					break
				default:
					return
			}
		}
	}

	const handleBack = () => {
		setError(null)

		switch (step) {
			case 1:
				setInterests([])
				setStep(0)
				return

			case 2:
				setVehicles([])
				setStep(1)
				return

			case 3:
				setBrands([])
				setStep(2)
				return
			case 4:
				setBrands([])
				setStep(3)
				return
			default:
				return
		}
	}

	return (
		user && (
			<Modal show={showModal} size={'lg'} className={'default_modal'}>
				<Modal.Body>
					<div className={'text-right pt-3 pr-3 link'}>
						<FontAwesomeIcon onClick={logout} icon={faTimes} />
					</div>
					<div className='row p-md-5 d-flex justify-content-center'>
						{step === 0 && <RegisterSelectType userType={userType} setUserType={setUserType} />}
						{step === 1 && (
							<RegisterInterests
								handleBack={handleBack}
								userType={userType}
								interests={interests}
								setInterests={setInterests}
							/>
						)}
						{step === 2 && (
							<RegisterVehicles
								handleBack={handleBack}
								userType={userType}
								vehicles={vehicles}
								setVehicles={setVehicles}
							/>
						)}
						{step === 3 && (
							<RegisterBrands handleBack={handleBack} brands={brands} setBrands={setBrands} vehicles={vehicles} />
						)}
						{step === 4 && (
							<RegisterAddress
								handleBack={handleBack}
								userType={userType}
								location={location}
								setLocation={setLocation}
								name={name}
								lastName={lastName}
								setName={setName}
								setLastName={setLastName}
								company={company}
								setCompany={setCompany}
							/>
						)}
						<ProgressBar progress={((step + 1) / 5) * 100} />
						<div className='mt-5'>
							<SquareButton
								disabled={buttonDisabled}
								onClick={handleContinue}
								text={step < 4 ? 'Continuar' : 'Registrarme'}
							/>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		)
	)
}
