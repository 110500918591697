import React from "react";

export const TimeLineYear = ({ year }) => {
  return (
    <div className="col mt-5 mt-md-0 d-flex justify-content-between tlmobile">
      <div
        className={
          year.year === 2021
            ? "timeline_text current_year py-5 pl-5 pr-3"
            : "timeline_text pr-2 pt-5"
        }
        style={{ marginTop: year.margin }}
      >
        <p className={"text-right font-weight-bold"}>{year.title}</p>
        <p className={"text-right"}>{year.text}</p>
      </div>
      <div
        className={
          "d-flex flex-column align-items-center justify-content-start"
        }
      >
        <span className="year font-weight-bold">{year.year}</span>
        <div className="keydriver">+</div>
        <div className="mt-2 driver_divider" />
      </div>
    </div>
  );
};
