import React from "react";
import { useSelector } from "react-redux";

export const CarPic = (props) => {
  const user = useSelector((state) => state.user.user);

  return (
    <div className={"car_profile_image"}>
      <img src={props.url} className="img-fluid" alt="Imágen del Vehículo" />
      {/*{!user && <h5 className={'font-weight-bold'}><span className={'link'}>Suscribite <FontAwesomeIcon icon={faChevronRight} /></span> para poder visualizar la información completa</h5>}*/}
    </div>
  );
};
