import React from "react";
import { DetailsTable, InfoProCarDetails } from "../molecules";
import { useSelector } from "react-redux";
import { Divider } from "components/atoms";
import { AppHelper } from "helpers";
import { RAW_DATASHEET, VISUAL_DATASHEET } from "static/permissions/sections";

export const CarDetailsTables = ({ vehicle, setShowModal }) => {
  const user = useSelector((state) => state.user.user);

  const showReport = () => {
    localStorage.reportModelo = vehicle.description;
    localStorage.reportType = "Ficha Técnica";
    setShowModal(true);
  };

  return (
    AppHelper.hasPermission(user, RAW_DATASHEET) && (
      <div id="car_details_tables_container" className="mt-3">
        <Divider />
        <div className="d-flex align-items-center mb-4">
          <h5
            data-tip="Consulta la valuación histórica de este modelo  por mes y año de publicación"
            className="font-weight-bolder mb-0"
          >
            Ficha Técnica
          </h5>
          <small className="chart_contact text-right">
            Tenés algo que informarnos?{" "}
            <strong className={"link"} onClick={showReport}>
              Reportar
            </strong>
          </small>
        </div>
        {vehicle.vehicleType === "cars" &&
          AppHelper.hasPermission(user, VISUAL_DATASHEET) && (
            <div className="row">
              <div className="col-12">
                <InfoProCarDetails vehicle={vehicle} />
              </div>
            </div>
          )}
        <div className="row">
          <DetailsTable
            data={vehicle?.specificationFeatures}
            title="Especificaciones"
          />
          <DetailsTable
            data={vehicle?.motorTransmitionFeatures}
            title="Motor y Transmisión"
          />
          {vehicle.securityFeatures.length > 0 && (
            <DetailsTable data={vehicle.securityFeatures} title="Seguridad" />
          )}
          {vehicle.comfortFeatures.length > 0 && (
            <DetailsTable data={vehicle.comfortFeatures} title="Confort" />
          )}
        </div>
      </div>
    )
  );
};
