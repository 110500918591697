import React, { useState, useEffect } from "react";
import { Logo } from "./Logo";
import { InfoCreditsModal } from "../organisms/InfoCreditsModal";
import { AppHelper } from '../../helpers'
import { BUY_INFOCREDITS } from "static/permissions/sections";

export const PlanSectionCard = ({ type, title, subscription, user }) => {
  const [showInfoCreditsModal, setShowInfoCreditsModal] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href)
    const openPaymentModal = url.searchParams.get('openpaymentmodal')
    
    if (openPaymentModal === 'true' && AppHelper.hasPermission(user, BUY_INFOCREDITS)) {
      setShowInfoCreditsModal(true)
    }
  }, [])



  return (
    <div className="col-6 plan_section_card h-100 d-flex flex-column">
      <p className={"title mb-0"}>{title}</p>
      {type === "plan" && (
        <div className="d-flex p-3 flex-column body bg-white pb-4 profile-plan">
          <h2 className={"font-weight-bold"}>
            {subscription
              ? subscription.name === "Free"
                ? "Registrado sin cargo"
                : "Plan " + subscription.name
              : "Gratis"}
          </h2>
          {subscription && subscription.subscriptionType.type != "fleet" && (
            <div>
              <p className={"font-weight-bold mb-1"}>
                ${subscription.price.toFixed(2).replace(".", ",")}
                .-
              </p>
              <p className={"mb-4"}>
                /{subscription.type === "anual" ? "año" : "mes"}
              </p>
            </div>
          )}
        </div>
      )}
      {type === "gestion" && (
        <div className="d-flex px-3 pt-3 pb-0 flex-column body bg_green profile-plan">
          <h2 className={"font-weight-bold mb-0"}>
            <span className={"text-white"}>Agregar</span> <br /> Info <br />{" "}
            Créditos
          </h2>
          <h1
            className={
              "text-right font-weight-light w-100 text-white mb-0 display-4"
            }
            onClick={() => setShowInfoCreditsModal(true)}
            style={{
              cursor: "pointer",
            }}
          >
            +
          </h1>
        </div>
      )}
      <InfoCreditsModal
        show={showInfoCreditsModal}
        hide={() => setShowInfoCreditsModal(false)}
      />
    </div>
  );
};
