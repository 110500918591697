import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { ApiHelper } from "helpers";
import { auth } from "helpers/Firebase";
import actions from "store/actions";
import { SquareButton } from "../atoms";
import Loader from "react-loader-spinner";
import { localStorage } from "globalthis/implementation";

export const RecoverModal = () => {
  const [ email, setEmail ] = useState("");
  const [ username, setUsername ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ errors, setErrors ] = useState({
    email: null,
    otpNotValid: null,
  });
  const dispatch = useDispatch();
  const [ success, setSuccess ] = useState(false);
  const [ otp, setOtp ] = useState();
  const [ step, setStep ] = useState(1);
  const [ password1, setPassword1 ] = useState("");
  const [ password2, setPassword2 ] = useState("");
  const [ accountRecovered, setAccountRecovered ] = useState(false);
  const [ showRegisterModal, setShowRegisterModal ] = useState(false);
  const [ codeSent, setCodeSent ] = useState(false);
  const [ isSendingCode, setIsSendingCode ] = useState(false);
  const show = useSelector((state) => state.app.showRecover);

  const hide = () => dispatch(actions.app.setShowRecover(false));

  const recover = async (e) => {
    e.preventDefault();
    setError(false);


    if (username) {
      try {

        setLoading(true)

        if (window.grecaptcha) {
          window.grecaptcha.ready(function () {
            window.grecaptcha
              .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY,
                { action: 'submit' }).then(async function (token) {
                  localStorage.reCaptcha = token
                  const res = await ApiHelper.get(
                    "admin",
                    `/user/recover?username=${username}`
                  );

                  if (res.status === 204) {
                    setError("Usuario inexistente");
                  }
                  const resEmail = await ApiHelper.post(
                    "admin",
                    `/user/username/${username}`
                  )

                  await ApiHelper.sendOTP(username)
                  setEmail(resEmail.data)
                  setStep(2)
                  setLoading(false)
                }, err => {
                  setLoading(false)
                })
          })
        }
      } catch (error) {
        console.log(error)
        setLoading(false);
        setIsSendingCode(false)
        setError(
          "El email ingresado es incorrecto")
      }
    } else if (!username) {
      setError("Debes ingresar un usuario");
      setLoading(false);
      return;
    }



  };

  const recoverAccount = async (e) => {
    e.preventDefault();
    setError(false);
    setCodeSent(false);
    setLoading(true)
    if (
      username &&
      password1 &&
      password1.length >= 6 &&
      password1 === password2 &&
      otp?.length === 4
    ) {
      setLoading(true)

      try {

        setIsSendingCode(true)

        setLoading(true);

        const data = {
          otp: otp,
          username: username,
          password: password1,
        };


        if (window.grecaptcha) {
          window.grecaptcha.ready(async function () {
            const tokenCaptcha = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' })
            localStorage.reCaptcha = tokenCaptcha
            const res = await ApiHelper.post("admin", "/user/otp", data);

            const userEmail = res.data;
            setSuccess("La cuenta ha sido recuperada con éxito");
            await auth.signInWithEmailAndPassword(userEmail, password1);

            const token = await auth.currentUser.getIdToken(true);

            let userData = new FormData();
            userData.append("grant_type", "firebase");
            userData.append("firebase_token_id", token);
            const res2 = await ApiHelper.post("auth", "/oauth/token", userData);

            localStorage.token = res2.data.access_token;
            localStorage.user = JSON.stringify({ email: userEmail });
            dispatch(actions.user.setUser({ email: userEmail }));
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            setAccountRecovered(true);
            setLoading(false);
            setIsSendingCode(false);

          })
        }
      } catch (error) {
        setError('Error, ingrese un codigo valido')
        setLoading(false)
        setIsSendingCode(false)
        console.log(error)
      }
    } else {
      setLoading(false)
      if (password1.length < 6) {
        setError("La contraseña debe tener al menos 6 caracteres");
        return;
      } else if (password1 !== password2) {
        setError("Las contraseñas deben coincidir");
        setLoading(false);
        return;
      } else if (otp?.length < 4 || !otp) {
        setError("Ingrese un código válido")
      }
    }

  };

  const sendCode = async () => {

    setError(false);
    try {
      setIsSendingCode(true);
      if (window.grecaptcha) {
        window.grecaptcha.ready(function () {
          window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
              action: "submit",
            }).then(async function (tokenCaptcha) {
              localStorage.reCaptcha = tokenCaptcha;
              await ApiHelper.sendOTP(username);
              setCodeSent(true);
            })
        })
      }
      setError(false)
    } catch (err) {
      console.log(err)
      setError("Hubo un error al enviar el código");
    } finally {
      setIsSendingCode(false);
    }
  };

  const showLogin = () => {
    dispatch(actions.app.setShowLogin(true));
    dispatch(actions.app.setShowRegister(false));
    dispatch(actions.app.setShowReset(false));
  };

  return (
    <>
      <Modal
        show={show}
        onHide={hide}
        size={"sm"}
        className={"default_modal"}
        id={"login_modal"}
      >
        <Modal.Body className={"p-0"}>
          <div className="row">
            <div className="col-12 p-5 d-flex flex-column align-items-center">
              <h5 className="font-weight-bold mb-4 text-center">
                Recuperá tu cuenta
              </h5>
              {step === 1 && (
                <>
                  <p className={"mt-2 crossed"}>
                    <span>Ingresá el email de la cuenta</span>
                  </p>
                  <form onSubmit={recover}>
                    <input
                      name={"email"}
                      value={username}
                      onChange={(event) => setUsername(event.target.value)}
                      className={
                        "mt-2 custom_input default-shadow p-2 w-100 mb-3"
                      }
                      placeholder={"Email"}
                      type={"email"}
                    />

                    {!success && (
                      <SquareButton
                        loading={loading}
                        type="submit"
                        text={"Enviar"}
                      />
                    )}
                  </form>

                  {error && (
                    <p className={"text-center color_red mt-2"}>{error}</p>
                  )}
                  {success && (
                    <p className={"text-center text-success mt-2"}>{success}</p>
                  )}
                </>
              )}
              {step === 2 && (
                <>
                  <p className={"mt-2 px-2"}>
                    <span>
                      Ingresá el código recibido en la casilla de correo
                    </span>
                    <span>
                      <b> {email}</b>
                    </span>
                  </p>
                  <form onSubmit={recoverAccount}>
                    <div className="p-2 mb-4">
                      <OtpInput
                        value={otp}
                        onChange={(e) => setOtp(e)}
                        numInputs={4}
                        separator={<span>-</span>}
                        isInputNum={true}
                        inputStyle={{
                          width: "2rem",
                          height: "2rem",
                          margin: "0 10px",
                          fontSize: "14px",
                          padding: "5px",
                          borderRadius: 4,
                          border: "1px solid rgba(0,0,0,0.3)",
                        }}
                        focusStyle={{
                          border: "2px solid #5FFFE8",
                        }}
                      />
                    </div>

                    <input
                      name={"password1"}
                      value={password1}
                      onChange={(event) => setPassword1(event.target.value)}
                      className={
                        "mt-2 custom_input default-shadow p-2 w-100 mb-3"
                      }
                      placeholder={"Contraseña"}
                      type={"password"}
                    />
                    <input
                      name={"password2"}
                      value={password2}
                      onChange={(event) => setPassword2(event.target.value)}
                      className={
                        "mt-1 custom_input default-shadow p-2 w-100 mb-3"
                      }
                      placeholder={"Confirmar contraseña"}
                      type={"password"}
                    />

                    {!success && (
                      <div>
                        <SquareButton
                          loading={loading}
                          type="submit"
                          text={"Recuperar"}
                        />
                        {error && !codeSent && (
                          <p
                            onClick={sendCode}
                            className="mt-4 text-center pointer"
                          >
                            Reenviar código
                          </p>
                        )}

                        {codeSent && !error && (
                          <p className="code_sent mt-4">
                            Te hemos enviado un nuevo código a tu casilla de
                            correo
                          </p>
                        )}
                      </div>
                    )}
                  </form>
                  {error && (
                    <p className={"text-center color_red mt-2"}>{error}</p>
                  )}
                  {success && (
                    <p className={"text-center text-success mt-2"}>{success}</p>
                  )}


                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};
