import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Loader, PrimaryNew, SecondaryNew, SquareButton } from '../components/atoms'
import { ApiHelper } from '../helpers'

export const News = withRouter(({ history }) => {
	const [categories, setCategories] = useState([])
	const [filterString, setFilterString] = useState('')
	const [category, setCategory] = useState()
	const [news, setNews] = useState([])
	const [primaryNew, setPrimaryNew] = useState(null)
	const [readyToShow, setReadyToShow] = useState(false)
	const [currentPage, setCurrentPage] = useState()
	const [totalPages, setTotalPages] = useState()
	const [ads, setAds] = useState([])
	const listInnerRef = useRef()
	const [contentToRender, setContentToRender] = useState([])

	useEffect(() => {
		getNewsTypes()
		getAds()
	}, [])

	useEffect(() => {
		getNews()
	}, [category])

	useEffect(() => {
		if (news.length) {
			let renderedAds = []
			let renderedNews = []
			let contentToRender = []
			Array.from(Array([...news, ...ads].length - 1), (e, i) => {
				let row = Math.ceil((i + 1) / 3)
				let position = (i % 3) + 1
				let availableAds = ads.filter((a, i) => i !== 0)
				if (row % 2 === 0) {
					const adPosition = 3 - ((row / 2 - Math.floor(row / 6) * 3) % 3)
					if (adPosition === position) {
						if (availableAds[renderedAds.length]) {
							contentToRender.push(availableAds[renderedAds.length])
							renderedAds.push(availableAds[renderedAds.length])
						}
					} else {
						if (news[renderedNews.length]) {
							contentToRender.push(news[renderedNews.length])
							renderedNews.push(news[renderedNews.length])
						}
					}
				} else {
					if (news[renderedNews.length]) {
						contentToRender.push(news[renderedNews.length])
						renderedNews.push(news[renderedNews.length])
					}
				}
			})
			setContentToRender(contentToRender)
		}
	}, [news, ads])

	const getNewsTypes = async () => {
		try {
			const res = await ApiHelper.get('admin', '/category')
			setCategories(res.data)
		} catch (error) {
			console.log(error)
		}
	}

	const getAds = async () => {
		try {
			const res = await ApiHelper.get('admin', `/advertisements/users?size=BANNER_V_S&section=noticias-cuadrado`)

			setAds(shuffle(res.data))
		} catch (error) {
			console.log(error)
		}
	}

	function shuffle(array) {
		var currentIndex = array.length,
			temporaryValue,
			randomIndex

		while (0 !== currentIndex) {
			randomIndex = Math.floor(Math.random() * currentIndex)
			currentIndex -= 1

			temporaryValue = array[currentIndex]
			array[currentIndex] = array[randomIndex]
			array[randomIndex] = temporaryValue
		}
		return array
	}

	const getNews = async () => {
		const info = {
			pageNumber: 0,
			pageSize: 12,
		}

		if (category) {
			try {
				const { data } = await ApiHelper.getPaginatedNewsByCategory(category, info)

				let primaryNew = null
				primaryNew = data?.pageElements.filter((n) => n.hierarchy === 'Alta')[0]
				const news = data?.pageElements.filter((n) => n.id !== primaryNew?.id)

				setPrimaryNew(primaryNew)
				setNews(news)
				setReadyToShow(true)
				return news
			} catch (error) {
				console.log(error)
			}
		} else {
			try {
				const { data } = await ApiHelper.getPaginatedNews(info)

				let primaryNew = null
				primaryNew = data.pageElements.filter((n) => n.hierarchy === 'Alta')[0]
				const news = data.pageElements.filter((n) => n.id !== primaryNew.id)

				setTotalPages(data.pages)
				setCurrentPage(data.pageNumber + 1)
				setPrimaryNew(primaryNew)
				setNews(news)
				setReadyToShow(true)
				return news
			} catch (error) {
				console.log(error)
			}
		}
	}

	const onScroll = async () => {
		if (listInnerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
			if (Math.ceil(scrollTop) + clientHeight >= scrollHeight) {
				await loadMoreNews()
			}
		}
	}

	const loadMoreNews = async () => {
		if (currentPage < totalPages) {
			const info = {
				pageNumber: currentPage,
				pageSize: 12,
			}
			if (category) {
				try {
					const { data } = await ApiHelper.getPaginatedNewsByCategory(category, info)
					setNews((news) => [...news, ...data?.pageElements])
					setCurrentPage(data?.pageNumber + 1)
				} catch (error) {
					console.log(error)
				}
			} else {
				try {
					const { data } = await ApiHelper.getPaginatedNews(info)
					setNews((news) => [...news, ...data?.pageElements])
					setCurrentPage(data?.pageNumber + 1)
				} catch (err) {
					console.log(err)
				}
			}
		}
	}

	const handleAdClick = async (e, ad) => {
		e.preventDefault()
		try {
			if (window.gtag) {
				window.gtag('event', 'click-publicidad', {
					event_category: 'noticias',
					event_label: ad.link,
					value: ad.link,
				})
			}
			await ApiHelper.patch('admin', `/advertisements/${ad.id}/clicks/`, '1')
			window.open(ad.link, '_blank')
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<Loader>
			<div id='news_section' className='page_default_margin'>
				<div className={'container'}>
					<div className='row mb-3'>
						<div className='col-12 col-md-2 justify-content-between align-items-center'>
							<h2 className='font-weight-bolder'>Noticias</h2>
						</div>
						<div className='col-12 col-lg-10 mb-sm-3 mt-2'>
							<ul className='d-flex flex-wrap newsmobile'>
								{categories.length > 0 && (
									<li
										onClick={() => setCategory(null)}
										className={`${
											!category && 'bg-dark text-white'
										} px-3 py-1 text-uppercase font-weight-bold d-flex align-items-center link rounded-0`}
									>
										Últimas Noticias
									</li>
								)}
								{categories.map((c, index) => {
									return (
										<li
											onClick={() => setCategory(c)}
											className={`${
												category === c ? 'bg-dark text-white' : index > 0 ? 'border-left' : null
											} px-3 py-1 text-uppercase font-weight-bold d-flex align-items-center link rounded-0`}
											key={c.id}
										>
											{c.name}
										</li>
									)
								})}
							</ul>
						</div>

						<input
							id={'news_search_input'}
							className={'w-100 mx-auto px-3'}
							value={filterString ? filterString : ''}
							onChange={(e) => setFilterString(e.target.value)}
							placeholder={'Buscá la noticia que quieras'}
						/>
					</div>

					<div id='news_container' className='px-3' ref={listInnerRef} onScroll={onScroll}>
						{readyToShow && (
							<div className='row'>
								{primaryNew ? (
									!filterString ? (
										<PrimaryNew article={primaryNew} />
									) : primaryNew.title.toLowerCase().includes(filterString.toLocaleLowerCase()) ? (
										<PrimaryNew article={primaryNew} />
									) : null
								) : null}
								{/* {news.length && <SecondaryNew article={news[0]} />} */}
								{ads.length && (
									<div className='col-12 col-md-4 mt-2 px-1 new_background'>
										<a href={ads[0].link} rel='noopener noreferrer' onClick={(e) => handleAdClick(e, ads[0])}>
											<div
												className='secondary_new add_background'
												style={{
													filter: 'none',
													backgroundImage: `url('${ApiHelper.adminRoute}/files/${ads[0].image}')`,
												}}
											/>
										</a>
									</div>
								)}
								{contentToRender.map((c, i) => {
									if (c.bannerImage) {
										return <SecondaryNew key={i} article={c} />
									} else {
										return (
											<div className='col-12 col-md-4 mt-2 px-1 new_background'>
												<a
													className='link'
													href={c.link}
													onClick={(e) => handleAdClick(e, c)}
													rel='noopener noreferrer'
												>
													<div
														className='secondary_new add_background'
														style={{
															filter: 'none',
															backgroundImage: `url('${ApiHelper.adminRoute}/files/${c.image}')`,
														}}
													/>
												</a>
											</div>
										)
									}
								})}
								{/* {Array.from(Array([...news, ...ads].length - 1), (e, i) => {
                  renderContent(i);
                })} */}
								{/* {news.map((n, index) => {
                  if (!filterString) {
                    return (
                      <>
                        {renderAdd(index)}
                        <SecondaryNew key={n.id} article={n} />
                      </>
                    );
                  } else if (
                    filterString &&
                    n.title
                      .toLowerCase()
                      .includes(filterString.toLocaleLowerCase())
                  ) {
                    return <SecondaryNew key={n.id} article={n} />;
                  }
                })} */}
							</div>
						)}
					</div>
					{currentPage < totalPages && (
						<div className='d-flex justify-content-center my-5'>
							<SquareButton onClick={loadMoreNews} text={'Cargar más'} fullWidth={false} />
						</div>
					)}
				</div>
			</div>
		</Loader>
	)
})
