import { AsyncPaginate } from "react-select-async-paginate";
import React from "react";

const PaginatedSelect = ({ value, ...rest }) => {
  return (
    <AsyncPaginate
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "white",
          primary: "black",
        },
      })}
      noOptionsMessage={() => "No hay opciones"}
      loadingMessage={() => "Cargando opciones..."}
      classNamePrefix="custom_select"
      className={`shadow-lg rounded-pill`}
      value={value || ""}
      {...rest}
    />
  );
};

export default PaginatedSelect;
