import React, { useState, useEffect } from "react";
import { PriceSelectPill } from "../atoms/PriceSelectPill";
import { SquareButton } from "../atoms";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";
import logo from "../../static/img/INFO_GRID_WHITE_LOGO.png";
import { ApiHelper } from "../../helpers";

export default ({
  onSubscribe,
  subscriptions,
  activeSubscription,
  canSuscribe,
}) => {
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState("monthly");
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    const selectedSubscription = subscriptions.find(
      (s) =>
        s.subscriptionType.type === "professional" && s.type === selectedPeriod
    );
    setSelectedSubscription(selectedSubscription);
  }, [subscriptions, selectedPeriod]);

  return (
    <div className={"plan_card_container"}>
      {selectedSubscription && (
        <div className={"plan_card default_shadow background_gray"}>
          <div className={"bg_blue infogrid_card_header"}>
            <img src={logo} alt="logo" />
          </div>
          <div className="body p-4 d-flex flex-column align-items-center">
            <div>
              <h4 className={"font-weight-bold color_blue text-center"}>
                Plan Individual
              </h4>
              <p className={"text-center"}>
                Suscripción Standard para que puedas consultar precios ágilmente
              </p>
              <div className={"border-bottom w-100"}>
                <div className={"d-flex w-100 justify-content-center"}>
                  <h3 className={"font-weight-bold"}>$</h3>
                  <h1 className={"font-weight-bold display-4"}>
                    {selectedSubscription.price}
                  </h1>
                </div>
                <div className="d-flex justify-content-center">
                  <p className="font-weight-bold color_blue">PRECIO</p>
                  <PriceSelectPill
                    selectedPeriod={selectedPeriod}
                    setSelectedPeriod={setSelectedPeriod}
                  />
                </div>
              </div>

              <div
                className={
                  "d-flex border-bottom w-100 py-4 flex-column align-items-center justify-content-center"
                }
              >
                <p className={"font-weight-bold"}>Incluye</p>
                <p className="mb-1">Autos & Motos</p>
                <p className="mb-1">Visualización en formato Grilla</p>
                <p className="mb-1">
                  Hasta{" "}
                  <strong>{selectedSubscription.infocreditsPerMonth}</strong>{" "}
                  consultas únicas
                </p>
                <p className="mb-1">Función búsquedas masivas</p>
              </div>
              <div
                className={
                  "d-flex w-100 py-4 flex-column align-items-center justify-content-center"
                }
              >
                <p className={"font-weight-bold"}>Características</p>
                <p className="mb-1">Ficha Técnica Ilimitada</p>
                <p className="mb-1">Descripción Marca / Modelo / Versión</p>
              </div>
            </div>
            {user &&
              user.activeSubscription?.name === "InfoPro Individual" &&
              user.activeSubscription?.type === "monthly" &&
              selectedSubscription.type === "monthly" && (
                <h4 className={"font-weight-bold text-center"}>PLAN ACTUAL</h4>
              )}
            {user &&
              user.activeSubscription?.name === "InfoPro Individual" &&
              user.activeSubscription?.type === "anual" &&
              selectedSubscription.type === "anual" && (
                <h4 className={"font-weight-bold text-center"}>PLAN ACTUAL</h4>
              )}

            {user &&
              user.type === "professional" &&
              canSuscribe &&
              user.activeSubscription.basedFromId !==
                selectedSubscription.id && (
                <div className="w-75">
                  <SquareButton
                    onClick={() => onSubscribe(selectedSubscription)}
                    text={"Suscribite"}
                  />
                </div>
              )}
            {!user && (
              <div className="w-75">
                <SquareButton
                  onClick={() => dispatch(actions.app.setShowRegister(true))}
                  text={"Comenzar"}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
