import React from 'react'

const SearchButtons = ({ onClick, compare }) => {
	return (
		<div className='w-100  d-flex flex-column align-items-center pr-2'>
			<button
				onClick={onClick}
				className='btn btn-dark  btn-sm rounded-pill mb-2 font-weight-bolder w-100'
				type='submit'
			>
				Ver Ficha
			</button>
			<button
				onClick={compare}
				className='btn btn-light btn-sm rounded-pill mb-2 font-weight-bolder w-100'
				type='submit'
			>
				Comparar 
			</button>
		</div>
	)
}

export default SearchButtons
