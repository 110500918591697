import React, { useEffect, useState } from "react";
import AdXL from "../components/ads/AdXL";
import { PageIndex } from "../components/atoms";
import { Preview, Articles, Pdf } from "../components/magazine";
import { ApiHelper } from "../helpers";
export default () => {
  const [magazines, setMagazines] = useState([]);
  const [month, setMonth] = useState();

  useEffect(() => {
    if (magazines[0]) {
      const date = new Date(2020, magazines[0].month - 1);
      const month = date.toLocaleString("es", { month: "long" });
      setMonth(month);
    }
  }, [magazines]);

  useEffect(() => {
    getMagazines();
  }, []);

  const getMagazines = async () => {
    const res = await ApiHelper.get("admin", "/magazine?latest=true");

    setMagazines(res.data.reverse());
  };

  return (
    <div className={"top_margin"}>
      <div className="container">
        <PageIndex />
        <AdXL subsection="-1" />
        <Preview month={month} magazines={magazines} />
        <p className="seo">Solicita tu ejemplar </p>
        <AdXL subsection="-2" />
        <Pdf month={month} magazine={magazines[0]} />
      </div>
    </div>
  );
};
