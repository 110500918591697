import React, { useEffect } from "react";
import Banner from "../components/nosotros/banner/Banner";
import Plan from "../components/nosotros/plan/Plan";
import Companies from "../components/nosotros/companies/Companies";
import Subscription from "../components/nosotros/subscriptions/Subscription";
import {
  Technology,
  PriceTable,
  Comparator,
  MobileApp,
} from "../components/professionals";
import { useSelector } from "react-redux";

export const Professionals = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="professionals_page" className={"mt-5 pt-3 text_normal"}>
      <p className="seo">Descubri nuevas funcionalidades para que accedas a un servicio integral.</p>
      {/* <Banner /> */}
      <Plan />
      <Companies />
      <Technology />
      <PriceTable />
      <Comparator />
      <MobileApp />
      <Subscription pro={true} />
    </div>
  );
};
