import React from 'react';

export class Revista extends React.Component {
    render(){
        return(
            <div className={'container'}>
                <p>Revista</p>
            </div>
        )
    }
}
