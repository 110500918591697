import React, { useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { SquareButton } from '../atoms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export const InfoCreditsAlertModal = ({ show, hide, getPrices }) => {
	const user = useSelector((state) => state.user.user)
	const [hideMessage, setHideMessage] = useState(false)

	const handleClick = () => {
		if (hideMessage) {
			localStorage.hidePriceAlert = true
		}

		if (window.gtag) {
			window.gtag('event', 'infocredito_spent', {
				value: 1,
				email: user?.email ?? '-',
				available: user?.availableInfocredits ?? '-',
				hideMessage: hideMessage,
			})
		}

		getPrices()
		hide()
	}

	return (
		<Modal show={show} onHide={hide} size={'sm'} className={'default_modal'}>
			<Modal.Body className={'p-0 background_gray'}>
				<p className={'text-right mr-3 mt-3 link'}>
					<FontAwesomeIcon onClick={hide} icon={faTimes} />
				</p>
				<div className='row px-3'>
					<div className='col-12 gray_background px-5'>
						<h6 className='font-weight-bold text-center'>
							Estás por consumir <br /> 1 Infocrédito
						</h6>
						<p className={'text-center'}>
							Infocréditos disponibles: <strong>{user?.availableInfocredits}</strong>
						</p>
						<Form.Group className={'w-100 text-center'} controlId={`form_check`}>
							<Form.Check
								type='checkbox'
								checked={hideMessage}
								label={'No mostrar este mensaje otra vez'}
								onChange={() => setHideMessage(!hideMessage)}
							/>
						</Form.Group>
					</div>
					<div className='col-6 p-3'>
						<SquareButton onClick={hide} text={'cancelar'} />
					</div>
					<div className='col-6 p-3'>
						<SquareButton onClick={handleClick} className={'bg_green'} text={'Continuar'} />
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}
