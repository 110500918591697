import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import actions from '../../store/actions'
import { ApiHelper, AppHelper } from '../../helpers'
import { FAVORITES } from 'static/permissions/sections'

export const CarDescription = ({ vehicle, type }) => {
	const user = useSelector((state) => state.user.user)
	const [favorite, setFavorite] = useState(vehicle.isFavouritedByLoggedInUser)
	const dispatch = useDispatch()

	const handleFavoriteClick = async () => {
		const id = vehicle.version.versionCode

		if (favorite) {
			await ApiHelper.delete('admin', `/favorite/${id}`)
			setFavorite(false)
		} else {
			const codia = vehicle.codia
			await ApiHelper.post('admin', `/favorite?vehicleType=${type}`, codia.toString())
			setFavorite(true)
		}
	}

	return (
		<div>
			<div className='row pt-3 pr-3'>
				<div className='col-12'>
					{AppHelper.hasPermission(user, FAVORITES) && (
						<p onClick={handleFavoriteClick} id='like_icon_link' className={' mb-2 text-right link'}>
							<FontAwesomeIcon
								style={{
									color: favorite ? 'black' : '#ccc',
								}}
								id='like_icon'
								icon={faHeart}
							/>
						</p>
					)}
					<h4 className='font-weight-bold mb-2'>{vehicle.version.versionDescription}</h4>
				</div>
			</div>
			<div className='row'>
				<div className='col-xl-12'>
					<p className='font-weight-bold mb-4'>{vehicle.version.brand.name}</p>

					<p>{vehicle.version.versionSummary || vehicle.version.brandSummary}</p>
				</div>
			</div>
		</div>
	)
}
