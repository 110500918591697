import React, { useEffect, useState } from "react";
import { PriceChart, ProfileFavorites } from "../molecules";
import { ApiHelper, AppHelper } from "../../helpers";
import actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { InfoCreditsAlertModal, InfoCreditsModal } from "../organisms";
import { PriceChartOption, SquareButton } from "../atoms";
import {
  COMPARATOR_FOUR_VEHICLES,
  CAR_PRICES,
  MOTORBIKE_PRICES,
  USED_PRICE,
} from "static/permissions/sections";

export default ({ vehicles, prices, setPrices }) => {
  const [showModal, setShowModal] = useState(false);
  const [showInfoCreditsModal, setShowInfoCreditsModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const vehicleType = useSelector((state) => state.search.vehicle);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [newPrices, setNewPrices] = useState([]);

  useEffect(() => {
    getConsultedPrices();
  }, [vehicles]);

  const getConsultedPrices = async () => {
    let vehicleType;
    const codias = [];

    vehicles.map((vehicle) => {
      vehicleType = vehicle?.vehicleType;
      if (
        !prices.some((p) => p.codia === vehicle?.codia) &&
        user.codiasConsultados.some((c) => c.codia === vehicle?.codia)
      ) {
        codias.push(vehicle?.codia);
      }
    });
    let promises = [];
    codias.map((codia) => {
      promises.push(getSinglePrice(codia, vehicleType));
    });
    const responses = await Promise.all(promises);
    setNewPrices((newPrices) => [...newPrices, ...responses]);
  };

  const getSinglePrice = async (codia, vehicleType) => {
    try {
      const res = await ApiHelper.getPrices(codia, { vehicleType });
      const price = {
        credits: res.userCredits,
        codia,
        prices: filterUsedPrice(res.usedPrices),
      };
      if (res.newPrice) {
        price.prices.unshift({
          year: "0km",
          price: res.newPrice.price,
        });
      }
      return price;
    } catch (error) {
      console.log(error);
      if (error.status === 401) {
        if (localStorage.hidePriceAlert) {
          localStorage.clear();
          localStorage.hidePriceAlert = true;
        } else {
          localStorage.clear();
        }
        // window.location.href = window.location.origin;
        window.location.replace("/?showloginmodal=true&showsession=true");
      }
    }
  };

  const filterUsedPrice = (prices) => {
    let filteredPrices = prices;
    if (!AppHelper.hasPermission(user, USED_PRICE)) {
      const year = new Date().getFullYear();

      filteredPrices = prices.filter((price) => price.year !== year);
    }

    return filteredPrices;
  };

  const pricesClick = async (vehicle) => {
    const { codia, vehicleType } = vehicle;
    const res = await getSinglePrice(codia, vehicleType);
    setNewPrices((newPrices) => [...newPrices, res]);

    const modifiedUser = {
      ...user,
      availableInfocredits: res.credits,
      codiasConsultados: [
        ...user.codiasConsultados,
        { codia, vehicleType, dateConsulted: new Date().toISOString() },
      ],
    };

    dispatch(actions.user.fillUserData(modifiedUser));
    localStorage.user = JSON.stringify(modifiedUser);
  };

  const handleClick = async (vehicle) => {
    const { vehicleType } = vehicle;

    if (user?.availableInfocredits === 0) {
      setShowInfoCreditsModal(true);
      
      return
    } 

    if (user?.infoCreditsActive === false) {
      dispatch(actions.app.setShowSubscriptionDisabled(true));
      return;
    }
    if (
      vehicleType === "motorcycles" &&
      !AppHelper.hasPermission(user, MOTORBIKE_PRICES)
    ) {
      dispatch(actions.app.setShowWrongPlanModal(true));
      return;
    } else if (
      vehicleType === "cars" &&
      !AppHelper.hasPermission(user, CAR_PRICES)
    ) {
      dispatch(actions.app.setShowWrongPlanModal(true));
      return;
    }
    setSelectedVehicle(vehicle);

    if (localStorage.hidePriceAlert) {
      pricesClick(vehicle);
    } else {
      setShowModal(true);
    }
  };

  return (
    <div>
      <div className={"row border_top_black"}>
        <div className="col-2 pt-3">
          <p className="th-comparator">
            <strong>Precios</strong>
          </p>
        </div>
        <div className="col-10">
          <div className="row">
            {vehicles.map((vehicle, index) => {
              const price = newPrices.find((p) => p.codia === vehicle.codia);
              return (
                <div
                  key={vehicle?.codia}
                  className={`pt-2 pb-2 col-${
                    AppHelper.hasPermission(user, COMPARATOR_FOUR_VEHICLES)
                      ? "3"
                      : "6"
                  } ${index % 2 === 1 ? "background_gray" : ""}`}
                >
                  {price ? (
                    <div className={"overlay_container"}>
                      <div className="d-flex justify-content-between">
                        <small className="font-weight-bold">Fabricacíon</small>
                        <small className="font-weight-bold mr-2">Pesos</small>
                      </div>
                      <ul className="p-0 mt-3 price_chart_table">
                        {price.prices.map((price, index) => (
                          <PriceChartOption
                            hideDollar={true}
                            small={true}
                            key={index}
                            price={price}
                          />
                        ))}
                      </ul>
                      <div className={"scroll_overlay"} />
                    </div>
                  ) : (
                    <div className="d-flex flex-column justify-content-end">
                      {vehicle?.list_price && (
                        <div className="d-flex justify-content-between">
                          <p>0km</p>

                          <p>
                            ${" "}
                            {new Intl.NumberFormat("es").format(
                              vehicle.list_price * 1000
                            )}
                          </p>
                        </div>
                      )}

                      <SquareButton
                        size={"sm"}
                        className={"w-25"}
                        onClick={() => handleClick(vehicle)}
                        text={"Mostrar Precios"}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <InfoCreditsModal
          show={showInfoCreditsModal}
          hide={() => setShowInfoCreditsModal(false)}
        />
        <InfoCreditsAlertModal
          show={showModal}
          hide={() => setShowModal(false)}
          getPrices={() => pricesClick(selectedVehicle)}
        />
      </div>
      {prices.length > 0 && (
        <div className={"row border_top_black"}>
          <div className="col-2 pt-3">
            <p className="th-comparator">
              <strong>Tendencia</strong>
            </p>
          </div>
          <div className="col-10">
            <div className="row">
              {vehicles.map((vehicle, index) => {
                const price = prices.find(
                  (p) => p.codia === vehicle.version.versionCode
                );

                return (
                  <div
                    className={`pt-3 ${
                      AppHelper.hasPermission(user, COMPARATOR_FOUR_VEHICLES)
                        ? "col-3"
                        : "col-6"
                    } ${index % 2 === 1 ? "background_gray" : ""}`}
                  >
                    <p className="v-hidden">asd</p>
                    {price && (
                      <PriceChart
                        small={true}
                        hidePrices={true}
                        prices={price.prices}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
