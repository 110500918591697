import React, { useState, useEffect } from "react";
import { Logo, SectionSeparator } from "../atoms";

export default () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setOpacity(0);
  }, [selectedIndex]);

  useEffect(() => {
    if (!opacity) {
      setTimeout(() => {
        setOpacity(1);
      }, 300);
    }
  }, [opacity]);

  const methods = [
    {
      title: "Tecnología",
      bullets: [
        <p key="tec_1" className={"mb-3 text text-center text-md-left"}>
          <strong>Software:</strong> Metodología de investigación única en
          LATAM: enfocada en abordar la complejidad del mercado automotor.
        </p>,
        <p key="tec_2" className={"mb-2 text text-center text-md-left"}>
          <strong>Big data:</strong> Procesamos y analizamos grandes cantidades
          de datos macro/micro económicas del mercado como: monto de
          transferencias, transacciones de compra-venta, subastas, precios 0km
          de lista oficiales entre otros.
        </p>,
      ],
      lineHeight: 0,
    },
    {
      title: "Experiencia",
      bullets: [
        <p key="exp_1" className={"mb-3 text text-center text-md-left"}>
          <strong>Trayectoria:</strong> Nuestra experiencia a través de los años
          y la búsqueda de una constante optimización de nuestros procesos y
          métodos de investigación, dan como resultado una base de{" "}
          <strong>información única, objetiva y confiable.</strong>{" "}
        </p>,
        <p key="exp_2" className={"mb-2 text text-center text-md-left"}>
          <strong>Mejora continua:</strong> Nuestro método se fortalece a partir
          del aprendizaje e intercambio continuo que generamos con nuestros
          clientes.
        </p>,
      ],
      lineHeight: "25%",
    },
    {
      title: "Enfoque",
      bullets: [
        <p key="enf_1" className={"mb-3 text text-center text-md-left"}>
          <strong>Análisis cualitativo:</strong> Contamos con un equipo de
          especialistas técnicos abocados a{" "}
          <strong>examinar en tiempo real</strong>, diversos factores que
          afectan las valuaciones del parque automotor.{" "}
        </p>,
        <p key="enf_2" className={"mb-3 text text-center text-md-left"}>
          <strong>Monitoreo constante:</strong> Realizamos ajustes según
          estacionalidad, tendencias del mercado, variables económicas,
          comportamiento de usuarios y evolución del mercado global.
        </p>,
        <p key="enf_3" className={"mb-3 text text-center text-md-left"}>
          <strong>Objetividad:</strong> Nuestro compromiso es realizar un{" "}
          <strong>análisis imparcial</strong>, identificando variables de desvió
          que puedan distorsionar <strong>valuaciones de mercado.</strong>{" "}
        </p>,
      ],
      lineHeight: "50%",
    },
    {
      title: "Innovación",
      bullets: [
        <p key="inn_1" className={"mb-3 text text-center text-md-left"}>
          <strong>Vanguardia:</strong> Nuestro objetivo es estar un paso delante
          de los acontecimientos que puedan ocurrir en el mercado nacional e
          internacional. Por eso, contamos con un equipo periodístico cuya
          misión es investigar y cubrir las últimas novedades y primicias del
          universo automotor. Esta selección de contenidos, nutre y complementa
          el análisis del equipo técnico y nuestro portal de noticias.
        </p>,
        <p key="inn_2" className={"mb-2 text text-center text-md-left"}>
          <strong>Evolución:</strong> A lo largo de más de 25 años de
          trayectoria, hemos afrontado un proceso de optimización constante
          enfocado en la búsqueda permanente de mejora continua de nuestro
          método de investigación y procesos de gestión.
        </p>,
      ],
      lineHeight: "75%",
    },
  ];
  return (
    <section id={"method_section"}>
      <div className="container py-5 my-5">
        <h2 className={"font-weight-bold text-center"}>
          Metodología <Logo />
        </h2>
        <div className="row mt-5">
          <div className="col-12 col-md-5">
            {methods.map((method, index) => (
              <div key={index} className={"row mb-4"}>
                <div className="col-12 col-md-8 d-flex align-items-center">
                  <p
                    onClick={() => setSelectedIndex(index)}
                    className={`link title text-uppercase default_shadow w-100 ${
                      index !== selectedIndex && "gray_background"
                    }`}
                  >
                    {method.title}
                  </p>
                </div>
                <div className="d-none d-md-flex col-md-4 align-items-center pr-0">
                  <div
                    onClick={() => setSelectedIndex(index)}
                    className="plus link"
                  >
                    <span>+</span>
                  </div>
                  <span
                    className={`d-none d-md-block selected_method_line ${
                      index === selectedIndex && "selected"
                    }`}
                  />
                </div>
              </div>
            ))}
            <span
              className={"method_link d-none d-md-block"}
              style={{ height: methods[selectedIndex].lineHeight }}
            />
          </div>
          <div
            className="mt-3 mt-md-0 col-12 col-md-7 bullets"
            style={{ opacity: opacity }}
          >
            {opacity !== 0 &&
              methods[selectedIndex].bullets.map((bullet) => bullet)}
          </div>
        </div>
      </div>
      <SectionSeparator right={true} />
    </section>
  );
};
