import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

export const GoogleButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="btn google_button btn-circle mr-2">
      <FontAwesomeIcon icon={faGoogle} color={"white"} />
    </button>
  );
};
