import React, { useState, useEffect, useLayoutEffect } from "react";
import { RegisterModalHeader } from "../atoms";
import { Select } from "../atoms";
import axios from "axios";
import {ApiHelper} from "../../helpers";

const barrios = [
  {
    name: "Chacarita",
    comuna: 15,
    perimetro: 7724.8529545700003,
    area: 3115707.1062699999,
  },
  {
    name: "Paternal",
    comuna: 15,
    perimetro: 7087.5132953299999,
    area: 2229829.03363,
  },
  {
    name: "Villa Crespo",
    comuna: 15,
    perimetro: 8131.8570753200001,
    area: 3615977.5563400001,
  },
  {
    name: "Almagro",
    comuna: 5,
    perimetro: 8537.9013684900001,
    area: 4050752.2452400001,
  },
  {
    name: "Caballito",
    comuna: 6,
    perimetro: 10990.964471499999,
    area: 6851028.9135699989,
  },
  {
    name: "Villa Santa Rita",
    comuna: 11,
    perimetro: 5757.7523347099996,
    area: 2153690.81329,
  },
  {
    name: "Monte Castro",
    comuna: 10,
    perimetro: 7361.0364175499999,
    area: 2627481.3250799999,
  },
  {
    name: "Villa Real",
    comuna: 10,
    perimetro: 5070.9656524399998,
    area: 1334146.08283,
  },
  {
    name: "Flores",
    comuna: 7,
    perimetro: 15772.439509600001,
    area: 8590784.1312899999,
  },
  {
    name: "Floresta",
    comuna: 10,
    perimetro: 7519.6540918000001,
    area: 2320640.73551,
  },
  {
    name: "Constitución",
    comuna: 1,
    perimetro: 6326.8413747300001,
    area: 2101994.9981300002,
  },
  {
    name: "San Cristobal",
    comuna: 3,
    perimetro: 5987.2264787900003,
    area: 2043711.1216599999,
  },
  {
    name: "Boedo",
    comuna: 5,
    perimetro: 6602.5102659300001,
    area: 2609850.8792099999,
  },
  {
    name: "Velez Sarsfield",
    comuna: 10,
    perimetro: 7045.5783317200003,
    area: 2400518.32602,
  },
  {
    name: "Villa Luro",
    comuna: 10,
    perimetro: 8652.9300312699997,
    area: 2566936.8725100001,
  },
  {
    name: "Parque Patricios",
    comuna: 4,
    perimetro: 7878.4349931099996,
    area: 3743439.99645,
  },
  {
    name: "Mataderos",
    comuna: 9,
    perimetro: 11124.6357921,
    area: 7398178.8403500002,
  },
  {
    name: "Villa Lugano",
    comuna: 8,
    perimetro: 15057.468389400001,
    area: 9296142.3614000008,
  },
  {
    name: "San Telmo",
    comuna: 1,
    perimetro: 5178.0328392800002,
    area: 1232270.1587400001,
  },
  {
    name: "Saavedra",
    comuna: 12,
    perimetro: 11132.139345600001,
    area: 5538676.8328200001,
  },
  {
    name: "Coghlan",
    comuna: 12,
    perimetro: 4627.5631313800004,
    area: 1279951.37256,
  },
  {
    name: "Villa Urquiza",
    comuna: 12,
    perimetro: 10021.459664800001,
    area: 5449058.9228499997,
  },
  {
    name: "Colegiales",
    comuna: 13,
    perimetro: 6374.6190450699996,
    area: 2289774.1042800001,
  },
  {
    name: "Balvanera",
    comuna: 3,
    perimetro: 8375.8218108100009,
    area: 4342280.2680400005,
  },
  {
    name: "Villa Gral. Mitre",
    comuna: 11,
    perimetro: 6290.4405605600004,
    area: 2162275.1153500001,
  },
  {
    name: "Parque Chas",
    comuna: 15,
    perimetro: 4531.2533962799998,
    area: 1385411.5137400001,
  },
  {
    name: "Agronomía",
    comuna: 15,
    perimetro: 6556.1677718700003,
    area: 2122169.3421200002,
  },
  {
    name: "Villa Ortuzar",
    comuna: 15,
    perimetro: 5684.9952111399998,
    area: 1853802.0520299999,
  },
  {
    name: "Barracas",
    comuna: 4,
    perimetro: 12789.7917712,
    area: 7953453.4314299999,
  },
  {
    name: "Parque Avellaneda",
    comuna: 9,
    perimetro: 10165.614027699999,
    area: 4732708.2093099998,
  },
  {
    name: "Parque Chacabuco",
    comuna: 7,
    perimetro: 8406.9143207500001,
    area: 3832116.6992199998,
  },
  {
    name: "Nueva Pompeya",
    comuna: 4,
    perimetro: 11033.5103019,
    area: 4951510.8742699996,
  },
  {
    name: "Palermo",
    comuna: 14,
    perimetro: 22126.531858400002,
    area: 15845871.288000001,
  },
  {
    name: "Villa Riachuelo",
    comuna: 8,
    perimetro: 10596.8267392,
    area: 4232595.9247899996,
  },
  {
    name: "Villa Soldatti",
    comuna: 8,
    perimetro: 12441.695877300001,
    area: 8688163.5582800005,
  },
  {
    name: "Villa Pueyrredon",
    comuna: 12,
    perimetro: 7522.3603824299998,
    area: 3303239.50936,
  },
  {
    name: "Villa Devoto",
    comuna: 11,
    perimetro: 11562.0068,
    area: 6404480.8537100013,
  },
  {
    name: "Liniers",
    comuna: 9,
    perimetro: 9256.9273212900007,
    area: 4374418.7367700003,
  },
  {
    name: "Versalles",
    comuna: 10,
    perimetro: 5650.4662864299999,
    area: 1406833.5958,
  },
  {
    name: "Puerto Madero",
    comuna: 1,
    perimetro: 17725.720821200001,
    area: 5040970.8851399999,
  },
  {
    name: "Monserrat",
    comuna: 1,
    perimetro: 6739.2223110200002,
    area: 2198621.9408900002,
  },
  {
    name: "San Nicolas",
    comuna: 1,
    perimetro: 6548.0847539599999,
    area: 2289007.5825899998,
  },
  {
    name: "Belgrano",
    comuna: 13,
    perimetro: 20609.7753969,
    area: 7999239.81384,
  },
  {
    name: "Recoleta",
    comuna: 2,
    perimetro: 21452.838647799999,
    area: 6317264.5605600001,
  },
  {
    name: "Retiro",
    comuna: 1,
    perimetro: 18837.988687000001,
    area: 4512710.9070300004,
  },
  {
    name: "Nuñez",
    comuna: 13,
    perimetro: 10785.2610814,
    area: 4424199.2650899999,
  },
  {
    name: "Boca",
    comuna: 4,
    perimetro: 20060.003939400001,
    area: 5036240.4544099998,
  },
];

export const RegisterAddress = (props) => {
  const {
    userType,
    handleBack,
    location,
    setLocation,
    name,
    setName,
    lastName,
    setLastName,
    company,
    setCompany,
  } = props;
  const [provinces, setProvinces] = useState([]);
  const [localities, setLocalities] = useState([]);
  const [error, setError] = useState(null);
  const [phone, setPhone] = useState();

  useEffect(() => {
    const { province } = location;
    const getLocalities = async () => {
      let localities = [];
      if (province.label.includes("Ciudad")) {
        localities = barrios;
      } else {
        let {data} = await ApiHelper.getLocalities(province.name);
        localities = data;
      }

      localities.map((localidad) => {
        localidad.label = localidad.name;
         localidad.value = localidad.name;
      });

      localities.sort(function (a, b) {
        let textA = a.label.toUpperCase();
        let textB = b.label.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setLocalities(localities);
    };
    if (province) {
      getLocalities();
    }
  }, [ location.province ]);

  useEffect(() => {
    getProvinces();
  }, []);

  const getProvinces = async () => {
    try {
      let res = await ApiHelper.getProvinces();
      res.data.map((provincia) => {
        provincia.value = provincia.id;
        provincia.label = provincia.name;
      });
      res.data.sort(function (a, b) {
        let textA = a.label.toUpperCase();
        let textB = b.label.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setProvinces(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (locationType, value) => {
    switch (locationType) {
      case "province":
        setLocation({ ...location, province: value });
        return;
      case "locality":
        setLocation({ ...location, locality: value });
        return;
    }
  };

  const handleAlpha = (e) => {
    let value = e.target.value;
    value = value.replace(/[^A-Za-z-\s]*$/, "")

    return value;
  };

  const validatePhone = (value) => {
    setPhone(value);
    if (value.toString().length < 7) {
      setError("Ingrese un número de teléfono válido");
    } else {
      setLocation({ ...location, phone: value });
      setError(null);
    }
  };

  return (
    <div className="col-12 d-flex flex-column align-items-center">
      <RegisterModalHeader onClick={handleBack} />
      <h5 className="font-weight-bold mb-4">
        {userType === "professional"
          ? "Finalización de registro"
          : "¡Gracias por llegar hasta acá!"}
      </h5>
      {userType === "professional" ? (
        <p>5. Necesitamos saber tu zona y un teléfono de contacto.</p>
      ) : (
        <p className={"text-center"}>
          5. Queremos saber tu zona y un teléfono de contacto
          <br />
          ¡No te preocupes, no te vamos a llamar!
        </p>
      )}
      <div className={"container p-5"}>
        <div className="row d-flex justify-content-center">
          <div className="mt-4 col-12 col-md-6 ">
            <span className="font-weight-bold">Nombre*</span>
            <input
              name={"prefix"}
              value={name}
              onChange={(event) => setName(handleAlpha(event))}
              className={"custom_input default-shadow p-2 w-100 mb-3"}
              placeholder={"Nombre"}
              type={"text"}
              maxLength="23"
            />
          </div>
          <div className="mt-4 col-12 col-md-6 ">
            <span className="font-weight-bold">Apellido*</span>
            <input
              name={"prefix"}
              value={lastName}
              onChange={(event) => setLastName(handleAlpha(event))}
              className={"custom_input default-shadow p-2 w-100 mb-3"}
              placeholder={"Apellido"}
              type={"text"}
              maxLength="23"
            />
          </div>
          <div className="col-12 col-md-6">
            <span className="font-weight-bold">Cód. Área*</span>
            <input
              name={"prefix"}
              value={location.phonePrefix}
              onChange={(event) =>
                setLocation({ ...location, phonePrefix: event.target.value })
              }
              className={"custom_input default-shadow p-2 w-100 mb-3"}
              placeholder={"Cód. Área"}
              maxLength={6}
              type={"number"}
            />
          </div>
          <div className="col-12 col-md-6">
            <span className="font-weight-bold">Teléfono*</span>
            <input
              id="phoneInput"
              name={"phone"}
              value={phone}
              onChange={(e) => validatePhone(e.target.value)}
              className={"custom_input default-shadow p-2 w-100 mb-3"}
              placeholder={"Teléfono"}
              type={"number"}
            />
            {error && <p className="color_red text-center">{error}</p>}
          </div>

          {/* {userType === "professional" && (
            <div className="col-12 col-md-6 ">
              <span className="font-weight-bold">Razón Social.</span>
              <input
                name={"prefix"}
                value={company}
                onChange={(event) => setCompany(handleAlpha(event))}
                className={"custom_input default-shadow p-2 w-100 mb-3"}
                placeholder={"Razón Social"}
                type={"text"}
              />
            </div>
          )} */}
          <div className="col-12 col-md-6">
            <span className="font-weight-bold">Provincia*</span>
            <Select
              placeholder={"Seleccione"}
              options={provinces}
              selectedOption={location.province}
              onSelect={(value) => handleSelect("province", value)}
            />
          </div>
          <div className="col-12 col-md-6">
            <span className="font-weight-bold">Localidad*</span>
            <Select
              placeholder={"Seleccione"}
              options={localities}
              selectedOption={location.locality}
              onSelect={(value) => handleSelect("locality", value)}
            />
          </div>
        </div>
      </div>
      <p className={"text-center"}>
        Gracias por formar parte de InfoAuto,{" "}
        <strong>recibirás un email con la confirmación de la cuenta.</strong>{" "}
        <br /> (Por favor, no te olvides de revisar la carpeta de spam)
      </p>
    </div>
  );
};
