export const icons = [
    {
        description: 'AirBag',
        icon: 'airbag'
    },
    {
        description: 'Frenos ABS',
        icon: 'abs'
    },
    {
        description: 'AirBag lateral',
        icon: 'sideAirbag'
    },
    {
        description: 'AirBag cabeza',
        icon: 'headAirbag'
    },
    {
        description: 'AirBag cortina',
        icon: 'curtainAirbag'
    },
    {
        description: 'AirBag rodilla',
        icon: 'kneeAirbag'
    },
    {
        description: 'Fijación ISOFIX',
        icon: 'isofix'
    }
];


