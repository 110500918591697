import React from "react";
import Banner from "../components/nosotros/banner/Banner";
import Plan from "../components/nosotros/plan/Plan";
import Companies from "../components/nosotros/companies/Companies";
import MarketPlace from "../components/nosotros/marketplace/MarketPlace";
import Search from "../components/nosotros/search/Search";
import Compare from "../components/nosotros/compare/Compare";
import Sheet from "../components/nosotros/sheet/Sheet";
import Invitation from "../components/nosotros/invitation/Invitation";
import Subscription from "../components/nosotros/subscriptions/Subscription";

export class Nosotros extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="nosotros_page" className={"mt-5 text_normal"}>
        <p className="seo">Encontra toda la información de tu próximo auto o moto usado o 0km </p>
        <Banner />
        <Plan />
        <Companies />
        <MarketPlace />
        <Search />
        <Compare />
        <Sheet />
        <Invitation />
        <Subscription individuals={true} showMessage={true} pro={false} />
      </div>
    );
  }
}
