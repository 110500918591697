import React, { useState, useEffect } from 'react'

import {
	IoBarChart,
	IoBarChartOutline,
	IoCartOutline,
	IoChatboxEllipsesOutline,
	IoLayers,
	IoLayersOutline,
} from 'react-icons/io5'
import { Form, InputGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Select, SquareButton } from 'components/atoms'
import { useForm } from 'react-hook-form'
import { ApiHelper } from 'helpers'
// import barrios from 'constants/barrios'
import InputField from 'components/atoms/InputField'

export default function UserType({ newUser, setUser, nextStep }) {
	const user = useSelector((state) => state.user.user)
	const [interests, setInterests] = useState([])
	const [provinces, setProvinces] = useState([])
	const [localities, setLocalities] = useState([])
	const {
		handleSubmit,
		register,
		setValue,
		control,
		getValues,
		resetField,
		watch,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			firstName: '',
			lastName: '',
			phonePrefix: '',
			phoneNumber: '',
			province: '',
			locality: '',
		},
	})

	const barrios = [
		{ value: 'Chacarita', label: 'Chacarita' },
		{ value: 'Paternal', label: 'Paternal' },
		{ value: 'Villa Crespo', label: 'Villa Crespo' },
		{ value: 'Almagro', label: 'Almagro' },
		{ value: 'Caballito', label: 'Caballito' },
		{ value: 'Villa Santa Rita', label: 'Villa Santa Rita' },
		{ value: 'Monte Castro', label: 'Monte Castro' },
		{ value: 'Villa Real', label: 'Villa Real' },
		{ value: 'Flores', label: 'Flores' },
		{ value: 'Floresta', label: 'Floresta' },
		{ value: 'Constitución', label: 'Constitución' },
		{ value: 'San Cristobal', label: 'San Cristobal' },
		{ value: 'Boedo', label: 'Boedo' },
		{ value: 'Velez Sarsfield', label: 'Velez Sarsfield' },
		{ value: 'Villa Luro', label: 'Villa Luro' },
		{ value: 'Parque Patricios', label: 'Parque Patricios' },
		{ value: 'Mataderos', label: 'Mataderos' },
		{ value: 'Villa Lugano', label: 'Villa Lugano' },
		{ value: 'San Telmo', label: 'San Telmo' },
		{ value: 'Saavedra', label: 'Saavedra' },
		{ value: 'Coghlan', label: 'Coghlan' },
		{ value: 'Villa Urquiza', label: 'Villa Urquiza' },
		{ value: 'Colegiales', label: 'Colegiales' },
		{ value: 'Balvanera', label: 'Balvanera' },
		{ value: 'Villa Gral. Mitre', label: 'Villa Gral. Mitre' },
		{ value: 'Parque Chas', label: 'Parque Chas' },
		{ value: 'Agronomía', label: 'Agronomía' },
		{ value: 'Villa Ortuzar', label: 'Villa Ortuzar' },
		{ value: 'Barracas', label: 'Barracas' },
		{ value: 'Parque Avellaneda', label: 'Parque Avellaneda' },
		{ value: 'Parque Chacabuco', label: 'Parque Chacabuco' },
		{ value: 'Nueva Pompeya', label: 'Nueva Pompeya' },
		{ value: 'Palermo', label: 'Palermo' },
		{ value: 'Villa Riachuelo', label: 'Villa Riachuelo' },
		{ value: 'Villa Soldatti', label: 'Villa Soldatti' },
		{ value: 'Villa Pueyrredon', label: 'Villa Pueyrredon' },
		{ value: 'Villa Devoto', label: 'Villa Devoto' },
		{ value: 'Liniers', label: 'Liniers' },
		{ value: 'Versalles', label: 'Versalles' },
		{ value: 'Puerto Madero', label: 'Puerto Madero' },
		{ value: 'Monserrat', label: 'Monserrat' },
		{ value: 'San Nicolas', label: 'San Nicolas' },
		{ value: 'Belgrano', label: 'Belgrano' },
		{ value: 'Recoleta', label: 'Recoleta' },
		{ value: 'Retiro', label: 'Retiro' },
		{ value: 'Nuñez', label: 'Nuñez' },
		{ value: 'Boca', label: 'Boca' },
	]

	const province = watch('province')
	const firstName = watch('firstName')
	const lastName = watch('lastName')
	const phonePrefix = watch('phonePrefix')
	const phoneNumber = watch('phoneNumber')
	const locality = watch('locality')

	const isEnabled =
		province && firstName && lastName && phonePrefix && phoneNumber && locality

	useEffect(() => {
		const getLocalities = async () => {
			let localities = []
			if (province.label.includes('Ciudad')) {
				localities = barrios
			} else {
				let { data } = await ApiHelper.getLocalities(province.name)
				localities = data
			}

			localities.map((localidad) => {
				if (localidad.name) {
					localidad.value = localidad.name
					localidad.label = localidad.name
				}
			})

			localities.sort(function (a, b) {
				let textA = a.label.toUpperCase()
				let textB = b.label.toUpperCase()
				return textA < textB ? -1 : textA > textB ? 1 : 0
			})
			setLocalities(localities)
		}
		if (province) {
			getLocalities()
			setValue('locality', '')
		}
	}, [province])

	useEffect(() => {
		getProvinces()
	}, [])

	const isFleetUser =
		user.activeSubscription?.subscriptionType?.type === 'fleet'

	function handleContinue() {
		setUser((prev) => ({ ...prev, interests: interests }))
		nextStep()
	}

	const getProvinces = async () => {
		try {
			let res = await ApiHelper.getProvinces()
			res.data.map((provincia) => {
				provincia.value = provincia.id
				provincia.label = provincia.name
			})
			res.data.sort(function (a, b) {
				let textA = a.label.toUpperCase()
				let textB = b.label.toUpperCase()
				return textA < textB ? -1 : textA > textB ? 1 : 0
			})

			setProvinces(res.data)
		} catch (error) {
			console.log(error)
		}
	}
  async function onSubmit(user) {
		if (!isEnabled) return;

    setUser((prev) => ({ ...prev, ...user }));
    const data = {
      type: isFleetUser ? "professional" : newUser.userType,
      activity: [],
      areasOfInterest: newUser.interests,
      vehiclesOfInterest: [],
      brandsOfInterest: [],
      phoneNumber: phoneNumber,
      prefix: phonePrefix,
      province: province.name,
      locality: locality.name,
      firstName: firstName,
      lastName: lastName,
      company: "",
    };
    if (isFleetUser) {
      await ApiHelper.patch("admin", "/user", data);
    } else {
      await ApiHelper.post("admin", "/user", data);
    }
    const res = await ApiHelper.get("admin", "/user");
    localStorage.user = JSON.stringify(res.data);
    if (isFleetUser) {
      window.location.href = window.location.origin;
    } else {
      nextStep();
    }
  }

	

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className='col-12 d-flex flex-column align-items-center'>
				<div className='container text-center bg-white p-5'>
					<h5 className='font-weight-bold mb-4'>
						¡Gracias por llegar hasta acá!
					</h5>
					<p className='mb-0'>
						Queremos saber tu zona y un teléfono de contacto
					</p>
					<p>¡No te preocupes, no te vamos a llamar!</p>
					<div className='container-sm px-md-5'>
						<div className='row text-left'>
							<div className='mt-4 col-12 col-md-6 '>
								<InputField label='Nombre'>
									<input
										name='firstName'
										className={'custom_input default-shadow p-2 w-100 mb-3'}
										placeholder={'Ingrese nombre'}
										type={'text'}
										maxLength={23}
										{...register('firstName', {
											required: 'Campo requerido',
										})}
									/>
								</InputField>
							</div>
							<div className='mt-4 col-12 col-md-6 '>
								<InputField label='Apellido'>
									<input
										name='lastName'
										className={'custom_input default-shadow p-2 w-100 mb-3'}
										placeholder={'Ingrese apellido'}
										type={'text'}
										maxLength={23}
										{...register('lastName', {
											required: 'Campo requerido',
										})}
									/>
								</InputField>
							</div>
							<div className='col-12 col-md-6 '>
								<InputField label='Prefijo'>
									<input
										name='phonePrefix'
										className={'custom_input default-shadow p-2 w-100 mb-3'}
										placeholder={'Cód. de área. Ej: 54'}
										type={'text'}
										maxLength={6}
										{...register('phonePrefix', {
											required: 'Campo requerido',
										})}
									/>
								</InputField>
							</div>
							{/* <div className="col-12 col-md-6 ">
              <Form.Label htmlFor="basic-url" className="fw-bold">Prefijo</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">+54</InputGroup.Text>
                <Form.Control
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              </div> */}
              <div className="col-12 col-md-6 ">
                <InputField label="Teléfono">
                  <input
                    name="phone"
                    className={"custom_input default-shadow p-2 w-100 mb-3"}
                    placeholder={"Teléfono. Ej: 1154845321"}
                    type={"text"}
                    {...register("phoneNumber", {
                      required: "Campo requerido",
                    })}
                  />
                </InputField>
              </div>
              <div className="col-12 col-md-6">
                <InputField label="Provincia">
                  <Select
                    placeholder={"Seleccione"}
                    options={provinces}
                    selectedOption={province}
                    onSelect={(value) => {
                      setValue("province", value);
                      setValue("locality", '')
                    }}
                  />
                </InputField>
              </div>
              <div className="col-12 col-md-6">
                <InputField label="Localidad">
                  <Select
                    placeholder={"Seleccione"}
                    options={localities}
                    selectedOption={locality}
                    onSelect={(value) => setValue("locality", value)}
                  />
                </InputField>
              </div>
            </div>
          </div>

					<div className={'container px-md-5 pt-2 pb-4'}>
						<div className='d-flex mt-4 justify-content-center'>
							<SquareButton
								disabled={!isEnabled}
								loading={isSubmitting}
								fullWidth={false}
								text={'Continuar'}
							/>
						</div>
					</div>
				</div>
			</div>
		</form>
	)
}
