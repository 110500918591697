import React from "react";
import Number from "./Number";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { PageIndex, SectionSeparator } from "../../atoms";

export default () => {
  return (
    <div className="">
      <div className="container py-5 mb-5">
        <PageIndex />
        <h1 className={"text-center font-weight-bold mt-5 mb-4"}>
          Para que puedas tomar buenas decisiones tenés que estar bien informado
        </h1>
        <p className="text-center font-weight-normal">
          Somos la Guía oficial de precios de vehículos y motos de Argentina.
          Trabajamos para brindarte información real y confiable del mercado
          automotor en un solo lugar. Desarrollamos la metodología Infoauto ®,
          es única en Latam y reconocida hace más de 25 años por los expertos de
          la industria.
        </p>
        <div className="row my-5">
          <Number
            title={"Modelos de vehículos valorizados."}
            number={"+ 15.000"}
          />
          <Number
            title={"Millones de Usuarios Anuales."}
            number={"+ 1.240.000"}
          />
          <Number title={"Consultas de precios diarias."} number={"+ 85.000"} />
        </div>
        <div className="row">
          <div className="col-12">
            <h2 className="mt-2 font-weight-normal plan_message text-center align-items-center">
              <strong>Encontrá</strong> el plan perfecto para vós{" "}
              <span className={"icon_box ml-2 px-3 pb-1"}>
                <a href="#planes">
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    size={"sm"}
                    className="text-white"
                  />
                </a>
              </span>
            </h2>
          </div>
        </div>
      </div>
      <SectionSeparator />
    </div>
  );
};
