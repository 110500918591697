import React from "react";
import {
  Title,
  TimeLine,
  Methods,
  Numbers,
  Suscriptions,
} from "../components/individuals";
import Companies from "../components/nosotros/companies/Companies";

export class Individuals extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div id="particulares_page" className={"mt-5 text_normal"}>
        <Title />
        <TimeLine />
        <Methods />
        <Numbers />
        <Companies />
        <Suscriptions pro={false} />
      </div>
    );
  }
}
