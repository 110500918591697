import React from 'react'
import img from '../../../static/img/fichaIA.png'
import { SectionSeparator } from '../../atoms'

export default () => {
	return (
		<div id={'marketplace_section'} className='background_cyan pt-5'>
			<div className='container py-5 mb-5'>
				<h2 className='font-weight-normal text-center'>
					<div>
						<span className={'font-weight-bold'}>No somos un marketplace,</span>
					</div>{' '}
					<br />
					somos una consultora especializada y objetiva.
				</h2>
				<p className={'text-center mt-3'}>
					<strong>El 50% de las personas desconfía de los precios publicados online.</strong> <br />{' '}
					Existen más de 20 precios diferentes en distintos sitios para la misma unidad usada.
				</p>
				<img alt={'infoauto'} src={img} className={'w-100'} />
			</div>
			<SectionSeparator />
		</div>
	)
}
