import React from "react";
import ReactSelect from "react-select";

export const Select = ({
	options,
	selectedOption,
	placeholder,
	onSelect,
	className,
	size,
}) => {
	const placeholderOption = {
		value: null,
		label: "Seleccione",
		disabled: true,
	};

	return (
		<ReactSelect
			theme={(theme) => ({
				...theme,
				colors: {
					...theme.colors,
					primary25: "white",
					primary: "black",
				},
			})}
			maxMenuHeight={size}
			isOptionDisabled={(option) => option.disabled}
			noOptionsMessage={() => "No hay coincidencias"}
			onChange={onSelect}
			getOptionLabel={(option) => (option.label ? option.label : option.name)}
			getOptionValue={(option) => option.value}
			options={[placeholderOption, ...options]}
			className={`shadow-lg rounded-pill ${className}`}
			value={selectedOption}
			classNamePrefix='custom_select'
			placeholder={placeholder}
		/>
	);
};
