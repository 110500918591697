import React from "react";
import BottomFooter from "./BottomFooter";
import TopFooter from "./TopFooter";
import BottomImage from "./BottomImage";

const Footer = () => {
    return (
        <footer id="footer">
            <BottomImage />
            <div className="">
                <TopFooter />
                <BottomFooter />
            </div>
        </footer>
    )
};

export default Footer;
