import {
  INFOGRID,
  COMPARATOR,
  VISUAL_SEARCH,
} from "../static/permissions/sections";

export const menu = [
  {
    id: 1,
    name: "Servicio",
    route: "/servicio",
    child: [
      {
        id: 1,
        name: "Particulares",
        route: "/particulares",
      },
      {
        id: 2,
        name: "Profesionales",
        route: "/profesionales",
      },
      {
        id: 2,
        name: "Empresas",
        route: "/empresas",
      },
    ],
  },
  {
    id: 2,
    name: "Nosotros",
    route: "/nosotros",
  },
  {
    id: 3,
    name: "Noticias",
    route: "/noticias",
  },
  {
    id: 7,
    name: "Búsquedas",
    child: [
      {
        id: 1,
        name: "Asistida",
        route: "/busqueda-asistida",
      },
      {
        id: 2,
        name: "Visual",
        route: "/busqueda-visual",
        permission: VISUAL_SEARCH,
        individual: true,
        logged: true,
      },
      {
        id: 3,
        name: "Comparador",
        route: "/comparador",
        permission: COMPARATOR,
        logged: true,
      },
      {
        id: 4,
        name: "InfoGrid",
        route: "/infogrid",
        permission: INFOGRID,
        pro: true,
      },
      
    ],
  },
  {
    id: 5,
    name: "Revista",
    route: "/revista",
  },
  {
    id: 6,
    name: "Contacto",
    // route: '/contacto'
  },
];
