import React, { useEffect, useState } from 'react';

export default () => {
    const [time, setTime] = useState(['-', '-', '-', '-']);

    

    useEffect(() => {
        const interval = setInterval(() => {
            let now = new Date();
            let nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
         
            var z = new Date(nextMonth - now);
            
            var epoch = new Date('1970-01-01T00:00:00');
            var diff_years = z.getYear() - epoch.getYear();
            var diff_month = z.getMonth() - epoch.getMonth();
            var diff_days = z.getDate() - epoch.getDate();
            var diff_hours = z.getHours() - epoch.getHours();
            var diff_minutes = z.getMinutes() - epoch.getMinutes();
            var diff_seconds = z.getSeconds() - epoch.getSeconds();
            setTime([diff_days, diff_hours, diff_minutes, diff_seconds])
        }, 1000);
        return () => clearInterval(interval);
    });

    return (
        <div className={'counter mb-4 mb-md-0 row bg-white default_shadow px-3 py-4'}>
            <div className="col-3">
                <h2 className="d-md-none font-weight-bold text-center">{time[0]}</h2>
                <h1 className="d-none d-md-block font-weight-bold text-center">{time[0]}</h1>
                <p className="text-center mb-0">días</p>
            </div>
            <div className="col-3">
                <h2 className="d-md-none font-weight-bold text-center">{time[1]}</h2>
                <h1 className="d-none d-md-block font-weight-bold text-center">{time[1]}</h1>
                <p className="text-center mb-0">horas</p>
            </div>
            <div className="col-3">
                <h2 className="d-md-none font-weight-bold text-center">{time[2]}</h2>
                <h1 className="d-none d-md-block font-weight-bold text-center">{time[2]}</h1>
                <p className="text-center mb-0">minutos</p>
            </div>
            <div className="col-3">
                <h2 className="d-md-none font-weight-bold text-center">{time[3]}</h2>
                <h1 className="d-none d-md-block font-weight-bold text-center">{time[3]}</h1>
                <p className="text-center mb-0">segundos</p>
            </div>
        </div>
    )
}
