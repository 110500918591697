import React, { useState } from "react";
import { Modal, } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SquareButton } from "../atoms";
import { ApiHelper } from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import actions from "../../store/actions";

const DeleteAccountModal = ({ show, hide }) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onDeleteAccount = async (e) => {
    try {
      setIsLoading(true);
      await ApiHelper.delete("auth", "/account");
      localStorage.clear();
      window.location.href = window.location.origin;
      hide();
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={hide}
      size={"md"}
      className={"default_modal"}
    >
      <Modal.Body className={"p-0 background_gray"}>
        <div className={"text-right pt-3 pr-3 link"}>
          <FontAwesomeIcon
            onClick={hide}
            icon={faTimes}
          />
        </div>
            <div className="gray_background p-5">
              <h1 className="font-weight-normal mb-0 border-bottom-1 pb-3">
                Eliminar cuenta
              </h1>
              <div className="mt-4 justify-center">
                <p  style={{color: 'red'}} className="font-weight-bold">Estás a punto de eliminar permanentemente tu cuenta. Esta acción es irreversible y se borrarán todos tus
							datos asociados.</p>
                  <div>
                    <p className="font-weight-bold">Por favor ingrese su email para confirmar que desea eliminar su cuenta.</p>
                    <label className="font-weight-bold">Email</label>
                    <input
                      name={"email"}
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      className={"custom_input default-shadow p-2 w-100 mb-3"}
                      placeholder={"Email"}
                      type={"email"}
                    />
                  </div>
                  <div className="col-12 text-center mt-3 text-center text-md-right">
                    <SquareButton
                      loading={isLoading}
                      onClick={onDeleteAccount}
                      text={"Eliminar cuenta"}
                      disabled={email !== user?.email}
                    />
                  </div>
                </div>
              </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteAccountModal;