import React, { Fragment, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css'
import { default as ReactLoader } from 'react-loader-spinner'
import { ApiHelper, AppHelper } from '../../helpers'

import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { useSelector } from 'react-redux'
import { DIGITAL_MAGAZINE } from 'static/permissions/sections'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default ({ magazine, month }) => {
	const [numPages, setNumPages] = React.useState(null)
	const [pageNumber, setPageNumber] = useState(1)
	const [controlOpacity, setControlOpacity] = useState(0)
	const user = useSelector((state) => state.user.user)

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages)
	}

	const loader = () => {
		return (
			<div
				className={
					'magazine_loader d-flex align-items-center justify-content-center default_shadow'
				}
			>
				<ReactLoader type='ThreeDots' color='#10ffdc' height={50} width={50} />
			</div>
		)
	}

	const handlePageNumber = (action) => {
		if (pageNumber === 1) {
			if (action) {
				setPageNumber(2)
			}
		} else if (pageNumber === 2) {
			if (!action) {
				setPageNumber(1)
			} else {
				windowWidth > 974 ? setPageNumber(4) : setPageNumber(3)
			}
		} else {
			if (action) {
				windowWidth > 974 ? setPageNumber(pageNumber + 2) : setPageNumber(pageNumber + 1)
			} else {
				windowWidth > 974 ? setPageNumber(pageNumber - 2) : setPageNumber(pageNumber - 1)
			}
		}
	}

	const [windowWidth, setWindowWidth] = useState(window.innerWidth)

	useEffect(() => {
		window.addEventListener('resize', () => {
			setWindowWidth(window.innerWidth)
		})
	}, [])

	const handle = useFullScreenHandle()

	return (
		AppHelper.hasPermission(user, DIGITAL_MAGAZINE) && (
			<section className={'my-5 text-center'}>
				{user && user.activeSubscription.name != 'Free' && (
					<>
						<small className={'text-muted text-uppercase text-center'}>Hojeá nuestra revista</small>
						<FullScreen handle={handle}>
							<div
								className={
									'magazine_container my-4 d-flex justify-content-center flex-column h-100'
								}
							>
								{magazine && (
									<Fragment>
										{handle.active === true && (
											<div className='d-flex justify-content-end'>
												<button
													style={{ width: '150px' }}
													className='mb-1 rounded button-narrow'
													onClick={handle.exit}
												>
													Exit Fullscren
												</button>
											</div>
										)}

										<Document
											className={'magazine_reader'}
											file={`${ApiHelper.adminRoute}/files/${magazine.link}`}
											onLoadSuccess={onDocumentLoadSuccess}
											loading={loader}
										>
											<Page
												loading={loader}
												onMouseEnter={() => setControlOpacity(1)}
												onMouseLeave={() => setControlOpacity(0)}
												className={'magazine_page default_shadow'}
												pageNumber={pageNumber}
												width={450}
												height={handle.active ? window.innerHeight - 100 : 550}
											/>
											{windowWidth > 975 && pageNumber !== 1 && pageNumber !== numPages ? (
												<Page
													loading={loader}
													onMouseEnter={() => setControlOpacity(1)}
													onMouseLeave={() => setControlOpacity(0)}
													className={'magazine_page default_shadow'}
													pageNumber={pageNumber + 1}
													height={handle.active ? window.innerHeight - 100 : 550}
												/>
											) : (
												<Fragment></Fragment>
											)}
										</Document>
									</Fragment>
								)}

								<div
									className='page_control_container d-flex justify-content-center'
									style={{ opacity: controlOpacity }}
								>
									<div
										className={'default_shadow page_control d-flex align-items-center'}
										onMouseEnter={() => setControlOpacity(1)}
									>
										<FontAwesomeIcon
											onClick={() => pageNumber > 1 && handlePageNumber(false)}
											icon={faChevronLeft}
											className={'mr-3'}
											color={pageNumber === 1 ? '#ccc' : 'black'}
										/>
										<p
											className='text-center mb-0'
											style={{
												userSelect: 'none',
											}}
										>
											{pageNumber} de {numPages}
										</p>
										<FontAwesomeIcon
											onClick={() =>
												((windowWidth < 974 && pageNumber < numPages) ||
													(windowWidth > 974 && pageNumber + 1 < numPages)) &&
												handlePageNumber(true)
											}
											icon={faChevronRight}
											className={'ml-3'}
											color={
												pageNumber === numPages ||
												(windowWidth > 974 && pageNumber + 1 === numPages)
													? '#ccc'
													: 'black'
											}
										/>
									</div>
								</div>
							</div>
							<div>
								<button className='mt-4 mb-4 rounded button-narrow' onClick={handle.enter}>
									Fullscreen
								</button>
							</div>
						</FullScreen>

						<small className={'text-muted text-uppercase text-center text-uppercase'}>
							EDICIÓN {month} - AÑO {magazine?.year}
						</small>
					</>
				)}
			</section>
		)
	)
}
