import React, { useEffect } from 'react'
import { ApiHelper } from '../../helpers'

import usePayment from 'hooks/usePayment'

const UserPayment = ({ newUser, nextStep }) => {
	const { renderCard, renderForm } = usePayment(suscribeToPlan)

	useEffect(() => {
		if (window.gtag) {
			window.gtag('event', 'begin_checkout')
		}
	}, [])

	async function suscribeToPlan(cardToken, paymentMethodId, dni) {
		const data = {
			planId: newUser.subscription.id,
			cardToken: cardToken,
			dni: dni,
			paymentMethodId: paymentMethodId,
		}
		await ApiHelper.suscribeToPlan(data)

		if (window.gtag) {
			window.gtag('event', 'purchase', {
				...newUser.subscription,
			})
		}
		nextStep()
		setTimeout(() => {
			window.location.reload()
		}, 3000)
	}

	return (
		<div className={'container py-2 bg-white p-5'}>
			<div className='row'>
				<div className='col-12 p-5'>
					<h5 className={'mb-4 text-center font-weight-bold'}>Medio de pago</h5>
					<p className='text-center'>Para finalizar la contratación, cargá los siguientes datos.</p>
					{renderCard()}
					{renderForm()}
				</div>
			</div>
		</div>
	)
}

export default UserPayment
