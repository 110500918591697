import actionsTypes from '../actions/actionTypes';



const initialState = {
    user: localStorage.user ? JSON.parse(localStorage.user) : null,
    registerCompleted: false,
    pro: true,
};

export default function(state = initialState, action){
    switch(action.type){
        case actionsTypes.SET_USER:
            return Object.assign({}, state, {
                user: action.user
            });
        case actionsTypes.FILL_USER_DATA:
            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    ...action.user
                },
                registerCompleted: true
            });
        default:
            return state;
    }
}
