import React from "react";
import { TimeLineYear, SectionSeparator } from "../atoms";

export default () => {
  const years = [
    {
      year: 1996,
      title: "Nace InfoAuto",
      text:
        "Diseñamos nuestra 1ra Guía de Precios con 800 modelos y desarrollamos el servicio de homologación de Tablas de compañías de seguros.",
    },
    {
      year: 1998,
      title: "Cobertura Nacional",
      text:
        "Por primera vez nuestra revista llega a todas las provincias del país.",
      margin: 70,
    },
    {
      year: 2010,
      title: "Incorporamos Motos",
      text:
        "Sumamos a nuestras bases de datos, todos los modelos de motos y cuatriciclos comercializados en Argentina",
      margin: 140,
    },
    {
      year: 2013,
      title: "Nuevos Servicios",
      text:
        "Alcanzamos los 32.000 ejemplares vendidos. Sumamos la Suscripción Digital y nuestra APP a la oferta de servicios. Mas de  120 empresas utilizan nuestra motorización de datos en sus sistemas.",
      margin: 70,
    },
    {
      year: 2021,
      title: "Transformación Digital",
      text:
        "Lanzamos nuestro nuevo sitio incorporando nuevos servicios para el segmento de Particulares y Profesionales de la Industria con INFOGRID. Se inicia el proceso de migración de servicios corporativos a traves de WS.",
    },
  ];
  return (
    <section id={"timeline"} className={"pt-5"}>
      <div className="container py-5">
        <h2 className="font-weight-normal text-center">
          Elaboramos la guía oficial de precios, vehículos 0km y usados a través
          de <strong>nuestra metodología de investigación</strong>
        </h2>
        <p
          className={
            "subtitle text-uppercase font-weight-normal text-center mt-4"
          }
        >
          UN <strong>CAMINO</strong> DE INNOVACIÓN
        </p>
      </div>
      <SectionSeparator right={true} />
      <div className="pt-3 pb-5 background_gray">
        <div className="container mb-5">
          <div className="container-fluid">
            <div className="row">
              {years.map((year) => (
                <TimeLineYear
                  className="year"
                  key={year.year}
                  index={year.year}
                  year={year}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <SectionSeparator />
    </section>
  );
};
