import React, {useEffect, useState} from 'react';
import { ProfilePlan } from "../components/molecules";
import actions from "../store/actions";
import {ApiHelper} from "../helpers";

export default () => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState();

    useEffect(() => {
        window.addEventListener('message', function(data) {
            if(data.data.includes('token')){
                let token = data.data.replace(new RegExp('.*' + 'token: '), '');
                localStorage.token = token;
                setToken(token);
            }
        }, false );
    }, []);

    useEffect(() => {
        if(token){
            getUser();
        }
    }, [token]);

    const getUser = async () => {
        try {
            let res = await ApiHelper.get('admin', '/user');
            if (res.data.birthDate) {
                res.data.birthDate = res.data.birthDate.substring(0, 10);
            }
            setUser(res.data);
        } catch (error) {
            console.log(error)
        }
    };

    if(user){
        return <ProfilePlan currentUser={user}/>
    } else {
        return null;
    }
}
