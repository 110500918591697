import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel as ImageCarousel } from 'react-responsive-carousel';
import { ApiHelper } from "../../helpers";

export const Carousel = ({ images }) => {
    return (
        <div className="news-carousel">
        <ImageCarousel>
            {images.map(image => (
                <div key={image} className="news-carousel-slider">
                    <img src={`${ApiHelper.adminRoute}/files/${image}`} />
                </div>
            ))}
        </ImageCarousel>
        </div>
    )
}
