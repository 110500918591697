import React, { useEffect, useState } from 'react'
import { ApiHelper } from '../../helpers'
import AdXL from '../ads/AdXL'
import { Carousel, NewsSocialBar } from '../molecules'

export const RegularNew = ({ article }) => {
	const [blocks, setBlocks] = useState([])

	const [paragraphs] = useState([
		{
			title: 'Medidas',
			img: 'https://lh3.googleusercontent.com/proxy/6UQ8bPrxfhuv3499awceyJ9tfob4PMXTdzDOWYSZQKPLglAT_XSmmM4kW40VUH-c7-y32WJ4dfibwUXFSDfbwJblEOjvrNsEiz4M9MhfcBqvhJpiJg4OMnB-5amk4A',
			text: 'Fusing edge and exclusivity with obsessive performance, this is the IS F SPORT Black Line Special Edition. Limited to 900 vehicles, this unique edition boasts exclusive features like sleek, black outside mirrors and 18-inch split-five-spoke F SPORT alloy wheels* with a Black Vapo',
		},
		{
			title: 'Medidas',
			img: 'https://lh3.googleusercontent.com/proxy/6UQ8bPrxfhuv3499awceyJ9tfob4PMXTdzDOWYSZQKPLglAT_XSmmM4kW40VUH-c7-y32WJ4dfibwUXFSDfbwJblEOjvrNsEiz4M9MhfcBqvhJpiJg4OMnB-5amk4A',
			text: 'Fusing edge and exclusivity with obsessive performance, this is the IS F SPORT Black Line Special Edition. Limited to 900 vehicles, this unique edition boasts exclusive features like sleek, black outside mirrors and 18-inch split-five-spoke F SPORT alloy wheels* with a Black Vapo',
		},
		{
			title: 'Medidas',
			img: 'https://lh3.googleusercontent.com/proxy/6UQ8bPrxfhuv3499awceyJ9tfob4PMXTdzDOWYSZQKPLglAT_XSmmM4kW40VUH-c7-y32WJ4dfibwUXFSDfbwJblEOjvrNsEiz4M9MhfcBqvhJpiJg4OMnB-5amk4A',
			text: 'Fusing edge and exclusivity with obsessive performance, this is the IS F SPORT Black Line Special Edition. Limited to 900 vehicles, this unique edition boasts exclusive features like sleek, black outside mirrors and 18-inch split-five-spoke F SPORT alloy wheels* with a Black Vapo',
		},
		{
			title: 'Medidas',
			img: 'https://lh3.googleusercontent.com/proxy/6UQ8bPrxfhuv3499awceyJ9tfob4PMXTdzDOWYSZQKPLglAT_XSmmM4kW40VUH-c7-y32WJ4dfibwUXFSDfbwJblEOjvrNsEiz4M9MhfcBqvhJpiJg4OMnB-5amk4A',
			text: 'Fusing edge and exclusivity with obsessive performance, this is the IS F SPORT Black Line Special Edition. Limited to 900 vehicles, this unique edition boasts exclusive features like sleek, black outside mirrors and 18-inch split-five-spoke F SPORT alloy wheels* with a Black Vapo',
		},
	])

	useEffect(() => {
		blocksSort()
	}, [article])

	const blocksSort = () => {
		let blocks = article.blockList
		blocks.sort(function (a, b) {
			return a.id - b.id
		})
		setBlocks(blocks)
	}

	if (article) {
		return (
			<div id={'release_new'}>
				<div
					className='release_new_head d-flex align-items-start p-5'
					style={{
						background: `url(${ApiHelper.adminRoute}/files/${article.bannerImage}) center no-repeat`,
					}}
				>
					<h1 className='text-white font-weight-bold mt-5 pt-2 pt-md-5'>{article.title}</h1>
				</div>
				<div className={'p-3 container'}>
					<NewsSocialBar
						title={article.title}
						tags={article.tagList}
						date={article.date}
						source={article.source}
					/>
					<h3 className={'font-weight-bold mt-4'}>{article.epigraph}</h3>
					{blocks[0] ? (
						blocks[0].type === 'text' ? (
							<p className={'mt-4 mb-2'} dangerouslySetInnerHTML={{ __html: blocks[0].content }} />
						) : (
							<div className='row mb-4'>
								{blocks[0].images.map((image, i) => (
									<div key={i} className='col-6'>
										<img
											className={'w-100'}
											src={`${ApiHelper.adminRoute}/files/${image}`}
											alt='auto'
										/>
									</div>
								))}
							</div>
						)
					) : null}
					<Carousel images={article.images} />
					{blocks.map((block, index) => {
						if (index > 0) {
							if (block.type === 'text') {
								return (
									<p
										key={index}
										className={'mt-4 mb-2'}
										dangerouslySetInnerHTML={{ __html: block.content }}
									/>
								)
							} else if (block.type === 'image') {
								return (
									<div key={index} className='row mb-4'>
										{block.images.map((image, i) => (
											<div key={i} className='col-6'>
												<img
													key={index}
													className={'w-100'}
													src={`${ApiHelper.adminRoute}/files/${image}`}
													alt='auto'
												/>
											</div>
										))}
									</div>
								)
							}
						}
					})}
					{/*{article.paragraphList.map((paragraph, index) => index > 0 && index < 3 ? <p key={index} className={'mt-4 mb-2'} dangerouslySetInnerHTML={{__html: paragraph}} /> : null)}*/}
					{/*<p className={'mt-4 mb-4'}>Fusing edge and exclusivity with obsessive performance, this is the IS F SPORT Black Line Special Edition. Limi\ ted to 900 vehicles, this unique edition boasts exclusive features like sleek, black outside mirrors and 18-inch split-five-spoke F SPORT alloy wheels* with a Black Vapor Chrome finish. Inside, you'll find Indigo Blue accents throughout–from ornamentation to an accented heated steering wheel to seats trimmed in unique Black NuLuxe®* with gray details and Indigo Blue stitching. And offering the next level of keyless entry, the exclusive SmartAccess* card key delivers the convenience of a key fob, with the added benefit of fitting easily in your wallet. Select the image above for a complete list of features and additional imagery.</p>*/}
					<div className='row mb-4'>
						<div className='col-12'>
							<AdXL subsection='-standard' />
						</div>
					</div>

					<NewsSocialBar
						title={article.title}
						tags={article.tagList}
						date={article.date}
						source={article.source}
						borderTop
					/>
				</div>
			</div>
		)
	}
}
