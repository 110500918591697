import actionTypes from "./actionTypes";

const appActions = {
  setLoading: (state) => {
    return {
      type: actionTypes.SET_LOADING,
      payload: state,
    };
  },
  setShowRegister: (state) => {
    return {
      type: actionTypes.SET_SHOW_REGISTER,
      payload: state,
    };
  },
  setShowLogin: (state) => {
    return {
      type: actionTypes.SET_SHOW_LOGIN,
      payload: state,
    };
  },
  setShowRecover: (state) => {
    return {
      type: actionTypes.SET_SHOW_RECOVER,
      payload: state,
    };
  },
  setShowContact: (state) => {
    return {
      type: actionTypes.SET_SHOW_CONTACT,
      payload: state,
    };
  },
  setShowReset: (state) => {
    return {
      type: actionTypes.SET_SHOW_RESET,
      payload: state,
    };
  },
  setPaymentPending: (show) => {
    return {
      type: actionTypes.SET_SHOW_PAYMENT_PENDING,
      payload: show,
    };
  },
  setShowBillingModal: (show) => {
    return {
      type: actionTypes.SET_SHOW_BILLING_MODAL,
      payload: show,
    };
  },
  setShowWrongPlanModal: (show) => {
    return {
      type: actionTypes.SET_SHOW_WRONG_PLAN_MODAL,
      payload: show,
    };
  },
  setShowPaymentModal: (show) => {
    return {
      type: actionTypes.SET_SHOW_PAYMENT_MODAL,
      payload: show,
    };
  },
  setLoginMessage: (message) => {
    return {
      type: actionTypes.SET_LOGIN_MESSAGE,
      payload: message,
    };
  },
  setSelectedPlan: (plan) => {
    return {
      type: actionTypes.SET_SELECTED_PLAN,
      payload: plan,
    };
  },
  setShowSubscriptionDisabled: (disabled) => {
    return {
      type: actionTypes.SET_SHOW_SUBSCRIPTION_DISABLED,
      payload: disabled,
    };
  },
};

export default appActions;
