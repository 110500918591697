import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApiHelper } from "../../helpers";
import { PaginatedSelect, Select } from "../atoms";

export const InfoProSearchBar = ({
  brand,
  setBrand,
  model,
  setModel,
  quantity,
  setQuantity,
  versions,
}) => {
  const [ brands, setBrands ] = useState([]);
  const [ models, setModels ] = useState([]);
  const quantities = [ 50, 100, "Todo" ];
  const vehicleType = useSelector((state) => state.search.vehicle);

  useEffect(() => {
    getBrands();
  }, [ vehicleType ]);

  const getBrands = async () => {
    const data = {
      paginated: false,
      hasUsedPrice: true,
      vehicleType: vehicleType,
    };
    let res = await ApiHelper.getDownloadedBrands(data);
    res.map((brand) => {
      brand.value = brand.id;
      brand.label = brand.name;
      brand.type = "brand";
    });

    setBrands(res);
  };

  const getModels = async () => {
    const data = {
      paginated: false,
      hasUsedPrice: true,
      vehicleType: vehicleType,
    };

    const res = await ApiHelper.getModels(brand.id, data);
    res.map((model) => {
      model.value = model.id;
      model.label = model.name;
      model.type = "model";
    });

    setModels(res);
  };

  const handleMaxResults = (value) => {

    setQuantity(value)
  }


  const loadModels = async (searchQuery, loadedOptions, { page }) => {
    if (brand) {
      const data = {
        paginated: false,
        hasUsedPrice: true,
        vehicleType,
        page,
      };
      const res = await ApiHelper.getPaginatedModels(brand.id, data);

      return {
        options: res.data.response,
        hasMore: res.data.pagination.totalPages > page,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: true,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    }
  };

  return (
    <div className="row infopro_search">
      <div className="col-12 col-md-6 col-lg-3 mb-4 mb-md-0 d-flex align-items-center">
        <p className={"mb-0 font-weight-bold mr-3"}>Marca</p>
        <Select
          className={"dropdown flex-grow-1"}
          options={brands}
          selectedOption={brand}
          placeholder={"Seleccione"}
          onSelect={setBrand}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-3 mb-4 mb-md-0 d-flex align-items-center">
        <p className={"mb-0 font-weight-bold mr-3"}>Modelo</p>
        <PaginatedSelect
          key={"model"}
          value={model}
          loadOptions={loadModels}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
          defaultOptions={false}
          cacheUniqs={[ brand ]}
          onChange={setModel}
          isSearchable={false}
          placeholder="Seleccione"
          additional={{
            page: 1,
          }}
          className="dropdown flex-grow-1 default_shadow"
        />
      </div>
      {/* {versions.length > 0 && (
        <div className="col-12 col-md-6 mb-md-0 d-flex justify-content-center flex-column">
          <div className="d-flex justify-content-center justify-content-md-end align-items-center">
            <p className={"text-left text-md-right mb-0"}>
              Resultados por página:
            </p>
            {quantities.map((q, index) => (
              <span
                onClick={() => handleMaxResults(q)}
                key={index}
                className={`link quantity_selector ${q === quantity && "font-weight-bold"
                  }`}
              >
                {q}
              </span>
            ))}
          </div>
          <p
            className={
              "text-center  text-md-right mt-1 color_blue result_quantity"
            }
          >
            {quantity !== "Todo" &&
              `${quantity > versions.length ? versions.length : quantity
              } de `}
            <strong>{versions.length}</strong> resultados
          </p>
        </div>
      )} */}
    </div>
  );
};
