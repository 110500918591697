import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { FAVORITES, SEARCH_HISTORY } from "static/permissions/sections";
import { Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { menu } from "../../../constants";
import actions from "store/actions";
import { NavItem } from "../../molecules";
import {
  LoginModal,
  RecoverModal,
  RegisterDataModal,
  RegisterModal,
  ResetPassModal,
} from "../../organisms";
import Access from "./Access";
import MiniSearchBar from "./MiniSearchBar";
import ProfileThumbnail from "./ProfileThumbnail";
import { AppHelper } from "helpers";
import { IoMenu } from "react-icons/io5";

const Header = () => {
  const user = useSelector((state) => state.user.user);
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [showMenu, setShowMenu] = useState(false);
  const [usesBlackLogo, setUsesBlackLogo] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const dispatch = useDispatch();

  const logout = () => {
    localStorage.clear();
    window.location.href = window.location.origin;
  };

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width:
          targetRef.current.offsetWidth > 0
            ? targetRef.current.offsetWidth
            : 200,
        height: targetRef.current.offsetHeight,
      });
    }
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
      if (targetRef.current) {
        setDimensions({
          width:
            targetRef.current.offsetWidth > 0
              ? targetRef.current.offsetWidth
              : 200,
          height: targetRef.current.offsetHeight,
        });
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 20) {
        setUsesBlackLogo(true);
      } else if (window.pageYOffset < 20) {
        setUsesBlackLogo(false);
      }
    });
  }, []);

  return (
    <Navbar
      fixed={"top"}
      id="header__nav"
      className="navbar-dark navbar-expand-xl"
      bg="dark"
      expand="xl"
    >
      <a
        href={window.location.origin}
        id="header_img_link"
        className={`navbar-brand px-3 px-lg px-lg-4 py-1 py-lg-4  ${
          usesBlackLogo ? "bg-black" : "bg-white"
        } text-dark`}
      >
        <img
          src={
            usesBlackLogo
              ? "/assets/img/logo/logo-02.png"
              : "/assets/img/logo/logo-01.png"
          }
          style={{ width: 120 }}
          alt="logo"
        />
      </a>
      <Navbar.Toggle
        style={{ backgroundColor: "#f7f7f7" }}
        aria-controls="header__menu"
      >
        <IoMenu color="black" size="20px" />
        {/* <span style={{ color: "black" }} className="navbar-toggler-icon" /> */}
      </Navbar.Toggle>

      <Navbar.Collapse id="header__menu">
        <div className="mx-auto">
          <Nav>
            <ul ref={targetRef} className="navbar-nav pb-4 pb-md-0">
              {windowWidth < 1200 && user && (
                <li className="nav-item">
                  <a
                    href={"/perfil/mis-datos"}
                    className="spacing_1 nav-link text-uppercase font-weight-bold text_small"
                  >
                    Perfil
                  </a>
                </li>
              )}
              {windowWidth < 1200 && !user && (
                <li className="nav-item">
                  <a
                    onClick={() => dispatch(actions.app.setShowLogin(true))}
                    className="spacing_1 nav-link text-uppercase font-weight-bold text_small"
                  >
                    Acceder / Registrarse
                  </a>
                </li>
              )}
              {menu.map((item) => {
                return (
                  <li
                    key={`${item.id}_menu`}
                    className="nav-item link"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {item.child ? (
                      <ul>
                        {" "}
                        <NavItem
                          mobile={windowWidth < 1200}
                          item={item}
                          className="text_small"
                        />
                      </ul>
                    ) : (
                      <a
                        onClick={() =>
                          item.name === "Contacto" &&
                          dispatch(actions.app.setShowContact(true))
                        }
                        href={item.route}
                        className="spacing_1 link nav-link text-uppercase font-weight-bold text_small"
                      >
                        {item.name}
                        <span className="sr-only">(current)</span>
                      </a>
                    )}
                  </li>
                );
              })}
              {AppHelper.hasPermission(user, FAVORITES) && windowWidth < 1200 && (
                <li className="nav-item">
                  <a
                    href={"/perfil/favoritos"}
                    className="spacing_1 nav-link text-uppercase font-weight-bold text_small"
                  >
                    Favoritos
                  </a>
                </li>
              )}

              {AppHelper.hasPermission(user, SEARCH_HISTORY) &&
                windowWidth < 1200 && (
                  <li className="nav-item">
                    <a
                      href={"/perfil/historial"}
                      className="spacing_1 nav-link text-uppercase font-weight-bold text_small"
                    >
                      Historial
                    </a>
                  </li>
                )}
              {windowWidth < 1200 && user && (
                <li className="nav-item">
                  <a
                    onClick={logout}
                    className="nav-link text-uppercase font-weight-bold text_small"
                  >
                    Salir
                    <FontAwesomeIcon className={"ml-2"} icon={faSignOutAlt} />
                  </a>
                </li>
              )}
            </ul>
          </Nav>
          {windowWidth >= 1200 && <MiniSearchBar dimensions={dimensions} />}
        </div>

        {windowWidth >= 1200 ? user ? <ProfileThumbnail /> : <Access /> : null}
        <RegisterDataModal />
      </Navbar.Collapse>
      <LoginModal />
      <RegisterModal />
      <ResetPassModal />
      <RecoverModal />
    </Navbar>
  );
};

export default Header;
