import React, { useState } from 'react'
import empresasImg from '../../../static/img/empresas.jpg'
import particularesImg from '../../../static/img/particulares.jpg'
import profesionalesImg from '../../../static/img/profesionales.jpg'
import Subscription from './Subscription'

const Subscriptions = () => {
	const [ subscriptions ] = useState([
		{
			id: 1,
			name: 'Particulares',
			image: particularesImg,
			bullets: [
				'Descubrí toda la información de tu próximo auto o moto.',
				'Guía de precios con fotos de Usados y 0Km',
				'Compará, decidí y ganá tiempo',
				'Fichas Técnicas confiables y fáciles de interpretar',
			],
			link: '/particulares',
		},
		{
			id: 2,
			name: 'Profesionales',
			image: profesionalesImg,
			bullets: [
				'Guía de precios en formato grilla de Usados y 0Km similar a la revista',
				'Precios Históricos y Usado del Año',
				'Historial de Consultas',
				'Comparador hasta 4 vehículos.',
			],
			link: '/profesionales',
		},
		{
			id: 3,
			name: 'Empresas',
			image: empresasImg,
			bullets: [
				'Consulta Masiva de datos en línea precios y fichas técnicas',
				'Homologación de Bases de Datos - Consultoría',
				'Publicidad, Prensa- Comunicación y Lanzamientos',
				'Soluciones a medida',
			],
			link: '/empresas',
		},
	])

	return (
		<section id='subscriptions_section' className='container mt-5'>
			<div>
				<h2 className='text-center text-uppercase font-weight-bolder spacing_2'>
					Soluciones INFOAUTO
				</h2>
				<p className='text-uppercase text-center font-weight-bold mt-4 text-secondary'>
					Comprendemos las necesidades de nuestros clientes
				</p>
				<div className='row no-gutters'>
					{subscriptions.map((subscription) => {
						return (
							<div className='col-12 col-lg-4'>
								<Subscription key={subscription.id} subscription={subscription} isProfessional={subscription.id === 2} />
							</div>
						)
					})}
				</div>
			</div>
		</section>
	)
}

export default Subscriptions
