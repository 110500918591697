import React, { useEffect, Fragment, useState } from 'react'
import Banner from '../components/home/banner/Banner'
import Subscriptions from '../components/home/subscription/Subscriptions'
import Video from '../components/home/video/Video'
import Magazine from '../components/home/magazine/Magazine'
import News from '../components/home/news/News'
import { HomeParallax, ShareBar } from '../components/molecules'
import { useDispatch, useSelector } from 'react-redux'
import actions from 'store/actions'
import { useHistory } from 'react-router-dom'

export const Home = ({ location }) => {
	const dispatch = useDispatch()
	const user = useSelector((state) => state.user.user)
	const history = useHistory()

	useEffect(() => {
		const url = new URL(window.location.href)
		const showLogin = url.searchParams.get('showloginmodal')

		if (showLogin === 'true' && !user) {
			dispatch(actions.app.setShowLogin(true))
		}
	}, [location.search])

	useEffect(() => {
		const url = new URL(window.location.href)
		const showPayment = url.searchParams.get('openpaymentmodal')

		if (showPayment === 'true' && user) {
			history.push('/perfil/plan?openpaymentmodal=true')
		}
	}, [user])

	const isInactive = user && user.infoCreditsActive === false

	useEffect(() => {
		if (isInactive) {
			const infoCreditsAlertDate = localStorage.getItem('infoCreditsAlertDate')
			let shouldAlert = true
			if (infoCreditsAlertDate) {
				const alertDate = new Date(infoCreditsAlertDate)
				const now = new Date()
				if (now.getDay() === alertDate.getDay()) {
					shouldAlert = false
				}
			}
			if (shouldAlert) {
				dispatch(actions.app.setShowSubscriptionDisabled(true))
			}
		} else {
			localStorage.removeItem('infoCreditsAlertDate')
		}
	}, [isInactive])

	return (
		<Fragment>
			<Banner />
			<ShareBar />
			<Subscriptions />
			<HomeParallax />
			<Video />
			<Magazine />
			<News />
		</Fragment>
	)
}
