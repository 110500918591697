import React from "react";
import { ApiHelper } from "../../helpers";
import { Link } from "react-router-dom";
import { AppHelper } from "../../helpers";


const PrimaryNew = ({ article }) => {


  // const validate = () => {
  //   AppHelper.validateURL(AppHelper.articleURL(article), article.title)
  // }

  return (
    <article className="col-12 col-md-8 mt-2 px-1">
      <Link to={`/noticias/articulo/${AppHelper.articleURL(article)}`}>
        <div
          className="new_background primary_new"
          style={{
            backgroundImage: article
              ? `url(${ApiHelper.adminRoute}/files/${article.previewImage})`
              : `url(https://www.diariomotor.com/imagenes/2015/11/mazda-3.jpg)`,
          }}
        >
          <div className="background_overlay position-absolute w-100 h-100 p-2 d-flex flex-column justify-content-end fixed-top">

            {article.epigraph && (
              <p
                dangerouslySetInnerHTML={{ __html: article.epigraph }}
                className="text-white mb-2 preview"
              />
            )}
            <h3 className="text-white">
              {article ? article.title : "NOTICIA DE PRIMERA JERARQUÍA"}
            </h3>
          </div>
        </div>
      </Link>
    </article>
  );

}

export default PrimaryNew
