import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

export const ProfileUsers = withRouter(({ history }) => {
  const pro = useSelector((state) => state.user.pro);

  useEffect(() => {
    if (!pro) {
      history.push("/perfil/datos");
    }
  }, []);

  return (
    <>
      <div className="background_gray py-5">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <span className="font-weight-bold mb-2">
                Buscar usuario via E-mail
              </span>
              <input
                name={"user"}
                className={"custom_input default-shadow p-2 w-100 my-3"}
                placeholder={"Usuario"}
                type={"text"}
              />
            </div>
            <div className="col-6 d-flex align-items-center">
              <p className={"link"}>
                {" "}
                <FontAwesomeIcon icon={faPlusCircle} className={"mr-2"} />{" "}
                Vincular Usuario
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={"my-4"}>
        <div className="container">
          <div className="row">
            <div className="col-6">
              <p>
                <strong>Nombre y Apellido</strong>
              </p>
            </div>
            <div className="col-6">
              <p>
                <strong>Correo electrónico</strong>
              </p>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-6">
              <p className={"custom_input default-shadow p-2 w-100"}>Sandra</p>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <small>
                  Infocréditos disponibles: <strong>50</strong>
                </small>
                <small className="text-uppercase">Ver detalle de usuario</small>
              </div>
            </div>
            <div className="col-6">
              <p className={"custom_input default-shadow p-2 w-100"}>
                sfilaretto@gmail.com
              </p>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-6">
              <p className={"custom_input default-shadow p-2 w-100"}>Sandra</p>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <small>
                  Infocréditos disponibles: <strong>50</strong>
                </small>
                <small className="text-uppercase">Ver detalle de usuario</small>
              </div>
            </div>
            <div className="col-6">
              <p className={"custom_input default-shadow p-2 w-100"}>
                sfilaretto@gmail.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
