import React from "react";
import imgHistorico from "../static/img/img-precio-historico.jpeg";

function PreguntasFreq() {
  return (
    <div className="page_default_margin">
      <div className={"container mb-5"}>
        <h2 className="font-weight-bolder">Preguntas Frecuentes</h2>
        <hr />
        <h4 className="mb-4">
          <b>Sobre IA</b>
        </h4>

        <div className="mb-2">
          <h5>
            <b>¿Quiénes somos?</b>
          </h5>

          <p>
            El Grupo IA es la única <b>consultora independiente en Latam</b>{" "}
            especializada en la <b>investigación del mercado automotor </b>{" "}
            argentino, nuestro compromiso es brindar respaldo para simplificar
            la toma decisiones y la administración de datos a escala a todos
            nuestros clientes. Para ello, creamos hace más de 24 años la{" "}
            <b> Guía oficial de precios de vehículos de Argentina.</b>
          </p>
        </div>
        <div className="mb-2">
          <h5>
            <b>¿Qué hacemos?</b>
          </h5>

          <p>
            Elaboramos a través de la metodología de investigación ® InfoAuto la{" "}
            <b>Guía oficial de precios vehículos 0 km y usados.</b>
          </p>
        </div>
        <hr />
        <h4 className="mb-4">
          <b>Guía de precios</b>
        </h4>

        <div className="mb-2">
          <h5>
            <b>¿Por qué IA es la Guía Oficial de Precios?</b>
          </h5>
          <p>
            Porque la utiliza para trabajar la{" "}
            <b>
              {" "}
              totalidad del mercado automotor, asegurador, financiero y
              prendario.{" "}
            </b>{" "}
            A su vez es la referencia de precios para{" "}
            <b> usuarios particulares </b> que quieren comprar o vender su
            vehículo.
          </p>
        </div>
        <div className="mb-2">
          <h5>
            <b>¿Cómo se elabora la Guía Oficial de Precios?</b>
          </h5>
          <p>Nuestro método de trabajo se compone de tres grandes pilares:</p>
        </div>
        <div className="ml-3">
          <h6>
            <b>Tecnología:</b>
          </h6>

          <p>
            • Software: Metodología de investigación única en LATAM: enfocada en
            abordar la complejidad del mercado automotor.
            <br />• Big data: Procesamos y analizamos grandes cantidades de
            datos macro/micro económicas del mercado como: monto de
            transferencias, transacciones de compra-venta, subastas, precios 0km
            de lista oficiales entre otros.
          </p>
        </div>

        <div className="ml-3">
          <h6>
            <b>Metodología:</b>
          </h6>

          <p>
            • Trayectoria: Nuestra experiencia a través de los años y la
            búsqueda de una constante optimización de nuestros procesos y
            métodos de investigación, dan como resultado una base de información
            única, objetiva y confiable.
            <br />• Mejora continua: Nuestro método se fortalece a partir del
            aprendizaje e intercambio continuo que generamos con nuestros
            clientes.
          </p>
        </div>

        <div className="ml-3">
          <h6>
            <b>Enfoque:</b>
          </h6>

          <p>
            • Análisis cualitativo: Contamos con un equipo de especialistas
            técnicos abocados a examinar en tiempo real, diversos factores que
            afectan las valuaciones del parque automotor.
            <br />
            • Monitoreo constante: Realizamos ajustes según estacionalidad,
            tendencias del mercado, variables económicas, comportamiento de
            usuarios y evolución del mercado global.
            <br />• Objetividad: Nuestro compromiso es realizar un análisis
            imparcial, identificando variables de desvió que puedan distorsionar
            valuaciones de mercado.
          </p>
        </div>

        <div className="ml-3">
          <h6>
            <b>Innovación:</b>
          </h6>
          <p>
            • Vanguardia: Nuestro objetivo es estar un paso delante de los
            acontecimientos que puedan ocurrir en el mercado nacional e
            internacional. Por eso, contamos con un equipo periodístico cuya
            misión es investigar y cubrir las últimas novedades y primicias del
            universo automotor. Esta selección de contenidos, nutre y
            complementa el análisis del equipo técnico y nuestro portal de
            noticias.
            <br />• Evolución: A lo largo de más de 25 años de trayectoria,
            hemos afrontado un proceso de optimización constante enfocado en la
            búsqueda permanente de mejora continua de nuestro método de
            investigación y procesos de gestión.
          </p>
        </div>
        <hr />
        <div className="mb-4 mt-4">
          <h5>
            <b>¿Por qué es importante utilizar la Guía Oficial de Precios?</b>
          </h5>
          <p>
            Porque para tomar decisiones importantes hay que estar bien
            informado. IA es la <b>única fuente independiente y objetiva </b> de
            provisión de precios de mercado. Solo nos dedicamos a la{" "}
            <b> investigación y análisis </b> del Mercado Automotor.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Por qué es información real en tiempo real?</b>
          </h5>
          <p>
            El valor REAL de los vehículos en Argentina se ve afectado por
            variables del mercado. A lo largo del mes, realizamos una{" "}
            <b>exhaustiva investigación </b> de mercado con alcance Nacional,
            analizando las variaciones de precios en los vehículos y
            confeccionando la Guía Oficial de Precios. Actualizada en tiempo
            REAL.
          </p>
        </div>

        <div className="mb-4">
          <h5>
            <b>
              ¿Qué diferencia hay entre los precios publicados en las páginas de
              venta de vehículos y los precios exhibidos en IA?
            </b>
          </h5>
          <p>
            Estudios revelan que una misma unidad puede estar publicado hasta 20
            veces con precios distintos. A su vez cada vendedor puede variar el
            precio de venta de su auto según intereses personales que no siempre
            están alineados con la realidad del mercado.
          </p>
          <p>
            {" "}
            Como consultora independiente y objetiva, IA analiza las tendencias
            y las variables de impacto que afectan los precios de los vehículos.
            Utilizando tecnología única que permite procesar y analizar grandes
            cantidades de datos estadísticos y económicos.
          </p>
        </div>

        <div className="mb-4">
          <h5>
            <b>¿Cada cuánto se actualizan los valores?</b>
          </h5>
          <p>
            Los valores informados se actualizan en <u>forma mensual</u>, el 1er
            día de cada mes.
          </p>
        </div>

        <div className="mb-4">
          <h5>
            <b>¿Cuál es el alcance y cobertura de la los valores IA?</b>
          </h5>
          <p>
            Nuestro servicio abarca todos los vehículos comercializados en
            Argentina durante los últimos 30 años.
          </p>
        </div>

        <div className="mb-4">
          <h5>
            <b>¿Cómo está compuesta la base de datos de vehículos de IA?</b>
          </h5>
          <p>
            Total de modelos aprox: <b>15.000</b>
            <br />
            Tipos de vehículos: autos, motos, pick-ups, utilitarios y camiones
            <br />
            Fichas técnicas: Motor y transmisión/ Datos técnicos/ Seguridad/
            Confort
          </p>
        </div>

        <div className="mb-4">
          <h5>
            <b>¿Qué es un modelo?</b>
          </h5>
          <p>
            Se entiende por modelo a cada uno de los vehículos de igual
            denominación que tiene una marca.
            <br />
            Un modelo puede agrupar varios vehículos con diferente cilindrada,
            motorización y equipamiento.
            <br />
            Ejemplo:
            <br />
            Marca: Peugeot
            <br />
            Modelo: 208
          </p>
        </div>

        <div className="mb-4">
          <h5>
            <b>¿Qué es una versión?</b>
          </h5>
          <p>
            Es un vehículo especifico agrupado dentro de una marca y modelo.
            Cada marca asigna nombres a estas versiones dependiendo de su propia
            política más que por consideraciones técnicas.
            <br />
            <b>
              <u>Ejemplo:</u>
            </b>
            <br />
            Marca: Peugeot
            <br />
            Modelo: 208
            <br />
            <b>Versiones:</b>
            <ul>
              <li>208 1.5 5P ACTIVE</li>
              <li>208 1.5 5P ALLURE</li>
              <li>208 1.5 5P ALLURE NAV</li>
              <li>208 1.6 5P ACTIVE</li>
              <li>208 1.6 5P ALLURE</li>
              <li>208 1.6 5P ALLURE NAV TIPTRONIC</li>
              <li>208 1.6 5P ALLURE TOUCH</li>
              <li>208 1.6 5P FELINE</li>
              <li>208 1.6 5P FELINE PK CUIR</li>
              <li>208 1.6 5P FELINE TIPTRONIC</li>
            </ul>
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Qué es un Usado del Año?</b>
          </h5>
          <p>
            Es el valor de un auto recientemente patentado con más de 3 meses de
            uso. Los precios de usados del año se disponibilizan a partir de
            Abril hasta Diciembre inclusive
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Qué es un Precio Histórico?</b>
          </h5>
          <p>
            Es una opción de búsqueda que ofrece IA, para consultar la
            cotización histórica mes a mes que tuvo un vehículo en los últimos
            10 años.
          </p>
        </div>
        <hr />
        <h4 className="mb-4">
          <b>Contratación</b>
        </h4>
        <div className="mb-4">
          <h5>
            <b>¿Necesitás que te ayudemos con tu compra?</b>
          </h5>
          <p>
            Escribinos a:
            <br />
            <a href="mailto:info@infoauto.com.ar">Info@infoauto.com.ar</a>
            <br />
            <a href="https://wa.me/541156385906/?text=Hola+InfoAuto%21+Quisiera+hacer+una+consulta">
              Contactanos via WhatsApp
            </a>
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Cómo hacer un Up grade de servicio? </b>
          </h5>
          <p>
            Contamos con diferentes planes por segmento y según las necesidades
            de consulta que tiene cada uno de nuestros clientes, si necesitas
            ampliar tu plan o comprar InfoCreditos para seguir consultando, en
            tu perfil de usuario/Plan tenes la opción de Upgrade con un solo
            clic
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Qué es un InfoCredito?</b>
          </h5>
          <p>
            Son packs para agregar consultas de precios de usados en caso de que
            hayas consumido los de tu suscripción, se compran y acreditan en el
            momento. Tenes 3 disponibles: de 15 / 45 / 60 consultas.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Puedo agregar más InfoCreditos a mi suscripción?</b>
          </h5>
          <p>
            Podés comprar fácilmente en el momento si te quedaste sin crédito en
            tu plan. Podes acceder desde tu perfil de usuario – Planes –Agregar
            InfoCreditos – y se cargara a tu tarjeta de crédito con la cual
            abonaste la suscripción de tu plan actual. El pago es por única vez,
            y la activación es inmediata para que puedas seguir consultando
            precios de usados.
            <br />
            Podés agregar InfoCreditos todas las veces que quieras, pero{" "}
            <b>
              tene en cuenta que al finalizar el mes volverás a tener la
              cantidad de InfoCreditos que están incluidos dentro de tu
              suscripción mensual.
            </b>
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Qué es InfoGrid Grupal?</b>
          </h5>
          <p>
            <b>InfoGrid Grupal</b> es un plan de suscripción a medida para
            empresas que requieran contratar el servicio para grupos de
            usuarios. Podes solicitar un <b>presupuesto a medida</b> . Completa
            el formulario y nuestro equipo comercial se comunicará a la
            brevedad.
          </p>
        </div>

        <div className="mb-4">
          <h5>
            <b>¿Necesitas un servicio a medida para Grandes Cuentas ?</b>
          </h5>
          <p>
            {" "}
            IA cuenta con un equipo exclusivo de atención a empresas. Podés
            solicitar <b>asesoramiento </b> y un <b>presupuesto a medida</b> en
            forma personalizada y directa. Adicionalmente al nuestros planes de
            integración vía Api para motorizar sistemas de gestión, contamos con
            una amplia gama de soluciones para poder resolver las necesidades
            del mercado. Completa el formulario y nuestro equipo comercial de
            Grandes Cuentas se comunicará a la brevedad.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Puedo probar el servicio antes de contratarlo?</b>
          </h5>
          <p>
            {" "}
            ¡Si! Solamente con registrarte podes utilizar nuestras herramientas
            básicas de búsqueda, consultar precios de 0km libremente y hasta
            tres vehículos usados.
          </p>
        </div>
        <hr />
        <h4 className="mb-4">
          <b>InfoAuto App</b>
        </h4>
        <div className="mb-4">
          <h5>
            <b>¿Cómo descargo la App?</b>
          </h5>
          <p>Podes descargar nuestra app desde Google Play o App Store.</p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Qué puedo hacer desde la App?</b>
          </h5>
          <p>
            No importa donde estés, nuestra app está diseñada para que tengas a
            tu disposición las mismas herramientas que en nuestro sitio web pero
            con visualización simplificada.
            <br />
            De forma simple y rápida encontrá toda la información que necesitas
            y llevala en tu bolsillo.
          </p>
        </div>
        <hr />
        <h4 className="mb-4">
          <b>Mi Cuenta</b>
        </h4>
        <div className="mb-4">
          <h5>
            <b>
              ¿Cómo activo mi cuenta si ya tenía contratada una suscripción en
              la versión anterior de la web de Info Auto?
            </b>
          </h5>
          <p>
            Recuperá tu cuenta haciendo click aquí. Solo tendrás que ingresar el
            mail que tenes asociado a tu suscripción activa. Si no recordás cuál
            es tu mail ponete en contacto con nosotros haciendo click aquí.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Qué puedo hacer desde mi perfil?</b>
          </h5>
          <p>
            Tu perfil es donde se encuentra toda la información de tu cuenta. En
            esta sección podés editar y completar tu información básica (al
            hacerlo sumaras 20 InfoCreditos). Además podés visualizar y editar
            los datos de tu plan y también solicitar la baja del mismo.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Puedo pasar de plan mensual a un plan anual?</b>
          </h5>
          <p>
            No es posible cambiar la frecuencia de pago de mensual a anual
            dentro de una suscripción activa. Para cambiar a un plan anual y
            acceder al 10% de descuento, tenes que dar de baja tu suscripción y
            generar una nueva.
          </p>
        </div>

        <hr />
        <h4 className="mb-4">
          <b>Servicios</b>
        </h4>
        <div className="mb-4">
          <h5>
            <b>¿Qué tipo de servicios ofrecen?</b>
          </h5>
          <p>
            Tenemos un servicio adaptado para cada necesidad según el perfil y
            necesidades de acceso a información de nuestros clientes:
            <br />
            a. <b>Planes para Particulares:</b> que quieran informarse para
            realizar alguna operación de compra/venta.
            <br />
            b. <b>Planes para Profesionales de la Industria: </b> Productores de
            Seguros, liquidadores, inmobiliarias, agentes de compra-venta,
            peritos, etc... y requieran una herramienta de consulta para
            facilitarte la gestión diaria.
            <br />
            c. <b>Servicio Corporativo:</b> Para empresas que requieren
            servicios a medida para automatizar diferentes procesos.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>
              ¿Con qué criterio se consumen los InfoCreditos que tengo
              disponibles en mi suscripción?
            </b>
          </h5>
          <p>
            Nuestras bases de consulta de precios se actualizan el 1er día de
            cada mes, si consultas un vehículo dentro del mes, solo se te
            descuenta 1 InfoCredito, pudiendo consultarlo las veces que
            necesites, podes acceder desde tu Historial de consultas en tu
            perfil de Usuario.
            <br />
            El paquete de InfoCreditos es valido para consumir del 1 al 31 de
            cada Mes.
            <br />
            Al inicio de un nuevo mes, se actualizan los precios y te vuelven a
            acreditar el total de los InfoCreditos de tu suscripción.
            <br />
            Importante: Los InfoCreditos no son acumulables mes a mes, se
            renuevan cada vez que se actualiza la base de precios, mes a mes.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>
              ¿Qué diferencia hay entre servicios para particulares y
              profesionales?
            </b>
          </h5>
          <p>
            Sabemos que cada uno de nuestros clientes tienen necesidades
            diferentes. Por eso desarrollamos dos soluciones completamente
            distintas, diseñadas para optimizar el uso y la visualización de la
            información.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Cuáles son las opciones de pago del servicio?</b>
          </h5>
          <p>
            El medio de pago para pagar tu suscripción mensual o anual es
            Tarjeta de Crédito.{" "}
          </p>
        </div>
        <hr />
        <h4 className="mb-4">
          <b>Funcionalidades</b>
        </h4>
        <div className="mb-4">
          <h5>
            <b>¿Qué es una ficha técnica visual?</b>
          </h5>
          <p>
            Es un modo de visualizar los datos técnicos más destacados de un
            vehículo de manera practica y sencilla.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Qué son las valoraciones de usuarios?</b>
          </h5>
          <p className="mb-0">
            Es un espacio donde los usuarios registrados pueden otorgar una
            puntuación para cada vehículo según las siguientes categorías:
          </p>
          <ul className="mb-0">
            <li>Calidad</li>
            <li>Fiabilidad</li>
            <li>Performance</li>
            <li>Confort</li>
            <li>Estilo</li>
          </ul>
          <p>
            El resultado exhibido es la puntuación ponderada de todas las
            opiniones acumuladas que se convierten en un puntaje promedio.
            <br />
            IA no participa en estas valoraciones, es un recurso exclusivo para
            uso de nuestros clientes.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Qué es la búsqueda asistida?</b>
          </h5>
          <p>
            Encontrar la versión que estás buscando, no siempre es tarea
            sencilla. Existen Modelos que agrupan hasta 250 versiones distintas.
            Por eso desarrollamos esta herramienta de búsqueda para que puedas
            encontrar de manera ágil el modelo que necesitas consultar.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Qué es la búsqueda visual?</b>
          </h5>
          <p>
            No siempre sabes que modelo o versión buscas. Para estos casos
            creamos la búsqueda visual, la cual permite filtrar por tipo de
            vehículo, rango de precios y características técnicas.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Qué es InfoGrid ?</b>
          </h5>
          <p>
            Es un software especialmente diseñado para simplificar la gestión
            que llevan adelante los profesionales de la industria automotriz.
            Ofrece la visualización de la Guía Oficial de Precios en formato
            grilla optimizando el tiempo de consulta y la calidad de la
            información brindada al cliente final.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Qué es la revista digital? </b>
          </h5>
          <p>
            Muchos de nuestros usuarios necesitan tener una solución ágil para
            consultar la Guía Oficial de Precios sin perderse el contenido
            editorial de nuestra revista. Por ello incluimos un visualizador que
            te permite ver la revista del mes on line dentro de nuestro sitio
            web.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Qué contiene la guía útil?</b>
          </h5>
          <p className="mb-0">
            La guía útil fue pensada para aquellos compradores que quieren saber
            qué pasos no deben omitir a la hora de comprar un auto. Cuanta con
            una serie de InfoTips redactados por el departamento técnico de IA:
            <br />
            <br />
            Guías útiles disponibles:
          </p>
          <ul>
            <li>Pasos para comprar tu primer auto</li>
            <li>Pasos comprar un usado</li>
            <li>Pasos para comprar un 0 km</li>
          </ul>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Qué es el historial de consultas?</b>
          </h5>
          <p>
            Es un servicio de visualización para usuarios con suscripción
            activa, desde tu perfil podrás acceder al histórico de todas las
            consultas de precios que realizaste en el transcurso del mes.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Qué son mis autos favoritos?</b>
          </h5>
          <p>
            Es una funcionalidad que te permite agrupar y visualizar los autos
            que sean de particular interés para vos.
          </p>
        </div>
        <div className="mb-4">
          <h5>
            <b>¿Cómo consulto un precio histórico?</b>
          </h5>
          <p>
            Podés acceder a esta herramienta una vez que hayas accedido a la
            Ficha Técnica del vehículo que quieras consultar.
          </p>
          <img
            src={imgHistorico}
            alt="img-precio-historico"
            className="col-12 col-md-8"
          />
        </div>
      </div>
    </div>
  );
}

export default PreguntasFreq;
