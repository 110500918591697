import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  COMPARATOR,
  COMPARATOR_FOUR_VEHICLES,
} from "static/permissions/sections";
import { ApiHelper, AppHelper } from "../../helpers";
import defaultCarImage from "../../static/img/covered_car.jpg";
import defaultMotorcycleImage from "../../static/img/covered_motorcycle.png";
import actions from "../../store/actions";
import { CarPic, ManufacturingYears } from "../atoms";
import { CarDescription, PriceOptions } from "../molecules";

export const CarProfile = ({ vehicle, newPrice }) => {
  const vehicleType = useSelector((state) => state.search.vehicle);
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const [images, setImages] = useState();
  const dispatch = useDispatch();

  const years = vehicle.version.years.length ? vehicle.version.years : vehicle.years

  useEffect(() => {
    const carouselImages = async () => {
      const res = await ApiHelper.get(
        "preciosa",
        `/models/${vehicle.version.versionCode}/photos`,
        { vehicleType: vehicleType }
      );
    };
    carouselImages();
  }, []);

  const compare = () => {
    if (AppHelper.hasPermission(user, COMPARATOR)) {
      let codias = localStorage.comparatorCodias
        ? JSON.parse(localStorage.comparatorCodias)
        : [];
      if (
        codias.length === 0 ||
        codias.some((c) => c.vehicleType == vehicleType)
      ) {
        if (codias.some((c) => c.codia == vehicle.version.versionCode)) {
          history.push(`/comparador`);
        } else if (
          codias.length < 2 ||
          (AppHelper.hasPermission(user, COMPARATOR_FOUR_VEHICLES) &&
            codias.length < 4)
        ) {
          codias = [
            ...codias,
            { codia: vehicle.version.versionCode, vehicleType },
          ];
        } else {
          codias[0] = { codia: vehicle.version.versionCode, vehicleType };
        }
      } else {
        codias = [{ codia: vehicle.version.versionCode, vehicleType }];
      }
      localStorage.comparatorCodias = JSON.stringify(codias);
      history.push(`/comparador`);
    } else {
      dispatch(
        actions.app.setLoginMessage(
          "Para esta funcionalidad debes estar registrado"
        )
      );
      dispatch(actions.app.setShowRegister(true));
    }
  };

  return (
    <div id="car_profile_container">
      <div className="row">
        <div className="col-xl-8">
          {location.pathname.includes("cars") ? (
            <CarPic
              url={
                vehicle.photoUrl ||
                vehicle.photo_url ||
                defaultCarImage.default ||
                defaultCarImage
              }
            />
          ) : vehicle?.model?.name.includes("ATV") ? (
            <CarPic
              url={
                vehicle.version.photoUrl
                  ? vehicle.version.photoUrl
                  : defaultMotorcycleImage.default
                  ? defaultMotorcycleImage.default
                  : defaultMotorcycleImage
              }
            />
          ) : (
            <CarPic
              url={
                vehicle.version.photoUrl
                  ? vehicle.version.photoUrl
                  : defaultMotorcycleImage.default
                  ? defaultMotorcycleImage.default
                  : defaultMotorcycleImage
              }
            />
          )}
        </div>
        <div className="col-xl-4 d-flex flex-column justify-content-between">
          <CarDescription vehicle={vehicle} type={vehicleType} />
          {/* {vehicle.hasNewPrice ? (
            <PriceOptions price={newPrice.list_price} />
          ) : null} */}
          <PriceOptions price={newPrice?.list_price} compare={compare} />
        </div>
      </div>
      {console.error('vehicle', vehicle)}
      <ManufacturingYears years={years} />
      {/*<div className="row mt-2">*/}
      {/*  <div className="col-12">*/}
      {/*    */}
      {/*  /!*<div className="col-xl-4">*!/*/}
      {/*  /!*  <small className={'mb-0'}>Todos los valores expresados son en <span className='font-weight-bold'>pesos argentinos.</span></small>*!/*/}
      {/*  /!*</div>*!/*/}
      {/*</div>*/}
    </div>
  );
};
