import React, { useEffect, useState } from 'react'
import { ApiHelper } from '../../helpers'
import AdXL from '../ads/AdXL'
import { CircleGraph, NewParagraph } from '../atoms'
import { NewsSocialBar } from '../molecules'

export const ReleaseNewApp = ({ article }) => {
	const [blocks, setBlocks] = useState([])

	const { date, source, tagList } = article




	const [paragraphs] = useState([
		{
			title: 'Medidas',
			img: 'https://lh3.googleusercontent.com/proxy/6UQ8bPrxfhuv3499awceyJ9tfob4PMXTdzDOWYSZQKPLglAT_XSmmM4kW40VUH-c7-y32WJ4dfibwUXFSDfbwJblEOjvrNsEiz4M9MhfcBqvhJpiJg4OMnB-5amk4A',
			text: 'Fusing edge and exclusivity with obsessive performance, this is the IS F SPORT Black Line Special Edition. Limited to 900 vehicles, this unique edition boasts exclusive features like sleek, black outside mirrors and 18-inch split-five-spoke F SPORT alloy wheels* with a Black Vapo',
		},
		{
			title: 'Medidas',
			img: 'https://lh3.googleusercontent.com/proxy/6UQ8bPrxfhuv3499awceyJ9tfob4PMXTdzDOWYSZQKPLglAT_XSmmM4kW40VUH-c7-y32WJ4dfibwUXFSDfbwJblEOjvrNsEiz4M9MhfcBqvhJpiJg4OMnB-5amk4A',
			text: 'Fusing edge and exclusivity with obsessive performance, this is the IS F SPORT Black Line Special Edition. Limited to 900 vehicles, this unique edition boasts exclusive features like sleek, black outside mirrors and 18-inch split-five-spoke F SPORT alloy wheels* with a Black Vapo',
		},
		{
			title: 'Medidas',
			img: 'https://lh3.googleusercontent.com/proxy/6UQ8bPrxfhuv3499awceyJ9tfob4PMXTdzDOWYSZQKPLglAT_XSmmM4kW40VUH-c7-y32WJ4dfibwUXFSDfbwJblEOjvrNsEiz4M9MhfcBqvhJpiJg4OMnB-5amk4A',
			text: 'Fusing edge and exclusivity with obsessive performance, this is the IS F SPORT Black Line Special Edition. Limited to 900 vehicles, this unique edition boasts exclusive features like sleek, black outside mirrors and 18-inch split-five-spoke F SPORT alloy wheels* with a Black Vapo',
		},
		{
			title: 'Medidas',
			img: 'https://lh3.googleusercontent.com/proxy/6UQ8bPrxfhuv3499awceyJ9tfob4PMXTdzDOWYSZQKPLglAT_XSmmM4kW40VUH-c7-y32WJ4dfibwUXFSDfbwJblEOjvrNsEiz4M9MhfcBqvhJpiJg4OMnB-5amk4A',
			text: 'Fusing edge and exclusivity with obsessive performance, this is the IS F SPORT Black Line Special Edition. Limited to 900 vehicles, this unique edition boasts exclusive features like sleek, black outside mirrors and 18-inch split-five-spoke F SPORT alloy wheels* with a Black Vapo',
		},
	])

	const formattedDate = new Date(date)
	const day = formattedDate.getDate()
	const month = formattedDate.getMonth()
	const year = formattedDate.getFullYear()

	const months = [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
	]


	useEffect(() => {
		blocksSort()
	}, [article])

	const blocksSort = () => {
		let blocks = article.blockList
		blocks.sort(function (a, b) {
			return a.id - b.id
		})
		setBlocks(blocks)
	}

	return (
		<div id={'release_new'}>

			<div className={'p-3 container'}>
				<div className='container'>
					<div id='news_social_bar' >
						<div>
							<p className='mb-0'>
								{date && `${day} de ${months[month]} ${year}`} | {source}
							</p>
						</div>
						<div>
							<div id='tags_container' className={'ml-3 mb-2 '} style={{ overflowX: 'auto' }}>
								{tagList?.map((tag, index) => (
									<span key={index} className={'mr-3 new_tag text-uppercase'}>
										#{tag}
									</span>
								))}
							</div>
						</div>
					</div>
					<p className={'mt-3'} dangerouslySetInnerHTML={{ __html: article.epigraph }} />
					<div className='row px-1 px-md-5' align='center'>
						<div className='col-6 col-lg-3 px-5 circle-graph'>
							<CircleGraph
								unit={'HP'}
								value={article.power ? article.power : 90}
								label={'Potencia'}
								max={600}
							/>
						</div>
						<div className='col-6 col-lg-3 px-5 circle-graph'>
							<CircleGraph
								unit={'KMPH'}
								value={article.speed ? article.speed : 120}
								label={'Velocidad Máxima'}
								max={400}
							/>
						</div>
						<div className='col-6 col-lg-3 px-5 circle-graph'>
							<CircleGraph
								unit={'LITROS'}
								value={article.fuel ? article.fuel : 40}
								label={'Tanque de Combustible'}
								max={500}
							/>
						</div>
						<div className='col-6 col-lg-3 px-5 circle-graph'>
							<CircleGraph
								unit={'LITROS'}
								value={article.trunk ? article.trunk : 50}
								label={'Capacidad del baul'}
								max={3000}
							/>
						</div>
					</div>
				</div>

				<div className='col-12 p-5'>
					<div className='row mt-4'>
						<table className={'w-100 table-responsive-sm table-responsive-md'}>
							<thead>
								<tr className='text-center'>
									<th>Versión</th>
									<th className='px-4 px-lg-0'>Motor</th>
									<th>Potencia</th>
									<th className='px-4 px-lg-0'>Caja</th>
									<th>Consumo</th>
									<th className='px-4 px-lg-0'>Seguridad</th>
									<th>Precio</th>
								</tr>
							</thead>
							<tbody>
								{article.launchAttributes.map((v) => {
									return (
										<tr className={'text-center'}>
											<td className={'text-center'}>{v?.version}</td>
											<td className={'text-center'}>{v?.engine}</td>
											<td className={'text-center'}>{v?.power}</td>
											<td className={'text-center'}>{v?.gearBox}</td>
											<td className={'text-center'}>{v?.consumption}</td>
											<td className={'text-center'}>{v?.security}</td>
											<td className={'text-center'}>${v?.price}</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
					<div className='row mt-4'>
						<AdXL subsection='-lanzamiento-1' />
					</div>
					<div className='row mt-4 '>
						{blocks.map((block, index) => (
							<NewParagraph index={index} key={block.id} paragraph={block} />
						))}
					</div>
				</div>


				<div className='row mt-4'>
					<div className='col-12'>
						<AdXL subsection='-lanzamiento-2' />
					</div>
				</div>
			</div>
		</div>
	)
}
