import actionsTypes from "../actions/actionTypes";

const initialState = {
  filters: [],
  vehicle: localStorage.vehicleType || "cars",
  searchBarResults: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionsTypes.CLEAR_SEARCH:
      return Object.assign({}, state, {
        filters: [],
      });
    case actionsTypes.SET_PRIMARY_SEARCH:
      return Object.assign({}, state, {
        filters: [...action.search],
      });
    case actionsTypes.REMOVE_SEARCH_ELEMENT:
      return Object.assign({}, state, {
        filters: state.filters.filter((e) => e !== action.element),
      });
    case actionsTypes.SET_SEARCH:
      return Object.assign({}, state, {
        filters: action.search,
      });
    case actionsTypes.SET_VEHICLE_TYPE:
      return Object.assign({}, state, {
        vehicle: action.vehicleType,
        filters: [],
      });
    case actionsTypes.SET_SEARCH_BAR_RESULTS:
      return Object.assign({}, state, {
        searchBarResults: action.results,
      });
    default:
      return state;
  }
}
