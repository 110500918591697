import React from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import actions from '../../store/actions'

const CarTypeCard = ({ history, vehicle, type, big, canNavigate = true }) => {
	const dispatch = useDispatch()
	const navigate = () => {

		dispatch(actions.search.setVehicleType(vehicle))
		history.push(`/busqueda-visual/${vehicle}/${type.id}`)
	}

	return (
		<div className={`my-2 link`} onClick={canNavigate && navigate}>
			<div className='text-center'>
				{type.img ? (
					<img
						src={type.img}
						className={'vs-car-img w-75 p-3 p-md-1'}
						alt='Imágen Tipo del Vehículo'
					/>
				) : null}
				<p className={type.img ? null : 'mt-2'}>{type.name}</p>
			</div>
		</div>
	)
}

export default withRouter(CarTypeCard)
