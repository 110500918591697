import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../../store/actions'
import SearchBar from './SearchBar'
import TypeFilter from './TypeFilter'
import { ApiHelper, AppHelper } from 'helpers'
import { SUBSCRIBE_BUTTON } from 'static/permissions/sections'

const Banner = () => {
	const [background, setBackground] = useState('bg1')
	const user = useSelector((state) => state.user.user)
	const dispatch = useDispatch()

	const notUserOrFree = !user || user.activeSubscription?.name === 'Free'
	const [price, setPrice] = useState(0)

	useEffect(() => {
		getSubscriptionPrice()
	}, [])

	useEffect(() => {
		setTimeout(() => {
			setBackground(background === 'bg1' ? 'bg2' : 'bg1')
		}, 10000)
	}, [background])

	const getSubscriptionPrice = async () => {
		const { data: subscriptions } = await ApiHelper.get('admin', '/subscription/template')
		const cheapestSubscription = subscriptions.find(
			(s) => s.type === 'monthly' && s.subscriptionType.subscriptionCode === 'individualCar'
		)
		setPrice(cheapestSubscription.price)
	}

	return (
		<header className={`masthead ${background}`}>
			<div className='container h-100 py-4 px-0'>
				<div className='row h-100 align-items-center justify-content-center no-gutters'>
					<div className='col-12 text-center px-4'>
						<h1 className='font-weight-bolder text-uppercase text-light display-4 mb-5'>
							Bienvenido a la guía oficial de precios
						</h1>
						<div className='row d-flex align-items-center no-gutters mb-0'>
							<div className={`col-12 mx-auto ${notUserOrFree ? 'col-lg-3' : 'col-lg-12'}`}>
								<TypeFilter />
							</div>
							{notUserOrFree && (
								<p className=' col-12 col-lg-9 text-uppercase text-light spacing_3 text-lg-right '>
									Consultá{' '}
									<span className={'font-weight-bolder'} style={{ fontSize: '16px' }}>
										gratis
									</span>
									<wbr></wbr> 0km o desde<wbr></wbr> ${price} / mes
								</p>
							)}
						</div>
						<div style={{ height: '70px' }}>
							<SearchBar />
						</div>
						{!user && (
							<div className='mt-4 mb-3'>
								<a href={'/busqueda-asistida'}>
									<h6 className='mb-0 text-white text-uppercase font-weight-bolder'>
										Búsqueda asistida{' '}
										<span>
											<FontAwesomeIcon icon={faChevronRight} />
										</span>
									</h6>
								</a>
							</div>
						)}
						{AppHelper.hasPermission(user, SUBSCRIBE_BUTTON) && (
							<button
								onClick={() =>
									user
										? (window.location = `${window.location.origin}/perfil/plan`)
										: dispatch(actions.app.setShowRegister(true))
								}
								id='suscribe_button'
								type='button'
								className='btn btn-light btn-sm text-uppercase mt-4 mb-3 py-2 font-weight-bolder spacing_3'
							>
								SUSCRIBITE
							</button>
						)}
						<div>
							<a
								href={'/nosotros'}
								className='mt-4 link text-uppercase font-weight-bold text-center spacing_2'
								id='more_info_button'
							>
								<span id='more_info_button_text'>Saber más</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Banner
