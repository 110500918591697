import actionTypes from "./actionTypes";

const searchActions = {
    setFilterTypes: filters => {
        return {
            type: actionTypes.SET_FILTER_TYPES,
            filters: filters
        }
    },

};


export default searchActions;
