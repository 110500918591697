import React from 'react'
import { useSelector } from 'react-redux'
import { CarTypeCard } from '../atoms'

export const VisualSelector = () => {
	const vehicleTypes = useSelector((state) => state.vehicleTypes)
	const types = useSelector((state) => state.visualSearch.types)


	return (
		<div id='visual_selector_container' className='mt-5'>
			<div className='row no-gutters grey-container pb-4'>
				{types['cars'].map(
					(type) =>
						type.img && (
							<div className={`col-6 col-sm-4 col-md-3 `} key={type.id}>
								<CarTypeCard type={type} vehicle={'cars'} />
							</div>
						)
				)}
			</div>

			<div className='row no-gutters light-grey-container justify-content-center'>

				{types['cars'].map(
					(type) =>
						!type.img && (
							<div className={`col-6 col-sm-4 col-md-3 `} key={type.id}>
								<CarTypeCard type={type} vehicle={'cars'} />
							</div>
						)
				)}
			</div>

			<div className='row no-gutters grey-container justify-content-center'>

				{types['motorcycles'].map(
					(type) =>
						type.img && (
							<div className={`col-6 col-sm-4 col-md-3 `} key={type.id}>
								<CarTypeCard type={type} vehicle={'motorcycles'} />
							</div>
						)
				)}
			</div>
		</div>
	)
}
