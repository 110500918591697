import permissions from '../static/permissions'
import { COMPARATOR_FOUR_VEHICLES } from 'static/permissions/sections'
import { CARS, MOTORBIKES } from 'static/vehicleTypes'
import carImage from 'static/img/covered_car.jpg'
import motorbikeImage from 'static/img/covered_motorcycle.png'

class AppHelper {
	static DEV = 'DEV'
	static PROD = 'PROD'

	static appStatus = this.DEV

	static getAppStatus = () => {
		return this.appStatus
	}

	static DataURIToBlob(dataURI) {
		const splitDataURI = dataURI.split(',')
		const byteString =
			splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
		const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

		const ia = new Uint8Array(byteString.length)
		for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)

		return new Blob([ia], { type: mimeString })
	}

	static parseParams = (querystring) => {
		const params = new URLSearchParams(querystring)

		const obj = {}

		for (const key of params.keys()) {
			if (params.getAll(key).length > 1) {
				obj[key] = params.getAll(key)
			} else {
				obj[key] = params.get(key)
			}
		}

		return obj
	}

	static stripHtml(html) {
		var temporalDivElement = document.createElement('div')
		temporalDivElement.innerHTML = html
		return temporalDivElement.textContent || temporalDivElement.innerText || ''
	}

	static hasPermission = (user, permission) => {
		if (!permission) {
			return true
		} else if (user?.activeSubscription?.subscriptionType) {
			const { subscriptionCode } = user.activeSubscription.subscriptionType
			return permissions[subscriptionCode].includes(permission)
		} else {
			return permissions.unsuscribed.includes(permission)
		}
	}

	static canCompareVehicle = (user) => {
		let codias = localStorage.comparatorCodias ? JSON.parse(localStorage.comparatorCodias) : []
		if (codias.length < 2) {
			return true
		} else if (codias.length < 4 && AppHelper.hasPermission(user, COMPARATOR_FOUR_VEHICLES)) {
			return true
		} else {
			return false
		}
	}

	static vehicleImage = (vehicle) => {
		let image = vehicle.photoUrl || vehicle.photo_url || vehicle.imagen
		if (!image) {
			switch (vehicle.vehicleType) {
				case CARS:
					image = carImage
					break
				case MOTORBIKES:
					image = motorbikeImage
					break
				default:
					image = carImage
					break
			}
		}
		return image
	}

	static articleURL = (article) => {
		const title = encodeURI(article.title)

		return `${title
			.replace(/\s/g, '-')
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.toLowerCase()}_${article.id}`
	}

	static validateURL = (url, name) => {
		const formatedName = name
			.replace(/\s/g, '-')
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.toLowerCase()
		if (url !== formatedName) {
			throw new Error()
		}
	}

	static isValidCUITCUIL(cuit) {
		if (cuit.length != 13) return false

		let rv = false
		let resultado = 0
		let cuit_nro = cuit.replace('-', '')
		const codes = '6789456789'
		let verificador = parseInt(cuit_nro[cuit_nro.length - 1])
		let x = 0

		while (x < 10) {
			let digitoValidador = parseInt(codes.substring(x, x + 1))
			if (isNaN(digitoValidador)) digitoValidador = 0
			let digito = parseInt(cuit_nro.substring(x, x + 1))
			if (isNaN(digito)) digito = 0
			let digitoValidacion = digitoValidador * digito
			resultado += digitoValidacion
			x++
		}
		resultado = resultado % 11
		rv = resultado == verificador
		return rv
	}
}

export default AppHelper
