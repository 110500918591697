import React, { useState } from "react";
// import { UserType } from "../atoms";
import particularesImg from "../../static/img/particulares.jpg";
import profesionalesImg from "../../static/img/profesionales.jpg";
import { useDispatch, useSelector } from "react-redux";
import UserTypeCard from "./UserTypeCard";
import { SquareButton } from "components/atoms";

export default function UserType({ newUser, setUser, nextStep }) {
  const user = useSelector((state) => state.user.user);
  const [selectedUserType, setSelectedUserType] = useState(newUser.userType);

  const userTypes = [
    {
      id: 1,
      name: "Particulares",
      value: "individual",
      image: particularesImg,
      bullets: [
        "Descubrí toda la información de tu próximo auto o moto.",
        "Guía de precios con fotos de Usados y 0Km",
        "Compara, decidí y gana tiempo",
        "Fichas Técnicas confiables y fáciles de interpretar",
      ],
    },
    {
      id: 2,
      name: "Profesionales",
      value: "professional",
      image: profesionalesImg,
      bullets: [
        "Guía de precios en formato grilla de Usados y 0Km similar a la revista",
        "Precios Históricos y Usado del Año",
        "Historial de Consultas",
        "Comparador hasta 4 vehículos.",
      ],
    },
  ];

  function handleContinue() {
    setUser((prev) => ({ ...prev, userType: selectedUserType }));
    nextStep();
  }

  return (
    <div className="col-12 d-flex flex-column align-items-center">
      <h5 className="font-weight-bold mb-4 text-center">¡Hola!<br />Queremos conocerte mejor</h5>
      <p>
        1. Seleccioná tu{" "}
        <span className={"font-weight-bold"}>tipo de usuario</span>
      </p>
      <div className={"container px-md-5 pt-2 pb-4"}>
        {user.activeSubscription?.name !== "InfoPro Grupal" ? (
          <div className="row">
            {userTypes.map((userType) => (
              <UserTypeCard
                key={userType.id}
                userType={userType}
                selectedUserType={selectedUserType}
                setSelectedUserType={setSelectedUserType}
              />
            ))}
          </div>
        ) : (
          <div className="row">
            <UserType
              userType={userTypes[1]}
              selectedUserType={selectedUserType}
              setSelectedUserType={setSelectedUserType}
            />
          </div>
        )}

        <p className="text-center mt-3 mb-0" style={{ fontSize: "0.78rem"}}>
          {!selectedUserType
            ? "Selecciona un tipo de usuario"
            : `Seleccionaste `}
          {selectedUserType && (
            <strong>
              usuario{" "}
              {selectedUserType === "individual" ? "particular" : "profesional"}
            </strong>
          )}
        </p>
        <div className="d-flex mt-4 justify-content-center">
          <SquareButton
            fullWidth={false}
            disabled={!selectedUserType}
            onClick={handleContinue}
            text={"Continuar"}
          />
        </div>
      </div>
    </div>
  );
}
