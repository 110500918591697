import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { SquareButton } from "./SquareButton";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import ApiHelper from "../../helpers/ApiHelper";

const PaymentLoader = () => {
  const paymentPending = useSelector((state) => state.app.showPaymentPending);
  const [paymentRejected, setPaymentRejected] = useState(false);

  useEffect(() => {
    if (paymentPending) {
      const getPaymentStatus = async () => {
        let status;
        try {
          const res = await ApiHelper.getPaymentStatus();
          status = res.data;
        } catch (error) {
          console.log(error);
        } finally {
          if (status) {
            if (status === "paid") {
              window.location.reload();
            } else if (status === "cancelled") {
              setPaymentRejected(true);
            }
          } else {
            setTimeout(getPaymentStatus, 5000);
          }
        }
      };
      getPaymentStatus();
    }
  }, [paymentPending]);

  return (
    <Modal show={paymentPending} size={"md"} className={"default_modal"}>
      <Modal.Body className={"background_gray"}>
        <h1 className="text-center">Pago en proceso</h1>
        {paymentRejected ? (
          <p className="text-center">
            El pago fue rechazado, por favor prueba con otra tarjeta.
          </p>
        ) : (
          <div>
            <p className="text-center">
              La página se recargará una vez que el pago se haya procesado.
            </p>
            <div className="d-flex justify-content-center">
              <Loader type="TailSpin" color="#10ffdc" height={50} width={50} />
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );

};

export default PaymentLoader;
