import React, { useState } from "react";
import { SectionSeparator } from "../../atoms";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

export default () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const companies = [
    [
      "toyota.png",
      "peugeot.png",
      "volvo.png",
      "citroen.png",
      "vw.png",
      "ds.png",
    ],
    [
      "galeno.jfif",
      "fedpat-logo-alta.png",
      "Allianz.png",
      "Aon.png",
      "Boston.png",
      "chubb.png",
      "coop.png",
      "copan.png",
      "equitativa.png",
      "caruso.png",
      "Berkley-Argentina-Seguros2.png",
      "lanueva.png",
      "lacaja.jfif",
      "lasegunda.png",
      "logo-colon.jpg",
      "galicia.png",
      "hdi-seguros.png",
      "horisonte.png",
      "Integrity-Seguros-Logo.jpg",
      "zurich.png",
      "mapfre.png",
      "meridional-seguros.png",
      "iunigo_logo_negro.png",
      "Logo-Antartida-865x865.jpg",
      "Logo-NS-Blanco.png",
      "mercantil.png",
      "nativa.jfif",
      "orbis.jfif",
      "norte.jfif",
      "parana.png",
      "prov.jfif",
      "pruden.png",
      "Rivadavia.jfif",
      "rus.png",
      "sancor.jpg",
      "smg.jfif",
      "sura.png",
      "victoria.png",
      "lps.png",
    ],
    [
      "Ituran.png",
      "crm.png",
      "marsh-02.png",
      "logo-lauro-asesores-02-1030x226.png",
      "gamasi.png",
      "ike.png",
      "Instsegaut.jpg",
      "holando.png",
      "logo_cooperativa1.png",
    ],
  ];

  const ButtonGroup = ({ next, previous, goToSlide }) => {
    return (
      <div className="carousel-button-group">
        {" "}
        <FontAwesomeIcon icon={faChevronLeft} onClick={() => previous()} />
      </div>
    );
  };

  return (
    <div className={"pt-md-5"}>
      <div className="container p-5 mb-md-5">
        <h2 className={"font-weight-bold text-center mb-5"}>
          Estas empresas confían en nosotros.
        </h2>

        {companies.map((companyGroup, index) => {
          return (
            <Carousel
              className=""
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={2000}
              arrows={false}
              key={index}
              responsive={responsive}
              itemClass="carousel_item"
            >
              {companyGroup.map((company, index) => {
                const img = require(`../../../static/img/companies/${company}`)
                  .default
                  ? require(`../../../static/img/companies/${company}`).default
                  : require(`../../../static/img/companies/${company}`);
                return (
                  <div key={index} className="company_image p-3">
                    <img className={"bw_image"} src={img} alt="" />
                  </div>
                );
              })}
            </Carousel>
          );
        })}
      </div>

      <SectionSeparator right={true} />
    </div>
  );
};
