import React from "react";
import {
  Header,
  Response,
  Integration,
  Contact,
} from "../components/enterprise";
import Companies from "../components/nosotros/companies/Companies";

export class Enterprise extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div id="professionals_page" className={"mt-5 text_normal"}>
        <p className="seo">Desarrollamos Soluciones a Medida. Consulta Masiva de datos en línea </p>
        <Header />
        <Response />
        <Integration />
        <Companies />
        <Contact />
      </div>
    );
  }
}
