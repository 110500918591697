import React from 'react';
import {useLocation} from 'react-router-dom';

export default () => {
    const location = useLocation();
    const routeName = location.pathname.charAt(1).toUpperCase() + location.pathname.substring(2, 20);
    return(
        <p className={'mt-4'}><strong>Home</strong> > {routeName}</p>
    )
}
