import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { FacebookButton, SquareButton, Logo, PasswordInput, AppleButton, GoogleButton } from 'components/atoms'
import { ApiHelper } from 'helpers'
import { useDispatch, useSelector } from 'react-redux'
import firebase from 'firebase'
import actions from 'store/actions'
import { auth } from 'helpers/Firebase'
import { Form } from 'react-bootstrap'
import { useHistory } from 'react-router'

export const RegisterModal = () => {
	const [user, setUser] = useState('')
	const [password, setPassword] = useState('')
	const [passwordConfirm, setPasswordConfirm] = useState('')
	const [email, setEmail] = useState('')
	const [loading, setLoading] = useState(false)
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [notifications, setNotifications] = useState(false)
	const [accept, setAccept] = useState(false)
	const [error, setError] = useState(false)
	const dispatch = useDispatch()
	const show = useSelector((state) => state.app.showRegister)
	const googleProvider = new firebase.auth.GoogleAuthProvider()
	const facebookProvider = new firebase.auth.FacebookAuthProvider()
	const appleProvider = new firebase.auth.OAuthProvider('apple.com')
	const loginMessage = useSelector((state) => state.app.loginMessage)

	const history = useHistory()

	useEffect(() => {
		document.addEventListener('keydown', handleEnter)
		return () => document.removeEventListener('keydown', handleEnter)
	}, [show, user, password])

	const hide = () => {
		dispatch(actions.app.setShowRegister(false))
		dispatch(actions.app.setLoginMessage(null))
	}

	const register = async (e) => {
		e.preventDefault()
		setError(false)
		if (!loading) {
			if (password && email && accept && password === passwordConfirm) {
				setLoading(true)
				firebase
					.auth()
					.createUserWithEmailAndPassword(email, password)
					.then(async () => {
						localStorage.setItem('user', JSON.stringify({ firstName, lastName, username: email }))

						await auth.signInWithEmailAndPassword(email, password)

						const token = await auth.currentUser.getIdToken(true)

						let loginData = new FormData()
						loginData.append('grant_type', 'firebase')
						loginData.append('firebase_token_id', token)

						if (window.gtag) {
							window.gtag('event', 'sign_up', { profile_complete: false, email: email })
						}

						try {
							const loginRes = await ApiHelper.post('auth', '/oauth/token', loginData)
							localStorage.token = loginRes.data.access_token
							localStorage.user = JSON.stringify({ email: email })
							dispatch(actions.user.setUser({ email: email }))
							hide()
						} catch (error) {
							if (error.status === 400) {
								dispatch(actions.app.setShowRegister(false))
								window.location.replace(`/activacion?${email}`)
							}
						}
					})
					.catch((err) => {
						setLoading(false)
						switch (err.message) {
							case 'The email address is already in use by another account.':
								setError('La dirección de correo ingresada ya está en uso')
								break
							case 'Password should be at least 6 characters':
								setError('La contraseña debe tener al menos 6 caracteres')
								break
							default:
								setError(err.message)
						}
					})
			} else if (password && email && !accept) {
				setError('Debes aceptar los términos y condiciones')
				setLoading(false)
				return
			} else if (!password && !email) {
				setError('Por favor complete todos los campos')
				setLoading(false)
				return
			} else if (password !== passwordConfirm) {
				setError('Las contraseñas deben coincidir')
				setLoading(false)
			} else if (!email) {
				setError('Ingrese un email válido')
				setLoading(false)
			}
		}
	}

	const handleEnter = (key) => {
		if (user && password && key.code === 'Enter') {
			register()
		}
	}

	const providerLogin = async (provider) => {
		try {
			await firebase.auth().signInWithPopup(provider)
			const token = await auth.currentUser.getIdToken(true)
			let data = new FormData()
			data.append('grant_type', 'firebase')
			data.append('firebase_token_id', token)
			const res = await ApiHelper.post('auth', '/oauth/token', data)
			localStorage.token = res.data.access_token
			let newUser
			try {
				const { data } = await ApiHelper.get('admin', '/user')
				newUser = data
			} catch (error) {
				newUser = { email: auth.currentUser.email }
			} finally {
				localStorage.user = JSON.stringify(newUser)
				dispatch(actions.user.setUser(newUser))
				window.location.href = window.location.origin
			}
		} catch (error) {
			switch (error.code) {
				case 'auth/account-exists-with-different-credential':
					setError('Ya hay una cuenta registrada con el email ingresado')
					break
				default:
					setError('Por favor intentá con otro método de inicio de sesión')
					break
			}
		}
	}

	const facebookLogin = async (provider) => {
		provider.addScope('email')

		try {
			await firebase.auth().signInWithPopup(provider)
			if (auth.currentUser.email) {
				const token = await auth.currentUser.getIdToken(true)
				let data = new FormData()
				data.append('grant_type', 'firebase')
				data.append('firebase_token_id', token)
				const res = await ApiHelper.post('auth', '/oauth/token', data)
				localStorage.token = res.data.access_token
				let newUser
				try {
					const { data } = await ApiHelper.get('admin', '/user')
					newUser = data
				} catch (error) {
					newUser = { email: auth.currentUser.email }
				} finally {
					localStorage.user = JSON.stringify(newUser)
					dispatch(actions.user.setUser(newUser))
					hide()
				}
			} else {
				setError('Por favor intentá con otro método de inicio de sesión.')
			}
		} catch (error) {
			console.log(error)
		}
	}

	const showLogin = () => {
		dispatch(actions.app.setShowLogin(true))
		dispatch(actions.app.setShowRegister(false))
	}

	return (
		<Modal show={show} onHide={hide} size={'lg'} className={'default_modal'} id={'login_modal'}>
			<Modal.Body className={'p-0'}>
				<div className='row'>
					<div className='col-12 col-md-6 p-5 d-flex flex-column align-items-center'>
						<h5 className='font-weight-bold mb-4'>Registrate</h5>
						{loginMessage && <p className='text-center error'>{loginMessage}</p>}
						<form onSubmit={register}>
							<input
								name={'email'}
								value={email}
								onChange={(event) => setEmail(event.target.value)}
								className={'mt-2 custom_input default-shadow p-2 w-100 mb-3'}
								placeholder={'Email'}
								type={'text'}
							/>
							<PasswordInput
								name={'password'}
								value={password}
								onChange={(event) => setPassword(event.target.value)}
								placeholder={'Contraseña'}
							/>
							<PasswordInput
								name={'passwordConfirm'}
								value={passwordConfirm}
								onChange={(event) => setPasswordConfirm(event.target.value)}
								placeholder={'Confirme Contraseña'}
							/>
							<Form.Group className={'w-100'} controlId={`form_check`}>
								<Form.Check
									type='checkbox'
									checked={notifications}
									label={'Quiero recibir novedades por e-mail'}
									onChange={() => setNotifications(!notifications)}
								/>
							</Form.Group>

							<Form.Group className={'w-100 d-flex'} controlId={`form_check`}>
								<Form.Check type='checkbox' checked={accept} onChange={() => setAccept(!accept)} />
								<label className={'mb-3 mt-1'}>
									He leído y aceptado los{' '}
									<span className={'text_green'}>
										<a href={'/terminos'}>Términos y Condiciones y las Políticas de Privacidad</a>
									</span>
								</label>
							</Form.Group>

							<SquareButton loading={loading} type='submit' text={'Registrarme'} />
						</form>
						{error && <p className={'text-center color_red mt-2'}>{error}</p>}
						<p className={'mt-3'}>
							¿Ya tenés cuenta?{' '}
							<span onClick={showLogin} className={'text_green font-weight-bold link'}>
								Ingresá
							</span>
						</p>
						<p className={'mt-2 crossed'}>
							<span>o registrate con redes</span>
						</p>
						<div className='d-flex justify-content-center'>
							<GoogleButton onClick={() => providerLogin(googleProvider)} />
							<FacebookButton onClick={() => facebookLogin(facebookProvider)} />
							<AppleButton onClick={() => providerLogin(appleProvider)} />
						</div>
					</div>
					<div className='col-12 col-md-6 gray_background p-5 d-flex flex-column align-items-center'>
						<h5 className='font-weight-bold text-center'>
							¿Por qué crear una cuenta en <Logo />?
						</h5>
						<ul className={'w-100 mt-4'}>
							<li className={'mb-3'}>Accedé a datos de más de 15.000 vehículos</li>
							<li className={'mb-3'}>Consultas de precios de 0km ilimitadas</li>
							<li className={'mb-3'}>3 consultas de precios de vehículos usados sin cargo</li>
							<li className={'mb-3'}>Compará tus vehículos favoritos</li>
							<li className={'mb-3'}>Fichas técnicas con más de 50 datos por vehículo</li>
						</ul>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}
