import React from 'react'
import usePayment from 'hooks/usePayment'

export default function NewCard() {
	const { renderForm } = usePayment(callback)

	async function callback(cardToken, paymentMethodId, dni) {
		const message = {
			type: 'cardResponse',
			data: { cardToken, paymentMethodId, dni },
		}
		window.ReactNativeWebView.postMessage(JSON.stringify(message))

		if (window.gtag) {
			window.gtag('event', 'add_payment_info')
		}
	}

	return renderForm()
}
