import actionsTypes from '../actions/actionTypes'

const initialState = [];

export default function(state = initialState, action){
    switch(action.type){
        case actionsTypes.SET_VEHICLE_TYPES:
            return action.vehicleTypes;
        default:
            return state;
    }
};
