import React from "react";
import appStoreImg from "../../static/img/apple-store.png";
import googlePlayImg from "../../static/img/google-play.png";
import mobileAppImg from "../../static/img/mockup-infopro.png";

export default () => {
  return (
    <section>
      <div className="row mt-5 no-gutters">
        <div className="col-12 col-md-5  mb-5 mb-md-0">
          <img src={mobileAppImg} className={"w-100"} alt="" />
        </div>
        <div className="col-12 col-md-7 d-flex flex-column justify-content-center align-items-center mb-5">
          <h1 className={"text-center display-4"}>
            {" "}
            <span className={"font-weight-bold text-center"}>Nuestra app,</span>
            <br />
            <span className={"font-weight-normal"}>tu herramienta.</span>
          </h1>
          <h6 className="mb-4 mt-4 font-weight-normal text-center w-50">
            Descargala, consultá el precio del auto o moto que buscás y brindale
            soporte a tus clientes estés donde estés.
          </h6>
          <div className="w-25">
            <a target="_blank" href="https://apps.apple.com/ar/app/infoauto-argentina/id1550112119?l=en">
              <img src={appStoreImg} className={"w-100 mb-3"} alt="img-app-store" />
            </a>
          </div>
          <div className="w-25">
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.infoauto.app&hl=es_AR&gl=US">
              <img src={googlePlayImg} className={"w-100"} alt="img-play-store" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
