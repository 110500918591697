import React, { useState, useEffect } from "react";
import AdXL from "../components/ads/AdXL";
import {
  VisualSearchHeader,
  VisualSelectedFilters,
} from "../components/organisms";
import { useSelector } from "react-redux";
import { SectionTitleWithSelector } from "../components/molecules";
import { AppHelper } from "helpers";
import { VISUAL_SEARCH } from "static/permissions/sections";
import { useHistory } from "react-router";

export const VisualSearchResult = () => {
  const [hasPermission, setHasPermission] = useState(false);
  const user = useSelector((state) => state.user.user);
  const history = useHistory();

  useEffect(() => {
    if (AppHelper.hasPermission(user, VISUAL_SEARCH)) {
      setHasPermission(true);
    } else {
      window.location.href = "/404";
    }
  }, []);

  return (
    hasPermission && (
      <div
        id="visual_search_page"
        style={{ marginTop: "80px" }}
      >
        <div className={"container"}>
          <div className="container-fluid p-xl-5">
            <div className="mb-5">
              <AdXL subsection="-filtros" />
            </div>
            <SectionTitleWithSelector title={"Búsqueda por filtro visual"} />
            <VisualSelectedFilters />
          </div>
        </div>
      </div>
    )
  );
};
