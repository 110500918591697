import {combineReducers} from 'redux'
import userReducer from './userReducer'
import searchReducer from "./searchReducer";
import filterTypesReducer from "./filterTypesReducer";
import vehicleTypesReducer from "./vehicleTypesReducer";
import dollarReducer from "./dollarReducer";
import appReducer from "./appReducer";
import visualSearchReducer from "./visualSearchReducer";

export default combineReducers({
    user: userReducer,
    search: searchReducer,
    filterTypes: filterTypesReducer,
    vehicleTypes: vehicleTypesReducer,
    dollar: dollarReducer,
    app: appReducer,
    visualSearch: visualSearchReducer,
});
