import React from "react";
import { Carousel } from "react-responsive-carousel";
import { SecondaryNew } from "../atoms";

export default ({ news }) => {
  return (
    <div className="col-12 col-md-4 px-0">
      <Carousel
        infiniteLoop
        autoPlay
        showIndicators={true}
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        transitionTime={400}
        interval={6000}
        dynamicHeight={false}
        swipeable={true}
      >
        {news.map((n) => (
          <SecondaryNew key={n.id} article={n} carousel={true} />
        ))}
      </Carousel>
    </div>
  );
};
