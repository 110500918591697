import React, { useEffect, useState, version } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ApiHelper, AppHelper } from '../../helpers'
import actions from '../../store/actions'
import { VehicleTypeSelector, PaginatedSelect } from '../atoms'
import { DollarWidget, MobileAdvancedSearchPrices } from '../molecules'
import { InfoCreditsAlertModal, ReportModal } from '../organisms'
import { InfoCreditsModal } from '../organisms/InfoCreditsModal'
import Column from './Column'
import MainColumn from './MainColumn'
import MobileSelect from './MobileSelect'
import SearchButtons from './SearchButtons'
import SubmitColumn from './SubmitColumn'
import { COMPARATOR, COMPARATOR_FOUR_VEHICLES } from '../../static/permissions/sections'

const SearchTable = () => {
	const [brands, setBrands] = useState([])
	const [brandsToShow, setBrandsToShow] = useState([])
	const [selectedBrand, setSelectedBrand] = useState(null)
	const [selectedType, setSelectedType] = useState({})
	const [showModels, setShowModels] = useState(false)
	const [models, setModels] = useState([])
	const [versions, setVersions] = useState([])
	const [versionsToShow, setVersionsToShow] = useState([])
	const [selectedVersion, setSelectedVersion] = useState(null)
	const [showVersions, setShowVersions] = useState(false)
	const [selectedModel, setSelectedModel] = useState(null)
	const [showYears, setShowYears] = useState(false)
	const [years, setYears] = useState([])
	const [selectedYear, setSelectedYear] = useState(null)
	const [prices, setPrices] = useState([])
	const [showPrices, setShowPrices] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [showAlertModal, setShowAlertModal] = useState(false)
	const [pricesReadyToShow, setPricesReadyToShow] = useState(false)
	const [pricesLoading, setPricesLoading] = useState(false)
	const vehicle = useSelector((state) => state.search.vehicle)
	const user = useSelector((state) => state.user.user)
	const [showInfoCreditsModal, setShowInfoCreditsModal] = useState(false)

	const isDesktop = window.innerWidth > 767

	const dispatch = useDispatch()
	const history = useHistory()

	const getBrands = async () => {
		const data = {
			hasUsedPrice: true,
			vehicleType: vehicle,
			paginated: false,
		}
		const res = await ApiHelper.getDownloadedBrands(data)
		setBrands(res)
		setBrandsToShow(res)
	}

	const getModels = async () => {
		if (isDesktop) {
			const data = {
				paginated: false,
				hasUsedPrice: true,
				vehicleType: vehicle,
				pageSize: 100,
			}
			const res = await ApiHelper.getModels(selectedBrand.id, data)
			setModels(res)
			setShowModels(true)
		}
	}

	const getYears = async () => {
		console.log(selectedModel)
		let years = []
		if (selectedModel.list_price) {
			years.push({ id: '0km', name: '0km' })
		}
		for (let i = selectedModel.prices_to; i >= selectedModel.prices_from; i--) {
			years.push({ id: i, name: i, value: i })
		}
		setYears(years)
		setShowYears(true)
	}

	const getVersions = async () => {
		if (isDesktop) {
			const data = {
				vehicleType: vehicle,
				pageSize: 100,
			}
			if (selectedYear.id === '0km') {
				data.newPrice = true
			} else {
				data.fromYear = selectedYear.id
				data.toYear = selectedYear.id
				data.hasUsedPrice = true
			}
			const res = await ApiHelper.getVersions(selectedBrand.id, selectedModel.id, data)

			if (window.gtag) {
				window.gtag('event', 'busqueda_visual_completada', {
					brand: selectedBrand.id,
					model: selectedModel.id,
					...data,
				})
			}

			setVersions(res)
			setVersionsToShow(res)
			setShowVersions(true)
		}
	}

	useEffect(() => {
		setBrands([])
		setPrices([])
		setModels([])
		setYears([])
		setVersions([])
		setSelectedBrand(null)
		setSelectedVersion(null)
		setSelectedYear(null)
		setSelectedModel(null)
		setVersionsToShow([])
		getBrands()
	}, [vehicle])

	useEffect(() => {
		setSelectedModel(null)
		if (selectedBrand) {
			setPrices([])
			setModels([])
			setYears([])
			setVersions([])
			setVersionsToShow([])
			getModels()
		}
	}, [selectedBrand])

	useEffect(() => {
		setYears([])
		setVersions([])
		setVersionsToShow([])
		setPrices([])
		setSelectedYear(null)
		if (selectedModel) {
			getYears()
		}
	}, [selectedModel])

	useEffect(() => {
		setVersions([])
		setVersionsToShow([])
		setPrices([])
		setSelectedVersion(null)
		if (selectedYear) {
			getVersions()
		}
	}, [selectedYear])

	useEffect(() => {
		setShowModels(!!selectedType.id)
		setSelectedModel(null)
	}, [selectedType])

	useEffect(() => {
		setPrices([])
		if (selectedVersion) {
			setPricesReadyToShow(true)
			if (user?.codiasConsultados.some((v) => v.codia === selectedVersion?.id)) {
				getPrices()
				return
			} else if (selectedVersion.list_price && !selectedVersion.newPrice) {
				getNewPrice()
			}
		} else {
			setPricesReadyToShow(false)
		}
	}, [selectedVersion])

	const getNewPrice = async () => {
		const res = await ApiHelper.get('preciosa', `/models/${selectedVersion.codia}/listPrice?vehicleType=${vehicle}`)
		setSelectedVersion((version) => ({
			...version,
			newPrice: res.data.list_price,
		}))
	}

	const handleShowPrices = () => {
		if (!user && selectedVersion) {
			dispatch(actions.app.setShowRegister(true))
			return
		}

		if (selectedVersion) {
			if (user.availableInfocredits === 0) {
				setShowInfoCreditsModal(true)
			} else {
				if (localStorage.hidePriceAlert) {
					getPrices()
				} else {
					setShowAlertModal(true)
				}
			}
		}
	}

	const getPrices = async () => {
		setPricesLoading(true)
		try {
			setShowAlertModal(false)
			const data = {
				vehicleType: vehicle,
			}
			const res = await ApiHelper.getPrices(selectedVersion.id || selectedVersion.codia, data)

			if (res.newPrice) {
				setPrices([{ year: '0km', price: res.newPrice.price }, ...res.usedPrices])
			} else {
				setPrices(res.usedPrices)
			}
			if (!user?.codiasConsultados.some((v) => v.codia === selectedVersion.id)) {
				dispatch(
					actions.user.fillUserData({
						...user,
						codiasConsultados: [
							...user.codiasConsultados,
							{
								codia: selectedVersion.id,
								vehicleType: vehicle,
								dateConsulted: new Date().toISOString(),
							},
						],
						availableInfocredits: res.userCredits,
					})
				)
			} else {
				dispatch(
					actions.user.fillUserData({
						...user,
						availableInfocredits: res.userCredits,
					})
				)
			}
			localStorage.user = JSON.stringify({
				...user,
				availableInfocredits: res.userCredits,
			})
		} catch (error) {
			if (error.status === 401) {
				if (localStorage.hidePriceAlert) {
					localStorage.clear()
					localStorage.hidePriceAlert = true
				} else {
					localStorage.clear()
				}
				// window.location.href = window.location.origin;
				window.location.replace('/?showloginmodal=true&showsession=true')
			}

			console.log(error)
		} finally {
			setPricesLoading(false)
		}
	}

	const showReport = () => {
		localStorage.reportType = 'Precio'
		localStorage.reportModelo = vehicle.description
		if (selectedVersion) {
			setShowModal(true)
		}
	}

	const onClick = () => {
		dispatch(
			actions.search.setPrimarySeach([
				{ ...selectedBrand, type: 'brand' },
				{ ...selectedModel, type: 'model' },
				{ ...selectedVersion, type: 'version' },
				{ ...selectedYear, type: 'year' },
			])
		)
		history.push(`/ficha/${vehicle}/${selectedVersion.codia}`)
	}

	const compare = () => {
		if (AppHelper.hasPermission(user, COMPARATOR)) {
			let codias = localStorage.comparatorCodias ? JSON.parse(localStorage.comparatorCodias) : []
			if (codias.length === 0 || codias.some((c) => c.vehicleType == vehicle)) {
				if (codias.some((c) => c.codia == selectedVersion.codia)) {
					history.push(`/comparador`)
				} else if (
					codias.length < 2 ||
					(AppHelper.hasPermission(user, COMPARATOR_FOUR_VEHICLES) && codias.length < 4)
				) {
					codias = [...codias, { codia: selectedVersion.codia, vehicleType: vehicle }]
				} else {
					codias[0] = {
						codia: selectedVersion.codia,
						vehicleType: vehicle,
					}
				}
			} else {
				codias = [{ codia: selectedVersion.codia, vehicleType: vehicle }]
			}
			localStorage.comparatorCodias = JSON.stringify(codias)
			history.push(`/comparador`)
		} else {
			dispatch(actions.app.setLoginMessage('Para esta funcionalidad debes estar registrado'))
			dispatch(actions.app.setShowRegister(true))
		}
	}

	const loadModels = async (searchQuery, loadedOptions, { page }) => {
		if (selectedBrand) {
			const data = {
				hasUsedPrice: true,
				vehicleType: vehicle,
				page,
			}

			try {
				const res = await ApiHelper.getPaginatedModels(selectedBrand.id, data)

				return {
					options: res.data.response,
					hasMore: res.data.pagination.totalPages > page,
					additional: {
						page: searchQuery ? 2 : page + 1,
					},
				}
			} catch {
				return {
					options: [],
					hasMore: false,
					additional: {
						page: 1,
					},
				}
			}
		} else {
			return {
				options: [],
				hasMore: true,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			}
		}
	}

	const loadVersions = async (searchQuery, loadedOptions, { page }) => {
		if (selectedYear) {
			const data = {
				vehicleType: vehicle,
				pageNumber: page,
				features: {},
			}

			if (selectedYear.id === '0km') {
				data.newPrice = true
			} else {
				data.fromYear = selectedYear.id
				data.toYear = selectedYear.id
			}
			const res = await ApiHelper.getPaginatedVersions(selectedBrand.id, selectedModel.id, data)

			return {
				options: res.data.response,
				hasMore: res.data.pagination.totalPages > page,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			}
		} else {
			return {
				options: [],
				hasMore: true,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			}
		}
	}

	return (
		<div className=' my-3 my-md-5 '>
			<div className='row mb-2 '>
				<div className='col-md-3'>
					<h5 className='font-weight-bolder text-center text-md-left mt-1'>Búsqueda Asistida</h5>
				</div>
				<div className='col-md-2 pt-3 pt-md-0'>
					<VehicleTypeSelector />
				</div>
				<div className='col-md-4 pt-3 pt-md-0'>
					<DollarWidget />
				</div>
				{selectedVersion && (
					<div className='col-md-3'>
						<p className='text-center text-md-right mt-1'>
							<small>
								¿Tenés algo que informarnos?{' '}
								<strong className={'link'} onClick={showReport}>
									Reportar
								</strong>
							</small>
						</p>
					</div>
				)}
				<ReportModal hide={() => setShowModal(false)} show={showModal} vehicle={selectedVersion} />
			</div>
			<div id='search_table' className='row d-none d-md-flex'>
				<MainColumn
					values={brandsToShow}
					setValues={setBrandsToShow}
					setValue={setSelectedBrand}
					allValues={brands}
					selectedValue={selectedBrand}
					onSelect={setSelectedBrand}
					title={'Marca'}
					grayBackground={true}
				/>
				<Column
					showResults={showModels}
					name={'Modelo'}
					values={models}
					selectedValue={selectedModel}
					onSelect={setSelectedModel}
				/>
				<Column
					grayBackground={true}
					showResults={showYears}
					name={'Fabricación'}
					values={years}
					selectedValue={selectedYear}
					onSelect={setSelectedYear}
					center={true}
					sm={true}
				/>
				<MainColumn
					values={versionsToShow}
					setValues={setVersionsToShow}
					setValue={setSelectedVersion}
					allValues={versions}
					selectedValue={selectedVersion}
					onSelect={setSelectedVersion}
					title={'Versión'}
					center={true}
					lg={true}
				/>

				<SubmitColumn
					name={'Precio'}
					selectedValue={selectedYear}
					values={prices}
					brand={selectedBrand}
					year={selectedYear}
					model={selectedModel}
					version={selectedVersion}
					center={true}
					showPrices={showPrices}
					prices={prices}
					selectedVersion={selectedVersion}
					getPrices={handleShowPrices}
					pricesReadyToShow={pricesReadyToShow}
					pricesLoading={pricesLoading}
				/>
			</div>
			<div id='search_table_mobile' className='d-block d-md-none' style={{ minHeight: 600 }}>
				<MobileSelect placeholder='Marca' options={brands} selectedOption={selectedBrand} onSelect={setSelectedBrand} />
				{selectedBrand && (
					<PaginatedSelect
						key={'models'}
						className={'mb-3 default_shadow'}
						value={selectedModel}
						loadOptions={loadModels}
						getOptionValue={(option) => option.id}
						getOptionLabel={(option) => option.name}
						onChange={setSelectedModel}
						isSearchable={false}
						placeholder='Modelo'
						cacheUniqs={[selectedBrand]}
						additional={{
							page: 1,
						}}
					/>
				)}

				{years.length > 0 && (
					<MobileSelect
						placeholder='Fabricación'
						options={years}
						selectedOption={selectedYear}
						onSelect={setSelectedYear}
					/>
				)}
				{selectedYear && (
					<PaginatedSelect
						key={'version'}
						value={selectedVersion}
						className={'mb-3 default_shadow'}
						loadOptions={loadVersions}
						getOptionValue={(option) => option.codia}
						getOptionLabel={(option) => option.description}
						onChange={setSelectedVersion}
						isSearchable={false}
						placeholder='Versión'
						cacheUniqs={[selectedYear]}
						additional={{
							page: 1,
						}}
					/>
				)}

				{selectedVersion && (
					<div>
						{selectedVersion?.newPrice && (
							<div className={`btn text-left w-100 d-flex justify-content-between bg_green`}>
								<span className={'font-weight-bold'}>0km</span>

								<span>${new Intl.NumberFormat('es').format(selectedVersion?.newPrice * 1000)}</span>
							</div>
						)}
						<SearchButtons onClick={onClick} compare={compare} />
						<MobileAdvancedSearchPrices
							selectedYear={selectedYear}
							prices={prices}
							pricesLoading={pricesLoading}
							pricesReadyToShow={pricesReadyToShow}
							getPrices={handleShowPrices}
						/>
					</div>
				)}

				<InfoCreditsAlertModal getPrices={getPrices} hide={() => setShowAlertModal(false)} show={showAlertModal} />
				<InfoCreditsModal show={showInfoCreditsModal} hide={() => setShowInfoCreditsModal(false)} />
			</div>
		</div>
	)
}

export default SearchTable
