import React from "react";
import { RatingBox } from "../molecules";
import { Button } from "../atoms";
import { withRouter } from "react-router-dom";
import coveredCarImg from "../../static/img/covered_car.jpg";
import coveredMotoImg from "../../static/img/covered_motorcycle.png";
import { AppHelper } from "helpers";
import actions from "store/actions";

import {
  COMPARATOR,
  COMPARATOR_FOUR_VEHICLES,
} from "static/permissions/sections";
import { useSelector, useDispatch } from "react-redux";

const HistoryCard = ({
  car,
  history,
  lastSearch,
  vehicleType,
  dateConsulted, //yyyy-mm-dd,
}) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  // format dateconsulted to locale string
  const date = new Date(dateConsulted).toLocaleDateString("es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  });

  const compare = () => {
    const { versionCode } = car;

    if (AppHelper.hasPermission(user, COMPARATOR)) {
      let codias = localStorage.comparatorCodias
        ? JSON.parse(localStorage.comparatorCodias)
        : [];
      if (
        codias.length === 0 ||
        codias.some((c) => c.vehicleType == vehicleType)
      ) {
        if (codias.some((c) => c.codia == versionCode)) {
          history.push(`/comparador`);
        } else if (
          codias.length < 2 ||
          (AppHelper.hasPermission(user, COMPARATOR_FOUR_VEHICLES) &&
            codias.length < 4)
        ) {
          codias = [...codias, { codia: versionCode, vehicleType }];
        } else {
          codias[0] = { codia: versionCode, vehicleType };
        }
      } else {
        codias = [{ codia: versionCode, vehicleType }];
      }
      localStorage.comparatorCodias = JSON.stringify(codias);
      history.push(`/comparador`);
    } else {
      dispatch(
        actions.app.setLoginMessage(
          "Para esta funcionalidad debes estar registrado"
        )
      );
      dispatch(actions.app.setShowLogin(true));
    }
  };

  return (
    <div className="row result_card p-2">
      <div className="col-12 col-md-3">
        <img
          className="w-100"
          alt="imagen-vehiculo"
          src={AppHelper.vehicleImage(car)}
        />
      </div>
      <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
        <h5 className="font-weight-bold mb-1">
          {car.versionDescription ? car.versionDescription : car.model}
        </h5>
        <small className="font-weight-bold">{car?.brand}</small>
        {/* <RatingBox rating={4.1} /> */}
        {car?.years && (
          <>
            <small className="font-weight-bold">Años de comercialización</small>
            <div className="d-flex car_years w-100 flex-wrap">
              {car?.years?.map((year, index) => (
                <p key={index} className="mr-3 mb-1">
                  {year}
                </p>
              ))}
            </div>
          </>
        )}
        {dateConsulted && (
          <p>
            <small className="font-weight-bold">Fecha de consulta: </small>{" "}
            {date}
          </p>
        )}
      </div>
      <div className="col-12 col-md-3 d-flex flex-column justify-content-center align-items-center align-items-md-end">
        <Button onClick={compare} text={"Comparar"} />
        <Button
          text={"Ver Ficha"}
          dark={true}
          onClick={() =>
            history.push(
              `/ficha/${car.vehicleType}/${
                car.versionCode ? car.versionCode : car.codia
              }`
            )
          }
        />
      </div>
    </div>
  );
};

export default withRouter(HistoryCard);
