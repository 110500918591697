import React from "react";
import { PriceSelectPill } from "../atoms/PriceSelectPill";
import { SquareButton } from "../atoms";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";

export default ({ landingPage }) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  return (
    <div className={"plan_card_container"}>
      <div className={"plan_card default_shadow background_gray"}>
        <h3
          className={`bg_black text-white text-center p-2 font-weight-bold mb-0`}
        >
          Gratis
        </h3>
        <div className="body p-4 d-flex flex-column align-items-center">
          <div>
            <p className={"text-center"}>
              Registrate <span className="font-weight-bold">sin cargo</span> y
              consultá precios de todos los 0km
            </p>
            <div
              className={"d-flex border-bottom w-100 justify-content-center"}
            >
              <h3 className={"font-weight-bold"}>$</h3>
              <h1 className={"font-weight-bold display-1"}>0</h1>
            </div>
            <div
              className={
                "d-flex border-bottom w-100 py-4 flex-column align-items-center justify-content-center"
              }
            >
              <p className={"font-weight-bold"}>Incluye</p>
              <p className="mb-1">
                Consulta 0km modo{" "}
                <span className="font-weight-bold">Ilimitado</span>
              </p>
              <p className="mb-1">
                Ficha técnica <span className="font-weight-bold">Básica</span>
              </p>
              <p className="mb-1 text-center">
                Hasta <strong>3</strong> consultas únicas de usados
              </p>
              <p className="mb-1">
                <strong>(auto & moto)</strong>
              </p>
            </div>
            <div
              className={
                "d-flex w-100 py-4 flex-column align-items-center justify-content-center"
              }
            >
              <p className={"font-weight-bold"}>Características</p>
              <p className="mb-1">Ficha Técnica Básica</p>
              <p className="mb-1">Descripción Marca / Modelo / Versión</p>
              <p className="mb-1">Comparador hasta 2 Unidades.</p>
            </div>
          </div>
          {user &&
            user.activeSubscription.name === "Free" &&
            user.type === "individual" && (
              <h4 className={"font-weight-bold text-center"}>PLAN ACTUAL</h4>
            )}
          {landingPage && !user && (
            <div className="w-75">
              <SquareButton
                onClick={() => dispatch(actions.app.setShowRegister(true))}
                text={"Registrate"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
