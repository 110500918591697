import React from 'react'
import { Select } from '../atoms'

export const FeatureFilter = ({ title, selectedOption, options, placeholder, onChange, big }) => {
  return (
    <div className={`${big ? 'col-lg-12' : 'col-lg-6'} mb-4 px-5 mb-3`}>
      <p className={`font-weight-bold mb-3`}>{title}</p>
      <Select options={options} selectedOption={selectedOption} onSelect={onChange} placeholder={placeholder} />
    </div>
  )

};


