import { SquareButton } from 'components/atoms'
import InputField from 'components/atoms/InputField'
import React, { useEffect, useRef, useState } from 'react'
import Cards from 'react-credit-cards'

import { useForm } from 'react-hook-form'
import useDebounce from './useDebounce'

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ContactModal } from 'components/organisms/ContactModal'
import logoMp from 'static/img/logo_mp.png'

export default function usePayment(callback, err = null) {
	const [paymentMethodId, setPaymentMethodId] = useState(null)
	const [error, setError] = useState(null)
	const [showModal, setShowModal] = useState(false)

	const buttRef = useRef(null)

	const {
		handleSubmit,
		register,
		watch,
		setValue,
		formState: { errors, isSubmitting, isValid },
	} = useForm({
		defaultValues: {
			securityCode: '',
			cardExpirationMonth: '',
			cardExpirationYear: '',
			cardholderName: '',
			cardNumber: '',
			dni: '',
		},
	})
	const cardNumber = watch('cardNumber')

	const debouncedCardNumber = useDebounce(cardNumber, 500)

	function loadMpLibrary() {
		if (!window.mp) {
			window.mp = new window.MercadoPago(process.env.REACT_APP_MP_PUBLISH_KEY)
		}
	}

	useEffect(() => {
		loadMpLibrary()
		window.addEventListener('message', (message) => {
			try {
				const parsedData = JSON.parse(message.data)
				if (parsedData?.type === 'newCard') {
					Object.keys(parsedData.payload).map((key) => {
						setValue(key, parsedData.payload[key])
					})
					// buttRef.current.click();
					setTimeout(() => {
						document.getElementById('submitPayment').click()
					}, 1000)
				}
			} catch (error) {
				console.log('error', error)
			}
		})
	}, [])

	useEffect(() => {
		if (debouncedCardNumber.length > 5 && !paymentMethodId) {
			const getPaymentMethods = async () => {
				try {
					const paymentMethods = await window.mp.getPaymentMethods({
						bin: debouncedCardNumber,
					})
					setPaymentMethodId(paymentMethods.results[0]?.id)
				} catch (e) {
					console.log(e)
				}
			}
			getPaymentMethods()
		} else if (debouncedCardNumber.length <= 5) {
			setPaymentMethodId(null)
		}
	}, [debouncedCardNumber])

	async function generateCardToken(card) {
		const cardToken = await window.mp.createCardToken(card)
		return cardToken
	}

	async function getCardToken(card) {
		let cardToken = await generateCardToken(card)

		try {
			await callback(cardToken.id, paymentMethodId, card.dni)
		} catch (error) {
			if (error.status === 401) {
				if (localStorage.hidePriceAlert) {
					localStorage.clear()
					localStorage.hidePriceAlert = true
				} else {
					localStorage.clear()
				}
				if (window.ReactNativeWebView) {
					window.ReactNativeWebView.postMessage(JSON.stringify('error'))
				} else {
					window.location.href = window.location.origin
				}
			}
			setError('Hubo un error al procesar tu pago, por favor intentá con otra tarjeta')
		}
	}

	function renderCard() {
		return (
			<Cards
				cvc={watch('securityCode')}
				expiry={`${watch('cardExpirationMonth')}/${watch('cardExpirationYear')}`}
				name={watch('cardholderName')}
				number={watch('cardNumber')}
				placeholders={{ name: 'Nombre Apellido' }}
				locale={{ valid: 'Hasta' }}
			/>
		)
	}

	function renderForm() {
		return (
			<form id='paymentForm' onSubmit={handleSubmit(getCardToken)}>
				<div className='row justify-content-center mt-4'>
					<div className='col-12 col-lg-4 d-flex flex-column'>
						<InputField label='Número de tarjeta' error={errors.cardNumber}>
							<input
								id='cardNumber'
								placeholder='Ingrese número de tarjeta'
								{...register('cardNumber', {
									required: 'Campo obligatorio',
									minLength: { value: 1, message: 'Al menos 1 caracter' },
									pattern: {
										value: /^[0-9]*$/,
										message: 'Ingrese sólo números',
									},
								})}
								className={'custom_input default-shadow p-2 w-100 mb-3'}
								type='text'
							/>
						</InputField>
					</div>
					<div className='col-12 col-lg-4 d-flex flex-column'>
						<InputField label='Nombre y apellido' error={errors.cardholderName}>
							<input
								placeholder='Como figura en la tarjeta'
								id='cardholderName'
								{...register('cardholderName', {
									required: 'Campo obligatorio',
									minLength: { value: 1, message: 'Al menos 1 caracter' },
								})}
								className={'custom_input default-shadow p-2 w-100 mb-3'}
								type='text'
							/>
						</InputField>
					</div>
					<div className='col-12 col-lg-4 d-flex flex-column'>
						<InputField label='DNI' error={errors.dni}>
							<input
								id='docNumber'
								placeholder='Ingrese DNI'
								{...register('dni', {
									required: 'Campo obligatorio',
									minLength: { value: 1, message: 'Al menos 1 caracter' },
								})}
								className={'custom_input default-shadow p-2 w-100 mb-3'}
								type='text'
							/>
						</InputField>
					</div>
					<div className='col-12 col-lg-4 d-flex flex-column'>
						<InputField label='Mes' error={errors.cardExpirationMonth}>
							<input
								id='cardExpirationMonth'
								placeholder='Ingrese mes de vencimiento'
								{...register('cardExpirationMonth', {
									required: 'Campo obligatorio',
									minLength: {
										value: 2,
										message: 'Ingrese dos caracteres',
									},
									maxLength: {
										value: 2,
										message: 'Ingrese dos caracteres',
									},
								})}
								className={'custom_input default-shadow p-2 w-100 mb-3'}
								type='text'
								maxLength={2}
							/>
						</InputField>
					</div>
					<div className='col-12 col-lg-4 d-flex flex-column'>
						<InputField label='Año' error={errors.cardExpirationYear}>
							<input
								id='cardExpirationYear'
								placeholder='Ingrese año de vencimiento'
								{...register('cardExpirationYear', {
									required: 'Campo obligatorio',
									minLength: {
										value: 2,
										message: 'Al menos 2 caracteres',
									},
								})}
								className={'custom_input default-shadow p-2 w-100 mb-3'}
								type='text'
								maxLength={2}
							/>
						</InputField>
					</div>
					<div className='col-12 col-lg-4 d-flex flex-column'>
						<InputField label='CVC' error={errors.cardExpirationYear}>
							<input
								id='securityCode'
								placeholder='Ingrese código de seguridad'
								{...register('securityCode', {
									required: 'Campo obligatorio',
								})}
								className={'custom_input default-shadow p-2 w-100 mb-3'}
								type='text'
							/>
						</InputField>
					</div>

					<div className='col-12 d-flex justify-content-center my-2'>
						<div className='mt-2'>
							<SquareButton
								// ref={buttRef}
								id='submitPayment'
								loading={isSubmitting}
								text='Comprar'
								type='submit'
							/>
						</div>
					</div>

					{error && (
						<div className='alert alert-danger' role='alert'>
							{error}
						</div>
					)}
				</div>
				<div className='d-flex flex-row justify-content-end align-items-center'>
					<p className='mb-0'>Powered by</p>
					<img src={logoMp} className='mp_logo' alt='logo_mercadopago' />
				</div>

				<div>
					Necesitas ayuda para realizar el pago?{' '}
					<span className='font-weight-bold link' onClick={() => setShowModal(true)}>
						Contactanos
					</span>
				</div>
				<ContactModal show={showModal} hide={() => setShowModal(false)} />
				<a
					className='text-success'
					href='https://wa.me/541156385906/?text=Hola+InfoAuto%21+Quisiera+hacer+una+consulta'
					target='_BLANK'
				>
					Contactanos via WhatsApp <FontAwesomeIcon icon={faWhatsapp} className='fa-whatsapp w-7 h-7' />
				</a>
			</form>
		)
	}

	return {
		errors,
		isSubmitting,
		watch,
		register,
		renderForm,
		renderCard,
		buttRef,
		isValid,
	}
}
