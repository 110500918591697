import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import {
  FAVORITES,
  SEARCH_HISTORY,
  USEFUL_GUIDE,
} from "static/permissions/sections";
import { Loader } from "../components/atoms";
import {
  ProfileBilling,
  ProfileFavorites,
  ProfileGuide,
  ProfileHeader,
  ProfileHistory,
  ProfileInfoFields,
  ProfilePlan,
  ProfileUsers,
} from "../components/molecules";
import { ApiHelper, AppHelper } from "../helpers";
import actions from "../store/actions";

export const Profile = () => {
  const [activeSection, setActiveSection] = useState("Perfil");
  const [hasPermission, setHasPermission] = useState(false);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getUser();
    getUserImage();
  }, []);

  useEffect(() => {
    const handlePermission = (hasPermission) => {
      if (hasPermission) {
        setHasPermission(true);
      } else {
        window.location.href = "/404";
      }
    };

    if (user) {
      switch (history.location.pathname) {
        case "/perfil/favoritos":
          handlePermission(AppHelper.hasPermission(user, FAVORITES));
          break;
        case "/perfil/historial":
          handlePermission(AppHelper.hasPermission(user, SEARCH_HISTORY));
          break;
        case "/perfil/guia-util":
          handlePermission(AppHelper.hasPermission(user, USEFUL_GUIDE));
          break;
        default:
          handlePermission(true);
          break;
      }
    }
  }, [history.location, user]);

  const getUser = async () => {
    try {
      dispatch(actions.app.setLoading(true));
      let res = await ApiHelper.get("admin", "/user");

      if (res.data.birthDate) {
        res.data.birthDate = res.data.birthDate.substring(0, 10);
      }
      dispatch(actions.app.setLoading(false));
      setUser({
        ...res.data,
      });
    } catch (error) {
      console.log(error)
      window.location.replace(window.location.origin);
    }
  };

  const getUserImage = async () => {
    try {
      const res = await ApiHelper.getImage("admin", "/user/profile");

      dispatch(actions.user.fillUserData({ image: res.data }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Loader>
      {hasPermission && (
        <div className="mt-5 pt-0 pt-lg-5">
          <div
            className={`pt-4 profile_header ${
              user && user.type === "professional" && "bg_blue"
            }`}
          >
            <ProfileHeader
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
          </div>
          <Switch>
            <Route
              exact
              path="/perfil/mis-datos"
              component={() => <ProfileInfoFields currentUser={user} />}
            />
            <Route
              exact
              path="/perfil/plan"
              component={() => <ProfilePlan currentUser={user} />}
            />
            <Route
              exact
              path="/perfil/facturacion"
              component={ProfileBilling}
            />
            <Route exact path="/perfil/guia-util" component={ProfileGuide} />
            <Route exact path="/perfil/historial" component={ProfileHistory} />
            <Route
              exact
              path="/perfil/favoritos"
              component={ProfileFavorites}
            />
            <Route
              exact
              path="/perfil/usuarios-vinculados"
              component={ProfileUsers}
            />
          </Switch>
        </div>
      )}
    </Loader>
  );
};
