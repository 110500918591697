import actionsTypes from "../actions/actionTypes";
import imgCabrio from "../../static/img/cabriolet.png";
import imgCupe from "../../static/img/cupe.png";
import imgPickup from "../../static/img/pickup.png";
import imgSedan4 from "../../static/img/sedan4.png";
import imgSedan5 from "../../static/img/sedan5.png";
import imgCamionetas from "../../static/img/camionetas.png";
import imgRural from "../../static/img/rural.png";
import imgMinivan from "../../static/img/minivan.png";
import imgMoto from "../../static/img/moto.png";
import imgAtv from "../../static/img/atv.png";

const initialState = {
  types: {
    cars: [
      {
        id: 1,
        name: "Cabriolet",
        img: imgCabrio,
        types: [
          {
            id: 2,
            long_description: "Cabriolet",
            description: "CAB",
          },
        ],
      },
      {
        id: 2,
        name: "Cupe",
        img: imgCupe,
        types: [
          {
            id: 1,
            long_description: "Cupe",
            description: "CUP",
          },
        ],
      },
      {
        id: 3,
        name: "Pick-Up",
        img: imgPickup,
        types: [
          {
            id: 11,
            long_description: "Pick-Up carga (+1000 kg) 4x4",
            description: "PB4",
          },
          {
            id: 9,
            long_description: "Pick-Up carga (-1000 kg)",
            description: "PKA",
          },
          {
            id: 10,
            long_description: "Pick-Up carga (+1000 kg)",
            description: "PKB",
          },
        ],
      },
      {
        id: 4,
        name: "Sedan 4 Puertas",
        img: imgSedan4,
        types: [
          {
            id: 3,
            long_description: "Sedan",
            description: "SED",
          },
        ],
      },
      {
        id: 5,
        name: "Sedan 3/5 Puertas",
        img: imgSedan5,
        types: [
          {
            id: 3,
            long_description: "Sedan",
            description: "SED",
          },
        ],
      },
      {
        id: 6,
        name: "Camionetas",
        img: imgCamionetas,
        types: [
          {
            id: 14,
            long_description: "Camionetas Familiares 4x4",
            description: "WA4",
          },
          {
            id: 13,
            long_description: "Camionetas Familiares (Cherokee)",
            description: "WAG",
          },
          {
            id: 6,
            long_description: "Jeep",
            description: "JEE",
          },
        ],
      },
      {
        id: 7,
        name: "Rural",
        img: imgRural,
        types: [
          {
            id: 8,
            long_description: "Rural",
            description: "RUR",
          },
        ],
      },
      {
        id: 8,
        name: "Van / Mini Van",
        img: imgMinivan,
        types: [
          {
            id: 12,
            long_description: "Familiares (Caravan)",
            description: "VAN",
          },
          {
            id: 19,
            long_description: "Mini Van",
            description: "MIN",
          },
        ],
      },
      {
        id: 9,
        name: "Camiones",
        types: [
          {
            id: 17,
            long_description: "Camiones Pesados",
            description: "PES",
          },
          {
            id: 16,
            long_description: "Camiones Semi Pesados",
            description: "SPE",
          },
          {
            id: 15,
            long_description: "Camiones Livianos",
            description: "LIV",
          },
        ],
      },
      {
        id: 10,
        name: "Furgón",
        types: [
          {
            id: 4,
            long_description: "Furgones Chicos (Courrier)",
            description: "FUA",
          },
          {
            id: 5,
            long_description: "Furgones Grandes (Traffic)",
            description: "FUB",
          },
        ],
      },
      {
        id: 11,
        name: "Mini Bus",
        types: [
          {
            id: 7,
            long_description: "Mini Bus",
            description: "MBU",
          },
        ],
      },
    ],
    motorcycles: [
      {
        id: 1,
        name: "Motos",
        img: imgMoto,
        types: [
          {
            id: 3,
            long_description: "Motos",
            description: "MOT",
          },
        ],
      },
      {
        id: 2,
        name: "Cuatriciclos",
        img: imgAtv,
        types: [
          {
            id: 3,
            long_description: "ATV",
            description: "ATV",
          },
        ],
      },
    ],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
