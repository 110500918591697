import React from "react";

export const VisualSearchLabel = ({ label, onDelete }) => {
  return (
    <div className="default_shadow pt-2 pb-2 pl-3 pr-3 mb-2 rounded-lg result_label d-flex justify-content-between">
      <p className="p-0 m-0 text-muted">{label}</p>
      {onDelete && (
        <span as="button" className="pointer" onClick={onDelete}>
          x
        </span>
      )}
    </div>
  );
};
