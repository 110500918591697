import React, { useState, useEffect } from 'react'
import SectionTitle from '../../components/searchResult/SectionTitle'
import { SearchResultFilters, HistoryCard } from '../../components/organisms'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { ApiHelper, AppHelper } from '../../helpers'
import ReactPaginate from 'react-paginate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import { default as ReactLoader } from 'react-loader-spinner'
import { FAVORITES } from 'static/permissions/sections'
import { CARS, MOTORBIKES } from 'static/vehicleTypes'

export const ProfileFavorites = (props) => {
	const user = useSelector((state) => state.user.user)
	const filters = useSelector((state) => state.search.filters)
	const [loading, setLoading] = useState(true)
	const [cars, setCars] = useState([])
	const vehicle = useSelector((state) => state.search.vehicle)
	const [lastSearchType, setLastSearchType] = useState(vehicle)

	const [offset, setOffset] = useState(0)
	const perPage = 10

	const [pageCount, setPageCount] = useState(0)
	const [datos, setDatos] = useState([])

	const handlePageClick = ({ selected }) => {
		setOffset(selected * perPage)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [offset])

	// useEffect(() => {
	//   getVehicles();
	// }, []);

	// const getVehicles = async () => {
	//   const carsData = {
	//     vehicleType: CARS,
	//     batch: user.codiasFavorites
	//       .filter((c) => c.vehicleType === CARS)
	//       .map((c) => c.codia),
	//   };
	//   const motorbikesData = {
	//     vehicleType: MOTORBIKES,
	//     batch: user.codiasFavorites
	//       .filter((c) => c.vehicleType === MOTORBIKES)
	//       .map((c) => c.codia),
	//   };

	//   const promises = [];
	//   carsData.batch.length > 0 && promises.push(ApiHelper.getInfoGrid(carsData));
	//   motorbikesData.batch.length > 0 &&
	//     promises.push(ApiHelper.getInfoGrid(motorbikesData));
	//   const res = await Promise.all(promises);
	//   const vehicles = [];
	//   res.map((r) =>
	//     r.map((vehicle) => {
	//       vehicles.push(vehicle);
	//     })
	//   );

	//   setCars(vehicles);
	//   setLoading(false);
	// };

	const [currentPage, setCurrentPage] = useState(1)
	const [postsPerPage] = useState(15)
	const [currentData, setCurrentData] = useState({
		responses: [],
		total: postsPerPage,
	})
	const [totalPages, setTotalPages] = useState(Math.ceil(currentData.total / postsPerPage))
	const [vehicles, setVehicles] = useState([])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [offset])

	const fetchVehicles = async () => {
		try {
			const { data } = await ApiHelper.get('admin', `/favorite?pageNumber=${currentPage}&pageSize=${postsPerPage}`)
			let vehicles = data.responses
			if (vehicles.length > 0) {
				const vehiclesWithoutDetails = vehicles.filter(
					(vehicle) => !vehicle.imagen || (!vehicle.versionDescription && !vehicle.model)
				)

				if (vehiclesWithoutDetails.length) {
					const vehiclesWithDetails = await fetchVehicleDetails(vehiclesWithoutDetails)

					vehicles = vehicles.map((vehicle) => {
						const vehicleWithDetails = vehiclesWithDetails.find(
							(vehicleWithDetails) => vehicleWithDetails.codia === vehicle.codia
						)
						return vehicleWithDetails ? { ...vehicle, ...vehicleWithDetails } : vehicle
					})
				}
			}

			setVehicles(vehicles)
			setTotalPages(Math.ceil(data.total / postsPerPage))
			setCurrentData(data)
			setLoading(false)
		} catch (error) {
			console.error(error)
			setLoading(false)
		}
	}

	const fetchVehicleDetails = async (vehiclesWithoutDetails) => {
		try {
			const vehiclesWithoutDetailsData = vehiclesWithoutDetails.map((vehicle) => ({
				codia: vehicle.codia,
				type: vehicle.vehicleType,
			}))
			const { data } = await ApiHelper.post('preciosa', `/models/list`, vehiclesWithoutDetailsData)
			return data
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		fetchVehicles()
	}, [currentPage])

	const paginate = ({ selected }) => {
		setCurrentPage(selected + 1)
		setOffset(selected * perPage)
	}

	return (
		<div id='search_result_page' className='py-5'>
			<div className='container'>
				<div className='mt-2'>
					<div className='mt-2 row pt-2'>
						{/* <SearchResultFilters filters={filters} /> */}
						<div className='col d-flex flex-column align-items-center'>
							{loading && <ReactLoader type='TailSpin' color='#10ffdc' height={50} width={50} />}
							{vehicles?.map((vehicle, index) => (
								<HistoryCard car={vehicle} key={index} vehicleType={vehicle.vehicleType} />
							))}

							{currentData.responses.length && (
								<div className='d-flex flex-column justify-content-center align-items-center'>
									<p className='text-right mt-3'>
										{/* Mostrando {offset + 1} a{" "}
                    					{offset + 10 < totalResults ? offset + 10 : totalResults} de{" "}
                   						 {totalResults} resultados */}
										Mostrando página {currentPage} de {totalPages}
									</p>

									<ReactPaginate
										data={vehicles}
										pageCount={totalPages}
										marginPagesDisplayed={2}
										pageRangeDisplayed={4}
										onPageChange={paginate}
										activeClassName={'active'}
										nextLabel={<FontAwesomeIcon icon={faAngleDoubleRight} />}
										previousLabel={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
										containerClassName={'pagination'}
										subContainerClassName={'pages pagination'}
									/>
								</div>
							)}

							{vehicles.length === 0 && !loading && (
								<h4 className={'text-center font-weight-bold mt-4'}>No tiene vehículos favoritos</h4>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
