import axios from 'axios'
import { faExternalLinkAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { ApiHelper } from '../../helpers'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../store/actions'
import { SquareButton } from '../atoms'
import InputMask from 'react-input-mask'
import { Select } from '../atoms/Select'
import { AlertModal } from '../organisms'
import InputField from 'components/atoms/InputField'

import { useForm, Controller } from 'react-hook-form'

import barrios from 'constants/barrios'

const UserBilling = ({ newUser, nextStep }) => {
	const user = useSelector((state) => state.user.user)

	const [provinces, setProvinces] = useState([])
	const [localities, setLocalities] = useState([])

	const {
		handleSubmit,
		register,
		setValue,
		control,
		getValues,
		resetField,
		watch,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			taxType: '',
			taxStatus: '',
			taxId: '',
			province: '',
			locality: '',
			address: '',
			zipCode: '',
			enterpriseName: '',
			email: '',
			fullName: '',
		},
	})

	const province = watch('province')
	const taxType = watch('taxType')
	const taxStatus = watch('taxStatus')
	const taxTypeRegex =
		taxType?.value === 'DNI'
			? /^\d{8}(?:[-\s]\d{4})?$/
			: taxType?.value === 'PASAPORTE'
			? /^[a-zA-Z]{3}[0-9]{6}$/
			: /^(20|23|24|27|30|33|34)(\-)?[0-9]{8}(\-)?[0-9]$/g

	useEffect(() => {
		getProvinces()
	}, [])

	useEffect(() => {
		const getLocalities = async () => {
			let localities = []
			if (province.label.includes('Ciudad')) {
				localities = barrios
			} else {
				let { data } = await ApiHelper.getLocalities(province.name)
				localities = data
			}

			localities.map((localidad) => {
				if (localidad.name) {
					localidad.value = localidad.name
					localidad.label = localidad.name
				}
			})

			localities.sort(function (a, b) {
				let textA = a.label.toUpperCase()
				let textB = b.label.toUpperCase()
				return textA < textB ? -1 : textA > textB ? 1 : 0
			})

			setLocalities(localities)
		}
		if (province) {
			getLocalities()
			setValue('locality', '')
		}
	}, [province])

	const getProvinces = async () => {
		try {
			let res = await ApiHelper.getProvinces()
			res.data.map((provincia) => {
				provincia.value = provincia.id
				provincia.label = provincia.name
			})
			res.data.sort(function (a, b) {
				let textA = a.label.toUpperCase()
				let textB = b.label.toUpperCase()
				return textA < textB ? -1 : textA > textB ? 1 : 0
			})
			setProvinces(res.data)
		} catch (error) {
			console.log(error)
		}
	}

	let taxTypes = [
		{ label: 'CUIT', value: 'CUIT' },
		{ label: 'CUIL', value: 'CUIL' },
		{ label: 'DNI', value: 'DNI' },
		{ label: 'PASAPORTE', value: 'PASAPORTE' },
	]

	const taxStatusList = [
		{ label: 'IVA Responsable Inscripto', value: 'IVA Responsable Inscripto' },
		{ label: 'IVA no Responsable', value: 'IVA no Responsable' },
		{ label: 'IVA Sujeto Exento', value: 'IVA Sujeto Exento' },
		{ label: 'Consumidor Final', value: 'Consumidor Final' },
		{ label: 'Responsable Monotributo', value: 'Responsable Monotributo' },
		{ label: 'Proveedor del Exterior', value: 'Proveedor del Exterior' },
		{ label: 'Cliente del Exterior', value: 'Cliente del Exterior' },
		{
			label: 'IVA Liberado – Ley Nº 19.640',
			value: 'IVA Liberado – Ley Nº 19.640',
		},
		{
			label: 'CUIVA Responsable Inscripto – Agente de PercepciónIT',
			value: 'IVA Responsable Inscripto – Agente de Percepción',
		},
		{
			label: 'Pequeño Contribuyente Eventual',
			value: 'Pequeño Contribuyente Eventual',
		},
		{ label: 'Monotributista Social', value: 'Monotributista Social' },
		{
			label: 'Pequeño Contribuyente Eventual Social',
			value: 'Pequeño Contribuyente Eventual Social',
		},
	]

	if (taxStatus?.value === 'Consumidor Final') {
		taxTypes = [taxTypes.find((type) => type.value === 'DNI')]
	} else if (
		taxStatus?.value === 'IVA no Responsable' ||
		taxStatus?.value === 'IVA Sujeto Exento' ||
		taxStatus?.value === 'Responsable Monotributo' ||
		taxStatus?.value === 'IVA Responsable Inscripto'
	) {
		taxTypes = [taxTypes.find((type) => type.value === 'CUIT')]
	}

	const onSubmit = async (userData) => {
		const { email } = user

		const { enterpriseName, taxStatus, taxId, taxType, address, province, locality, zipCode } = userData
		const data = {
			enterpriseName: enterpriseName,
			email: email,
			taxStatus: taxStatus.value,
			taxType: taxType.label,
			taxId: taxId,
			enterpriseAddress: address,
			enterpriseProvince: province.name,
			enterpriseLocality: locality.name,
			enterpriseZipCode: zipCode,
		}
		await ApiHelper.post('admin', '/user/billingInformation', data)
		nextStep()
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className={'container p-5 bg-white'}>
				<div className='row'>
					<div className='col-12 mb-2'>
						<h5 className={'mb-4 text-center font-weight-bold'}>Información de facturación</h5>
						<p className='text-center'>Ingresá los datos para realizar la factura.</p>
					</div>
					<>
						<div className='col-12 col-md-4'>
							<Controller
								control={control}
								name='taxStatus'
								render={({ field, fieldState }) => {
									return (
										<InputField label='Condición IVA' error={fieldState?.error}>
											<Select
												placeholder={'Seleccionar'}
												options={taxStatusList}
												selectedOption={field.value}
												onSelect={(value) => {
													field.onChange(value)
													setValue('taxType', '')
												}}
											/>
										</InputField>
									)
								}}
								rules={{ required: 'Ingrese una opción' }}
							/>
						</div>

						<div className='col-12 col-md-4'>
							<Controller
								control={control}
								name='taxType'
								render={({ field, fieldState }) => (
									<InputField disabled label='Tipo Doc.' error={fieldState?.error}>
										<Select
											placeholder={'Seleccionar'}
											options={taxTypes}
											onSelect={(value) => field.onChange(value)}
											selectedOption={field.value}
										/>
									</InputField>
								)}
								rules={{ required: 'Ingrese una opción' }}
							/>
						</div>
						<div className='col-12 col-md-4 '>
							<InputField label='Nro. Doc.' error={errors.taxId}>
								<input
									placeholder={'Número'}
									type={'text'}
									name={'taxId'}
									className={'custom_input default-shadow p-2 w-100 '}
									{...register('taxId', {
										required: 'Ingrese número',
										pattern: {
											value: taxTypeRegex,
											message: `Ingrese un ${taxType?.value || 'numero'} válido`,
										},
									})}
								/>
							</InputField>
						</div>
					</>

					<>
						{taxStatus?.value !== 'Consumidor Final' && taxStatus?.value !== 'Responsable Monotributo' ? (
							<div className='col-12 col-md-6 mt-3'>
								<InputField label='Razón Social' error={errors.enterpriseName}>
									<input
										name={'razonSocial'}
										className={'custom_input default-shadow p-2 w-100 my-3'}
										placeholder={'Razón Social'}
										type={'text'}
										{...register('enterpriseName', {
											required: 'Ingrese razón social',
											pattern: {
												value: /^[a-zA-Z\s]*$/,
												message: 'Ingrese únicamente letras y espacios',
											},
										})}
									/>
								</InputField>
							</div>
						) : (
							<div className='col-12 col-md-6 mt-3'>
								<InputField label='Nombre y Apellido' error={errors.fullName}>
									<input
										name={'fullName'}
										className={'custom_input default-shadow p-2 w-100 my-3'}
										placeholder={'Nombre y Apellido'}
										type={'text'}
										{...register('fullName', {
											required: 'Ingrese nombre y apellido',
										})}
									/>
								</InputField>
							</div>
						)}
						<div className='col-12 col-md-6 mt-3'>
							<InputField label='Email' error={errors.email}>
								<input
									name={'email'}
									className={'custom_input default-shadow p-2 w-100 my-3'}
									placeholder={'Email'}
									type={'email'}
									{...register('email', {
										required: 'Ingrese email',
										pattern: {
											value:
												/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
											message: 'Ingrese un email válido',
										},
									})}
								/>
							</InputField>
						</div>
					</>
				</div>

				<div className='row'>
					<div className='col-12 mt-4'>
						<h5 className='mb-4 font-weight-bold text-center'>Dirección de Facturación</h5>
					</div>

					<div className='col-12 col-md-6 mb-3'>
						<InputField label='Código postal' error={errors.zipCode}>
							<input
								name={'zipcode'}
								className={'custom_input default-shadow p-2 w-100 '}
								placeholder={'Código Postal'}
								type={'text'}
								{...register('zipCode', { required: 'Ingrese código postal' })}
							/>
						</InputField>
					</div>

					<div className='col-12 col-md-6 mb-3'>
						<InputField label='Dirección' error={errors.address}>
							<input
								name={'address'}
								className={'custom_input default-shadow p-2 w-100 '}
								placeholder={'Dirección'}
								type={'text'}
								{...register('address', {
									required: 'Ingrese Dirección',
									pattern: {
										value: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
										message: 'La dirección sólo puede estar formada por letras y números',
									},
								})}
							/>
						</InputField>
					</div>

					<div className='col-12 col-md-6  mb-3'>
						<Controller
							control={control}
							name='province'
							render={({ field, fieldState }) => (
								<InputField label='Provincia' error={fieldState?.error}>
									<Select
										className='select-modal'
										onSelect={(e) => field.onChange(e)}
										selectedOption={field.value}
										placeholder={'Provincia'}
										options={provinces}
									/>
								</InputField>
							)}
							rules={{ required: 'Ingrese una opción' }}
						/>
					</div>
					<div className='col-12 col-md-6 mb-3'>
						<Controller
							control={control}
							name='locality'
							render={({ field, fieldState }) => (
								<InputField label='Localidad' error={fieldState?.error}>
									<Select
										className='select-modal '
										onSelect={(e) => field.onChange(e)}
										selectedOption={field.value}
										placeholder={'Localidad'}
										options={localities}
									/>
								</InputField>
							)}
							rules={{ required: 'Ingrese una opción' }}
						/>
					</div>
					<div className='col-12 d-flex justify-content-center mt-3'>
						<SquareButton text='Continuar' fullWidth={false} className='px-5' />
					</div>
				</div>
			</div>
		</form>
	)
}

export default UserBilling
