import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import actions from "../../store/actions";

export const ReportTypePill = ({className, reportType, setReportType}) => {

    const types = ['Precio', 'Ficha Técnica'];


    return(
        <div className={`d-flex report_type_selector default_shadow link ${className}`}>
            {types.map((type, index) => <div key={index} onClick={() => setReportType(type)} className={`px-2 text-center py-2 text-uppercase font-weight-bolder ${type === reportType ? 'bg_dark' : ''}` }><span>{type}</span></div>)}
        </div>
    )
};
