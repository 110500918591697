import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { SquareButton } from "../atoms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import actions from "../../store/actions";

export const WrongPlanModal = () => {
  const show = useSelector((state) => state.app.showWrongPlanModal);
  const dispatch = useDispatch();
  const hide = () => {
    dispatch(actions.app.setShowWrongPlanModal(false));
  };

  return (
    <Modal show={show} onHide={hide} size={"lg"} className={"default_modal"}>
      <Modal.Body className={"p-0 background_gray"}>
        <div className={"text-right pt-3 pr-3 link"}>
          <FontAwesomeIcon
            onClick={hide}
            icon={faTimes}
          />
        </div>
        <div className="col-12 gray_background p-5">
          <h2 className="font-weight-normal text-center">
            <strong>Oops,</strong> no podés acceder a esta información con tu{" "}
            <strong>plan</strong> actual.
          </h2>
          <div className="row justify-content-center mt-4">
            <div className="col-12 col-md-4">
              <SquareButton onClick={hide} text={"Cancelar"} />
            </div>
            <div className="col-12 col-md-4">
              <a href="/perfil/plan">
                <SquareButton text={"Cambiar plan"} />
              </a>
            </div>{" "}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
