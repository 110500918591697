import React from 'react'
import img from '../../../static/img/comparador1.png'
import { SectionSeparator } from '../../atoms'

export default () => {
	return (
		<div className={'background_gray pt-5'}>
			<div className='container mb-5 py-5 d-flex flex-column justify-content-center align-items-center'>
				<h2 className='text-center font-weight-bold'>Compará, decidí y ganá tiempo</h2>
				<p className={'text-center mt-2'}>
					<strong>El 100% de las personas elige entre 3 marcas diferentes</strong>, nosotros te
					damos la posibilidad de comparar hasta 4.
				</p>
				<small>
					<strong>Home > Búsquedas ></strong> Comparador
				</small>
				<div className='pb-5 pt-3'>
					<img className={'default_shadow mt-3 w-100'} alt={'comparador'} src={img} />
				</div>
				<p className='font-weight-normal text-uppercase mt-4'>Comparador</p>
			</div>
			<SectionSeparator />
		</div>
	)
}
