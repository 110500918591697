import React from "react";
import tableImg from "../../static/img/prcios.png";
import { ChartCard } from "./ChartCard";

export default () => {
  return (
    <section className={"py-0 py-md-5"}>
      <div className="container py-3 py-md-5">
        <div className="row">
          <div className="col-12 col-md-5 order-md-2">
            <h2 className="font-weight-bold">
              Analizá el pasado y mirá con más detalle el presente.
            </h2>
            <ChartCard
              title={"Precios Históricos"}
              text={
                "Consultá cuál fue el valor de un Auto/Moto en los últimos 6 años y comparalo con su valor actual."
              }
            />
            <ChartCard
              title={"Usado del año"}
              text={
                "Consultá el valor de un auto recientemente patentado con mas de 3 meses de uso."
              }
            />
          </div>
          <div className="col-12 col-md-7 order-md-1 mt-5 mt-md-0">
            <img
              alt="tabla-precios"
              src={tableImg}
              className={"w-100 default_shadow mb-4"}
            />
            <p className={"text-uppercase text-center"}>
              Cuadro precios históricos{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
