import { SideMenuSearch } from "components/organisms";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiHelper } from "../../helpers";
import actions from "../../store/actions";
import { Button, ComfortOption, FilterOption } from "../atoms";

export const SearchResultFilterOptions = () => {
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.search.vehicle);
  const filters = useSelector((state) => state.search.filters);
  const [ comfortFiltersToShow, setComfortFiltersToShow ] = useState([]);
  const [ showComfort, setShowComfort ] = useState(false);
  const [ brand, setBrand ] = useState()
  const [ model, setModel ] = useState()
  const [ year, setYear ] = useState([])
  const [ version, setVersion ] = useState()

  const [ options ] = useState([
    {
      show: true,
      title: "Marcas",
      type: "brand",
      requiredValues: [],
    },
    {
      show: true,
      title: "Modelo",
      type: "model",
      requiredValues: [ "brand" ],
    },
    {
      show: true,
      title: "Fabricación",
      type: "year",
      requiredValues: [ "brand", "model" ],
    },
    {
      show: true,
      title: "Version",
      type: "version",
      requiredValues: [ "brand", "model" ],
    },
    {
      show: vehicle === "cars" ? true : false,
      title: "Tipo de Combustible",
      type: "fuel",
      requiredValues: [ "brand", "model" ],
    },
    {
      show: vehicle === "cars" ? true : false,
      title: "Puertas",
      type: "doors",
      requiredValues: [ "brand", "model" ],
    },
  ]);
  const [ activeOptions, setActiveOptions ] = useState([]);

  // useEffect(() => {
  //   setBrand(null);
  // }, [ vehicleType ]);

  // useEffect(() => {
  //   setModel(null);
  // }, [ brand ]);

  // useEffect(() => {
  //   if (model) {
  //     getYears();
  //   }
  //   setYears([]);
  //   setYear(null);
  // }, [ model ]);

  // useEffect(() => {
  //   if (year) {
  //     getVersions();
  //   }
  //   setVersions([]);
  //   setVersion(null);
  // }, [ year ]);


  useEffect(() => {
    const newOptions = [];
    options.map((option) => {
      const activeValue = filters.find((filter) => filter.type === option.type);
      let display = true;
      option.requiredValues.map((value) => {
        if (display) {
          const requiredActive = filters.find(
            (filter) => filter.type === value
          );
          display = !!requiredActive;
        }
      });
      if (!activeValue && display) {
        newOptions.push(option);
      }
    });
    setActiveOptions(newOptions);
  }, [ filters ]);

  useEffect(() => {
    getComfortFilters();
  }, [ vehicle ]);

  const getComfortFilters = async () => {
    const data = {
      vehicleType: vehicle,
    };
    const res = await ApiHelper.post("preciosa", "/features/comfort", data);
    const filters = res.data.response.filter(
      (option) => option.type === "boolean"
    );
    dispatch(actions.filterTypes.setFilterTypes(filters));
    setComfortFiltersToShow(filters);
  };

  return (
    <div className="filter_options_container mt-4 pt-3">
      {activeOptions.map((option, index) =>
        option.show ? (
          <FilterOption key={index} option={option} placeholder="Seleccione" />
        ) : null
      )}
      {/* <SideMenuSearch /> */}
      {filters.length >= 2 ? (
        <div className="d-flex flex-column border_top pt-2">
          <p className="font-weight-bold">Confort</p>
          {showComfort
            ? comfortFiltersToShow.map((option) => (
              <ComfortOption key={option.id} option={option} />
            ))
            : null}
          <div className={"mt-3 text-center"}>
            <Button
              text={showComfort ? "Ocultar" : "Mostrar"}
              dark={true}
              onClick={() => setShowComfort(!showComfort)}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
