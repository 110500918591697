import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ReportTypePill, Select, SquareButton } from "../atoms";
import { ApiHelper } from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { set } from "numeral";

export const ReportModal = ({ show, hide, vehicle }) => {
  const [reportType, setReportType] = useState("Precio");
  const user = useSelector((state) => state.user.user);
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.phoneNumber);
  const [message, setMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    setReportType(localStorage.reportType);
    setTimeout(() => {
      setSent(false);
    }, 1000);
  }, [show]);

  const submitForm = async (e) => {
    e.preventDefault();

    const data = {
      email,
      subject: `Reporte de ${reportType}`,
      message: `
            Tipo de reporte: ${reportType}
            Email: ${email}
            Telefono: ${phone}
            Modelo: ${localStorage.reportModelo}
            Mensaje: ${message}`,
    };

    try {
      setIsFetching(true);
      const res = await ApiHelper.post("admin", "/mail", data);

      setSent(true);
      setIsFetching(false);
      setTimeout(() => {
        hide();
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={hide}
      size={sent ? "sm" : "lg"}
      className={"default_modal"}
    >
      <Modal.Body className={"p-0 background_gray"}>
        <div className={"text-right pt-3 pr-3 link"}>
          <FontAwesomeIcon
            onClick={hide}
            icon={faTimes}
          />
        </div>
        {sent ? (
          <div>
            <div className="row">
              <div className="col-12 gray_background p-5 text-center">
                <h4 className="font-weight-normal mb-0">
                  <strong>Mensaje enviado</strong>!
                </h4>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 gray_background p-5">
              <h1 className="font-weight-normal mb-0">
                Hola{user?.firstName && `, ${user.firstName}`}!
              </h1>
              <h1 className="font-weight-normal mb-0 border-bottom-1 pb-3">
                ¿Qué querés que revisemos?
              </h1>
              {vehicle && (
                <>
                  <h3 className="mt-4">
                    {vehicle.versionDescription || vehicle.name}
                  </h3>
                  <h5>{vehicle.brand.name}</h5>
                </>
              )}
              <div className="d-flex mt-5 align-items-center">
                <strong className={"mr-2"}>Seleccionar tipo de consulta</strong>{" "}
                <ReportTypePill
                  setReportType={setReportType}
                  reportType={reportType}
                />
              </div>
              <div className="row mt-1">
                <div className="mt-4 col-12 col-md-4 ">
                  <label className="font-weight-bold">Email</label>
                  <input
                    name={"email"}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    className={"custom_input default-shadow p-2 w-100 mb-3"}
                    placeholder={"Email"}
                    type={"email"}
                  />
                </div>
                <div className="mt-4 col-12 col-md-4 ">
                  <label className="font-weight-bold">Telefono</label>
                  <input
                    name={"phone"}
                    defaultValue={phone}
                    onChange={(event) => setPhone(event.target.value)}
                    className={"custom_input default-shadow p-2 w-100 mb-3"}
                    placeholder={"Teléfono"}
                    type={"tel"}
                  />
                </div>
                <div className="col-12 mb-4 d-flex flex-column">
                  <label className={"font-weight-bold"}>Mensaje</label>
                  <textarea
                    name={"message"}
                    defaultValue={message}
                    onChange={(event) => setMessage(event.target.value)}
                    placeholder={"Escriba su mensaje"}
                    className={"custom_input text_area w-100 p-2 px-3"}
                  />
                </div>
                {/* <div className="col-12 text-center mt-3 text-center text-md-right">
                                    <a className={'font-weight-bold mr-3'}>contacto@infoauto.com.ar</a>
                                    <button className={'btn btn-black text-white'}>ENVIAR</button>
                                </div> */}
                <div className="col-12 text-center mt-3 text-center text-md-right">
                  <SquareButton
                    loading={isFetching}
                    onClick={submitForm}
                    text={"ENVIAR"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
