import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import {firebaseConfig} from '../constants'

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

export {
    auth
};
