import React from 'react'

export const Pill = (props) => {
  return (
    <div id={props.attr} className='col-6 col-md-12 mb-3'>
      <div className='text-uppercase measurement-pill font-weight-bolder py-3 d-flex align-items-center justify-content-around default_shadow'>
          <span>{props.attr}</span>
          <span>|</span>
          <span className={'font-weight-normal'}>{props.value} {props.unit}</span>
      </div>
    </div>
  )
}
