import React, { useEffect, useState } from 'react'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import { ApiHelper } from '../../helpers'
import { Button, CarTypeCard, PaginatedSelect, SquareButton } from '../atoms'
import { FeatureFilter } from '../molecules'

const VisualSelectedFilters = ({ history }) => {
	const { id, type } = useParams()
	const date = new Date()
	const vehicleTypes = useSelector((state) => state.vehicleTypes)
	const [brands, setBrands] = useState([])
	const [models, setModels] = useState([])
	const [fuels, setFuels] = useState([])
	const [subTypes, setSubTypes] = useState([])
	const [transmissions, setTransmissions] = useState([])
	const [years] = useState({
		min: date.getFullYear() - 40,
		max: date.getFullYear(),
	})
	const [prices] = useState({ min: 0, max: 20000000 })
	const [selection, setSelection] = useState({
		years: years,
		prices: prices,
	})
	const [vehicleType, setVehicleType] = useState()
	const vehicle = useSelector((state) => state.search.vehicle)
	const types = useSelector((state) => state.visualSearch.types)
	const dispatch = useDispatch()

	useEffect(() => {
		// getVehicleTypes();
		getFuel()
		getTransmission()
	}, [])

	useEffect(() => {
		setSelection({
			years: years,
			prices: prices,
			brand: null,
			model: null,
			fuel: null,
			transmission: null,
		})
		setBrands([])
		setModels([])
	}, [vehicle])

	useEffect(() => {
		const vehicleType = types[type].find((type) => type.id == id)
		setVehicleType(vehicleType)
		// getBrands();
	}, [types])

	// useEffect(() => {
	//   if (!selection.brand) {
	//     getBrands();
	//   }
	// }, [selection.brand]);

	// useEffect(() => {
	//   // getBrands();
	// }, [subTypes]);

	useEffect(() => {
		if (vehicleType) {
			vehicleType.types.map((t) => {
				t.label = t.long_description
				t.value = t.id
			})
			setSubTypes(vehicleType.types)
			if (vehicleType.types.length === 1) {
				const newSelection = {
					...selection,
					subType: vehicleType.types[0],
				}
				setSelection(newSelection)
			}
		}
	}, [vehicleType])

	const getFuel = async () => {
		const data = {
			vehicleType: vehicle,
		}
		const res = await ApiHelper.post('preciosa', `/features/1/choices`, data)

		let options = []
		res.data.response.map((data) => {
			const option = {
				value: data.id,
				label: data.long_description,
				name: data.description,
				id: data.id,
			}
			options.push(option)
		})
		setFuels(options)
	}

	const getTransmission = async () => {
		try {
			const data = {
				vehicleType: vehicle,
			}
			const res = await ApiHelper.post('preciosa', `/features/transmissionTypes`, data)

			let options = []
			res.data.response[0].choices.map((data) => {
				const option = {
					value: data.id,
					label: data.long_description,
					name: data.description,
					id: data.id,
				}
				options.push(option)
			})
			setTransmissions(options)
		} catch (error) {
			console.log(error)
		}
	}

	const setBrand = (value) => {
		const newSelection = {
			...selection,
			brand: value,
			model: null,
		}
		setSelection(newSelection)
	}

	const setSubtype = (value) => {
		const newSelection = {
			...selection,
			subType: value,
		}
		setSelection(newSelection)
	}

	const setModel = (value) => {
		const newSelection = {
			...selection,
			model: value,
		}
		setSelection(newSelection)
	}

	const search = () => {
		if (selection.brand && selection.model) {
			let params = {
				brand: selection.brand.id,
				brandName: selection.brand.name,
				model: selection.model.id,
				modelName: selection.model.name,
				fromYear: selection.years.min,
				toYear: selection.years.max,
				minPrice: selection.prices.min,
				maxPrice: selection.prices.max,
				fuel: selection.fuel?.name,
				transmission: selection.transmission?.name,
				vehicleType: selection.subType?.description ? selection.subType.description : subTypes[0].description,
			}
			switch (vehicleType.name) {
				case 'Sedan 4 Puertas':
					params.feature_11 = '4'
					break
				case 'Sedan 3/5 Puertas':
					params.feature_11 = []
					params.feature_11[0] = '3'
					params.feature_11[1] = '5'
					break
			}

			params = new URLSearchParams(params)

			if (window.gtag) {
				window.gtag('event', 'busqueda_visual_completada', { ...params })
			}

			history.push(`/busqueda-visual/resultados?${params}`)
		}
	}

	const loadBrands = async (searchQuery, loadedOptions, { page }) => {
		const data = {
			paginated: true,
			vehicleType: vehicle,
			page,
		}

		const features = {
			feature_3: subTypes.map((type) => type.description),
		}

		if (vehicleType.name === 'Sedan 4 Puertas') {
			features.feature_11 = [4]
		} else if (vehicleType.name === 'Sedan 3/5 Puertas') {
			features.feature_11 = [3, 5]
		}

		const res = await ApiHelper.getPaginatedBrands(data, features)

		return {
			options: res.data.response,
			hasMore: res.data.pagination.totalPages > page,
			additional: {
				page: searchQuery ? 2 : page + 1,
			},
		}
	}

	const loadModels = async (searchQuery, loadedOptions, { page }) => {
		if (selection.brand) {
			const data = {
				paginated: false,
				hasUsedPrice: true,
				vehicleType: vehicle,
				page,
			}

			const features = {
				feature_3: subTypes.map((type) => type.description),
			}

			if (vehicleType.name === 'Sedan 4 Puertas') {
				features.feature_11 = [4]
			} else if (vehicleType.name === 'Sedan 3/5 Puertas') {
				features.feature_11 = [3, 5]
			}
			const res = await ApiHelper.getPaginatedModels(selection.brand.id, data, features)

			return {
				options: res.data.response,
				hasMore: res.data.pagination.totalPages > page,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			}
		} else {
			return {
				options: [],
				hasMore: true,
				additional: {
					page: searchQuery ? 2 : page + 1,
				},
			}
		}
	}

	const volver = () => {
		history.push('/busqueda-visual')
	}

	return (
		<div id='visual_selected_filters_container' className='mt-5 grey-container pt-3 mb-5'>
			<div className='row no-gutters'>
				<div id='car_type_container' className='col d-flex justify-content-center px-0'>
					{vehicleType ? (
						<div>
							<CarTypeCard canNavigate={false} type={vehicleType} big={true} />{' '}
							<div className=' d-flex justify-content-center'>
								{' '}
								<SquareButton text={'Volver'} fullWidth={false} onClick={volver} />{' '}
							</div>{' '}
						</div>
					) : null}
				</div>
				<div className='d-xl-none w-100' />
				<div id='car_info_container' className='col-xl-9'>
					<div className='row p-3'>
						<div className={`col-lg-6 mb-4 px-5 mb-3`}>
							<p className={`font-weight-bold mb-3`}>Marca</p>
							<PaginatedSelect
								key={'brand'}
								value={selection.brand}
								loadOptions={loadBrands}
								getOptionValue={(option) => option.id}
								getOptionLabel={(option) => option.name}
								onChange={setBrand}
								isSearchable={false}
								placeholder='Seleccione una marca'
								additional={{
									page: 1,
								}}
							/>
						</div>
						<div className={`col-lg-6 mb-4 px-5 mb-3`}>
							<p className={`font-weight-bold mb-3`}>Modelo</p>
							<PaginatedSelect
								key={'model'}
								value={selection.model}
								loadOptions={loadModels}
								getOptionValue={(option) => option.id}
								getOptionLabel={(option) => option.name}
								defaultOptions={false}
								cacheUniqs={[selection.brand]}
								onChange={setModel}
								isSearchable={false}
								placeholder='Seleccione un modelo'
								additional={{
									page: 1,
								}}
							/>
						</div>

						<FeatureFilter
							options={transmissions}
							selectedOption={selection.transmission}
							onChange={(value) => setSelection({ ...selection, transmission: value })}
							title={'Transmisión'}
							placeholder={'Seleccione una transmisión'}
						/>
						<FeatureFilter
							options={fuels}
							selectedOption={selection.fuel}
							onChange={(value) => setSelection({ ...selection, fuel: value })}
							title={'Combustible'}
							placeholder={'Seleccione Combustible'}
						/>
						<FeatureFilter
							options={subTypes}
							selectedOption={selection.subType}
							onChange={setSubtype}
							title={'Subtipo'}
							placeholder={'Seleccione Subtipo'}
						/>
						<div className='col-12 col-md-6' />

						<div className='mb-5 col-xl-6 px-5'>
							<p className='font-weight-bold mb-3'>Rango de precios</p>
							<p className='mb-3'>
								Mínimo: ${selection.prices.min.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')}
							</p>
							<p className='mb-4'>
								Máximo: ${selection.prices.max.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')}
							</p>
							<div className='p-2 mt-5'>
								<InputRange
									allowSameValues={true}
									minValue={prices.min}
									maxValue={prices.max}
									formatLabel={(value) => `$${value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')}`}
									value={selection.prices}
									step={5000}
									onChange={(value) => setSelection({ ...selection, prices: value })}
								/>
							</div>
						</div>
						<div className='mb-5 col-xl-6 px-5'>
							<p className='font-weight-bold mb-3'>Años de Fabricación</p>
							<p className='mb-3'>Mínimo: {selection.years.min}</p>
							<p className='mb-4'>Máximo: 0 KM {selection.years.max}</p>
							<div className='p-2 mt-5'>
								<InputRange
									allowSameValues={true}
									minValue={years.min}
									maxValue={years.max}
									formatLabel={(value) => value}
									value={selection.years}
									step={1}
									onChange={(value) => setSelection({ ...selection, years: value })}
								/>
							</div>
						</div>

						<div className='col-12 px-0 px-lg-5 text-right text-center'>
							{selection.brand && selection.model && <Button dark={true} text={'Buscar'} onClick={search} />}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default withRouter(VisualSelectedFilters)
