import React, { useEffect, useRef, useState } from 'react'

const MainColumn = (props) => {
	const [name, setName] = useState('')
	const { setValue, allValues, setValues, values, title, grayBackground, center, lg } = props
	const handleSearch = (event) => {
		let { value } = event.target
		setName(event.target.value)
		const values = allValues.filter((v) => v.name.toLowerCase().includes(value.toLowerCase()))
		setValues(values)
	}

	useEffect(() => {
		if (values.length === 0) {
			setValue(null)
		}
	}, [values])

	useEffect(() => {
		if (props.selectedValue && props.values.length === 1) {
			setValues(allValues)
			setName('')
		}
	}, [props.selectedValue])

	const ref = useRef()

	const handleEnter = (event) => {
		const { key } = event
		ref.current.scrollTop = 0
		if (key === 'Enter' && values.length > 0) {
			setValue(values[0])
			setValues(allValues)
			setName('')
		}
	}

	return (
		<div
			className={`col-2 advanced_search_column pb-2 ${grayBackground ? 'background_column' : ''} ${
				lg && 'col-3'
			}`}
		>
			<h5 className={`mt-3 font-weight-bold ${center && 'text-center'}`}>{title}</h5>
			<div className='form-group'>
				<input
					value={name}
					type='text'
					className='form-control my-1 border-0 default_shadow'
					placeholder='Buscar'
					onChange={handleSearch}
					onKeyDown={handleEnter}
				/>
			</div>
			<div ref={ref} className='options_container'>
				<ul className={'pr-0'}>
					{props.selectedValue && !name && props.values.length > 0 && (
						<li className={`btn text-left w-100 ${center && 'text-center'} bg_black text-white`}>
							{props.selectedValue.name}
						</li>
					)}
					{props.values.map(
						(value, index) =>
							!(value === props.selectedValue) && (
								<li
									key={index}
									className={`btn text-left w-100 ${center && 'text-center'} ${
										value === props.selectedValue ? 'bg_black text-white' : ''
									}`}
									onClick={() => props.onSelect(value)}
								>
									{value.name}
								</li>
							)
					)}
				</ul>
			</div>
			<div className='scroll_overlay' />
		</div>
	)
}

export default MainColumn
