import React, { useState, useEffect } from "react";
import AdXL from "../components/ads/AdXL";
import SectionTitle from "../components/searchResult/SectionTitle";
import { ResultCard } from "../components/organisms";
import {
  CarTypeCard,
  VisualSearchLabel,
  SquareButton,
  Button,
  ComfortOption,
} from "../components/atoms";
import { useSelector, useDispatch } from "react-redux";
import { ApiHelper, AppHelper } from "../helpers";
import { default as ReactLoader } from "react-loader-spinner";
import { useHistory, useLocation } from "react-router-dom";
import actions from "../store/actions";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { VISUAL_SEARCH } from "static/permissions/sections";

export const VisualSearchResults = (props) => {
  const filters = useSelector((state) => state.search.filters);
  const location = useLocation();
  const [cars, setCars] = useState([]);
  const comfortFilters = useSelector((state) => state.filterTypes);
  const vehicle = useSelector((state) => state.search.vehicle);
  const [lastSearchType, setLastSearchType] = useState(vehicle);
  const types = useSelector((state) => state.visualSearch.types);
  const [vehicleType, setVehicleType] = useState(null);
  const [yearString, setYearString] = useState();
  const [priceString, setPriceString] = useState();

  const [fuel, setFuel] = useState();
  const [pageCount, setPageCount] = useState(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [transmission, setTransmission] = useState();
  const params = AppHelper.parseParams(props.location.search);
  const history = useHistory();
  const [comfortFiltersToShow, setComfortFiltersToShow] = useState([]);
  const [showComfort, setShowComfort] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (AppHelper.hasPermission(user, VISUAL_SEARCH)) {
      setHasPermission(true);
      if (props.location.search) {
        // getBrands();
        // getModels();
        const fromYear = params.fromYear;
        const toYear = params.toYear;
        setYearString(`${fromYear} - ${toYear}`);
        setPriceString(
          `$${new Intl.NumberFormat("es").format(
            params.minPrice
          )} - $${new Intl.NumberFormat("es").format(params.maxPrice)}`
        );
        if (params.fuel) {
          getFuel();
        }
        if (params.transmission) {
          getTransmission();
        }
        getQuery();
      }
    } else {
      window.location.href = "/404";
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    getConfortFilters();
  }, [vehicle]);

  const getConfortFilters = async () => {
    const data = {
      vehicleType: vehicle,
    };
    const res = await ApiHelper.post("preciosa", "/features/comfort", data);
    const filters = res.data.response.filter(
      (option) => option.type === "boolean"
    );
    dispatch(actions.filterTypes.setFilterTypes(filters));
    setComfortFiltersToShow(filters);
  };

  const getFuel = async () => {
    const data = {
      vehicleType: vehicle,
    };
    const res = await ApiHelper.post("preciosa", `/features/1/choices`, data);
    const fuel = res.data.response.find(
      (fuelType) => fuelType.description == params.fuel
    );
    setFuel(fuel);
  };

  const getTransmission = async () => {
    const data = {
      vehicleType: vehicle,
    };
    const res = await ApiHelper.post(
      "preciosa",
      `/features/transmissionTypes`,
      data
    );
    const transmission = res.data.response[0].choices.find(
      (transmissionType) => transmissionType.description == params.transmission
    );

    setTransmission(transmission);
  };

  const getQuery = async () => {
    setLoading(true);

    const vehicleTypeId = params.vehicleType;
    const type = types.cars.find((t) => t.id == vehicleTypeId);

    setVehicleType(type);

    if (!Array.isArray(params.feature_11) && params.feature_11?.length > 1) {
      params.feature_11 = params.feature_11.split(",");
    }

    const features = {};

    filters.map((filter) => {
      const activeFilter = comfortFilters.find(
        (f) => f.description === filter.type
      );
      if (activeFilter) {
        features[`feature_${activeFilter.id}`] = true;
      }
    });

    let data = {
      features: {
        ...features,
        feature_3: params.vehicleType,
        feature_11: params.feature_11,
      },
      minPriceUsed: params.minPrice,
      maxPriceUsed: params.maxPrice,
      page: page + 1,
      vehicleType: vehicle,
    };

    if (params.fuel !== "undefined") {
      data.features.feature_1 = params.fuel;
    }
    if (params.transmission !== "undefined") {
      data.features.feature_7 = params.transmission;
    }

    try {
      const res = await ApiHelper.getPaginatedVersions(
        params.brand,
        params.model,
        data
      );

      if (
        res.data.pagination.totalPages > 0 &&
        res.data.response.length === 0
      ) {
        setPage(0);
        return;
      }
      setPageCount(res.data.pagination.totalPages);
      setCars(res.data.response);
      setLoading(false);
    } catch (error) {
      setCars([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (page > 0) {
      getQuery();
    }
  }, [filters, page]);

  const goBack = () => {
    window.location.href = `${window.location.origin}/busqueda-visual`;
  };

  const onDeleteFeature = (feature) => {
    dispatch(actions.search.setSearch(filters.filter((f) => f !== feature)));
  };

  const handlePageClick = (page) => {
    setPage(page.selected);
  };

  return (
    <div id="search_result_page" className="page_default_margin">
      <div className="container">
        <AdXL subsection="-resultado" />
        <div className="mt-4">
          <SectionTitle />
          <div id="result_section" className="mt-2 row pt-4">
            <div className="col-3">
              {vehicleType ? (
                <CarTypeCard type={vehicleType} big={true} />
              ) : null}
              <VisualSearchLabel label={params.brandName} />
              <VisualSearchLabel label={params.modelName} />
              <VisualSearchLabel label={params.vehicleType} />
              <VisualSearchLabel label={yearString} />
              <VisualSearchLabel label={priceString} />
              {fuel && <VisualSearchLabel label={fuel.long_description} />}
              {transmission && (
                <VisualSearchLabel label={transmission.long_description} />
              )}
              {filters.map((filter, index) => (
                <VisualSearchLabel
                  onDelete={() => onDeleteFeature(filter)}
                  key={index}
                  label={filter.name}
                />
              ))}

              <div className="d-flex flex-column border_top pt-2">
                <p className="font-weight-bold">Confort</p>
                {showComfort
                  ? comfortFiltersToShow.map((option) => (
                      <ComfortOption key={option.id} option={option} />
                    ))
                  : null}
                <div className={"mt-3 text-center"}>
                  <Button
                    text={showComfort ? "Ocultar" : "Mostrar"}
                    dark={true}
                    onClick={() => setShowComfort(!showComfort)}
                  />
                </div>
              </div>

              <SquareButton
                text="Volver a filtros"
                className="mt-4"
                onClick={() => history.goBack()}
              />
              <SquareButton
                text="Nueva búsqueda"
                className="mt-4"
                onClick={goBack}
              />
            </div>
            <div className="col d-flex flex-column align-items-center">
              {cars.map((car, index) => (
                <ResultCard
                  lastSearch={lastSearchType}
                  car={car}
                  key={index}
                  vehicleType={vehicle}
                />
              ))}
              {loading && (
                <div
                  className={
                    "d-flex justify-content-center align-items-center h-100"
                  }
                >
                  <ReactLoader
                    type="Puff"
                    color="#10ffdc"
                    height={50}
                    width={50}
                  />
                </div>
              )}
              {pageCount > 0 && (
                <ReactPaginate
                  data={cars}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  onPageChange={handlePageClick}
                  activeClassName={"active"}
                  nextLabel={<FontAwesomeIcon icon={faAngleDoubleRight} />}
                  previousLabel={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                />
              )}
              {!loading &&
                (cars.length === 0 ? (
                  <h4 className={"text-center font-weight-bold mt-4"}>
                    No existen modelos con las características seleccionadas
                  </h4>
                ) : cars.length === 0 && filters.length === 0 ? (
                  <h4 className={"text-center font-weight-bold mt-4"}>
                    Seleccione filtros para realizar una búsqueda
                  </h4>
                ) : null)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
