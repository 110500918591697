import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SHARE_PRICE, USED_PRICE } from "static/permissions/sections";
import { ApiHelper, AppHelper } from "../../helpers";
import actions from "../../store/actions";
import { BlurredGraph, PriceChartOption } from "../atoms";
import { PriceChart } from "../molecules";
import { InfoCreditsAlertModal } from "./InfoCreditsAlertModal";
import { InfoCreditsModal } from "./InfoCreditsModal";

export const VehicleSheetPriceChart = ({
  setShowModal,
  newPrice,
  versionCode,
  vehicleName = null,
  vehicle,
}) => {
  const [showPrice, setShowPrice] = useState(false);
  const [prices, setPrices] = useState([]);
  const [date, setDate] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const user = useSelector((state) => state.user.user);
  // const vehicle = useSelector((state) => state.search.vehicle);
  const dispatch = useDispatch();
  const url = window.location.href;
  const [showInfoCreditsModal, setShowInfoCreditsModal] = useState(false);

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.toLocaleString("es", { month: "long" });
    setDate({ year, month });
    if (user?.codiasConsultados.some((v) => v.codia == versionCode)) {
      getPrices();
    }
  }, [user]);

  const handleShowPrices = () => {
    if (user.availableInfocredits === 0) {
      setShowInfoCreditsModal(true);
    } else {
      if (localStorage.hidePriceAlert) {
        getPrices();
      } else {
        setShowAlert(true);
      }
    }
  };

  const getPrices = async () => {
    try {
      const currentYear = new Date().getFullYear();
      const data = {
        vehicleType: vehicle,
      };
      const res = await ApiHelper.getPrices(versionCode, data);

      if (!AppHelper.hasPermission(user, USED_PRICE)) {
        res.usedPrices = res.usedPrices.filter(
          (year) => year.year !== currentYear
        );
      }

      if (res.newPrice) {
        setPrices([
          { year: "0km", price: res.newPrice.price },
          ...res.usedPrices,
        ]);
      } else {
        setPrices(res.usedPrices);
      }

      if (!user?.codiasConsultados.some((v) => v.codia == versionCode)) {
        dispatch(
          actions.user.fillUserData({
            ...user,
            codiasConsultados: [
              ...user.codiasConsultados,
              {
                codia: versionCode,
                vehicleType: vehicle,
                dateConsulted: new Date().toISOString(),
              },
            ],
            availableInfocredits: res.userCredits,
          })
        );
      }

      localStorage.user = JSON.stringify({
        ...user,
        availableInfocredits: res.userCredits,
      });
      setShowAlert(false);
      setShowPrice(true);
    } catch (error) {
      console.log(error);
      if (error.status === 401) {
        if (localStorage.hidePriceAlert) {
          localStorage.clear();
          localStorage.hidePriceAlert = true;
        } else {
          localStorage.clear();
        }
        // window.location.href = window.location.origin;
        window.location.replace("/?showloginmodal=true&showsession=true");
      }
    }
  };

  const showReport = () => {
    localStorage.reportModelo = vehicle.description;
    localStorage.reportType = "Precio";
    setShowModal(true);
  };

  const shareWhatsapp = () => {
    let text = `Te comparto los precios del vehículo ${vehicleName}:%0a`;
    text += newPrice
      ? `0km --- $${new Intl.NumberFormat("es").format(
          newPrice.list_price * 1000
        )}%0a`
      : "";
    prices.map((price) => {
      text += `${price.year} --- $${new Intl.NumberFormat("es").format(
        price.price * 1000
      )}%0a`;
    });
    text += `%0aInfoAuto - La guía oficial de precios%0aPara más info ingresá en ${window.location.href}`;
    window.open(`whatsapp://send?text=${text}`);
  };

  const getShareEmailText = () => {
    let text = `subject=Precios ${vehicleName}&body=Te comparto los precios del vehículo ${vehicleName}:%0D%0A`;
    text += newPrice
      ? `0km --- $${new Intl.NumberFormat("es").format(
          newPrice.list_price * 1000
        )}%0D%0A`
      : "";
    prices.map((price) => {
      text += `${price.year} --- $${new Intl.NumberFormat("es").format(
        price.price * 1000
      )}%0D%0A`;
    });
    text += `%0D%0AInfoAuto - La guía oficial de precios%0D%0APara más info ingresá en ${window.location.href}`;
    return text;
  };

  return (
    <div>
      {showPrice ? (
        <div className="row my-4">
          <div className="col-12 d-flex align-items-center mb-3">
            <h5 className="font-weight-bolder mb-0">Precios</h5>
            <small className="ml-3 color_blue">
              Todos los valores correspondientes a{" "}
              <strong>
                {date.month} {date.year}
              </strong>
            </small>
            <small className="chart_contact text-right">
              Tenés algo que informarnos?{" "}
              <strong onClick={showReport} className={"link"}>
                Reportar
              </strong>
            </small>
          </div>
          <div className="col-12">
            <div className="row">
              <div id="price_table" className="col-md-6">
                <div className={"overlay_container"}>
                  <div className="d-flex justify-content-between">
                    <h6 className="font-weight-bold">Fabricacíon</h6>
                    <h6 className="font-weight-bold mr-2">Pesos</h6>
                  </div>
                  <ul className="p-0 price_chart_table">
                    {newPrice && (
                      <PriceChartOption
                        price={{ price: newPrice.list_price, year: "0km" }}
                      />
                    )}
                    {prices.map((price, index) => (
                      <PriceChartOption key={index} price={price} />
                    ))}
                  </ul>
                  <div className={"scroll_overlay"} />
                </div>
              </div>
              <div className="col-md-6">
                <PriceChart prices={prices} />
              </div>
              {AppHelper.hasPermission(user, SHARE_PRICE) && (
                <div className="col-12 d-flex justify-content-end align-items-center">
                  <p
                    className={
                      "font-weight-bold text-uppercase text-right mb-0"
                    }
                  >
                    Compartir
                  </p>

                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    className={"ml-3 pointer"}
                    size={"lg"}
                    onClick={shareWhatsapp}
                  />

                  <a
                    className="text-dark"
                    href={`mailto:?${getShareEmailText()}`}
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className={"ml-3"}
                      size={"lg"}
                    />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <BlurredGraph showModal={handleShowPrices} vehicleType={vehicle} />
      )}
      <InfoCreditsModal
        show={showInfoCreditsModal}
        hide={() => setShowInfoCreditsModal(false)}
      />
      <InfoCreditsAlertModal
        getPrices={getPrices}
        hide={() => setShowAlert(false)}
        show={showAlert}
      />
    </div>
  );
};
