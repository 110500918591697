import React from "react";

export const ProgressBar = ({ progress }) => {
  console.log(progress);
  return (
    <div id="progress_bar" className={"w-100"}>
      <div
        id={"progress_bar_fill"}
        className={"h-100"}
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};
