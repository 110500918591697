import React from "react";
import { Logo, SectionSeparator, PageIndex } from "../atoms";

export default () => {
  return (
    <section className={"individuals_title"}>
      <div className="p-5 mb-md-5">
        <div className="container mt-5">
          <PageIndex />
          <h1
            className={
              "font-weight-bold text-uppercase text-center mb-4 mt-md-5"
            }
          >
            <Logo />
          </h1>
          <p className={"text-center"}>
            Somos la única consultora <strong>independiente</strong>{" "}
            especializada en la investigación del mercado automotor argentino.{" "}
            <strong>Apasionados</strong> por lo que hacemos, nuestro{" "}
            <strong>compromiso</strong> es brindar <strong>respaldo</strong>{" "}
            para <strong>simplificar</strong> la toma de decisiones y la
            administración de datos a escala de todos nuestros clientes.
          </p>
          <p className="text-center font-weight-bold">
            Para ello, creamos hace más de 25 años la Guía oficial de precios de
            vehículos de Argentina
          </p>
          <ul className={"mt-5 flex-column flex-md-row"}>
            <li className={"default_shadow mb-3 mb-md-0"}>Compromiso</li>
            <li className={"default_shadow mb-3 mb-md-0"}>Objetividad</li>
            <li className={"default_shadow mb-3 mb-md-0"}>Experiencia</li>
          </ul>
        </div>
      </div>
      <SectionSeparator />
    </section>
  );
};
