import React from "react";
import { Parallax } from "react-parallax";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Logo } from "../atoms";
import background from "../../static/img/infoauto_background.jpg";

export const HomeParallax = () => {
  return (
    <section id={"home_parallax"} className={"mt-5"}>
      <Parallax bgImage={background} strength={600}>
        <div className="container py-5">
          <div className="row mt-2">
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <h1
                className={
                  "font-weight-bold text-center text-md-right display-4"
                }
              >
                Nuestro método,
                <br />
                tu respaldo.
              </h1>
            </div>
            <div className="col-12 col-md-6">
              <ul className="pl-1 pl-md-5" style={{ whiteSpace: "pre-wrap" }}>
                <li>
                  Única consultora especializada en el{"\n"}
                  <strong>estudio de mercado automotor</strong>
                </li>
                <li>
                  Más de 25 años de <strong>Trayectoria validada</strong> por
                  nuestros clientes
                </li>
                <li>Equipo técnico especializado</li>
                <li>
                  Método <strong>INFOAUTO®</strong>
                </li>
                <li>Sistema Único en Latam</li>
                <li>
                  Resultados <strong>imparciales</strong> y objetivos
                </li>
                <li>Software de Investigación propio</li>
                <li>
                  Acceso a la información en <strong>tiempo real</strong>
                </li>
                <li className="mt-3 link font-weight-bold">
                  <a className={"color_black"} href={"/nosotros"}>
                    Quiénes somos{" "}
                    <FontAwesomeIcon className={"ml-2"} icon={faChevronRight} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 col-md-4 parallax_bar" />
        </div>
      </Parallax>
    </section>
  );
};
