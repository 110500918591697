import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { IoEye, IoEyeOff } from "react-icons/io5";

const PasswordInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="password_input w-100 mb-3">
      <input
        className={"custom_input default-shadow p-2 mb-0 w-100"}
        type={showPassword ? "text" : "password"}
        {...props}
      />
      <div className="password_show d-flex h-100 align-items-center pr-2">
        <span className="link" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <IoEyeOff size="15px" /> : <IoEye size="15px" />}
        </span>
      </div>
    </div>
  );
};

export default PasswordInput;
