import React from "react";

import img from "../../../static/img/users.png";

export default () => {
  return (
    <div id={"invitation_section"} className={"background_gray pt-5"}>
      <div className="container p-5 d-flex flex-column align-items-center justify-content-center">
        <h2 className="text-center font-weight-bold">
          Si sos apasionado como nosotros,
          <br /> te invitamos a ser parte
        </h2>
        <h5 className={"text-center mt-3 py-1 px-2"}>
          Disfrutá de las últimas novedades del mundo automotor
        </h5>
        <h5 className={"text-center mt-1 py-1 px-2"}>
          Compartí tus búsquedas con quien vos quieras
        </h5>
        <h5 className={"text-center mt-1 py-1 px-2"}>
          Armá tu selección de Autos Favoritos
        </h5>
        <h5 className={"text-center mt-1 py-1 px-2"}>
          y cuando te decidas accedé a nuestra Guía Útil
        </h5>
      </div>
      <img src={img} alt="users" className={"w-100"} />
    </div>
  );
};
