import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: '0'
});



function ValueLabelComponent(props) {
    const { children, open, value } = props;



    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={`${value > 3000 ? formatter.format(value) : value}`}>
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};



const PrettoSlider = withStyles({
    root: {
        color: '#111111',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
        boxShadow: '0px 10px 10px rgba(0, 0, 0, .1), 0px 20px 20px rgba(0, 0, 0, .1)'
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 11px)',
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    track: {
        height: 8,
        borderRadius: 4,
        boxShadow: '0px 10px 10px rgba(0, 0, 0, .1), 0px 20px 20px rgba(0, 0, 0, .1)'
    },
    rail: {
        height: 8,
        borderRadius: 4,
        boxShadow: '0px 10px 10px rgba(0, 0, 0, .1), 0px 20px 20px rgba(0, 0, 0, .1)'
    },

})(Slider);




export function RangeSlider({range, step, title, minLabel, maxLabel, onChange, value, price}) {
    const handleChange = (event, newValue) => {
      onChange(newValue);
    };

    return (

        <div className='mb-5 col-xl-6 px-5'>
            <p className="font-weight-bold mb-3">{title}</p>
            <p className='mb-3'>{minLabel}: {price ? `$${new Intl.NumberFormat('es').format(value[0])}` : value[0]}</p>
            <p className='mb-3'>{maxLabel}:  {price ? `$${new Intl.NumberFormat('es').format(value[1])}` : value[1]}</p>
            <div className='p-2'>
                <PrettoSlider
                    valueLabelDisplay="auto"
                    defaultValue={range}
                    min={range[0]} max={range[1]}
                    step={step}
                    onChange={handleChange} />
            </div>

        </div>
    );
}
