import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { InfoCreditsAlertModal, InfoCreditsModal } from "../organisms";
import { ApiHelper } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";
import ReactTooltip from "react-tooltip";

export const InfoProPriceRow = ({
  icon,
  model,
  years,
  handlePin,
  feature,
  last,
  features = [],
}) => {
  const [ hovered, setHovered ] = useState(false);
  const [ prices, setPrices ] = useState([]);
  const [ version, setVersion ] = useState({ ...model, prices: [] });
  const [ showPrices, setShowPrices ] = useState(false);
  const [ listPrice, setListPrice ] = useState()
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const vehicleType = useSelector((state) => state.search.vehicle);
  const [ showInfoCreditsAlertModal, setShowInfoCreditsAlertModal ] =
    useState(false);
  const [showInfoCreditsModal, setShowInfoCreditsModal] = useState(false);
  const firstCell = useRef()


  useEffect(() => {

    if (user?.codiasConsultados.some((v) => v.codia === parseInt(model.codia))) {
      getPrices();
    }

    if (model.list_price > 0) {
      setListPrice(model.list_price);
    }

  }, [ model ]);




  const getPrices = async () => {

    try {

      const data = {
        vehicleType,
      };

      const res = await ApiHelper.getPrices(model.codia, data);
      let newPrices;

      newPrices = [ { year: "0km", price: model.list_price }, ...res.usedPrices ];

      if (!user?.codiasConsultados.some((v) => v.codia === model.codia)) {
        dispatch(
          actions.user.fillUserData({
            ...user,
            codiasConsultados: [
              ...user.codiasConsultados,
              {
                codia: model.codia,
                vehicleType,
                dateConsulted: new Date().toISOString(),
              },
            ],
            availableInfocredits: res.userCredits,
          })
        );
      } else {
        dispatch(
          actions.user.fillUserData({
            ...user,
            availableInfocredits: res.userCredits,
          })
        );
      }
      localStorage.user = JSON.stringify({
        ...user,
        availableInfocredits: res.userCredits,
      });


      setVersion({ ...version, prices: newPrices });
      setPrices(newPrices);
      setShowPrices(true);
    } catch (error) {
      if (error.status === 401) {
        if (localStorage.hidePriceAlert) {
          localStorage.clear();
          localStorage.hidePriceAlert = true;
        } else {
          localStorage.clear();
        }
        // window.location.href = window.location.origin;
        window.location.replace('/?showloginmodal=true&showsession=true');
      }
    }

  };

  const handleClick = async () => {
    if (user?.availableInfocredits === 0) {
      setShowInfoCreditsModal(true);

      return;
    } 

    if(!showPrices) {
      if(localStorage.hidePriceAlert){
        getPrices();
      }
      else {
        setShowInfoCreditsAlertModal(true)
      }
    }
  }

  return (
    <tr
      className={`${hovered && "hovered"} ${showPrices && "viewed"} ${last && "pinned_row"
        }`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <td
        ref={firstCell}
        className={`text-center infogrid-row link`}
        onClick={() => handlePin(version)}
      >
        <FontAwesomeIcon icon={icon} />
      </td>
      <td
        className={`pl-2 py-2 pr-5`}
      >
        {user.muestraCodia ? (
          <small className="">
            <b>{model.codia} </b>
          </small>
        ) : (
          ""
        )}
        <small>{model.description}</small>
      </td>
      {feature === 'prices' && <td
        onClick={handleClick}
        className={`see_price link text-center`}
      >
        <small className={"text-uppercase"}>
          <strong>{showPrices ? "Visto" : "Ver"}</strong>{" "}
          <FontAwesomeIcon
            className={"infopro_svg"}
            icon={hovered && !showPrices ? faSearch : faChevronRight}
          />
        </small>
      </td>}
      <InfoCreditsModal
          show={showInfoCreditsModal}
          hide={() => setShowInfoCreditsModal(false)}
      />
      <InfoCreditsAlertModal
        show={showInfoCreditsAlertModal}
        hide={() => setShowInfoCreditsAlertModal(false)}
        getPrices={getPrices}
      />
      {feature === "prices" &&
        years.map((year, index) => {
          const existingPrice = model.years.includes(year);

          const price = prices.find((p) => p.year === year);

          if (year === "0km" && listPrice) {
            return (
              <td
                key={year}
                className='text-center'
              >
                <span>{listPrice}</span>
              </td>
            )
          }
          return (
            <td
              key={year}
              className={`price_cell ${existingPrice && "filled_cell"} text-center`}
            >
              <span>{existingPrice ? (price ? price.price : "-") : "-"}</span>
            </td>
          );
        })}
      {feature !== "prices" &&
        features[ feature ]?.map((f, index) => {
          if (model) {
            let value = model.features.find((ef) => ef.id === f.id);

            if (f.type === "boolean" && value) {
              value.value =
                value.value === false
                  ? "NO"
                  : value.value === true
                    ? "SI"
                    : value.value;
            }

            return (
              <td key={index} className={"text-center"}>
                {value?.type === "choice" && <ReactTooltip effect={"solid"} />}
                <span data-tip={value?.value_description}>
                  {value ? value.value : "-"}
                </span>
              </td>
            );
          } else {
            return <td key={index} />;
          }
        })}
    </tr>
  );
};
