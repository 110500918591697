import React, { useState, useEffect } from "react";
import { PriceSelectPill } from "components/atoms/PriceSelectPill";
import { SquareButton } from "components/atoms";
import { useDispatch, useSelector } from "react-redux";
import actions from "store/actions";
import { ApiHelper } from "helpers";

export default ({ onSubscribe, subscriptions }) => {
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState("monthly");
  const [selectedType, setSelectedType] = useState("Auto Básico");

  useEffect(() => {
    if (subscriptions.length > 0) {
      setSelectedSubscription(
        subscriptions.find(
          (s) => s.name === "Auto Básico" && s.type === "monthly"
        )
      );
    }
  }, [subscriptions]);

  useEffect(() => {
    if (subscriptions) {
      setSelectedSubscription(
        subscriptions.find(
          (s) => s.name === selectedType && s.type === selectedPeriod
        )
      );
    }
  }, [selectedPeriod, selectedType]);

  const vehicleType = selectedType === "Auto Básico" ? "car" : "moto";
 
  return (
    <div className={"plan_card_container"}>
      {selectedSubscription && (
        <div className={"plan_card default_shadow background_gray"}>
          <div className="row default_shadow">
            <div
              onClick={() => setSelectedType("Auto Básico")}
              className={`link bg-white p-2 col-6 ${
                selectedType === "Auto Básico" && "bg_black text-white"
              }`}
            >
              <h3 className={"mb-0 font-weight-bold text-center"}>Auto</h3>
            </div>
            <div
              onClick={() => setSelectedType("Moto Básico")}
              className={`link bg-white p-2 col-6 ${
                selectedType === "Moto Básico" && "bg_black text-white"
              }\``}
            >
              <h3 className={"mb-0 font-weight-bold text-center"}>Moto</h3>
            </div>
          </div>
          <div className="body p-4 d-flex flex-column align-items-center">
            <div>
              <p className={"text-center"}>
                Suscripción Standard para que puedas consultar precios ágilmente
              </p>
              <div className={"border-bottom w-100"}>
                <div className={"d-flex w-100 justify-content-center"}>
                  <h3 className={"font-weight-bold"}>$</h3>
                  <h1 className={"font-weight-bold display-4"}>
                    {selectedSubscription.price}
                  </h1>
                </div>
                <div className="d-flex justify-content-center">
                  <p className="font-weight-bold color_blue">PRECIO</p>
                  <PriceSelectPill
                    selectedPeriod={selectedPeriod}
                    setSelectedPeriod={setSelectedPeriod}
                  />
                </div>
              </div>

              <div
                className={
                  "d-flex border-bottom w-100 py-4 flex-column align-items-center justify-content-center"
                }
              >
                <p className={"font-weight-bold"}>Incluye</p>
                <p className="mb-1">Consulta 0km Ilimitado</p>
                <p className="mb-1">Ficha Técnica Visual</p>
                <p className="mb-1 text-center">
                  Hasta{" "}
                  <strong>{selectedSubscription.infocreditsPerMonth}</strong>{" "}
                  consultas únicas de usados
                </p>
              </div>
              <div
                className={
                  "d-flex w-100 py-4 flex-column align-items-center justify-content-center"
                }
              >
                <p className={"font-weight-bold"}>Características</p>
                <p className="mb-1">Ficha Técnica Ilimitada y Visual</p>
                <p className="mb-1">Descripción Marca / Modelo / Versión</p>
                <p className="mb-1">Comparador hasta 4 Unidades.</p>
              </div>
            </div>

            <div className="w-75">
              <SquareButton
                onClick={() => onSubscribe(selectedSubscription)}
                text={"Contratar"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
