import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";


export const RegisterModalHeader = ({onClick}) => {
    return(
        <div className={'mb-5 d-flex justify-content-start align-items-center w-100'}>
            <span onClick={onClick} className={'link'}><FontAwesomeIcon icon={faChevronLeft} /> Volver</span>
        </div>
    )
};
