import React from 'react'
import img from '../../../static/img/busquedasIA.png'
import { SectionSeparator } from '../../atoms'

export default () => {
	return (
		<div>
			<div className={'p-5'}>
				<div className='container pt-5 mb-5'>
					<h2 className='text-center font-weight-bold'>
						Encontrar un vehículo ya no es un problema
					</h2>
					<p className={'my-3 text-center'}>
						<strong>El 73% de las personas no tienen decidido que vehículo quiere.</strong> <br />
						Por eso desarrollamos 3 tipos de buscadores ágiles y sencillos que te permitirán
						encontrarlo entre todos los modelos y versiones del mercado.
					</p>
					<img alt={'busquedas'} src={img} className={'w-100 mt-3'} />
				</div>
			</div>
			<SectionSeparator right={true} />
		</div>
	)
}
