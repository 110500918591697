import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { SquareButton } from "../atoms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export const CompletedProfileModal = ({ show, hide, onClick }) => {
  const user = useSelector((state) => state.user.user);
  const [hideMessage, setHideMessage] = useState(false);

  return (
    <Modal show={show} onHide={hide} size={"sm"} className={"default_modal"}>
      <Modal.Body className={"p-0 background_gray"}>
        <div className={"text-right pr-3 pt-3 link"}>
          <FontAwesomeIcon
            onClick={hide}
            icon={faTimes}
          />
        </div>
        <div className="row px-3 justify-content-center" align="center">
          <div className="col-12 gray_background px-5">
            <h6 className="font-weight-bold text-center">
              ¡Gracias por completar tu perfil!
            </h6>
            <br />
            <h6 className="font-weight-bold text-center">
              Te regalamos 20 InfoCréditos.
            </h6>
          </div>
          <div className="col-6 p-3" align="center">
            <SquareButton
              onClick={onClick}
              className={"bg_green"}
              text={"Continuar"}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
