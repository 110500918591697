import React, { useState } from "react";
import { DollarWidget } from "../molecules";
import { VehicleTypeSelector, InfoProLogo } from "../atoms";
import { ReportModal } from "../organisms";

import logo from "../../static/img/INFO_GRID_BLUE_LOGO.png";

export const InfoProHeader = () => {
  const [showModal, setShowModal] = useState(false);
  const showReport = () => {
    localStorage.reportType = "Precio";
    setShowModal(true);
  };
  return (
    <div id={"infopro_header"} className="row pb-3">
      <div className="col-12 col-lg-2 text-center d-flex align-items-center justify-content-center">
        <img src={logo} className={"img-logo mt-1"} alt="" />
      </div>
      <div className="col-12 col-lg-2 mt-4 mt-mb-0">
        <VehicleTypeSelector />
      </div>
      <div className="col-12 col-lg-5 mt-4 mt-mb-0">
        <DollarWidget />
      </div>
      <div className="col-12 col-lg-3 d-flex align-items-center justify-content-center ">
        <small className="text-center text-md-right  mt-1">
          ¿Tenés algo que informarnos?{" "}
          <strong onClick={() => setShowModal(true)} className={"link"}>
            Reportar
          </strong>
        </small>
      </div>
      {/* <ReportModal show={showModal} hide={showReport} /> */}
      <ReportModal show={showModal} hide={() => setShowModal(false)} />
    </div>
  );
};
