import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { SquareButton } from "../atoms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import firebase from "firebase";
import actions from "../../store/actions";

export const ChangePassModal = ({ show, hide, onClick, message }) => {
  const user = useSelector((state) => state.user.user);
  const [hideMessage, setHideMessage] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const firebaseUser = firebase.auth().currentUser;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const changePassword = async () => {
    setError(false);
    if (!loading) {
      setLoading(true);
      if (newPassword === newPasswordConfirm) {
        try {
          await firebase
            .auth()
            .signInWithEmailAndPassword(user.email, password);
          try {
            await firebaseUser.updatePassword(newPassword);
          } catch (error) {
            switch (error.message) {
              case "There is no user record corresponding to this identifier. The user may have been deleted.":
                setError(
                  "La dirección de correo ingresada no corresponde a un usuario registrado"
                );
                break;
              default:
                setError(error.message);
            }
          } finally {
            setLoading(false);
            setSuccess("La contraseña ha sido cambiada");
          }
        } catch (error) {
          setError("La contraseña actual es incorrecta");
        }

      } else {
        setError("Las contraseñas deben coincidir");
      }
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={hide} size={"sm"} className={"default_modal"}>
      <Modal.Body className={"p-0 background_gray"}>
        <p className={"text-right pr-3 pt-3"}>
          <FontAwesomeIcon
            onClick={hide}
            icon={faTimes}
          />
        </p>
        <div className="row px-4">
          <div className="col-12 gray_background px-5">
            <h6 className="font-weight-bold text-center mb-4">{message}</h6>
          </div>
          <div className="col-12 mb-4">
            <label>Contraseña</label>
            <input
              name={"currentPassword"}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className={"custom_input default-shadow p-2 w-100"}
              placeholder={"Contraseña actual"}
              type={"password"}
            />
          </div>
          <div className="col-12 mb-4">
            <label>Nueva contraseña</label>
            <input
              name={"newPassword"}
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              className={"custom_input default-shadow p-2 w-100"}
              placeholder={"Nueva contraseña"}
              type={"password"}
            />
          </div>
          <div className="col-12 mb-4">
            <label>Repita la contraseña</label>
            <input
              name={"newPasswordConfirm"}
              onChange={(e) => setNewPasswordConfirm(e.target.value)}
              value={newPasswordConfirm}
              className={"custom_input default-shadow p-2 w-100"}
              placeholder={"Nueva contraseña"}
              type={"password"}
            />
          </div>
          {error ? (
            <div className="col-12">
              <p className={"text-center color_red mt-2"}>{error}</p>
            </div>
          ) : null}
          {success ? (
            <div className="col-12">
              <p className={"text-center text-success mt-2"}>{success}</p>
            </div>
          ) : null}
          {!success && (
            <>
              <div className="col-6 p-3">
                <SquareButton onClick={hide} text={"Cancelar"} />
              </div>
              <div className="col-6 p-3">
                <SquareButton
                  loading={loading}
                  onClick={changePassword}
                  className={"bg_green"}
                  text={"Guardar"}

                />
              </div>
            </>
          )}
          {success && (
            <div className="col-12 p-3">
              <SquareButton onClick={hide} text={"Cerrar"} />
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
