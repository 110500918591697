import React, {useState, useEffect} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import {useSelector} from "react-redux";
import {SquareButton} from "../atoms";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {ContactModal} from "./ContactModal";

export const AlertModal = ({show, hide, onClick, message}) => {
    const user = useSelector(state => state.user.user);
    const [hideMessage, setHideMessage] = useState(false);

    return (
        <Modal show={show} onHide={hide} size={'sm'} className={'default_modal'}>
            <Modal.Body className={'p-0 background_gray'}>
                <div className={'text-right  pt-3 pr-3 link'}>
                    <FontAwesomeIcon onClick={hide}  icon={faTimes} />
                </div>
                <div className="row px-3">
                    <div className="col-12 gray_background px-5">
                        <h6 className="font-weight-bold text-center">{message}</h6>
                    </div>
                    <div className="col-6 p-3">
                        <SquareButton onClick={hide} text={'Cancelar'} />
                    </div>
                    <div className="col-6 p-3">
                        <SquareButton onClick={onClick} className={'bg_green'} text={'Continuar'} />
                    </div>

                </div>
            </Modal.Body>

        </Modal>

    );
};
