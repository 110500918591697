import {faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ApiHelper} from '../../helpers'
import {ProfileImage, Select, SquareButton} from '../atoms'
import actions from '../../store/actions'
import DeleteAccountModal from 'components/organisms/DeleteAccountModal'

import {
    AlertModal,
    ChangePassModal,
    CompletedProfileModal,
} from '../organisms'

const barrios = [
    {
        name: 'Chacarita',
        comuna: 15,
        perimetro: 7724.8529545700003,
        area: 3115707.1062699999,
    },
    {
        name: 'Paternal',
        comuna: 15,
        perimetro: 7087.5132953299999,
        area: 2229829.03363,
    },
    {
        name: 'Villa Crespo',
        comuna: 15,
        perimetro: 8131.8570753200001,
        area: 3615977.5563400001,
    },
    {
        name: 'Almagro',
        comuna: 5,
        perimetro: 8537.9013684900001,
        area: 4050752.2452400001,
    },
    {
        name: 'Caballito',
        comuna: 6,
        perimetro: 10990.964471499999,
        area: 6851028.9135699989,
    },
    {
        name: 'Villa Santa Rita',
        comuna: 11,
        perimetro: 5757.7523347099996,
        area: 2153690.81329,
    },
    {
        name: 'Monte Castro',
        comuna: 10,
        perimetro: 7361.0364175499999,
        area: 2627481.3250799999,
    },
    {
        name: 'Villa Real',
        comuna: 10,
        perimetro: 5070.9656524399998,
        area: 1334146.08283,
    },
    {
        name: 'Flores',
        comuna: 7,
        perimetro: 15772.439509600001,
        area: 8590784.1312899999,
    },
    {
        name: 'Floresta',
        comuna: 10,
        perimetro: 7519.6540918000001,
        area: 2320640.73551,
    },
    {
        name: 'Constitución',
        comuna: 1,
        perimetro: 6326.8413747300001,
        area: 2101994.9981300002,
    },
    {
        name: 'San Cristobal',
        comuna: 3,
        perimetro: 5987.2264787900003,
        area: 2043711.1216599999,
    },
    {
        name: 'Boedo',
        comuna: 5,
        perimetro: 6602.5102659300001,
        area: 2609850.8792099999,
    },
    {
        name: 'Velez Sarsfield',
        comuna: 10,
        perimetro: 7045.5783317200003,
        area: 2400518.32602,
    },
    {
        name: 'Villa Luro',
        comuna: 10,
        perimetro: 8652.9300312699997,
        area: 2566936.8725100001,
    },
    {
        name: 'Parque Patricios',
        comuna: 4,
        perimetro: 7878.4349931099996,
        area: 3743439.99645,
    },
    {
        name: 'Mataderos',
        comuna: 9,
        perimetro: 11124.6357921,
        area: 7398178.8403500002,
    },
    {
        name: 'Villa Lugano',
        comuna: 8,
        perimetro: 15057.468389400001,
        area: 9296142.3614000008,
    },
    {
        name: 'San Telmo',
        comuna: 1,
        perimetro: 5178.0328392800002,
        area: 1232270.1587400001,
    },
    {
        name: 'Saavedra',
        comuna: 12,
        perimetro: 11132.139345600001,
        area: 5538676.8328200001,
    },
    {
        name: 'Coghlan',
        comuna: 12,
        perimetro: 4627.5631313800004,
        area: 1279951.37256,
    },
    {
        name: 'Villa Urquiza',
        comuna: 12,
        perimetro: 10021.459664800001,
        area: 5449058.9228499997,
    },
    {
        name: 'Colegiales',
        comuna: 13,
        perimetro: 6374.6190450699996,
        area: 2289774.1042800001,
    },
    {
        name: 'Balvanera',
        comuna: 3,
        perimetro: 8375.8218108100009,
        area: 4342280.2680400005,
    },
    {
        name: 'Villa Gral. Mitre',
        comuna: 11,
        perimetro: 6290.4405605600004,
        area: 2162275.1153500001,
    },
    {
        name: 'Parque Chas',
        comuna: 15,
        perimetro: 4531.2533962799998,
        area: 1385411.5137400001,
    },
    {
        name: 'Agronomía',
        comuna: 15,
        perimetro: 6556.1677718700003,
        area: 2122169.3421200002,
    },
    {
        name: 'Villa Ortuzar',
        comuna: 15,
        perimetro: 5684.9952111399998,
        area: 1853802.0520299999,
    },
    {
        name: 'Barracas',
        comuna: 4,
        perimetro: 12789.7917712,
        area: 7953453.4314299999,
    },
    {
        name: 'Parque Avellaneda',
        comuna: 9,
        perimetro: 10165.614027699999,
        area: 4732708.2093099998,
    },
    {
        name: 'Parque Chacabuco',
        comuna: 7,
        perimetro: 8406.9143207500001,
        area: 3832116.6992199998,
    },
    {
        name: 'Nueva Pompeya',
        comuna: 4,
        perimetro: 11033.5103019,
        area: 4951510.8742699996,
    },
    {
        name: 'Palermo',
        comuna: 14,
        perimetro: 22126.531858400002,
        area: 15845871.288000001,
    },
    {
        name: 'Villa Riachuelo',
        comuna: 8,
        perimetro: 10596.8267392,
        area: 4232595.9247899996,
    },
    {
        name: 'Villa Soldatti',
        comuna: 8,
        perimetro: 12441.695877300001,
        area: 8688163.5582800005,
    },
    {
        name: 'Villa Pueyrredon',
        comuna: 12,
        perimetro: 7522.3603824299998,
        area: 3303239.50936,
    },
    {
        name: 'Villa Devoto',
        comuna: 11,
        perimetro: 11562.0068,
        area: 6404480.8537100013,
    },
    {
        name: 'Liniers',
        comuna: 9,
        perimetro: 9256.9273212900007,
        area: 4374418.7367700003,
    },
    {
        name: 'Versalles',
        comuna: 10,
        perimetro: 5650.4662864299999,
        area: 1406833.5958,
    },
    {
        name: 'Puerto Madero',
        comuna: 1,
        perimetro: 17725.720821200001,
        area: 5040970.8851399999,
    },
    {
        name: 'Monserrat',
        comuna: 1,
        perimetro: 6739.2223110200002,
        area: 2198621.9408900002,
    },
    {
        name: 'San Nicolas',
        comuna: 1,
        perimetro: 6548.0847539599999,
        area: 2289007.5825899998,
    },
    {
        name: 'Belgrano',
        comuna: 13,
        perimetro: 20609.7753969,
        area: 7999239.81384,
    },
    {
        name: 'Recoleta',
        comuna: 2,
        perimetro: 21452.838647799999,
        area: 6317264.5605600001,
    },
    {
        name: 'Retiro',
        comuna: 1,
        perimetro: 18837.988687000001,
        area: 4512710.9070300004,
    },
    {
        name: 'Nuñez',
        comuna: 13,
        perimetro: 10785.2610814,
        area: 4424199.2650899999,
    },
    {
        name: 'Boca',
        comuna: 4,
        perimetro: 20060.003939400001,
        area: 5036240.4544099998,
    },
]

export const ProfileInfoFields = ({ currentUser }) => {
	const [user, setUser] = useState(currentUser)
	const [img, setImg] = useState(null)
	const email = useSelector((state) => state.user?.user?.email)
	const [userImg, setUserImg] = useState(null)
	const [showAlert, setShowAlert] = useState(false)
	const [showChangePass, setShowChangePass] = useState(false)
	const [provinces, setProvinces] = useState([])
	const [localities, setLocalities] = useState([])
	const [showCompletedProfileModal, setShowCompletedProfileModal] =
		useState(false)
	const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)

    const [genderOptions] = useState([
        {
            label: 'Femenino',
            id: 1,
            value: 'FEMENINO',
        },
        {
            label: 'Masculino',
            id: 2,
            value: 'MASCULINO',
        },
        {
            label: 'Otro',
            id: 3,
            value: 'OTRO',
        },
    ])
    const dispatch = useDispatch()

    useEffect(() => {
        getUserImage()
        getProvinces()
    }, [])

    useEffect(() => {
        const {province} = user
        const getLocalities = async () => {
            let localities = []
            if (province.includes('Ciudad')) {
                localities = barrios
            } else {
                let {data} = await ApiHelper.getLocalities(province)
                localities = data
            }

            localities.map((localidad) => {
                localidad.label = localidad.name
                localidad.value = localidad.name
            })

            localities.sort(function (a, b) {
                let textA = a.label.toUpperCase()
                let textB = b.label.toUpperCase()
                return textA < textB ? -1 : textA > textB ? 1 : 0
            })
            setLocalities(localities)
        }
        if (province) {
            getLocalities()
        }
    }, [user.province])

    const getProvinces = async () => {
        try {
            let res = await ApiHelper.getProvinces()
            res.data.map((provincia) => {
                provincia.value = provincia.id
                provincia.label = provincia.name
            })
            res.data.sort(function (a, b) {
                let textA = a.label.toUpperCase()
                let textB = b.label.toUpperCase()
                return textA < textB ? -1 : textA > textB ? 1 : 0
            })
            setProvinces(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getUserImage = async () => {
        try {
            const res = await ApiHelper.getImage('admin', '/user/profile')

            if (res.data) {
                const reader = new window.FileReader()
                reader.readAsDataURL(res.data)
                reader.onload = () => {
                    const imageDataUrl = reader.result
                    setUserImg(imageDataUrl)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const saveUser = async () => {
        user.birthDate = new Date(user.birthDate).toISOString();
        let res = await ApiHelper.patch("admin", "/user", user);
        dispatch(actions.user.setUser(res.data));
        localStorage.user = JSON.stringify(res.data);
        if (res.data.completedProfile) {
            setShowCompletedProfileModal(true);
            setShowAlert(false);
        } else {
            window.location.reload();
        }
    };

    const handleImageChange = (e) => {
        let reader = new FileReader()
        let file = e.target.files[0]

        reader.onloadend = () => {
            setImg({
                file: file,
                imagePreviewUrl: reader.result,
            })
        }
        reader.readAsDataURL(file)
    }

    const saveImage = async () => {
        if (img) {
            const data = new FormData()
            data.append('file', img.file)
            await ApiHelper.post('admin', '/user/profile', data)
            window.location.reload()
        }
    }

    const handleAlpha = (e) => {
        let value = e.target.value

        value = value.replace(/[^A-Za-z-\s]*$/, '')
        return value
    }


    return (
        user && (
            <div className={'container mb-5'}>
                <div className={'row mt-4'}>
                    <div className='col-12 col-md-6'>
                        <h4 className={'mb-4'}>Foto de Perfil</h4>
                        <div className='row'>
                            <div className='col-2'>
                                <ProfileImage userImg={userImg} img={img}/>
                            </div>
                            <div className={'col-10 '}>
                                <div className={'pl-4 pl-lg-1  d-flex flex-column '}>
                                    <h5 className={'mb-1'}>Subí tu foto</h5>
                                    <p>Tu foto debería tener por lo menos 300px por 300px</p>
                                    <div>
                                        {img ? (
                                            <div className={'w-25 d-flex'}>
                                                <SquareButton
                                                    onClick={() => setImg(null)}
                                                    text={'Eliminar'}
                                                    size={'sm'}
                                                />
                                                <SquareButton
                                                    className={'ml-2'}
                                                    onClick={saveImage}
                                                    text={'Guardar'}
                                                    size={'sm'}
                                                />
                                            </div>
                                        ) : (
                                            <div className='custom-file'>
                                                <input
                                                    onChange={handleImageChange}
                                                    type='file'
                                                    className='custom-file-input'
                                                    id='customFileLang'
                                                    lang='es'
                                                />
                                                <label
                                                    className='custom-file-label'
                                                    htmlFor='customFileLang'
                                                >
                                                    Seleccionar Archivo
                                                </label>
                                            </div>
                                        )}

                                        {/*<SquareButton size={'sm'} text={'Subir Foto'} />*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div align='right' className='col-12 col-md-6'>
                        <div className='col-md-6'>
                            <SquareButton text={"Eliminar mi cuenta"} onClick={() => setShowAlert(true)}/>
                        </div>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-12 d-flex align-items-center mb-5'>
                        <h4 className={'mb-0'}>Información Básica</h4>
                        {!user.completedProfile && (
                            <small className={'ml-3 color_light_blue'}>
                                Completá todos tus datos y ganá 20 InfoCréditos
                            </small>
                        )}
                    </div>
                    <div className='col-12 col-md-6 '>
						<span className='font-weight-bold mb-2'>
							Correo electronico{' '}
                            <small className={'color_light_blue font-weight-normal'}>
								<FontAwesomeIcon icon={faCheckCircle}/> Tu correo ha sido
								verificado
							</small>
						</span>
                        <p className={'default_shadow p-2 w-100 my-3'}>{email}</p>
                    </div>
                    <div className='col-12 col-md-4 d-flex align-items-end pb-3 mb-5 mb-md-0'>
                        <SquareButton
                            text={'Cambiar contraseña'}
                            onClick={() => setShowChangePass(true)}
                        />
                    </div>
                    <div className='col-12 col-md-2 '></div>

                    <div className='col-6 col-md-6 '>
                        <span className='font-weight-bold mb-2'>Nombre</span>
                        <input
                            name={'name'}
                            onChange={(e) => setUser({...user, firstName: handleAlpha(e)})}
                            className={'custom_input default-shadow p-2 w-100 my-3'}
                            value={user.firstName}
                            placeholder={'Nombre'}
                            type={'text'}
                            maxLength='23'
                        />
                    </div>
                    <div className='col-6 col-md-6 '>
                        <span className='font-weight-bold mb-2'>Apellido</span>
                        <input
                            name={'last_name'}
                            onChange={(e) => setUser({...user, lastName: handleAlpha(e)})}
                            className={'custom_input default-shadow p-2 w-100 my-3'}
                            value={user.lastName}
                            placeholder={'Apellido'}
                            type={'text'}
                            maxLength='23'
                        />
                    </div>
                    <div className='col-6 col-md-3 '>
                        <span className='font-weight-bold mb-2'>Género</span>
                        <Select
                            className={'my-3'}
                            onSelect={(e) => setUser({...user, gender: e.value})}
                            selectedOption={genderOptions.find(
                                (o) => o.value === user.gender
                            )}
                            placeholder={'Genero'}
                            options={genderOptions}
                        />
                    </div>
                    <div className='col-6 col-md-3 '>
						<span className='font-weight-bold mb-2 text-nowrap'>
							Fecha de Nacimiento
						</span>
                        <input
                            name={'birthday'}
                            onChange={(e) =>
                                setUser({...user, birthDate: e.currentTarget.value})
                            }
                            className={'custom_input default-shadow p-2 w-100 my-3'}
                            value={user.birthDate ? user.birthDate : ''}
                            type={'date'}
                        />
                    </div>
                    {/*<div className="col-12 col-md-6 ">*/}
                    {/*    <span className="font-weight-bold mb-2">Prefijo País</span>*/}
                    {/*    <input name={'phonePrefix'} type={'number'} onChange={e => setUser({...user, prefix: e.currentTarget.value})} value={user.prefix} className={'custom_input default-shadow p-2 w-100 my-3'} placeholder={'Prefijo'} type={'tel'}/>*/}
                    {/*</div>*/}
                    <div className='col-4 col-md-2 '>
                        <span className='font-weight-bold mb-2'>Cód. Área</span>
                        <input
                            name={'prefix'}
                            type={'phone'}
                            onChange={(e) =>
                                setUser({...user, prefix: e.currentTarget.value})
                            }
                            value={user.prefix}
                            className={'custom_input default-shadow p-2 w-100 my-3'}
                            placeholder={'Cód. Área'}
                            maxLength={6}
                            type={'tel'}
                        />
                    </div>
                    <div className='col-8 col-md-4 '>
                        <span className='font-weight-bold mb-2'>Teléfono</span>
                        <input
                            name={'phone'}
                            type={'phone'}
                            onChange={(e) =>
                                setUser({...user, phoneNumber: e.currentTarget.value})
                            }
                            value={user.phoneNumber}
                            className={'custom_input default-shadow p-2 w-100 my-3'}
                            placeholder={'Telefono'}
                            type={'tel'}
                        />
                    </div>

                    <div className="col-6 col-md-4 ">
                        <span className="font-weight-bold mb-2">Provincia</span>
                        <Select
                            className={"my-3"}
                            onSelect={(e) =>
                                setUser({...user, province: e.name, locality: ""})
                            }
                            selectedOption={provinces.find((o) => o.label === user.province)}
                            placeholder={"Provincia"}
                            options={provinces}
                        />
                        {/*<Select className={'my-3'} placeholder={'Provincia'} options={genderOptions}/>*/}
                        {/*<input name={'province'} onChange={e => setUser({ ...user, province: e.currentTarget.value })} value={user.province} className={'custom_input default-shadow p-2 w-100 my-3'} placeholder={'Provincia'} type={'text'} />*/}
                    </div>
                    <div className="col-6 col-md-6 ">
                        <span className="font-weight-bold mb-2">Localidad</span>
                        <Select
                            className={"my-3"}
                            onSelect={(e) => setUser({...user, locality: e.label})}
                            selectedOption={
                                localities.find((o) => o.label === user.locality) || ""
                            }
                            placeholder={"Localidad"}
                            options={localities}
                        />
                    </div>
                    <div className="col-4 col-md-2 ">
            <span className="font-weight-bold mb-2 text-nowrap ">
              Código Postal
            </span>
                        <input
                            name={"zipCode"}
                            type={"number"}
                            onChange={(e) =>
                                setUser({...user, zipCode: e.currentTarget.value})
                            }
                            value={user.zipCode ? user.zipCode : ""}
                            className={"custom_input default-shadow p-2 w-100 my-3"}
                            placeholder={"Código Postal"}
                            type={"number"}
                        />
                    </div>
                    <div className="col-8 col-md-6 ">
                        <span className="font-weight-bold mb-2">Dirección</span>
                        <input
                            name={"street"}
                            value={user.direction ? user.direction : ""}
                            onChange={(e) =>
                                setUser({...user, direction: e.currentTarget.value})
                            }
                            className={"custom_input default-shadow p-2 w-100 my-3"}
                            placeholder={"Direccion"}
                            type={"text"}
                        />
                    </div>
                    <div className="col-6 col-md-2 ">
            <span className="font-weight-bold mb-2 text-nowrap">
              Piso (opcional)
            </span>
                        <input
                            name={"streetNumber"}
                            onChange={(e) =>
                                setUser({...user, floor: e.currentTarget.value})
                            }
                            value={user.floor ? user.floor : ""}
                            className={"custom_input default-shadow p-2 w-100 my-3"}
                            placeholder={"Piso"}
                            type={"text"}
                        />
                    </div>
                    <div className="col-6 col-md-2 ">
            <span className="font-weight-bold mb-2 text-nowrap">
              Unidad (opcional)
            </span>
            <input
              name={"streetNumber"}
              onChange={(e) =>
                setUser({ ...user, unit: e.currentTarget.value })
              }
              value={user.unit ? user.unit : ""}
              className={"custom_input default-shadow p-2 w-100 my-3"}
              placeholder={"Unidad"}
              type={"text"}
            />
          </div>
          <div className="col-12 col-md-2 d-flex align-items-end pb-3">
            <SquareButton text={"Guardar"} onClick={() => setShowAlert(true)} />
          </div>
						<div className="col-12 col-md-2 d-flex align-items-end pb-3 mt-3">
						<button style={{ color: "red", background: "none", border: "none" }}
						 	onClick={() => setShowDeleteAccountModal(true)}
						 >Eliminar Cuenta</button>
					</div>
          <AlertModal
            message={"¿Seguro desea guardar los cambios?"}
            onClick={saveUser}
            show={showAlert}
            hide={() => setShowAlert(false)}
          />
          <CompletedProfileModal
            onClick={() => window.location.reload()}
            show={showCompletedProfileModal}
            hide={() => setShowCompletedProfileModal(false)}
          />
          <ChangePassModal
            message={"Cambiar contraseña"}
            onClick={saveUser}
            show={showChangePass}
            hide={() => setShowChangePass(false)}
          />
					<DeleteAccountModal show={showDeleteAccountModal} hide={() => setShowDeleteAccountModal(false)} />
        </div>
      </div>
    )
  );
};
