import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { SquareButton } from "../atoms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const UnsuscribeModal = ({ show, hide, unsuscribe }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleUnsuscribe = async () => {
    try {
      setIsLoading(true);
      await unsuscribe();
      setTimeout(() => {
        hide();
      }, 1000);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={hide} size={"sm"} className={"default_modal"}>
      <Modal.Body className={"p-0 background_gray"}>
        <div className={"text-right pt-3 pr-3 link"}>
          <FontAwesomeIcon
            onClick={hide}
            icon={faTimes}
          />
        </div>
        <div className="row">
          <div className="col-12 gray_background p-5">
            <p className="font-weight-bold text-center">
              ¿Seguro deseas desuscribirte?
            </p>
            <p className="text-center">
              Se enviará una solicitud para que demos de baja tu suscripción
            </p>
            <div className="d-flex justify-content-center">
              <SquareButton
                onClick={handleUnsuscribe}
                loading={isLoading}
                text="Confirmar"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UnsuscribeModal;
