import React, { useState } from "react";
import { UserType } from "../atoms";
import particularesImg from "../../static/img/particulares.jpg";
import profesionalesImg from "../../static/img/profesionales.jpg";
import { useDispatch, useSelector } from "react-redux";

export const RegisterSelectType = ({ setUserType, userType }) => {
  const user = useSelector((state) => state.user.user);

  const [subscriptions] = useState([
    {
      id: 1,
      name: "Particulares",
      value: "individual",
      image: particularesImg,
      bullets: [
        "Descubrí toda la información de tu próximo auto o moto.",
        "Guía de precios con fotos de Usados y 0Km",
        "Compara, decidí y gana tiempo",
        "Fichas Técnicas confiables y fáciles de interpretar",
      ],
    },
    {
      id: 2,
      name: "Profesionales",
      value: "professional",
      image: profesionalesImg,
      bullets: [
        'Guía de precios en formato grilla de Usados y 0Km similar a la revista',
				'Precios Históricos y Usado del Año',
				'Historial de Consultas',
				'Comparador hasta 4 vehículos.'
      ],
    },
  ]);

  return (
    <div className="col-12 d-flex flex-column align-items-center">
      <h5 className="font-weight-bold mb-4">¡Hola! Queremos conocerte mejor</h5>
      <p>
        1. Seleccioná tu{" "}
        <span className={"font-weight-bold"}>tipo de usuario</span>
      </p>
      <div className={"container px-md-5 pt-2 pb-4"}>
        {user.activeSubscription?.name != "InfoPro Grupal" ? (
          <div className="row">
            {subscriptions.map((subscription) => {
              return (
                <UserType
                  key={subscription.id}
                  subscription={subscription}
                  userType={userType}
                  setUserType={setUserType}
                />
              );
            })}
          </div>
        ) : (
          <div className="row">
            <UserType
              key={2}
              subscription={subscriptions[1]}
              userType={"professional"}
              setUserType={setUserType}
            />
          </div>
        )}

        <p className="text-center mt-3 mb-0">
          {!userType ? "Selecciona un tipo de usuario" : `Seleccionaste `}
          {userType && (
            <strong>
              usuario {userType === "individual" ? "particular" : "profesional"}
            </strong>
          )}
        </p>
      </div>
    </div>
  );
};
