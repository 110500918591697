import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple } from "@fortawesome/free-brands-svg-icons";

export const AppleButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="btn apple_button btn-circle">
      <FontAwesomeIcon icon={faApple} color={"white"} />
    </button>
  );
};
