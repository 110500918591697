import React from 'react';

export const Calificaciones = () => {
    return (
        <div className="page_default_margin">
            <div className={'container mb-5'}>
                <h2 className="font-weight-bolder">Calificaciones</h2>
                <h4 className="mt-5"><b>Acuerdo entre InfoAuto y el Usuario</b></h4>
                <hr />
                <p>El presente contrato describe los términos y condiciones aplicables al uso de los servicios ofrecidos por InfoAuto S.A.
                dentro del sitio www.infoauto.com (en adelante “Sitio”) a usted (en adelante “Usuario”). Estos términos y condiciones
            tienen el carácter de obligatorios y vinculantes.</p>
                <p>El uso por parte del Usuario de este sitio constituye un acuerdo a todos los términos y condiciones, por tal motivo sugerimos al usuario leerlos atentamente.
                Utilizando El Sitio el Usuario acuerda estos términos y condiciones de uso como así también cualquier otro término, pauta o regla aplicable a todo o una parte de este Sitio.
            Si el Usuario no está de acuerdo con estos términos y condiciones de uso, debe abstenerte de utilizar el Sitio y consecuentemente los servicios ofrecidos.</p>


            </div>
        </div>
    )
}