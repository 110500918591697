import React, { useState } from "react";
import { UserType } from "../atoms";
import { RegisterModalHeader } from "../atoms";
import { Form } from "react-bootstrap";

export const RegisterVehicles = ({
  userType,
  handleBack,
  vehicles,
  setVehicles,
}) => {
  const [regularVehicles] = useState(["Autos", "Motos"]);
  const [professionalVehicles] = useState([
    "Autos",
    "Pick Ups",
    "Utilitarios",
    "Camiones",
    "Motos",
    `SUV's`,
  ]);

  const handleCheck = (vehicle) => {
    setVehicles(
      vehicles.includes(vehicle)
        ? vehicles.filter((i) => i !== vehicle)
        : [...vehicles, vehicle]
    );
  };

  const handleCheckAll = () => {
    if (userType === "individual") {
      setVehicles(
        vehicles.length === regularVehicles.length ? [] : regularVehicles
      );
    } else if (userType === "professional") {
      setVehicles(
        vehicles.length === professionalVehicles.length
          ? []
          : professionalVehicles
      );
    }
  };

  return (
    <div className="col-12 d-flex flex-column align-items-center">
      <RegisterModalHeader onClick={handleBack} />
      <h5 className="font-weight-bold mb-4">
        {userType === "individual"
          ? "Que tipo de vehículo va mas con vos?"
          : "Personalización de servicio para Usuario Profesional"}
      </h5>
      <p>
        3.{" "}
        {userType === "individual"
          ? `Contanos cuáles son tus `
          : `Seleccioná tus `}
        <strong>vehículos de interés</strong>
      </p>
      <div className={"container p-5"}>
        <div className="row d-flex justify-content-md-center">
          {userType === "individual" &&
            regularVehicles.map((vehicle, index) => {
              return (
                <Form.Group
                  key={index}
                  className={"mr-4"}
                  controlId={`${vehicle}_check`}
                >
                  <Form.Check
                    type="checkbox"
                    checked={vehicles.includes(vehicle)}
                    label={vehicle}
                    onChange={() => handleCheck(vehicle)}
                  />
                </Form.Group>
              );
            })}
          {userType === "professional" &&
            professionalVehicles.map((vehicle, index) => {
              return (
                <Form.Group
                  key={index}
                  className={"mr-4"}
                  controlId={`${vehicle}_check`}
                >
                  <Form.Check
                    type="checkbox"
                    checked={vehicles.includes(vehicle)}
                    label={vehicle}
                    onChange={() => handleCheck(vehicle)}
                  />
                </Form.Group>
              );
            })}
          <Form.Group className={"mr-4"} controlId={`all_check`}>
            <Form.Check
              type="checkbox"
              className={"font-weight-bold"}
              checked={
                userType === "individual"
                  ? regularVehicles.length === vehicles.length
                  : professionalVehicles.length === vehicles.length
              }
              label={userType === "individual" ? "Ambos" : "Todos"}
              onChange={handleCheckAll}
            />
          </Form.Group>
        </div>
      </div>
      <p>
        Seleccióna al menos{" "}
        <strong>
          un vehículo de interés para poder continuar con el registro.
        </strong>
      </p>
    </div>
  );
};
