import React, { useEffect, useState, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../../store/actions";
import { ApiHelper } from "../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faMotorcycle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { DebounceSearch } from "../../../hooks/search";

const SearchBar = ({ history, dimensions }) => {
  const [results, setResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const [height, setHeight] = useState(0);
  const [isHome, setIsHome] = useState(false);
  const [resultsQuantity, setResultsQuantity] = useState(0);
  const [margin, setMargin] = useState(0);
  const [searching, setSearching] = useState(false);
  const { inputText, setInputText, search, close } = DebounceSearch();
  const list = useRef();
  const wrapperRef = useRef();
  const vehicle = useSelector((state) => state.search.vehicle);
  const [searchText, setSearchText] = useState();

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setResults([]);
    }
  };

  const setVehicle = (type) => {
    dispatch(actions.search.setVehicleType(type));
  };

  useEffect(() => {
    if (window.location.pathname === "/") {
      setIsHome(true);
    } else {
      setIsHome(false);
      setHeight(38);
    }
    history.listen((location) => {
      setResults([]);
      if (location.pathname === "/") {
        setHeight(0);
        setIsHome(true);
      } else {
        setIsHome(false);
        setHeight(38);
      }
    });
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    setMargin(list.current?.clientHeight);
  }, [search.result]);

  useEffect(() => {
    setResults([]);
    setSearchValue([]);
    setSearchText(vehicle === "cars" ? "del auto" : "de la moto");
  }, [vehicle]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("keydown", (key) => {
      if (key.code === "Escape") {
        setResults([]);
      }
    });
    return () => window.removeEventListener("keyup", handleScroll);
  }, [isHome]);

  const handleScroll = () => {
    if (window.pageYOffset > window.outerHeight * 0.5 && isHome) {
      setHeight(38);
    } else if (window.pageYOffset < 100 && isHome) {
      if (window.location.pathname === "/") {
        setHeight(0);
        setResults([]);
      }
    }
  };

  const showAll = () => {
    if (inputText && search.result) {
      history.push(
        `/resultado-busqueda?s=${inputText}&q=${search.result.pagination.totalResults}`
      );
      close();
    }
  };

  const navigate = (result) => {
    dispatch(
      actions.search.setPrimarySeach([
        { ...result.brand, type: "brand" },
        { ...result.model, type: "model" },
        {
          id: result.versionCode,
          name: result.versionDescription,
          type: "version",
        },
      ])
    );
    history.push(`/ficha/${vehicle}/${result.versionCode}`);
  };

  return (
    <div
      ref={wrapperRef}
      id="home_searchbar"
      className="input-group d-none d-md-flex justify-content-center"
      style={{ width: dimensions.width }}
    >
      <div
        className="d-flex w-100 mini_searchbar  "
        style={{ height: height, overflow: "hidden" }}
      >
        <FontAwesomeIcon
          icon={faSearch}
          className={"search_icon"}
          style={{ height: height === 38 ? 14 : 0, overflow: "hidden" }}
        />

        <FontAwesomeIcon
          icon={faCar}
          color={vehicle === "cars" ? "black" : "#ccc"}
          className={"type_car_icon"}
          style={{
            height: height === 38 ? 14 : 0,
            overflow: "hidden",
            zIndex: 99,
          }}
          onClick={() => setVehicle("cars")}
        />

        <FontAwesomeIcon
          icon={faMotorcycle}
          className={"type_moto_icon"}
          color={vehicle === "motorcycles" ? "black" : "#ccc"}
          style={{
            height: height === 38 ? 14 : 0,
            overflow: "hidden",
            zIndex: 99,
          }}
          onClick={() => setVehicle("motorcycles")}
        />
        <input
          value={inputText}
          type="text"
          className="pl-5 form-control"
          style={{ flexGrow: 1 }}
          placeholder={`${
            dimensions.width > 300
              ? `Encontrá el precio ${searchText} que buscás, por marca, modelo, año y palabras clave...`
              : ""
          }`}
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
          onChange={(e) => setInputText(e.target.value)}
        />
      </div>
      {search.result && (
        <div id="home_search_results">
          <ul ref={list}>
            {search.result.response?.map((result, index) => (
              <li key={index} className="link text-center">
                <a href={`/ficha/${vehicle}/${result.versionCode}`}>
                  <span className="font-weight-bolder">
                    {result.brand.name}
                  </span>{" "}
                  - {result.versionDescription}
                </a>
              </li>
            ))}
          </ul>
          {search.result.response?.length > 0 && (
            <p
              style={{ marginTop: margin }}
              className={"text-center background_gray mini_searchbar_results"}
            >
              Mostrando {search.result.response?.length} de{" "}
              {search.result.pagination.totalResults}{" "}
              <span
                onClick={showAll}
                className={"ml-2 link color_blue font-weight-bold"}
              >
                Ver todos
              </span>
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(SearchBar);
