import React, { useState, useEffect } from "react";
import { Loader } from "../components/atoms";
import { ReleaseNewApp, RegularNewApp } from "../components/organisms";
import { useParams, withRouter } from "react-router-dom";
import { ApiHelper } from "../helpers";
import { useDispatch } from "react-redux";
import actions from "../store/actions";

export const AppNew = withRouter(() => {
	const [article, setArticle] = useState({});
	const { type, id } = useParams();
	const dispatch = useDispatch();
	const [categories, setCategories] = useState([
		{
			id: 1,
			name: "Últimas Noticias",
			articles: [],
			link: "",
			selected: false,
		},
		{
			id: 2,
			name: "Salones",
			articles: [],
			link: "salones",
			selected: type === "salones",
		},
		{
			id: 3,
			name: "Lanzamientos",
			articles: [],
			link: "lanzamientos",
			selected: type === "lanzamientos",
		},
		{
			id: 4,
			name: "Notas",
			articles: [],
			link: "notas",
			selected: type === "notas",
		},
		{
			id: 5,
			name: "Historial",
			articles: [],
			link: "historial",
			selected: type === "historial",
		},
		{
			id: 6,
			name: "Prototipos",
			articles: [],
			link: "prototipos",
			selected: type === "prototipos",
		},
		{
			id: 7,
			name: "Ecología",
			articles: [],
			link: "ecologia",
			selected: type === "ecologia",
		},
	]);

	useEffect(() => {
		getArticle();
	}, []);

	const getArticle = async () => {
		dispatch(actions.app.setLoading(true));
		const res = await ApiHelper.get("admin", `/news/${id}`);

		setArticle(res.data);
		dispatch(actions.app.setLoading(false));
	};

	//   const selectCategory = (link) => {
	//     window.location.href = `/noticias/${link}`;
	//   };

	

	return (
		<Loader>
			<div className="p-0">
				<div id="news_container" className={"container-fluid p-0"}>
					{article.type === "COMMON_NEWS" && (
						<RegularNewApp article={article} />
					)}
					{article.type === "LAUNCH_NEWS" && (
						<ReleaseNewApp article={article} />
					)}
				</div>
			</div>
		</Loader>
	);
});
