import React from 'react';
import {Logo, SectionSeparator} from "../atoms";

export default () => {
    return(
        <section className={'background_gray numbers_section pt-md-5'}>
            <div className="container py-5 mb-md-5">
                <h2 className="text-center font-weight-normal"><Logo/> en números</h2>
                <div className="row mt-5 bg-white py-4 default_shadow mb-5">
                    <div className="col sm-12">
                        <h1 className="text-center font-weight-bold">
                            <span className={'number_plus font-weight-normal'}>+</span> 15k
                        </h1>
                        <p className={'text-center mb-0'}>Vehículos Valorizados</p>
                        <div className="divider"></div>
                    </div>
                    <div className="col sm-12">
                        <h1 className="text-center font-weight-bold">
                            <span className={'number_plus font-weight-normal'}>+</span> 100k
                        </h1>
                        <p className={'text-center mb-0'}>Lectores mensuales de nuestra revista</p>
                        <div className="divider"></div>
                    </div>
                    <div className="col sm-12">
                        <h1 className="text-center font-weight-bold">
                            <span className={'number_plus font-weight-normal'}>+</span> 130
                        </h1>
                        <p className={'text-center mb-0'}>Corporaciones utilizan nuestro servicio</p>
                        <div className="divider"></div>
                    </div>
                    <div className="col sm-12">
                        <h1 className="text-center font-weight-bold">
                            <span className={'number_plus font-weight-normal'}>+</span> 10k
                        </h1>
                        <p className={'text-center mb-0'}>Profesionales del rubro suscriptos</p>
                        <div className="divider"></div>
                    </div>
                    <div className="col mt-3 mt-md-0 sm-12">
                        <h1 className="text-center font-weight-bold">
                            <span className={'number_plus font-weight-normal'}>+</span> 50k
                        </h1>
                        <p className={'text-center mb-0'}>Usuarios particulares suscriptos</p>
                    </div>

                </div>
            </div>
            <SectionSeparator />
        </section>
    )
}
