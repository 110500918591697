import React from 'react';

export default ({ number, title }) => {
    return (
        <div className="col-12 col-md-4 d-flex align-items-center justify-content-center flex-column mb-4 mb-md-0">
            <h1 className="font-weight-bold text-center">{number}</h1>
            <small className={'text-center font-weight-bold'}>{title}</small>
        </div>
    )
}
