import React from 'react'
import { useSelector } from 'react-redux'
import proLogo from '../../../static/img/footer-pro.jpg'
import logo from '../../../static/img/footer.jpg'

const BottomImage = () => {
	const user = useSelector((state) => state.user.user)
	return (
		<div className={'footer_image'}>
			<img
				id='footer_image_div'
				className='w-100'
				src={user && user.type === 'professional' ? proLogo : logo}
				alt='logo'
				style={{ minHeight: 400, objectFit: 'cover' }}
			/>
			<div className='footer_image_text d-flex flex-column justify-content-center align-items-end pr-5'>
				<h1>
					<span className={'font_logo mb_4 footer-text-logo'}>
						Info<strong>Auto</strong>
					</span>
				</h1>
				<h1 className={'font-weight-bold text-right color_black display-4 footer-text'}>
					La palabra autorizada en <br /> precios de autos y motos
				</h1>
			</div>
		</div>
	)
}

export default BottomImage
