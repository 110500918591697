import React from "react";
import { SectionSeparator } from "../atoms";
import respuestaImg from "../../static/img/respuesta.jpg";
import gestionImg from "../../static/img/gestion.jpg";
export default () => {
  return (
    <section className={"response_section"}>
      <div className="p-5 background_gray">
        <div className="container py-5 mb-5">
          <h2 className={"text-center font-weight-bold"}>
            Una respuesta para cada necesidad
          </h2>
          `
          <p className={"text-center"}>
            Desarrollamos Soluciones a Medida, con el objetivo de responder a
            las necesidades particulares de cada cliente. Analizamos y ofrecemos
            diferentes servicios alternativos, según los requerimientos y
            necesidades específicas.
          </p>
          <div className="row mt-5">
            <div className="col-12 col-md-6 img_col mb-3 mb-md-0">
              <img
                src={respuestaImg}
                alt="empresas"
                className={"w-100 default_shadow"}
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-center">
              <ul>
                <li>
                  <span>Consulta Masiva de datos en línea</span>
                </li>
                <li>
                  <span>Valuaciones de más de 15.000 Vehículos</span>
                </li>
                <li>
                  <span>Fichas Técnicas</span>
                </li>
                <li>
                  <span>Homologación de Bases de Datos</span>
                </li>
                <li>
                  <span>Asesoramiento técnico</span>
                </li>
                <li>
                  <span>Centro de Atención a Empresas IA.</span>
                </li>
                <li>
                  <span>Investigación de mercado</span>
                </li>
                <li>
                  <span>Publicidad</span>
                </li>
                <li>
                  <span>Prensa, Comunicación y Lanzamientos</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <SectionSeparator />
      <div className={"p-5"}>
        <div className="container py-5 img_right mb-5">
          <h2 className={"text-center font-weight-bold"}>
            Hacemos posible la automatización de diferentes procesos de gestión.
          </h2>
          `
          <p className={"text-center"}>
            Contamos con una interfaz para empresas que permite el acceso punto
            a punto de nuestra base de datos
          </p>
          <div className="row mt-5">
            <div className="col-12 col-md-6 d-flex align-items-center justify-content-end">
              <ul>
                <li>
                  <span>Cotización de seguros</span>
                </li>
                <li>
                  <span>Liquidación de siniestros</span>
                </li>
                <li>
                  <span>Emisión de pólizas</span>
                </li>
                <li>
                  <span>Valor de referencia de mercado para prendas</span>
                </li>
                <li>
                  <span>Seguimiento de valor de vehículos de flota</span>
                </li>
                <li>
                  <span>Motorización de Cotizadores on-line</span>
                </li>
                <li>
                  <span>Multi-cotizadores</span>
                </li>
                <li>
                  <span>Comparadores</span>
                </li>
                <li>
                  <span>Cálculo de tasas de riesgos</span>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 img_col">
              <img
                src={gestionImg}
                alt="empresas"
                className={"w-100 default_shadow"}
              />
            </div>
          </div>
        </div>
      </div>
      <SectionSeparator right={true} />
    </section>
  );
};
