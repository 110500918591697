import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, } from "react-redux";
import { SquareButton } from "../atoms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ContactModal } from "./ContactModal"
import { ApiHelper, AppHelper } from "../../helpers";
import { OneShotPaymentForm } from "components/organisms";
import { BUY_INFOCREDITS } from "static/permissions/sections";
import InfoCreditsBillingModal from "./InfoCreditsBillingModal";

export const InfoCreditsModal = ({ show, hide }) => {
  const user = useSelector((state) => state.user.user);
  const [showModal, setShowModal] = useState(false);
  const [showInfoBillingModal, setShowInfoBillingModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [options, setOptions] = useState()


  useEffect(() => {
    setSelectedProduct(null);
    getPrices()
  }, [show]);

  const getPrices = async () => {
    try {
      const { data } = await ApiHelper.get('admin', '/product')
      setOptions(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleClick = async (product) => {
    const { data } = await ApiHelper.get("admin", "/user/billingInformation");
    if (!data.complete) {
      setShowInfoBillingModal(true)
    }
    setSelectedProduct(product);
  }

  const handleViewSubscriptions = () => {
    window.location.href = "/perfil/plan";
  }

  const renderContent = () => {
    if (AppHelper.hasPermission(user, BUY_INFOCREDITS)) {
      if (showInfoBillingModal) {
        return (<InfoCreditsBillingModal show={show} setShowInfoBillingModal={setShowInfoBillingModal} />)
        // return (<div>asd</div>)
      } else if (selectedProduct) {
        return (<OneShotPaymentForm selectedProduct={selectedProduct} />)
      } else {
        return (
          <div className="row">
            <div className="col-12 gray_background p-5">
              {user?.availableInfocredits === 0 ? (
                <h2 className="font-weight-normal text-center">
                  <strong>Oops,</strong> te quedaste sin Info
                  <strong>Créditos</strong>
                </h2>
              ) : (
                <h2 className="font-weight-normal text-center">
                  <strong>Agregá</strong> Info
                  <strong>Créditos</strong>
                </h2>
              )}
              <p className="font-weight-normal text-center">
                Elegí la opción de recarga que mejor se adecúe a tus
                necesidades.
              </p>
              <div className="row mt-5">
                {options && options?.map((option, index) => (
                  <div
                    key={index}
                    className={"col-6 col-md p-2 background_gray"}
                  >
                    <div
                      className={
                        "default_shadow d-flex flex-column align-items-center p-3"
                      }
                    >
                      <h2
                        className={
                          "display-4 mb-0 text-center font-weight-bold"
                        }
                      >
                        {option.infoCredits}
                      </h2>
                      <p className={"text-center mb-1 text-uppercase"}>
                        <strong>Info</strong>Créditos
                      </p>
                      <small
                        className={
                          "text-center text-uppercase border_bottom_dark pb-2 mb-3"
                        }
                      >
                        Consultas usados
                      </small>
                      <div className={"d-flex"}>
                        <h6 className={"font-weight-bold"}>$</h6>
                        <h1 className={"text-center font-weight-bold"}>
                          {option.price}
                        </h1>
                      </div>
                      <SquareButton
                        text={"Comprar"}
                        onClick={() => handleClick(option)}
                      />
                    </div>
                    {option.off && (
                      <p
                        className={
                          " text-center mt-2 text_green font-weight-bold"
                        }
                      >
                        {option.off}% OFF
                      </p>
                    )}
                  </div>
                ))}
              </div>
              <p className={"text-center"}>
                ¿No encontrás lo que buscás?{" "}
                <strong onClick={() => setShowModal(true)} className={"link"}>
                  Contactanos
                </strong>
              </p>
              <ContactModal show={showModal} hide={() => setShowModal(false)} />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="row">
          <div className="col-12 gray_background p-5">
            {user?.availableInfocredits === 0 ? (
              <h2 className="font-weight-normal text-center">
                <strong>Oops,</strong> te quedaste sin Info
                <strong>Créditos</strong>
              </h2>
            ) : (
              <h2 className="font-weight-normal text-center">
                <strong>Agregá</strong> Info
                <strong>Créditos</strong>
              </h2>
            )}
            <p className="font-weight-normal text-center">
              ¡Cambiá tu suscripción para poder seguir consultando precios!
            </p>
            <div className="d-flex justify-content-center">
              <SquareButton
                onClick={handleViewSubscriptions}
                fullWidth={false}
                text="Ver suscripciones"
              />
            </div>
            <div className="row mt-5"></div>
            <p className={"text-center"}>
              ¿No encontrás lo que buscás?{" "}
              <strong onClick={() => setShowModal(true)} className={"link"}>
                Contactanos
              </strong>
            </p>
            <ContactModal show={showModal} hide={() => setShowModal(false)} />
          </div>
        </div>
      );
    }
  };

  return (
    <Modal show={show} onHide={hide} size={"lg"} className={"default_modal"}>
      <Modal.Body className={"p-0 background_gray"}>
        <div className={"text-right pt-3 pr-3 link"}>
          <FontAwesomeIcon
            onClick={hide}
            icon={faTimes}
          />
        </div>
        {renderContent()}
      </Modal.Body>
    </Modal>
  );
};
