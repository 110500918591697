import React, { useState, useEffect } from "react";
import { SquareButton, PlanSectionCard } from "../atoms";
import { ApiHelper, AppHelper } from "../../helpers";
import actions from "../../store/actions";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { ContactModal, UnsuscribeModal } from "../organisms";
import {
  faArrowAltCircleRight,
  faArrowAltCircleDown,
} from "@fortawesome/free-regular-svg-icons";
import {
  IndvidualFreeSubscription,
  IndividualCarMotoSubscription,
  IndividualPremiumSubscription,
  ProFreeSubscription,
  ProIndividualSubscription,
  ProGroupSubscription,
  IndividualSubscriptionDetails,
  ProSubscriptionDetails,
} from "../subscriptions";
import { BUY_INFOCREDITS, UNSUSCRIBE } from "static/permissions/sections";

export const ProfilePlan = ({ currentUser }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [user, setUser] = useState(currentUser);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [periodString, setPeriodString] = useState(null);
  const [expirationString, setExpirationString] = useState(null);
  const { activeSubscription } = user;
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [unsuscribed, setUnsuscribed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showUnsuscribe, setShowUnsuscribe] = useState(false);
  const isFleetUser = activeSubscription.subscriptionType.type === "fleet";


  useEffect(() => {
    getSubscriptions();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);

    });
  }, []);
  

  useEffect(() => {
    if (user.activeSubscription) {
      const { creationDateTime, expirationDateTime } = user.activeSubscription;
      const from = new Date(creationDateTime);
      const fromDate = `${from.getDate()}/${from.getMonth() + 1
        }/${from.getFullYear()}`;
      setFromDate(fromDate);
      const to = new Date(expirationDateTime);
      const toDate = `${to.getDate()}/${to.getMonth() + 1}/${to.getFullYear()}`;
      const monthFormatter = new Intl.DateTimeFormat("es", { month: "long" });
      const periodString = `${from.getDate()} de ${monthFormatter.format(
        from
      )} de ${from.getFullYear()} a ${to.getDate()} de ${monthFormatter.format(
        to
      )} de ${to.getFullYear()}`;
      setPeriodString(periodString);
      setExpirationString(
        `${to.getDate()} de ${monthFormatter.format(to)} de ${to.getFullYear()}`
      );
      setFromDate(fromDate);
      setToDate(toDate);
    }
  }, [user.activeSubscription]);

  const getSubscriptions = async () => {
    const res = await ApiHelper.get("admin", "/subscription/template");
    const subscriptions = res.data.filter(
      (subscription) =>
        subscription.professional === (user.type === "professional")
    );

    setSubscriptions(subscriptions);
  };

  const onSubscribe = async (subscription) => {
    dispatch(actions.app.setSelectedPlan(subscription));
    const { data } = await ApiHelper.get("admin", "/user/billingInformation")
    if (!data.complete) {
      dispatch(actions.app.setShowBillingModal(true));
    } else {
      dispatch(actions.app.setShowPaymentModal(true));
    }
  };

  const scroll = () => {
    const plans = document.getElementById("plans");
    plans.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  const handleUnsuscribe = async (e) => {
    const data = {
      email: "info@infoauto.com.ar",
      subject: `Solicitud de desuscripcion`,
      message: `
            Usuario: ${user.firstName} ${user.lastName}
            Id de usuario: ${user.id}
            Email: ${user.email}
            Telefono: ${user.phoneNumber}
          `,
    };

    try {
      await ApiHelper.post("admin", "/mail", data);

      setUnsuscribed(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="background_gray gray_divider h-100">
        <div className={"container py-5"}>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className={"d-flex align-items-center"}>
                <h5 className={"mb-0"}>
                  Tu plan actual:{" "}
                  <span className={"font-weight-bold"}>
                    {activeSubscription ? activeSubscription.name : "Gratuito"}
                  </span>
                </h5>
                {!isFleetUser && (
                  <div className="ml-3">
                    <SquareButton text={"Cambiar plan"} onClick={scroll} />
                  </div>
                )}
              </div>
              {activeSubscription && (
                <div className={"mt-3 mt-md-0"}>
                  <p>
                    <span className={"font-weight-bold"}>Estado: </span>
                    <span className={"color_light_blue"}>
                      Activo <FontAwesomeIcon icon={faCheckCircle} />
                    </span>{" "}
                  </p>
                  <p className={"mb-1"}>
                    <span className={"font-weight-bold"}>Incluye:</span>{" "}
                    {activeSubscription.infocreditsPerMonth} consultas mensuales
                  </p>
                  <p className={"mb-1"}>
                    <span className={"font-weight-bold"}>Inicio:</span>{" "}
                    {fromDate}{" "}
                  </p>
                  <p className={"mb-3"}>
                    <span className={"font-weight-bold"}>Finalización:</span>{" "}
                    {toDate}{" "}
                  </p>
                  {user.activeSubscription?.subscriptionType.type !=
                    "fleet" && (
                      <>
                        <p className={"mb-1"}>Próxima factura:</p>
                        <p className={"mb-1"}>
                          <span className={"font-weight-bold"}>Período:</span>{" "}
                          {periodString}
                        </p>

                        <p className="mb-3">
                          <strong>Vencimiento: </strong> {expirationString}
                        </p>
                      </>
                    )}

                  {AppHelper.hasPermission(user, UNSUSCRIBE) && (
                    <div>
                      {unsuscribed ? (
                        <strong className="color_green">
                          Desuscripción solicitada
                        </strong>
                      ) : (
                        <p
                          className="color_red">
                          <strong
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => setShowUnsuscribe(true)}
                          >
                            Quiero desuscribirme
                          </strong>
                        </p>
                      )}
                      <UnsuscribeModal
                        show={showUnsuscribe}
                        hide={() => setShowUnsuscribe(false)}
                        unsuscribe={handleUnsuscribe}
                      />
                    </div>
                  )
                  }
                </div>
              )}
            </div>
            <div className="col-12 col-md-6 mt-2">
              <div className="row">
                <PlanSectionCard
                  title={"Plan Actual"}
                  subscription={user.activeSubscription}
                  type={"plan"}
                />
                {AppHelper.hasPermission(user, BUY_INFOCREDITS) && (
                  <PlanSectionCard title={"Gestioná"} type={"gestion"} user={user} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${isFleetUser ? "d-none" : "d-block"}`}>
        <div className={`container mt-4 `} id={"plans"}>
          {activeSubscription &&
            subscriptions.length > 0 &&
            user.type === "individual" &&
            user.activeSubscription.name != "Premium" && (
              <div className="row p-4 d-flex justify-content-center">
                <div className="col-12 mb-5">
                  <h2 className={"font-weight-bold text-center"}>
                    ¿Querés mejorar tu plan?
                  </h2>
                </div>
                {user.activeSubscription.name === "Free" && (
                  <div className="col-12 col-md mt-2 mt-md-1">
                    <IndvidualFreeSubscription />
                  </div>
                )}

                {user.activeSubscription.name === "Free" && windowWidth > 749 && (
                  <div>
                    <FontAwesomeIcon
                      icon={faArrowAltCircleRight}
                      size="8x"
                      className="plan-arrow"
                    />
                  </div>
                )}
                {user.activeSubscription.name === "Free" && windowWidth < 750 && (
                  <div className="mt-4">
                    <FontAwesomeIcon
                      icon={faArrowAltCircleDown}
                      size="4x"
                      className="plan-arrow"
                    />
                  </div>
                )}

                <div className="col-12 col-md mt-4 mt-md-1">
                  <IndividualCarMotoSubscription
                    onSubscribe={onSubscribe}
                    canSuscribe={true}
                    subscriptions={subscriptions}
                    activeSubscription={activeSubscription}
                  />
                </div>
                {user.activeSubscription.name !== "Free" && windowWidth > 749 && (
                  <div>
                    <FontAwesomeIcon
                      icon={faArrowAltCircleRight}
                      size="8x"
                      className="plan-arrow"
                    />
                  </div>
                )}
                {user.activeSubscription.name !== "Free" && windowWidth < 750 && (
                  <div className="mt-4">
                    <FontAwesomeIcon
                      icon={faArrowAltCircleDown}
                      size="4x"
                      className="plan-arrow"
                    />
                  </div>
                )}
                <div className="col-12 col-md mt-5 mt-md-1">
                  <IndividualPremiumSubscription
                    onSubscribe={onSubscribe}
                    canSuscribe={true}
                    subscriptions={subscriptions}
                    activeSubscription={activeSubscription}
                  />
                </div>
              </div>
            )}
          <ContactModal show={showModal} hide={() => setShowModal(false)} />
          {activeSubscription &&
            subscriptions.length > 0 &&
            user.type === "professional" &&
            user.activeSubscription.name != "InfoPro Grupal" && (
              <div className="row p-4 d-flex justify-content-center">
                <div className="col-12 mb-5">
                  <h2 className={"font-weight-bold text-center"}>
                    ¿Querés mejorar tu plan?
                  </h2>
                </div>
                {user.activeSubscription.name === "Free" && (
                  <div className="col-12 col-md mt-2 mt-md-0">
                    <ProFreeSubscription />
                  </div>
                )}

                {user.activeSubscription.name === "Free" && windowWidth > 749 && (
                  <div>
                    <FontAwesomeIcon
                      icon={faArrowAltCircleRight}
                      size="8x"
                      className="plan-arrow"
                    />
                  </div>
                )}
                {user.activeSubscription.name === "Free" && windowWidth < 750 && (
                  <div className="mt-4">
                    <FontAwesomeIcon
                      icon={faArrowAltCircleDown}
                      size="4x"
                      className="plan-arrow"
                    />
                  </div>
                )}

                <div className="col-12 col-md mt-4 mt-md-1">
                  <ProIndividualSubscription
                    onSubscribe={onSubscribe}
                    canSuscribe={true}
                    subscriptions={subscriptions}
                    activeSubscription={activeSubscription}
                  />
                </div>
                {user.activeSubscription.name !== "Free" &&
                  user.activeSubscription.name !== "Premium" &&
                  windowWidth > 749 && (
                    <div>
                      <FontAwesomeIcon
                        icon={faArrowAltCircleRight}
                        size="8x"
                        className="plan-arrow"
                      />
                    </div>
                  )}
                {user.activeSubscription.name !== "Free" &&
                  user.activeSubscription.name !== "Premium" &&
                  windowWidth < 750 && (
                    <div className="mt-4">
                      <FontAwesomeIcon
                        icon={faArrowAltCircleDown}
                        size="4x"
                        className="plan-arrow"
                      />
                    </div>
                  )}
                <div className="col-12 col-md mt-4 mt-md-1">
                  <ProGroupSubscription
                    onSubscribe={onSubscribe}
                    canSuscribe={true}
                    subscriptions={subscriptions}
                    activeSubscription={activeSubscription}
                  />
                </div>

              </div>
            )}
        </div>
        <div className="d-flex justify-content-center mb-5  ">
          <div className="col-12 col-md-8" align="center">
            {user.type === "individual" ? (
              <IndividualSubscriptionDetails />
            ) : (
              <ProSubscriptionDetails />
            )}
            <p>
              ¿No encontrás lo que buscás?{" "}
              <strong className={"link"} onClick={() => setShowModal(true)}>
                Contactanos
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div >
  );
};
