import React, { useState } from "react";
import { DollarWidget } from "../molecules";
import { ContactModal } from "../organisms";
import { VehicleTypeSelector } from "../atoms";

const SearchTitle = ({ title }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="row">
      <div className="col-12 col-lg-3 d-flex align-items-center">
        <h4 className="font-weight-bold">{title}</h4>
      </div>

      <div className="col-12 col-lg-5 d-flex align-items-center">
        <DollarWidget />
      </div>

      <div className="col-12 col-lg-4 d-flex align-items-center">
        <small className="text-right">
          ¿No encontrás lo que buscás?{" "}
          <strong className={"link"} onClick={() => setShowModal(true)}>
            Contactanos
          </strong>
        </small>
      </div>
      <ContactModal show={showModal} hide={() => setShowModal(false)} />
    </div>
  );
};

export default SearchTitle;
