import React from 'react'

import { IndividualSubscriptionDetails, ProSubscriptionDetails } from 'components/subscriptions'
import IndividualFree from './subscriptions/IndividualFree'
import IndividualCarMoto from './subscriptions/IndividualCarMoto'
import IndividualPremium from './subscriptions/IndividualPremium.js'
import ProFree from './subscriptions/ProFree'
import ProCarMoto from './subscriptions/ProCarMoto'

export default ({ subscriptions = [], newUser, setUser, nextStep }) => {
	const pro = newUser.userType === 'professional'

	const onSubscribe = async (subscription) => {
		setUser((prev) => ({ ...prev, subscription }))
		nextStep()
	}

	return (
		<div id='planes'>
			<div className={`px-4 pt-3`}>
				<div className='row mt-5 d-flex justify-content-center mb-5'>
					<div className='d-flex flex-md-row flex-column justify-content-center align-items-center'>
						{pro ? (
							<>
								<ProFree />
								<ProCarMoto subscriptions={subscriptions} onSubscribe={onSubscribe} />
							</>
						) : (
							<>
								<IndividualFree />
								<IndividualCarMoto onSubscribe={onSubscribe} subscriptions={subscriptions} />
								<IndividualPremium onSubscribe={onSubscribe} subscriptions={subscriptions} />
							</>
						)}
					</div>
				</div>
				{pro ? <ProSubscriptionDetails /> : <IndividualSubscriptionDetails pro={false} />}

				{/*{!pro && <h4 className={'text-center font-weight-normal pt-5 find-mobile'}>No encontrás lo que buscás? <strong>Contactanos</strong></h4>}*/}
			</div>
		</div>
	)
}
