import React, { useEffect } from 'react'
import Select from 'react-select'

const MobileSelect = (props) => {
	const { showInput, onSelect, placeholder, selectedOption, options } = props

	useEffect(() => {
		if (options.length === 0) {
			onSelect(null)
		}
	}, [options])

	return (
		<div className={`mb-3`}>
			<Select
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary25: 'white',
						primary: 'black',
					},
				})}
				noOptionsMessage={() => 'No hay coincidencias'}
				getOptionLabel={(option) => option.name}
				getOptionValue={(option) => option.id}
				value={selectedOption ? selectedOption : null}
				options={options}
				className='shadow-lg rounded-pill'
				classNamePrefix='custom_select'
				placeholder={placeholder}
				onChange={onSelect}
			/>
		</div>
	)
}

export default MobileSelect
