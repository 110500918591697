import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faUser } from "@fortawesome/free-solid-svg-icons";
import actions from "store/actions";
import { withRouter } from "react-router-dom";
import { ApiHelper, AppHelper } from "helpers";
import { FAVORITES, SEARCH_HISTORY } from 'static/permissions/sections'
import useOnclickOutside from "react-cool-onclickoutside";

const ProfileThumbnail = ({ history }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [image, setImage] = useState(null);
  const user = useSelector((state) => state.user.user);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const ref = useOnclickOutside(() => {
    setShowMenu(false)
  })

  const logout = () => {

    if (localStorage.hidePriceAlert) {
      localStorage.clear();
      localStorage.hidePriceAlert = true;
    } else {
      localStorage.clear();
    }

    window.location.href = window.location.origin;
  };


  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);

    });
  }, []);



  const getUserImage = async () => {
    try {
      const res = await ApiHelper.getImage("admin", "/user/profile");

      dispatch(actions.user.fillUserData({ image: res.data }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div id="profile_thumbnail">
        <div
          className={"ml-3 ml-lg-0 d-lg-flex align-items-center btn nav-item"}
          onClick={() => setShowMenu(!showMenu)}
          ref={ref}
        >
          <div className={""}>
            <p className={"text-white mr-2 text-right"}>
              {user?.firstName ? `${user.firstName}` : ""}{" "}
              {windowWidth > 1183 && (
                <FontAwesomeIcon icon={faChevronDown} color={"white"} />
              )}
            </p>
            {user && (
              <p className={"text-right pr-2 credits"}>
                <small className={"color_gray"}>
                  {user?.availableInfocredits}{" "}
                  {user?.availableInfocredits === 1
                    ? "infocrédito"
                    : "infocreditos"}
                </small>
              </p>
            )}
          </div>

          {image ? (
            <img
              src={image}
              className="rounded-circle profile_thumbnail_picture ml-3 profile-mobile"
              alt="Imágen Perfil"
            />
          ) : (
            <div className="rounded-circle ml-3 profile_thumbnail d-flex align-items-center justify-content-center profile-mobile">
              <FontAwesomeIcon icon={faUser} color={"black"} size={"lg"} />
            </div>
          )}

          {showMenu && (
            <div
              id={"user_menu"}
              className={`default_shadow w-100 ${user.type === "individual" ? "individual" : "pro"
                }`}
            >
              <ul >
                <li
                  onClick={() => history.push("/perfil/mis-datos")}
                  className={"pl-2 font-weight-bold perfil"}
                >
                  Perfil
                </li>

                {
                  AppHelper.hasPermission(user, FAVORITES) && (
                    <li
                      className={"pl-2 font-weight-bold perfil"}
                      onClick={() => history.push("/perfil/favoritos")}
                    >
                      Favoritos
                    </li>
                  )}

                {
                  AppHelper.hasPermission(user, SEARCH_HISTORY) && (
                    <li
                      className={"pl-2 font-weight-bold perfil"}
                      onClick={() => history.push("/perfil/historial")}
                    >
                      Historial
                    </li>
                  )}


                <li onClick={logout} className={"pl-2 font-weight-bold perfil"}>
                  Salir
                </li>
              </ul>
            </div>
          )}
        </div>
        {windowWidth < 1183 && (
          <div className="ml-3 mb-4">
            <label
              onClick={() => history.push("/perfil/mis-datos")}
              className={"font-weight-bold mb-2 text-white"}
            >
              Perfil
            </label>

            <label
              onClick={logout}
              className={"pl-2 font-weight-bold text-white ml-4"}
            >
              Salir
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(ProfileThumbnail);


