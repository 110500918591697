import React from "react";

export const InfoProPill = ({ title, active }) => {
  if (!active) {
    active = false;
  } else if (active !== "true" && active !== "false" && active !== "NO") {
    active = true;
  } else {
    active = active == "true";
  }
  return (
    <div>
      <div className="row info_pro_pill default_shadow">
        <div
          className="col-6 d-flex align-items-center justify-content-center"
          style={{ backgroundColor: active ? "white" : "black" }}
        >
          <p style={{ color: active ? "black" : "white" }} className="mb-0 ">
            NO
          </p>
        </div>
        <div
          className="col-6 d-flex align-items-center justify-content-center"
          style={{ backgroundColor: active ? "black" : "white" }}
        >
          <p style={{ color: active ? "white" : "black" }} className="mb-0 ">
            SÍ
          </p>
        </div>
      </div>
      <p className="font-weight-bold text-uppercase text-center mt-2 mb-3">
        {title}
      </p>
    </div>
  );
};
