import React from "react";
import AdXL from "../../ads/AdXL";

const Video = () => {
  return (
    <section className="container-fluid py-5 background_gray px-0">
      <div className="container">
        <div className="row">
          <div className="col-12 embed-responsive embed-responsive-16by9">
            <iframe
              title="Home Video"
              src="https://www.youtube.com/embed/1vwCCrUURK4"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="embed-responsive-item"
            />
          </div>
        </div>
        <div className="mt-5">
          <AdXL subsection="-video" />
        </div>
      </div>
    </section>
  );
};

export default Video;
