import React from 'react';

export class Servicio extends React.Component {
    render(){
        return(
            <div>
                <p>Servicio</p>
            </div>
        )
    }
}
