import React, { useEffect, useState } from "react";
import { default as ReactLoader } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import actions from "store/actions";
import imgPrice from "../../static/img/VisualSearchPriceColumn.jpg";

export default ({
  prices,
  selectedYear,
  pricesLoading,
  pricesReadyToShow,
  getPrices,
}) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const [refreshPrice, setRefreshPrice] = useState(false);
  const user = useSelector((state) => state.user.user);
  const vehicle = useSelector((state) => state.search.vehicle);

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.toLocaleString("es", { month: "long" });
    setDate({ year, month });
  }, []);

  useEffect(() => {
    if (user && refreshPrice) {
      handleClick();
    }
  }, [user]);

  const handleClick = () => {
    if (user) {
      if (user.infoCreditsActive === false) {
        dispatch(actions.app.setShowSubscriptionDisabled(true));
        return;
      }
    }
    setRefreshPrice(false);
    if (
      user?.activeSubscription?.name.includes("Auto") &&
      vehicle === "motorcycles"
    ) {
      dispatch(actions.app.setShowWrongPlanModal(true));
      return;
    } else if (
      user?.activeSubscription?.name.includes("Moto") &&
      vehicle === "cars"
    ) {
      dispatch(actions.app.setShowWrongPlanModal(true));
      return;
    } else if (!user) {
      setRefreshPrice(true);
      dispatch(actions.app.setShowRegister(true));
    } else {
      getPrices();
    }
  };

  return (
    <div>
      {pricesLoading ? (
        <div className="d-flex justify-content-center">
          <ReactLoader type="Puff" color="#10ffdc" height={50} width={50} />
        </div>
      ) : pricesReadyToShow && prices.length === 0 ? (
        <div>
          <div>
            <h6
              style={{
                cursor: "pointer",
              }}
              className="pointer py-2"
              onClick={handleClick}
            >
              Mostrar precios >
            </h6>
          </div>
          <img src={imgPrice} className="price-col w-100" alt="mockup-prices" />
        </div>
      ) : (
        <div className={"mb-3"}>
          <p className={"font-weight-bold mb-0"}>Precios</p>
          {date && (
            <p className={"color_blue"}>
              <small>
                Valores correspondientes a{" "}
                <strong>
                  {date.month} {date.year}
                </strong>
              </small>
            </p>
          )}
          <ul className={"m-0 p-0"}>
            {prices.map((value, index) => (
              <li
                key={value.year}
                className={`btn text-left w-100 d-flex justify-content-between ${
                  selectedYear.id === value.year
                    ? "font-weight-bolder text-white bg_black"
                    : "border_bottom_dark"
                } ${value.year === "0km" && "bg_green"}`}
              >
                <span className={"font-weight-bold"}>{value.year}</span>

                <span>
                  ${new Intl.NumberFormat("es").format(value.price * 1000)}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
