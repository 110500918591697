import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const CircleGraph = ({unit, value, label, max}) => {
    return(
        <div className={'circle_graph'}>
            <div className={'default_shadow'} style={{padding: 15, borderRadius: 150}}>
                <CircularProgressbarWithChildren backgroundPadding={100} strokeWidth={3} value={value / max * 100} styles={buildStyles({strokeLinecap: "butt", pathColor: "#10ffdc"})}>
                    <h2 className={'font-weight-bold mb-0'}>{value}</h2>
                    <h6 className='font-weight-normal'>{unit}</h6>
                </CircularProgressbarWithChildren>
            </div>
            <h5 className="text-center font-weight-bold text-uppercase mt-3">{label}</h5>
        </div>
    )
};
