import React from 'react';

const Column = (props) => {
    const {grayBackground, values, selectedValue = {}, onSelect, center, sm, lg} = props;
    return (
        <div className={`advanced_search_column col-2 ${grayBackground ? 'background_column' : ''} `}>
            <h5 className={`mt-3 font-weight-bold column_title ${center && 'text-center'} ${grayBackground ? 'background_column' : 'bg-white'}`}>{props.name}</h5>
            <div className='options_container mt-2 '>
                <ul className={'pr-2'}>
                    {values.map((value, index) => <li className={`btn text-left w-100 ${center && 'text-center'} ${value === selectedValue ? 'bg_black text-white' : ''}`} onClick={() => onSelect(value)} key={index}>{value.name}</li>)}
                </ul>
            </div>
            <div className={'scroll_overlay'} />
        </div>
    )
};

export default Column;
