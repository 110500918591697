import actionTypes from "./actionTypes";

const vehicleTypesActions = {
    setVehicleTypes: vehicleTypes => {
        return {
            type: actionTypes.SET_VEHICLE_TYPES,
            vehicleTypes: vehicleTypes
        }
    }
};

export default vehicleTypesActions;
