import { AppHelper } from 'helpers'
import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FAVORITES, SEARCH_HISTORY, USEFUL_GUIDE } from 'static/permissions/sections'
import logo from '../../static/img/INFO_GRID_WHITE_LOGO.png'

export const ProfileHeader = withRouter(({ activeSection, setActiveSection, history }) => {
	const user = useSelector((state) => state.user.user)
	const showFauvoriteProfiles = ['Premium', 'InfoPro Individual', 'InfoPro Grupal']
	return (
		user && (
			<div className={`container`}>
				<div className='row'>
					<div className='col-12 col-md-8'>
						<div className='d-flex align-items-center'>
							<div>
								<h1 className={'font-weight-bold'}>
									{user.firstName} {user.lastName}
								</h1>
								<h5 className={'font-italic'}>
									Usuario{' '}
									<span className={'font-weight-bold'}>
										{user.type === 'professional' ? 'Profesional' : 'Particular'}
									</span>
								</h5>
							</div>
							{/*<div className={'ml-5'}>*/}
							{/*    <SquareButton size={'sm'} text={'Cambiar Plan'} />*/}
							{/*</div>*/}
							{/* {/* {user.type === 'professional' ? ( */}
							<a href={'/terminos'}>
								<small className={'ml-5 font-italic link color_white'}>
									Ver términos y condiciones
								</small>
							</a>

							<a href={'/preguntas-frecuentes'}>
								<small className={'ml-5 font-italic link color_white'}>
									Preguntas frecuentes
								</small>
							</a>

							{/* ) : ( */}
							{/* <a href={'/terminos'}>
									<small className={'ml-5 font-italic link color_black'}>
										Ver términos y condiciones
									</small>
								</a> */}
							{/* )} */}
						</div>
					</div>
					<div className='col-12 col-md-4 d-flex align-items-center flex-column justify-content-center'>
						{user?.type === 'professional' && <img className={'w-50'} src={logo} />}
						<h5 className='text-right mb-0 font-weight-normal'>
							<strong>InfoCréditos:</strong> {user.availableInfocredits}
						</h5>
					</div>
				</div>
				<div className={'d-flex pt-4 flex-wrap justify-content-center justify-content-md-start'}>
					<p
						onClick={() => history.push('/perfil/mis-datos')}
						className={`mr-4 mr-md-5 ${!(window.location.pathname === '/perfil/mis-datos') && 'font-weight-bold link'
							}`}
					>
						Perfil
					</p>
					<p
						onClick={() => history.push('/perfil/plan')}
						className={`mr-4 mr-md-5 ${!(window.location.pathname === '/perfil/plan') && 'font-weight-bold link'
							}`}
					>
						<u>Plan</u>
					</p>
					{user?.activeSubscription.subscriptionType.type != 'fleet' && (
						<p
							onClick={() => history.push('/perfil/facturacion')}
							className={`mr-4 mr-md-5 ${!(window.location.pathname === '/perfil/facturacion') && 'font-weight-bold link'
								}`}
						>
							<u>Facturación</u>
						</p>
					)}
					{AppHelper.hasPermission(user, FAVORITES) && (
						<>
							<p
								onClick={() => history.push('/perfil/favoritos')}
								className={`mr-4 mr-md-5 ${!(window.location.pathname === '/perfil/favoritos') && 'font-weight-bold link'
									}`}
							>
								<u>Favoritos</u>
							</p>
						</>
					)}
					{AppHelper.hasPermission(user, SEARCH_HISTORY) && (
						<p
							onClick={() => history.push('/perfil/historial')}
							className={`mr-4 mr-md-5 ${!(window.location.pathname === '/perfil/historial') && 'font-weight-bold link'
								}`}
						>
							<u>Historial</u>
						</p>
					)}

					{AppHelper.hasPermission(user, USEFUL_GUIDE) && (
						<p
							onClick={() => history.push('/perfil/guia-util')}
							className={`mr-4 mr-md-5 ${!(window.location.pathname === '/perfil/guia-util') && 'font-weight-bold link'
								}`}
						>
							<u>Guía Útil</u>
						</p>
					)}
				</div>
			</div>
		)
	)
})
