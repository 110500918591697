import React from 'react';

export class Contacto extends React.Component {
    render(){
        return(
            <div>
                <p>Contacto</p>
            </div>
        )
    }
}
