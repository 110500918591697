import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import jwtDecode from 'jwt-decode';
const initialState = {};


const checkTokenExpirationMiddleware = store => next => action => {
    if (localStorage.token !== null) {
        const token = localStorage.token
        try {
            if (jwtDecode(token).exp < (Date.now() / 1000)) {
                localStorage.removeItem('token');
                if (localStorage.hidePriceAlert) {
                    localStorage.clear();
                    localStorage.hidePriceAlert = true;
                } else {
                    localStorage.clear();
                }
                next(action);
                window.location.replace('/?showloginmodal=true&showsession=true');
            }
        } catch (error) {
            console.log(error?.message)
        }
    }
    next(action);
};


const middleWare = [ thunk, checkTokenExpirationMiddleware ];

const composeEnhancers =
    typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        }) : compose;



const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleWare)));


export default store;
