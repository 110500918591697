import React, { useEffect, useState } from "react";
import { ApiHelper } from "../../helpers";
import AdXL from "../ads/AdXL";
import { Carousel, NewsSocialBar } from "../molecules";

export const RegularNewApp = ({ article }) => {
  const [blocks, setBlocks] = useState([]);

  console.log(article);

  const { date, source, tagList } = article;

  const [paragraphs] = useState([
    {
      title: "Medidas",
      img: "https://lh3.googleusercontent.com/proxy/6UQ8bPrxfhuv3499awceyJ9tfob4PMXTdzDOWYSZQKPLglAT_XSmmM4kW40VUH-c7-y32WJ4dfibwUXFSDfbwJblEOjvrNsEiz4M9MhfcBqvhJpiJg4OMnB-5amk4A",
      text: "Fusing edge and exclusivity with obsessive performance, this is the IS F SPORT Black Line Special Edition. Limited to 900 vehicles, this unique edition boasts exclusive features like sleek, black outside mirrors and 18-inch split-five-spoke F SPORT alloy wheels* with a Black Vapo",
    },
    {
      title: "Medidas",
      img: "https://lh3.googleusercontent.com/proxy/6UQ8bPrxfhuv3499awceyJ9tfob4PMXTdzDOWYSZQKPLglAT_XSmmM4kW40VUH-c7-y32WJ4dfibwUXFSDfbwJblEOjvrNsEiz4M9MhfcBqvhJpiJg4OMnB-5amk4A",
      text: "Fusing edge and exclusivity with obsessive performance, this is the IS F SPORT Black Line Special Edition. Limited to 900 vehicles, this unique edition boasts exclusive features like sleek, black outside mirrors and 18-inch split-five-spoke F SPORT alloy wheels* with a Black Vapo",
    },
    {
      title: "Medidas",
      img: "https://lh3.googleusercontent.com/proxy/6UQ8bPrxfhuv3499awceyJ9tfob4PMXTdzDOWYSZQKPLglAT_XSmmM4kW40VUH-c7-y32WJ4dfibwUXFSDfbwJblEOjvrNsEiz4M9MhfcBqvhJpiJg4OMnB-5amk4A",
      text: "Fusing edge and exclusivity with obsessive performance, this is the IS F SPORT Black Line Special Edition. Limited to 900 vehicles, this unique edition boasts exclusive features like sleek, black outside mirrors and 18-inch split-five-spoke F SPORT alloy wheels* with a Black Vapo",
    },
    {
      title: "Medidas",
      img: "https://lh3.googleusercontent.com/proxy/6UQ8bPrxfhuv3499awceyJ9tfob4PMXTdzDOWYSZQKPLglAT_XSmmM4kW40VUH-c7-y32WJ4dfibwUXFSDfbwJblEOjvrNsEiz4M9MhfcBqvhJpiJg4OMnB-5amk4A",
      text: "Fusing edge and exclusivity with obsessive performance, this is the IS F SPORT Black Line Special Edition. Limited to 900 vehicles, this unique edition boasts exclusive features like sleek, black outside mirrors and 18-inch split-five-spoke F SPORT alloy wheels* with a Black Vapo",
    },
  ]);

  const formattedDate = new Date(date);
  const day = formattedDate.getDate();
  const month = formattedDate.getMonth();
  const year = formattedDate.getFullYear();

  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  useEffect(() => {
    blocksSort();
  }, [article]);

  const blocksSort = () => {
    let blocks = article.blockList;
    blocks.sort(function (a, b) {
      return a.id - b.id;
    });
    setBlocks(blocks);
  };

  if (article) {
    return (
      <div id={"release_new"}>
        <div className={"p-3 container"}>
          <div id="news_social_bar">
            <div>
              <p className="mb-0">
                {date && `${day} de ${months[month]} ${year}`} | {source}
              </p>
            </div>
            <div>
              <div
                id="tags_container"
                className={"ml-3 mb-2 "}
                style={{ overflowX: "auto" }}
              >
                {tagList?.map((tag, index) => (
                  <span key={index} className={"mr-3 new_tag text-uppercase"}>
                    #{tag}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <h3 className={"font-weight-bold mt-4"}>{article.epigraph}</h3>
          {blocks[0] &&
            (blocks[0].type === "text" ? (
              <p
                className={`mt-4 mb-2 ${
                  blocks[0].content.includes("iframe") ? "video_wrapper" : ""
                }`}
                dangerouslySetInnerHTML={{ __html: blocks[0].content }}
              />
            ) : (
              <div className="row mb-4">
                {blocks[0].images?.map((image, i) => (
                  <div key={i} className="col-6">
                    <img
                      className={"w-100"}
                      src={`${ApiHelper.adminRoute}/files/${image}`}
                      alt="auto"
                    />
                  </div>
                ))}
              </div>
            ))}

          {article.images && <Carousel images={article.images} />}
          {blocks.map((block, index) => {
            if (index > 0) {
              if (block.type === "text") {
                return (
                  <p
                    key={index}
                    className={`mt-4 mb-2 ${
                      block.content.includes("iframe") ? "video_wrapper" : ""
                    }`}
                    dangerouslySetInnerHTML={{ __html: block.content }}
                  />
                );
              } else if (block.type === "image") {
                return (
                  <div key={index} className="row mb-4">
                    {block.images.map((image, i) => (
                      <div key={i} className="col-6">
                        <img
                          key={index}
                          className={"w-100"}
                          src={`${ApiHelper.adminRoute}/files/${image}`}
                          alt="auto"
                        />
                      </div>
                    ))}
                  </div>
                );
              }
            }
          })}

          <div className="row mb-4">
            <div className="col-12">
              <AdXL subsection="-standard" />
            </div>
          </div>

          {/* <NewsSocialBar
						title={article.title}
						tags={article.tagList}
						date={article.date}
						source={article.source}
						borderTop
					/> */}
        </div>
      </div>
    );
  }
};
