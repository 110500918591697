export const DIGITAL_MAGAZINE = "DIGITAL_MAGAZINE";
export const SEARCH_HISTORY = "SEARCH_HISTORY";
export const FAVORITES = "FAVORITES";
export const VISUAL_DATASHEET = "VISUAL_DATASHEET";
export const RAW_DATASHEET = "RAW_DATASHEET";
export const VEHICLE_MEASUREMENTS = "MEASUREMENTS";
export const COMPARATOR = "COMPARATOR";
export const COMPARATOR_TWO_VEHICLES = "COMPARATOR_TWO_VEHICLES";
export const COMPARATOR_FOUR_VEHICLES = "COMPARATOR_TWO_VEHICLES";
export const PICTURES = "PICTURES";
export const HISTORIC_PRICE = "HISTORIC_PRICE";
export const USED_PRICE = "USED_PRICE";
export const USEFUL_GUIDE = "USEFUL_GUIDE";
export const INFOGRID = "INFOGRID";
export const SHARE_PRICE = "SHARE_PRICE";
export const VISUAL_SEARCH = "VISUAL_SEARCH";
export const CAR_PRICES = "CAR_PRICES";
export const MOTORBIKE_PRICES = "MOTORBIKE_PRICES";
export const BUY_INFOCREDITS = "BUY_INFOCREDITS";
export const UNSUSCRIBE = "UNSUSCRIBE";
export const SUBSCRIBE_BUTTON = 'SUBSCRIBE'
