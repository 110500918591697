import actionsTypes from '../actions/actionTypes'

const initialState = {
    blue: '-',
    bna: '-'
};

export default function(state = initialState, action){
    switch(action.type){
        case actionsTypes.SET_DOLLAR:
            return action.dollar;
        default:
            return state;
    }
};
