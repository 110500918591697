import React from 'react';

export const StockBadge = () => {
    return (
        <div className="stock_badge" align="center">
            <p>EDICIÓN<br/><span>AGOTADA</span></p>
        </div>
    );
};

