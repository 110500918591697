import React, { useState, useEffect } from "react";
import { Pill, Divider } from "../atoms";
import { AppHelper } from "helpers";
import { VEHICLE_MEASUREMENTS } from "static/permissions/sections";
import { useSelector } from "react-redux";

export function Measurements({ vehicle }) {
  const { specificationFeatures } = vehicle;
  const [values, setValues] = useState({});
  const user = useSelector((state) => state.user.user);
  const { length, width, height, weight } = values;


  const type = specificationFeatures?.find(
    (s) => s?.description === "Tipo de vehiculo" || "Tipo de vehículo"
  ).value;

  let file = "";

  switch (type) {
    case "CUP":
      file = require("../../static/img/types/Cupe.jpg");
      break;
    case "Jeep":
      file = require("../../static/img/types/Jeep.jpg");
      break;
    case "PKB":
      file = require("../../static/img/types/PickUp.jpg");
      break;
    case "PKA":
      file = require("../../static/img/types/PickUp.jpg");
      break;
    case "PB4":
      file = require("../../static/img/types/PickUp.jpg");
      break;
    case "WA4":
      file = require("../../static/img/types/Camioneta.jpg");
      break;
    case "WAG":
      file = require("../../static/img/types/Camioneta.jpg");
      break;
    case "RUR":
      file = require("../../static/img/types/Rural.jpg");
      break;
    case "VAN":
      file = require("../../static/img/types/MiniVan.jpg");
      break;
    case "MIV":
      file = require("../../static/img/types/MiniVan.jpg");
      break;
    case "CAB":
      file = require("../../static/img/types/Cabrio.jpg");
      break;
    case "LIV":
      file = require("../../static/img/types/CamionChico.jpg");
      break;
    case "JEE":
      file = require("../../static/img/types/Jeep.jpg");
      break;
    case "PES":
      file = require("../../static/img/types/CamionGrande.jpg");
      break;
    case "SPE":
      file = require("../../static/img/types/CamionGrande.jpg");
      break;
    case "SED":
      if (specificationFeatures?.find((s) => s.description === "Cantidad de puertas").value === "4") {
        file = require("../../static/img/types/Sedan4.jpg");
      } else if (specificationFeatures?.find((s) => s.description === "Cantidad de puertas").value === "5"
      ) {
        file = require("../../static/img/types/Sedan5.jpg");
      } else file = require("../../static/img/types/Sedan3.jpg");
      break;
    case "MBU":
      file = require("../../static/img/types/MiniBus.jpg");
      break;
    case "FUA":
      file = require("../../static/img/types/Furgon.jpg");
      break;
    case "MOT":
      file = require("../../static/img/types/Motorcycle.jpg");
      break;
    case "CUA":
      file = require("../../static/img/types/Atv.jpg");
      break;
    case "ATV":
      file = require("../../static/img/types/Atv.jpg");
      break;
    default:
      file = require("../../static/img/types/Sedan5.jpg");
      break;
  }

  useEffect(() => {

    setValues({
      length: specificationFeatures?.find((s) => s.description === "Largo")?.value,
      width: specificationFeatures?.find((s) => s.description === "Ancho")?.value,
      height: specificationFeatures?.find((s) => s.description === "Alto")?.value,
      weight: specificationFeatures?.find((s) => s.description === "Peso")?.value,
    });
  }, [vehicle]);



  return (
    AppHelper.hasPermission(user, VEHICLE_MEASUREMENTS) && (
      <div id="measurements_container" className="mt-4">
        <Divider />
        <div className="row">
          <div className="col-12">
            <h4 className="font-weight-bolder">Medidas</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-9 p-5">
            <img
              src={file.default ? file.default : file}
              className="specs-img img-fluid"
              alt="Imágen Medidas del Auto"
            />
            <p className="text-right small">
              <i>Imagen ilustrativa</i>
            </p>
          </div>

          <div className="col-12 col-md-3 d-flex align-items-center flex-column">
            <div className="row">
              <Pill attr="Largo" value={length ? length : "-"} unit="M" />
              <Pill attr="Ancho" value={width ? width : "-"} unit="M" />
              <Pill attr="Alto" value={height ? height : "-"} unit="M" />
              <Pill attr="Peso" value={weight ? weight : "-"} unit="KG" />
            </div>
          </div>
        </div>
      </div>
    )
  );
}
