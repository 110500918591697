import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { INFOGRID } from 'static/permissions/sections'
import AdXL from '../components/ads/AdXL'
import { Loader } from '../components/atoms'
import { InfoProHeader, InfoProSearchBar, InfoProTable } from '../components/molecules'
import { ApiHelper, AppHelper } from '../helpers'

export const InfoPro = () => {
	const [brand, setBrand] = useState(null)
	const [model, setModel] = useState(null)
	const [versions, setVersions] = useState([])
	const [quantity, setQuantity] = useState('Todo')
	const [hasPermission, setHasPermission] = useState(false)
	const [currentPage, setCurrentPage] = useState(0)
	const [totalPages, setTotalPages] = useState()
	const vehicleType = useSelector((state) => state.search.vehicle)
	const user = useSelector((state) => state.user.user)

	useEffect(() => {
		setBrand(null)
		setModel(null)
	}, [vehicleType])

	useEffect(() => {
		setModel(null)
	}, [brand])

	useEffect(() => {
		if (model) {
			getVersions()
		}
	}, [model])

	useEffect(() => {
		if (AppHelper.hasPermission(user, INFOGRID)) {
			setHasPermission(true)
		} else {
			window.location.href = '/404'
		}

		window.scrollTo(0, 0)
	}, [])

	const getVersions = async () => {
		try {
			const info = {
				hasUsedPrice: true,
				vehicleType: vehicleType,
				pageSize: 50,
				pageNumber: 1,
			}

			let { data } = await ApiHelper.getPagVersions(brand.id, model.id, info)

			setTotalPages(data?.pagination?.totalPages)
			let batch = data.response.map((v) => v.codia)
			const infoGridData = {
				vehicleType,
				batch,
			}
			const gridRes = await ApiHelper.getInfoGrid(infoGridData)
			if (window.gtag) {
				window.gtag('event', 'busqueda_infogrid_completada', { ...infoGridData })
			}
			setVersions(gridRes)
			setCurrentPage((prevNumber) => prevNumber + 1)
		} catch (error) {
			console.log(error)
		}
	}

	const loadMoreVersions = async () => {
		if (currentPage < totalPages) {
			try {
				const info = {
					pageNumber: currentPage + 1,
					pageSize: 50,
					hasUsedPrice: true,
					vehicleType: vehicleType,
				}

				const { data } = await ApiHelper.getPagVersions(brand.id, model.id, info)
				setCurrentPage((prevNumber) => prevNumber + 1)
				let batch = data.response.map((v) => v.codia)
				const infoGridData = {
					vehicleType,
					batch,
				}
				const gridRes = await ApiHelper.getInfoGrid(infoGridData)
				if (window.gtag) {
					window.gtag('event', 'busqueda_infogrid_completada', { ...infoGridData })
				}
				setVersions((versions) => [...versions, ...gridRes])
			} catch (error) {
				console.log(error)
			}
		}
	}

	return (
		<Loader>
			{hasPermission && (
				<section id='infopro_section' className='page_default_margin'>
					<div className='container'>
						<AdXL subsection='-1' />
						<div className='my-4'>
							<InfoProHeader />
							<div className={'mt-4'}>
								<p>
									Hace click en “ver precios” para visualizar la linea de precios de esa versión -{' '}
									<strong>Valor : 1 Crédito</strong>
								</p>
								<InfoProSearchBar
									versions={versions}
									quantity={quantity}
									setQuantity={setQuantity}
									brand={brand}
									setBrand={setBrand}
									model={model}
									setModel={setModel}
								/>

								<InfoProTable versions={versions} quantity={quantity} loadMoreVersions={loadMoreVersions} />
								{/* {currentPage < totalPages && (
                  <div className="d-flex justify-content-center my-5">
                    <SquareButton
                      onClick={loadMoreVersions}
                      text={"Cargar más"}
                      fullWidth={false}
                    />
                  </div>
                )} */}
							</div>
						</div>
						<AdXL subsection='-2' />
					</div>
				</section>
			)}
		</Loader>
	)
}
