import React, { useState } from "react";
import { SplitButton } from "../atoms";
import { ContactModal } from "./ContactModal";

export const HistoricPriceSearchHeader = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="row ">
      <div className="col-xl-5">
        <h1 className="font-weight-bold display-4 m-2">
          Búsqueda por precio histórico
        </h1>
      </div>

      <div className="col my-auto text-center">
        <div className="m-2">
          <SplitButton
            selected="Auto"
            opt1="Auto"
            onClickOpt1="FilterByCars"
            opt2="Moto"
            onClickOpt2="FilterByMotos"
          />
        </div>
      </div>
      <div className="col my-auto ">
        <div className="m-2">
          <div className="d-flex float-right">
            <p>
              ¿No encontrás lo que buscás?{" "}
              <span
                onClick={() => setShowModal(true)}
                className="font-weight-bold link"
              >
                Contactanos.
              </span>{" "}
            </p>
          </div>
        </div>
      </div>
      <ContactModal show={showModal} hide={() => setShowModal(false)} />
    </div>
  );
};
