import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { SquareButton } from "../atoms";
import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase";
import actions from "../../store/actions";

export const ResetPassModal = () => {
  const [ user, setUser ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ passwordConfirm, setPasswordConfirm ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const [ firstName, setFirstName ] = useState("");
  const [ lastName, setLastName ] = useState("");
  const [ notifications, setNotifications ] = useState(false);
  const [ accept, setAccept ] = useState(false);
  const [ error, setError ] = useState(false);
  const dispatch = useDispatch();
  const [ success, setSuccess ] = useState(false);
  const show = useSelector((state) => state.app.showReset);

  useEffect(() => {
    document.addEventListener("keydown", handleEnter);
    return () => document.removeEventListener("keydown", handleEnter);
  }, [ show, user, password ]);

  const hide = () => dispatch(actions.app.setShowReset(false));

  const reset = async (e) => {
    e.preventDefault();
    setError(false);
    if (!loading) {
      setLoading(true);
      if (email) {
        try {
          await firebase.auth().sendPasswordResetEmail(email);
          setSuccess(
            "Su solicitud ha sido enviada con éxito. Por favor, revise su casilla de correo."
          );
        } catch (error) {
          switch (error.message) {
            case "There is no user record corresponding to this identifier. The user may have been deleted.":
              setError(
                "La dirección de correo ingresada no corresponde a un usuario registrado"
              );
              break;
            case "The email address is badly formatted.":
              setError("El formato del mail es incorrecto");
              break;
            default:
              setError(error.message);
          }
        } finally {
          setLoading(false);
        }
      } else if (!email) {
        setError("Debes indicar una cuenta de correo");
        setLoading(false);
        return;
      }
    }
  };

  const handleEnter = (key) => {
    if (user && password && key.code === "Enter") {
      reset();
    }
  };

  const showLogin = () => {
    dispatch(actions.app.setShowLogin(true));
    dispatch(actions.app.setShowRegister(false));
    dispatch(actions.app.setShowReset(false));
  };

  return (
    <Modal
      show={show}
      onHide={hide}
      size={"sm"}
      className={"default_modal"}
      id={"login_modal"}
    >
      <Modal.Body className={"p-0"}>
        <div className="row">
          <div className="col-12 p-5 d-flex flex-column align-items-center">
            <h5 className="font-weight-bold mb-4 text-center">
              Recuperá tu contraseña
            </h5>
            {/* <FacebookButton register={true} /> */}
            <p className={"mt-2 crossed"}>
              <span>Ingresá el correo de la cuenta</span>
            </p>
            <form onSubmit={reset}>
              <input
                name={"email"}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                className={"mt-2 custom_input default-shadow p-2 w-100 mb-3"}
                placeholder={"Email"}
                type={"text"}
              />

              {!success && (
                <SquareButton loading={loading} type="submit" text={"Enviar"} />
              )}
            </form>
            {error && (
              <p className={"text-center color_red mt-2"}>{error}</p>
            )}
            {success && (
              <p className={"text-center text-success mt-2"}>{success}</p>
            )}
            <p className={"mt-3"}>
              ¿Ya tenés cuenta?{" "}
              <span
                onClick={showLogin}
                className={"text_green font-weight-bold link"}
              >
                Ingresá
              </span>
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
