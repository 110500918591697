import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const ProfileImage = ({ img, userImg }) => {
	return (
		<div id={'profile_image'} className={'default_shadow '}>
			{img || userImg ? (
				<img className={'profile_image_preview'} src={img ? img.imagePreviewUrl : userImg} />
			) : (
				<FontAwesomeIcon icon={faUser} color={'white'} size={'2x'} />
			)}
		</div>
	)
}
