import React, { useState, useEffect } from "react";
import Subscription from "../home/subscription/Subscription";
import { Logo } from "../atoms";

import particularesImg from "../../static/img/particulares.jpg";
import profesionalesImg from "../../static/img/profesionales.jpg";
import empresasImg from "../../static/img/empresas.jpg";

export default ({ pro }) => {
  const [subscriptions] = useState([
    {
      id: 1,
      name: "Particulares",
      image: particularesImg,
      bullets: [
        "Descubrí toda la información de tu próximo auto o moto.",
        "Guía de precios con fotos de Usados y 0Km",
        "Compara, decidí y gana tiempo",
        "Fichas Técnicas confiables y fáciles de interpretar",
      ],
      link: "/particulares",
    },
    {
      id: 2,
      name: "Profesionales",
      image: profesionalesImg,
      bullets: [
        "Guía de precios en formato grilla de Usados y 0Km - InfoGRID",
        "Precios Históricos y Usado del Año",
        "Historial de Consultas",
        "Compara hasta 4 vehículos simultáneamente.",
      ],
      link: "/profesionales",
    },
    {
      id: 3,
      name: "Empresas",
      image: empresasImg,
      bullets: [
        "Consulta Masiva de datos en línea precios y fichas técnicas",
        "Homologación de Bases de Datos - Consultoría",
        "Publicidad, Prensa- Comunicación y Lanzamientos",
        "Soluciones a medida",
      ],
      link: "/empresas",
    },
  ]);
  return (
    <section id="subscriptions_section" className={"background_gray p-5"}>
      <div className="container py-md-5 mb-md-3 mb-sm-2 ">
        <h2 className="font-weight-bold text-center">
          Conocé nuestras soluciones.
        </h2>
        <div className="row px-md-5 justify-content-center">
          {subscriptions.map((subscription) => {
            return (
              <div className='col-12 col-lg-4'>
                <Subscription
                  key={subscription.id}
                  subscription={subscription}
                  pro={pro}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
