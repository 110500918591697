import React, { useState, useEffect } from "react";
import { SquareButton } from "../components/atoms";
import { ApiHelper } from "../helpers";
import OtpInput from "react-otp-input";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router";
import { auth } from "../helpers/Firebase";
import actions from "../store/actions";
import { useDispatch } from "react-redux";
import { PasswordInput } from "../components/atoms";
import { useIntl } from "react-intl";

export const Otp = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: null,
    otpNotValid: null,
  });
  const [success, setSuccess] = useState(null);
  const [otp, setOtp] = useState();
  const [codeSent, setCodeSent] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    const mailUrl = window.location.href.split("?")[1];
    setEmail(mailUrl);

    if (localStorage.token) {
      window.location.href = "/404";
    }
  }, []);

  const validate = () => {
    let errors = {};

    if (!password) {
      errors.password = "Ingrese la contraseña";
    }
    if (!otp) {
      errors.otp = "Ingrese su código de activación";
    } else if (otp.length < 4) {
      errors.otp = "Ingrese los 4 caracteres del OTP";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const sendCode = async () => {
    try {
      setIsSendingCode(true);
      if (window.grecaptcha) {
        window.grecaptcha.ready(function () {
          window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
              action: "submit",
            })
            .then(async function (token) {
              localStorage.reCaptcha = token;
              await ApiHelper.sendOTP(email);
              setCodeSent(true);
            });
        });
      }
    } catch (error) {
      console.log(error);
      setErrors({ otpSend: "Hubo un error al enviar el código" });
    } finally {
      setIsSendingCode(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = new FormData();
    const valid = validate();
    if (valid) {
      try {
        setLoading(true);
        await auth.signInWithEmailAndPassword(email, password);
        const token = await auth.currentUser.getIdToken(true);
        data.append("grant_type", "firebase");
        data.append("firebase_token_id", token);
        const dataOtp = {
          otp: otp,
          email: email,
        };

        if (window.grecaptcha) {
          window.grecaptcha.ready(function () {
            window.grecaptcha
              .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
                action: "submit",
              })
              .then(async function (token) {
                localStorage.reCaptcha = token;
                try {
                  await ApiHelper.post("auth", "/otp", dataOtp);

                  setSuccess("Cuenta activada con éxito.");
                  try {
                    const res = await ApiHelper.post(
                      "auth",
                      "/oauth/token",
                      data
                    );

                    localStorage.token = res.data.access_token;
                    localStorage.user = JSON.stringify({ email: email });

                    const user = await ApiHelper.get("admin", "/user");
                    dispatch(actions.user.setUser(user.data));
                  } catch (error) {
                    console.log(error);
                    if (error.status === 412) {
                      window.location.href = window.location.origin;
                    }
                  }
                } catch (err) {
                  console.log(err);
                  setErrors({
                    otpNotValid:
                      "El código ingresado no es válido, por favor intentalo nuevamente",
                  });
                }
              });
          });
        }
      } catch (err) {
        console.log(err);
        setErrors({
          passwordNotValid:
            "La contraseña ingresada es incorrecta, por favor ingresela nuevamente .",
        });
        setPassword("");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="page_default_margin">
      <div className={"container mb-5"}>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <div className="card mt-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 p-5 d-flex flex-column align-items-center">
                    <h5 className="font-weight-bold mb-2 text-center">
                      Bienvenido a InfoAuto
                    </h5>
                    <p>
                      Aun tenemos que validar tu correo. Ingresa la contraseña y
                      el código que te enviamos a tu casilla de correo para
                      poder activar tu cuenta.
                    </p>
                    <form onSubmit={handleSubmit} className="w-100">
                      <label>Ingrese su contraseña</label>
                      <PasswordInput
                        name={"password"}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        className={"custom_input default-shadow p-2 w-100"}
                        placeholder={intl.formatMessage({
                          id: "login.modal.input.password",
                        })}
                      />

                      {errors.password && (
                        <div className="d-flex justify-content-center">
                          {" "}
                          <span className="color_red ">
                            {errors.password}
                          </span>{" "}
                        </div>
                      )}
                      {errors.passwordNotValid && (
                        <div className="d-flex justify-content-center">
                          {" "}
                          <span className="color_red ">
                            {errors.passwordNotValid}
                          </span>{" "}
                        </div>
                      )}

                      <p className={"mt-4 crossed"}>
                        <span>Ingresá el código recibido</span>
                      </p>
                      <div className="p-2 mb-3 d-flex justify-content-center">
                        <OtpInput
                          value={otp}
                          onChange={(e) => setOtp(e)}
                          numInputs={4}
                          separator={<span>-</span>}
                          isInputNum={true}
                          inputStyle={{
                            width: "2rem",
                            height: "2rem",
                            margin: "0 10px",
                            fontSize: "14px",
                            padding: "5px",
                            borderRadius: 4,
                            border: "1px solid rgba(0,0,0,0.3)",
                          }}
                          focusStyle={{
                            border: "2px solid #5FFFE8",
                          }}
                        />
                      </div>

                      {errors.otp && (
                        <div className="d-flex justify-content-center">
                          <p className="text-center color_red ">{errors.otp}</p>
                        </div>
                      )}

                      {!success && (
                        <div className="d-flex flex-column align-items-center">
                          <SquareButton
                            loading={loading}
                            type="submit"
                            text="Activar"
                            fullWidth={false}
                            className="px-3"
                          />

                          <div className="row align-items-center justify-content-center">
                            <p className="text-center mt-3">
                              ¿No llegó el código?{" "}
                            </p>
                            <span
                              onClick={sendCode}
                              className={"text_green font-weight-bold link"}
                            >
                              Reenviar
                            </span>
                          </div>

                          {isSendingCode && (
                            <div className="d-flex justify-content-center align-items-center">
                              <Loader
                                type="ThreeDots"
                                color="#5FFFE8"
                                height={20}
                                width={20}
                              />
                            </div>
                          )}
                          {errors.otpNotValid && (
                            <div className="d-flex justify-content-center">
                              {" "}
                              <p>{errors.otpNotValid}</p>{" "}
                            </div>
                          )}

                          {codeSent && !errors.otp && (
                            <div className="d-flex justify-content-center align-items-center">
                              <p className="code_sent text-center">
                                Te hemos enviado un nuevo código a tu casilla de
                                correo
                              </p>
                            </div>
                          )}
                        </div>
                      )}

                      {success ? (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <p className="text-center color_green mt-2">
                            {success}
                          </p>
                          <Loader
                            type="ThreeDots"
                            color="#10ffdc"
                            height={20}
                            width={20}
                          />
                        </div>
                      ) : null}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
