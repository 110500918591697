import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VISUAL_SEARCH } from "static/permissions/sections";
import AdXL from "../components/ads/AdXL";
import { SectionTitleWithSelector } from "../components/molecules";
import {
  VisualSelector
} from "../components/organisms";
import { ApiHelper, AppHelper } from "../helpers";
import actions from "../store/actions";

export const VisualSearch = () => {
  const vehicle = useSelector((state) => state.search.vehicle);
  const [ hasPermission, setHasPermission ] = useState(false);
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (AppHelper.hasPermission(user, VISUAL_SEARCH)) {
      setHasPermission(true);
    } else {
      window.location.href = "/404";
    }
    getVehicleTypes();
  }, [ vehicle ]);

  const getVehicleTypes = async () => {
    const data = {
      vehicleType: vehicle,
    };

    const res = await ApiHelper.post(
      "preciosa",
      "/features/vehicleTypes",
      data
    );

    if (res.data.response.length) {
      dispatch(
        actions.vehicleTypes.setVehicleTypes(res.data.response[ 0 ].choices)
      );
    }
  };

  return (
    hasPermission && (
      <div id="visual_search_page" style={{ marginTop: "80px" }}>
        <p className="seo">Buscador ágil y sencillo para acceder al precio de todos los modelos y versiones del mercado. </p>
        <div className={"container"}>
          <div className="container-fluid p-xl-5">
            <div className="mb-5">
              <AdXL subsection="" />
            </div>
            <SectionTitleWithSelector title={"Búsqueda por filtro visual"} />
            <VisualSelector />
          </div>
        </div>
      </div>
    )
  );
};
